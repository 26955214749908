$(document).ready(function() {
  $('#fgtpass-link').on('click', function() {
    $('#fgtPassForm').show();
    $('#loginForm').hide();
  });
});

  // $('span#fgtpass-link').on('click', function() {
  //   $(this).val('login');
  //   $('.login-pass').toggle();
  // });