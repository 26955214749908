/**
 * Global Factory
 *
 * @param  {[type]} $http  [description]
 * @param  {[type]} ENV){               var urlBase [description]
 * @return {[type]}        [description]
 */
app.factory('GlobalFactory', ['$http', 'ENV', function ($http, ENV) {
        var dirBase = ENV.APP_DIR;
        var urlBase = ENV.API_URL;
        var GlobalFactory = {};

        // Get Suburb Address
        GlobalFactory.getSuburbs = function () {
            return $http.get(dirBase + 'addressListing.json');
        };

        // Get Detailed Suburb Address
        GlobalFactory.getDetailedSuburbs = function () {
            return $http.get(dirBase + 'addressListingAll.json');
        };

        // Send data to Log table in database
        GlobalFactory.log = function (data) {
            return $http.post(urlBase + 'appLog', data);
        };

        return GlobalFactory;
    }]);

app.factory('WalletFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var WalletFactory = {};

        // Get wallet for user
        WalletFactory.getWalletForAccount = function (input) {
            return $http.post(urlBase + 'profile/getWalletForAccount', input);
        };

        // Get transactions for wallet
        WalletFactory.getTransactions = function (id, page) {
            return $http.get(urlBase + 'profile/getTransactions/' + id + '?page=' + page);
        };

        // Get transaction for wallet
        WalletFactory.getTransaction = function (input) {
            return $http.post(urlBase + 'profile/getTransaction', input);
        };

        // Get pending deposit transaction for wallet
        WalletFactory.getPendingDepositTransactions = function (input) {
            return $http.post(urlBase + 'profile/getPendingDepositTransactions', input);
        };

        // Add transaction for wallet
        WalletFactory.addTransaction = function (input) {
            return $http.post(urlBase + 'profile/addTransaction', input);
        };

        // change debtors status
        WalletFactory.updateDebtorStatus = function (input) {
            return $http.post(urlBase + 'profile/updateDebtorStatus', input);
        };

        return WalletFactory;
    }]);

app.factory('PeachFactory', ['$http', 'ENV', function ($http, ENV) {
        var urlBase = ENV.API_URL;
        var PeachFactory = {};

        // checkout
        PeachFactory.checkout = function (data) {
            return $http.post(urlBase + 'business/checkout2', data);
        };

        PeachFactory.getStatusWallet = function (data) {
            return $http.post(urlBase + 'business/getStatusWallet2', data);
        };

        PeachFactory.getStatus = function (data) {
            return $http.post(urlBase + 'business/getStatus2', data);
        };

        PeachFactory.getCard = function(data){
            return $http.post(urlBase + 'business/getCard2', data);
        };

        return PeachFactory;
    }]);


app.factory('AmrodFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var AmrodFactory = {};

        // Start customer registration
        AmrodFactory.startCustomerRegistration = function (input) {
            return $http.post(urlBase + 'services/amrod/startCustomerRegistration', input);
        };

        // Complete customer registration
        AmrodFactory.completeCustomerRegistration = function (input) {
            return $http.post(urlBase + 'services/amrod/completeCustomerRegistration', input);
        };

        // Create or update rush account profile token
        AmrodFactory.linkAccount = function (input) {
            return $http.post(urlBase + 'services/amrod/linkAccount', input);
        };

        // check if username is registered
        AmrodFactory.checkRegistrationStatus = function (input) {
            return $http.post(urlBase + 'services/amrod/checkRegistrationStatus', input);
        };

        AmrodFactory.isAmrodUser = function (input) {
            return $http.post(urlBase + 'services/amrod/isAmrodUser', input);
        };

        AmrodFactory.hasAmrodProfileToken = function (input) {
            return $http.post(urlBase + 'services/amrod/hasAmrodProfileToken', input);
        };

        // save StartCustomerRegistrationResponse for use when user activates his account
        AmrodFactory.saveStartCusRegResponse = function (input) {
            return $http.post(urlBase + 'services/amrod/saveStartCusRegResponse', input);
        };
        AmrodFactory.getStartCusRegResponse = function (input) {
            return $http.post(urlBase + 'services/amrod/getStartCusRegResponse', input);
        };

        AmrodFactory.queryOrder = function (input) {
            return $http.post(urlBase + 'services/amrod/queryOrder', input);
        };

//  // Register new user
//  AmrodFactory.registerUser = function (input) {
//    return $http.post(urlBase + 'business/register', input);
//  };
        AmrodFactory.getActiveOrders = function (input) {
            return $http.post(urlBase + 'services/amrod/getActiveAccountOrders', input);
        }
// Retrieve profile token for getActiveAccountOrders
        AmrodFactory.getProfileToken = function (input) {
            return $http.post(urlBase + 'services/amrod/getProfileToken', input);
        }
        return AmrodFactory;
    }]);
app.factory('TradeOnlyGiftFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var TradeOnlyGiftFactory = {};

        // Start customer registration
        TradeOnlyGiftFactory.startCustomerRegistration = function (input) {
            return $http.post(urlBase + 'services/togsa/startCustomerRegistration', input);
        };

        // Complete customer registration
        TradeOnlyGiftFactory.completeCustomerRegistration = function (input) {
            return $http.post(urlBase + 'services/togsa/completeCustomerRegistration', input);
        };

        // Create or update rush account profile token
        TradeOnlyGiftFactory.linkAccount = function (input) {
            return $http.post(urlBase + 'services/togsa/linkAccount', input);
        };

        // check if username is registered
        TradeOnlyGiftFactory.checkRegistrationStatus = function (input) {
            return $http.post(urlBase + 'services/togsa/checkRegistrationStatus', input);
        };

        TradeOnlyGiftFactory.isTradeOnlyGiftUser = function (input) {
            return $http.post(urlBase + 'services/togsa/isTradeOnlyGiftUser', input);
        };

        TradeOnlyGiftFactory.hasTOGProfileToken = function (input) {
            return $http.post(urlBase + 'services/togsa/hasTOGProfileToken', input);
        };

        // save StartCustomerRegistrationResponse for use when user activates his account
        TradeOnlyGiftFactory.saveStartCusRegResponse = function (input) {
            return $http.post(urlBase + 'services/togsa/saveStartCusRegResponse', input);
        };
        TradeOnlyGiftFactory.getStartCusRegResponse = function (input) {
            return $http.post(urlBase + 'services/togsa/getStartCusRegResponse', input);
        };

        TradeOnlyGiftFactory.queryOrder = function (input) {
            return $http.post(urlBase + 'services/togsa/queryOrder', input);
        };

//  // Register new user
//  TradeOnlyGiftFactory.registerUser = function (input) {
//    return $http.post(urlBase + 'business/register', input);
//  };
        TradeOnlyGiftFactory.getActiveOrders = function (input) {
            return $http.post(urlBase + 'services/togsa/getActiveAccountOrders', input);
        }
// Retrieve profile token for getActiveAccountOrders
        TradeOnlyGiftFactory.getProfileToken = function (input) {
            return $http.post(urlBase + 'services/togsa/getProfileToken', input);
        }
        return TradeOnlyGiftFactory;
    }]);

/*
 * Account Factory
 * Factory for getting account info, updating it and login.
 *
 * @param  $http
 * @return object
 */
app.factory('AccountFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var AccountFactory = {};

        // Post login form
        AccountFactory.postLogin = function (input) {

            return $http.post(urlBase + 'login', input);
        };

        // Logout
        AccountFactory.logout = function () {
            return $http.get(urlBase + 'profile/logout');
        };

        // Register new user
        AccountFactory.registerUser = function (input) {
            return $http.post(urlBase + 'registerUser', input);
        };

        // Activate new user account
        AccountFactory.activateAccount = function (input) {
            return $http.post(urlBase + 'activateAccount', input);
        };

        // Reset Password - forgot Password call
        AccountFactory.resetPassword = function (input) {
            return $http.post(urlBase + 'resetPassword', input);
        };

        // Reset Password - forgot Password call
        AccountFactory.newPassword = function (input) {
            return $http.post(urlBase + 'newPassword', input);
        };

        // Get account info
        AccountFactory.getProfile = function (id) {
            return $http.get(urlBase + 'profile/getAccountByUserId/' + id);
        };

        // Update account info
        AccountFactory.updateProfile = function (id, data) {
            return $http.put(urlBase + 'profile/updateAccount/' + id, data);
        };

        // Get order history
        AccountFactory.getOrderHistory = function (id, page) {
            return $http.get(urlBase + 'profile/getOrdersByAccountId/' + id + '?page=' + page);
        };

        // Get grouped order history
        AccountFactory.getGroupedOrderHistory = function (id, page) {
            return $http.get(urlBase + 'profile/getOrderGroupsByAccountId/' + id + '?page=' + page);
        };

        // Get specific order
        AccountFactory.getOrder = function (id, order) {
            return $http.get(urlBase + 'profile/account/' + id + '/orders/' + order);
        };

        // Get account's services
        AccountFactory.getServices = function (id) {
            return $http.get(urlBase + 'profile/getServices/' + id);
        };

        return AccountFactory;
    }]);

/*
 * Business Factory
 * Factory for managing business info, updating it and login.
 *
 * @param  $http
 * @return object
 */
app.factory('BusinessFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var BusinessFactory = {};

        // Post login form
        BusinessFactory.postLogin = function (input) {
            return $http.post(urlBase + 'business/login', input);
        };

        // Logout
        BusinessFactory.logout = function () {
            return $http.get(urlBase + 'business/logout');
        };

        // Register new user
        BusinessFactory.registerUser = function (input) {
            return $http.post(urlBase + 'business/register', input);
        };

        // Activate new user account
        BusinessFactory.activateAccount = function (input) {
            return $http.post(urlBase + 'activateAccount', input);
        };

        // Reset Password - forgot Password call
        BusinessFactory.resetPassword = function (input) {
            return $http.post(urlBase + 'business/forgotpassword', input);
        };

        // Reset Password - Reset Password call
        // BusinessFactory.resetPassword = function (input) {
        //   return $http.post(urlBase + 'resetPassword', input);
        // };

        // Reset Password - New Password call
        BusinessFactory.newPassword = function (input) {
            return $http.post(urlBase + 'business/newPassword', input);
        };

        // Get account info
        BusinessFactory.getProfile = function (id) {
            return $http.get(urlBase + 'profile/getAccountByUserId/' + id);
        };

        // Update account info
        BusinessFactory.updateProfile = function (id, data) {
            return $http.put(urlBase + 'profile/updateAccount/' + id, data);
        };

        // Get order history
        BusinessFactory.getOrderHistory = function (id, page) {
            return $http.get(urlBase + 'profile/getOrdersByAccountId/' + id + '?page=' + page);
        };

        BusinessFactory.getGroupedOrderHistory = function (id, page) {
            return $http.get(urlBase + 'profile/getOrderGroupsByAccountId/' + id + '?page=' + page);
        };

        // Get specific order
        BusinessFactory.getOrder = function (id, order) {
            return $http.get(urlBase + 'profile/account/' + id + '/orders/' + order);
        };

        // Complete Order
        BusinessFactory.completeOrder = function (input) {
            return $http.post(urlBase + 'completeOrder', input);
        };

        // Complete Basket Order
        BusinessFactory.completeBasketOrders = function (input) {
            return $http.post(urlBase + 'completeBasketOrders', input);
        };

        BusinessFactory.setPayU = function (input) {
            return $http.post(urlBase + 'business/processPayU', input);
        }

        BusinessFactory.refillWallet = function (input) {
            return $http.post(urlBase + 'business/refillWallet', input);
        }

        BusinessFactory.getRefillWalletPayUStatus = function (input) {
            return $http.post(urlBase + 'business/getRefillWalletStatus', input);
        }

        BusinessFactory.retrievePayUPaymentStatus = function (input) {
            return $http.post(urlBase + 'getPayUPaymentStatus', input);
        };
        BusinessFactory.retrievePayUDebtPaymentStatus = function (input) {
            return $http.post(urlBase + 'getPayUDebtPaymentStatus', input);
        };

        BusinessFactory.payUCancelOrder = function (input) {
            return $http.post(urlBase + 'cancelOrder', input);
        };

        BusinessFactory.isBasketOrderConfirmed = function (input) {
            return $http.post(urlBase + 'isBasketOrderConfirmed', input);
        };
        BusinessFactory.isPaymentConfirmed = function (input) {
            return $http.post(urlBase + 'isPaymentConfirmed', input);
        };

        BusinessFactory.notifyRushBasketSupport = function (input) {
            return $http.post(urlBase + 'notifyRushBasketSupport', input);
        };

        BusinessFactory.sendDebtsRecoveryEmail = function (id) {
            return $http.get(urlBase + 'sendDebtsRecoveryEmail/' + id);
        }

        return BusinessFactory;
    }]);

/*
 * Address Book Factory
 * Factory for address book
 *
 * @param  $http
 * @return object
 */
app.factory('AddressBookFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var AddressBookFactory = {};

        // Create new address record
        AddressBookFactory.createAddressBookRecord = function (input) {
            return $http.post(urlBase + 'profile/createAddressBookRecord', input);
        };

        // Update address book record info
        AddressBookFactory.updateAddressBookRecord = function (id, data) {
            return $http.put(urlBase + 'profile/updateAddressBookRecord/' + id, data);
        };

        // Delete address book record info
        AddressBookFactory.deleteAddressBookRecord = function (id) {
            return $http.put(urlBase + 'profile/deleteAddressBookRecord/' + id);
        };

        // Set address as default
        AddressBookFactory.setAddressBookRecordAsDefault = function (input) {
            return $http.post(urlBase + 'profile/setAddressBookRecordAsDefault', input);
        };

        // Set address as default
        AddressBookFactory.getDefaultRecipientAddress = function (user_id) {
            return $http.get(urlBase + 'profile/getDefaultRecipientAddress/' + user_id);
        };
        // Get sender degault address  as default
        AddressBookFactory.getDefaultSenderAddress = function (user_id) {
            return $http.get(urlBase + 'profile/getDefaultSenderAddress/' + user_id);
        };
        // Get all address records for user
        AddressBookFactory.getAddressBookByUserId = function (user_id) {
            return $http.get(urlBase + 'profile/getAddressBookByUserId/' + user_id);
        };

        return AddressBookFactory;
    }]);

/**
 * Order Factory
 *
 * @param  $http
 * @param  ENV
 * @return object
 */
app.factory('OrderFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var OrderFactory = {};

        // Create order
        OrderFactory.createOrder = function (input) {
            return $http.post(urlBase + 'profile/createOrder', input);
        };

        //Create Multiple Order
        OrderFactory.createMultipleOrders = function (input) {
            return $http.post(urlBase + 'profile/createMultipleOrders', input);
        };

        // Update order
        OrderFactory.updateOrder = function (id, input) {
            return $http.post(urlBase + 'profile/updateOrder/' + id, input);
        };

        // Soft delete order
        OrderFactory.softDelete = function (id) {
            return $http.put(urlBase + 'profile/softDelete/' + id);
        };

        // Soft delete order group
        OrderFactory.softDeleteGroup = function (groupRef) {
            return $http.put(urlBase + 'profile/softDeleteGroup/' + groupRef);
        };

        // View order
        OrderFactory.viewOrder = function (id) {
            return $http.get(urlBase + 'profile/viewOrder/' + id);
        };

        OrderFactory.updateOrderRate = function (input) {
            return $http.post(urlBase + 'profile/updateOrderRate', input);
        };

        OrderFactory.getOrderRate = function (input) {
            return $http.post(urlBase + 'profile/getOrderRate', input);
        };

        OrderFactory.updateOrderFeedback = function (input) {
            return $http.post(urlBase + 'profile/updateOrderFeedback', input);
        };

        OrderFactory.getInvoiceNumber = function (input) {
            return $http.post(urlBase + 'profile/getInvoiceNumber', input)
        }

        return OrderFactory;
    }]);

/**
 * [description]
 * @param  {[type]} $http  [description]
 * @param  {[type]} ENV){                 var urlBase [description]
 * @return {[type]}        [description]
 */
app.factory('CourierFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var CourierFactory = {};

        CourierFactory.findParcelCourierPrices = function (input) {
            return $http.post(urlBase + 'findParcelCourierPrices', input);
        };

        CourierFactory.findAmrodParcelCourierPrices = function (input) {
            return $http.post(urlBase + 'findAmrodParcelCourierPrices', input);
        };

        CourierFactory.findTOGSAParcelCourierPrices = function (input) {
            return $http.post(urlBase + 'findTOGSAParcelCourierPrices', input);
        };

        CourierFactory.findDocumentCourierPrices = function (input) {
            return $http.post(urlBase + 'findDocumentCourierPrices', input);
        };

        CourierFactory.completeOrder = function (input) {
            return $http.post(urlBase + 'profile/completeOrder', input);
        };

        return CourierFactory;
    }]);

/**
 * Contact Factory
 * Any contact from user to client
 *
 * @param  $http
 * @return object
 */
app.factory('ContactFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var ContactFactory = {};

        // Send Contact Email
        ContactFactory.sendContactEmail = function (input) {
            return $http.post(urlBase + 'sendContactEmail', input);
        };

        return ContactFactory;
    }]);


/**
 * VOUCHER Factory
 * Any payment with voucher
 *
 * @param  $http
 * @return object
 */
app.factory('VoucherFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var VoucherFactory = {};

        // Send Contact Email
        VoucherFactory.checkedDebtorStatus = function (input) {
            return $http.post(urlBase + 'profile/checkedDebtorStatus', input);
        };

        VoucherFactory.uncheckedDebtorStatus = function (input) {
            return $http.post(urlBase + 'profile/uncheckedDebtorStatus', input);
        };

        return VoucherFactory;
    }]);


/**
 * Blog Factory
 * Factory for getting articles.
 *
 * @param  $http
 * @return object
 */
app.factory('ArticlesFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var ArticlesFactory = {};

        // Get all Active Articles
        ArticlesFactory.getArticles = function () {
            return $http.get(urlBase + 'getArticles');
        };

        // Get a specific article
        ArticlesFactory.getArticle = function (id) {
            return $http.get(urlBase + 'getArticles/' + id);
        };

        return ArticlesFactory;
    }]);

/**
 * CustomMessages Factory
 *
 * @param  $http
 * @param  ENV
 * @return object
 */
app.factory('CustomMessagesFactory', ['$http', 'ENV', function ($http, ENV) {
        var urlBase = ENV.API_URL;
        var CustomMessagesFactory = {};

        // Get all partners
        CustomMessagesFactory.getMessages = function () {
            return $http.get(urlBase + 'getMessages');
        };

        return  CustomMessagesFactory;
    }]);

/**
 * Testimonies Factory
 * Factory for getting Testimonies.
 *
 * @param  $http
 * @return object
 */
app.factory('TestimoniesFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var TestimoniesFactory = {};

        // Get all testimonies
        TestimoniesFactory.getTestimonies = function () {
            return $http.get(urlBase + 'getTestimonies');
        };

        return TestimoniesFactory;
    }]);

/**
 * Factory
 * @param  $http
 * @return object
 */
app.factory('PartnersFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var PartnersFactory = {};
        //console.log(urlBase);
        // Get all partners
        PartnersFactory.getPartners = function () {


            return $http.get(urlBase + 'getPartners');
        };

        return PartnersFactory;
    }]);

/**
 * Tracking Factory
 * @param  $http
 * @return object
 */
app.factory('TrackingFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var TrackingFactory = {};

        // Get all partners
        TrackingFactory.getTracking = function (data) {
            return $http.post(urlBase + 'getTracking', data);
        };

        return TrackingFactory;
    }]);

    /**
     * Promo code Factory
     * @param  $http
     * @return object
     */
    app.factory('PromoCodeFactory', ['$http', 'ENV', function ($http, ENV) {

            var urlBase = ENV.API_URL;
            var PromoCodeFactory = {};

            // Get all partners
            PromoCodeFactory.getPromoCode = function (data) {
                return $http.post(urlBase + 'getPromoCode', data);
            };

            return PromoCodeFactory;
        }]);


    /**
     * Promo code Factory
     * @param  $http
     * @return object
     */
    app.factory('MarginGroupFactory', ['$http', 'ENV', function ($http, ENV) {

            var urlBase = ENV.API_URL;
            var MarginGroupFactory = {};

            MarginGroupFactory.getMarginGroup = function (id) {
                return $http.get(urlBase + 'getMarginGroupById/' + id);
            };

            MarginGroupFactory.updateToDefaultMarginGroup = function (id) {
                return $http.get(urlBase + 'updateToDefaultMarginGroup/' + id);
            };

            MarginGroupFactory.updateToTogsaMarginGroup = function (id) {
                return $http.get(urlBase + 'updateToTogsaMarginGroup/' + id);
            };

            MarginGroupFactory.updateToAmrodMarginGroup = function (id) {
                return $http.get(urlBase + 'updateToAmrodMarginGroup/' + id);
            };

            return MarginGroupFactory;
        }]);

/**
 * Faqs Factory
 *
 * @param  $http
 * @param  ENV
 * @return object
 */
app.factory('FAQsFactory', ['$http', 'ENV', function ($http, ENV) {
        var urlBase = ENV.API_URL;
        var FAQsFactory = {};

        // Get all partners
        FAQsFactory.getFaqs = function () {
            return $http.get(urlBase + 'getFaqs');
        };

        return FAQsFactory;
    }]);

app.factory('PublicHolidayFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var PublicHolidayFactory = {};

        PublicHolidayFactory.getPublicHolidays = function () {
            return $http.get(urlBase + 'getPublicHolidays');
        };

      return PublicHolidayFactory;
  }]);

/**
 * API Factory
 *
 * @param  $http
 * @param  $cookies
 * @return void
 */
app.factory('ApiFactory', ['$http', '$cookies', function ($http, $cookies) {

        return {
            init: function (token) {
                $http.defaults.headers.common['X-Auth-Token'] = token || $cookies.token;
            }
        };
    }]);


app.factory('quickQuotes', function () {
    var savedData = {};
    function set(data) {
        savedData = data;
    }
    function get() {
        return savedData;
    }

    return {
        set: set,
        get: get
    };

});

app.factory('pickup', function () {
    var savedData = {};
    function set(data) {
        savedData = data;
    }
    function get() {
        return savedData;
    }

    return {
        set: set,
        get: get
    };

});

app.factory('dropoff', function () {
    var savedData = {};
    function set(data) {
        savedData = data;
    }
    function get() {
        return savedData;
    }

    return {
        set: set,
        get: get
    };

});

app.factory('orderwgt', function () {
    var savedData = {}
    function set(data) {
        savedData = data;
    }
    function get() {
        return savedData;
    }

    return {
        set: set,
        get: get
    }

});

app.factory('payUFactory', function () {
    var savedData = {}
    function set(data) {
        savedData = data;
    }
    function get() {
        return savedData;
    }

    return {
        set: set,
        get: get
    }
});

app.factory('BasketFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var BasketFactory = {};

        BasketFactory.getSession = function () {
            return $http.get(urlBase + 'basket/getSession');
        }

        // Add order to basket
        BasketFactory.addBasketItem = function (input) {
            return $http.post(urlBase + 'basket/addBasketItem', input);
        }

        // update order to basket
        BasketFactory.updateBasketItem = function (id, data) {
            return $http.put(urlBase + 'basket/updateBasketItem/' + id, data);
        };

        // Check if item is present
        BasketFactory.checkLSORefPresence = function (input) {
            return $http.post(urlBase + 'basket/checkLSORefPresence', input);
        }

        // Delete order from basket
        BasketFactory.deleteOrderItemFrom = function (id) {
            return $http.put(urlBase + 'basket/deleteOrderItemFromBasket/' + id);
        }

        // Delete basket
        BasketFactory.deleteBasket = function (account_id) {
            return $http.put(urlBase + 'basket/deleteBasket/' + account_id);
        }

        // Get basket
        BasketFactory.getBasket = function (id) {
            return $http.get(urlBase + 'basket/getBasket/' + id);
        }
        return BasketFactory;
    }]);

app.factory('SharedWalletFactory', ['$http', 'ENV', function ($http, ENV) {

        var urlBase = ENV.API_URL;
        var SharedWalletFactory = {};

        // Get SharedUsers
        SharedWalletFactory.getSharedUsers = function (wallet_id, page) {
            return $http.get(urlBase + 'profile/getSharedUsers/' + wallet_id + '?page=' + page);
        };

        // Get SharedUsers
        SharedWalletFactory.blockSharedUser = function (id) {
            return $http.put(urlBase + 'profile/blockSharedUser/' + id);
        };

        // Delete address book record info
        SharedWalletFactory.deleteSharedUser = function (id) {
            return $http.put(urlBase + 'profile/deleteSharedUser/' + id);
        };

        // Get registerd users list
        SharedWalletFactory.getUsersList = function (page, searchStr) {
            searchStr = searchStr || '';
            return $http.get(urlBase + 'profile/getUsersList?page=' + page + '&search=' + searchStr);
        };

        // Add user to share wallet
        SharedWalletFactory.addSharedUser = function (input) {
            return $http.post(urlBase + 'profile/addSharedUser', input);
        }

        return SharedWalletFactory;
    }]);
