/**
 * Password Match Check for Registration Form
 * @return void
 */
app.directive('pwCheck', [function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                pwCheck: '='
            },
            link: function (scope, elem, attrs, ctrl) {
                var firstPassword = '#' + attrs.pwCheck;

                scope.$watch('pwCheck', function (firstPassword) {
                    ctrl.$validate();
                });
                ctrl.$validators.pwCheck = function (modelValue) {
                    return (ctrl.$pristine && (angular.isUndefined(modelValue) || modelValue === "")) || modelValue === scope.pwCheck;
                };
            }
        };
    }]);

/**
 * Suburb Match Check on Pickup and Dropoff forms
 * @return void
 */
app.directive('suburbCheck', [function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                suburbCheck: '='
            },
            link: function (scope, elem, attrs, ctrl) {
                var firstPassword = '#' + attrs.suburbCheck;

                scope.$watch('suburbCheck', function (firstPassword) {
                    // console.log(firstPassword);
                    ctrl.$validate();
                });
                ctrl.$validators.suburbCheck = function (modelValue) {
                    // console.log(modelValue.SUBURB);
                    // console.log(scope.suburbCheck);
                    return (ctrl.$pristine && (angular.isUndefined(modelValue) || modelValue.SUBURB === "")) || modelValue.SUBURB === scope.suburbCheck;
                };
            }
        };
    }]);


/**
 * Email Check
 * @return void
 */
app.directive('emailCheck', [function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                emailCheck: '='
            },
            link: function (scope, elem, attrs, ctrl) {
                var firstPassword = '#' + attrs.emailCheck;

                scope.$watch('emailCheck', function (firstPassword) {
                    ctrl.$validate();
                });
                ctrl.$validators.emailCheck = function (modelValue) {
                    return (ctrl.$pristine && (angular.isUndefined(modelValue) || modelValue === "")) || modelValue === scope.emailCheck;
                };
            }
        };
    }]);

/**
 * Add Parcel
 * Add Parcel template
 * 
 * @param  ENV
 * @return void
 */
app.directive('addParcel', ['ENV', function (ENV) {
        return {
            restrict: 'A',
            transclude: true,
            templateUrl: ENV.APP_DIR + 'partials/_parcel_template.html',
            replace: true
        };
    }]);

/**
 * Add Document
 * Add document template
 * 
 * @param  ENV
 * @return void
 */
app.directive('addDocument', ['ENV', function (ENV) {
        return {
            restrict: 'A',
            transclude: true,
            templateUrl: ENV.APP_DIR + 'partials/_document_template.html',
            replace: true
        };
    }]);

/**
 * Auto Fillable Field
 * To auto fillable input field
 * 
 * @param  $timeout
 * @return void
 */
app.directive('autoFillableField', ['$timeout', function ($timeout) {
        return {
            require: '?ngModel',
            restrict: 'A',
            link: function (scope, element, attrs, ngModel) {
                $timeout(function () {
                    if (ngModel.$viewValue !== element.val()) {
                        ngModel.$setViewValue(element.val());
                    }
                }, 500);
            }
        };
    }
]);

/**
 * Dynamic
 * Run dynamic html
 * 
 * @param  $compile
 * @return void
 */
app.directive('dynamic', ['$compile', function ($compile) {
        return {
            restrict: 'A',
            replace: true,
            scope: {dynamic: '=dynamic'},
            link: function postLink(scope, element, attrs) {
                scope.$watch('dynamic', function (html) {
                    element.html(html);
                    $compile(element.contents())(scope);
                });
            }
        };
    }]);


/**
 * My Current Time
 * return the current time
 * 
 * @param  dateFilter
 * @return void
 */
app.directive('myCurrentTime', ['dateFilter', function (dateFilter) {
        return function (scope, element, attrs) {
            var format;
            var city;
            var offset;

            scope.$watch(attrs.myCurrentTime, function (value) {
                format = value;
                city = "Durban";
                offset = -2;
                updateTime();
            });

            function updateTime() {
                // create Date object for current location
                var d = new Date();

                // getTimezoneOffset returns the difference in minutes between UTC and current time.. a negative value means that UTC is behind current time
                // adding the difference (converted to msecs) to the current time, gives you the UTC time in msecs
                var utc = d.getTime() + (d.getTimezoneOffset() * 60000);

                // to get desired time, subtract the desired offset from the UTC time (where the offset is the difference in hours between UTC and desired time)
                // it would have been better to add the difference between desired time and UTC (i.e the offset sign depicts whether the timezone is
                // before or after UTC)... but i don't want to change this code more

                //also... when taking codes from the internet, please learn what it does first. Yes, i'm a little bit flustered.
                var nd = new Date(utc - (3600000 * offset));

                var dt = dateFilter(nd, format);

                scope.currentTime.time = dt;
                scope.currentTime.hours = nd.getHours();
                scope.currentTime.mins = nd.getMinutes();
            }

            function updateLater() {
                setTimeout(function () {
                    updateTime(); // update DOM
                    updateLater(); // schedule another update
                    // console.log(scope.currentTime);
                }, 1000);
            }

            updateLater();
        };
    }]);

/**
 * shortenTown
 * Shorten Specific Towns
 * @return void
 */
app.directive('shortenTown', [function () {
        return function (scope, element, attrs) {
            scope.cities = [
                {
                    'city': 'JOHANNESBURG',
                    'code': 'JHB'
                },
                {
                    'city': 'CAPE TOWN',
                    'code': 'CPT'
                }
            ];

            var state;
            var shorten;
            scope.$watch(attrs.shortenTown, function (value) {
                shorten = value;
                for (var i = 0; i < scope.cities.length; i++) {
                    if (value == scope.cities[i]['city']) {
                        state = value;
                        shorten = state.replace(scope.cities[i]['city'], scope.cities[i]['code']);
                    }
                }
                ;
                // if(value == 'JOHANNESBURG'){
                //     console.log(value);
                //     state = value;
                //     shorten = state.replace('JOHANNESBURG',"JHB");
                // }else if(value == 'CAPE TOWN'){
                //     console.log(value);
                //     state = value;
                //     shorten = state.replace('CAPE TOWN',"CPT");
                // }else{
                //     shorten = value;
                // }

                element.text(shorten);
            });
        }
    }]);

/**
 * OWL CAROUSEL
 *
 * 
 * @return void
 */
app.directive("owlCarousel", function () {
    return {
        restrict: 'E',
        transclude: false,
        link: function (scope) {
            scope.initCarousel = function (element) {
                // provide any default options you want
                var defaultOptions = {
                    autoPlay: true,
                    center: true,
                    mergeFit: true
                };
                var customOptions = scope.$eval($(element).attr('data-options'));
                // combine the two options objects
                for (var key in customOptions) {
                    defaultOptions[key] = customOptions[key];
                }
                // init carousel
                $(element).owlCarousel(defaultOptions);
            };
        }
    };
})
/**
 * OWL CAROUSEL
 *
 * 
 * @return void
 */
app.directive('owlCarouselItem', [function () {
        return {
            restrict: 'A',
            transclude: false,
            link: function (scope, element) {
                // wait for the last item in the ng-repeat then call init
                if (scope.$last) {
                    scope.initCarousel(element.parent());
                }
            }
        };
    }]);

/**
 * validateId
 * Validate's id number input as a valid south africa id number.
 * 
 * @return void
 */
app.directive('validateId', [function () {
        return {
            require: 'ngModel',
            restrict: '',
            link: function (scope, elm, attrs, ctrl) {

                function isNumber(n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                }

                ctrl.$parsers.unshift(function (viewValue) {
                    // console.log(viewValue);

                    var idNumber = viewValue;
                    var valid = true;

                    //Ref: http://www.sadev.co.za/content/what-south-african-id-number-made
                    // SA ID Number have to be 13 digits, so check the length
                    if (idNumber.length != 13 || !isNumber(idNumber)) {
                        valid = false;
                    }

                    // get first 6 digits as a valid date
                    var tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));

                    var id_date = tempDate.getDate();
                    var id_month = tempDate.getMonth();
                    var id_year = tempDate.getFullYear();

                    var fullDate = id_date + "-" + id_month + 1 + "-" + id_year;
                    if (idNumber.length == 13) {
                        if (!((tempDate.getYear() == idNumber.substring(0, 2)) && (id_month == idNumber.substring(2, 4) - 1) && (id_date == idNumber.substring(4, 6)))) {
                            valid = false;
                        }

                        // get the gender
                        var genderCode = idNumber.substring(6, 10);
                        var gender = parseInt(genderCode) < 5000 ? "Female" : "Male";

                        // get country ID for citzenship
                        var citzenship = parseInt(idNumber.substring(10, 11)) == 0 ? "Yes" : "No";

                        // apply Luhn formula for check-digits
                        var tempTotal = 0;
                        var checkSum = 0;
                        var multiplier = 1;
                        for (var i = 0; i < 13; ++i) {
                            tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
                            if (tempTotal > 9) {
                                tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
                            }
                            checkSum = checkSum + tempTotal;
                            multiplier = (multiplier % 2 == 0) ? 1 : 2;
                        }
                        if ((checkSum % 10) != 0) {
                            valid = false;
                        }
                        ;
                    } else if (idNumber.length == 9) {
                        valid = true;
                    }
                    // console.log(valid);

                    // if no error found, hide the error message
                    ctrl.$setValidity('id', valid);
                    if (valid) {
                        return idNumber;
                    } else {
                        return undefined;
                    }
                });
            }
        };
    }]);

/**
 * GOOGLE AUTOCOMPLETE
 * Validate's id number input as a valid south africa id number.
 * 
 * @return void
 */
app.directive('googleplace', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, model) {
            var options = {
                types: [],
                componentRestrictions: {country: 'sa'}
            };
            scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

            google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
                scope.$apply(function () {
                    model.$setViewValue(element.val());
                });
            });
        }
    };
});

app.directive('disallowSpaces', function () {
    return {
        restrict: 'A',
        link: function ($scope, $element) {
            $element.bind('keydown', function (e) {
                if (e.which === 32) {
                    return false;
                    this.value = this.value.replace(/\s/g, "");
                }
            });
        }
    }
});

app.directive('disableSpace', function () {
    return {
        restrict: 'A',
        link: function ($scope, $element) {
            $element.bind('keydown', function (e) {
                return e.which !== 32;
            });
        }
    }
});


app.directive('carrierCourierit', function () {
    return {
        templateUrl: 'frontend/app/partials/_courier_it_tracking.html'
    };
});

app.directive('carrierSkynet', function () {
    return {
        templateUrl: 'frontend/app/partials/_skynet_tracking.html'
    };
});

app.directive('carrierDcb', function () {
    return {
        templateUrl: 'frontend/app/partials/_dcb_tracking.html'
    };
});

app.directive('carrierGlobeflight', function () {
    return {
        templateUrl: 'frontend/app/partials/_globeflight_tracking.html'
    };
});

app.directive('carrierDsv', function () {
    return {
        templateUrl: 'frontend/app/partials/_dsv_tracking.html'
    };
});

app.directive('carrierTcg', function () {
    return {
        templateUrl: 'frontend/app/partials/_TCG_tracking.html'
    };
});
