/**
 * login Controller
 * @param
 * @return {[type]}         [description]
 */
app.controller('LoginController', ['$scope', '$location', 'ngDialog', '$log', 'notifications', 'ngProgress', 'BusinessFactory', 'ApiFactory', '$cookieStore', '$timeout', 'blockUI', '$rootScope', '$localStorage', 'AmrodFactory', '$modal', 'ENV', 'CustomMessagesFactory', 'TradeOnlyGiftFactory', 'AccountFactory', function ($scope, $location, ngDialog, $log, notifications, ngProgress, BusinessFactory, ApiFactory, $cookieStore, $timeout, blockUI, $rootScope, $localStorage, AmrodFactory, $modal, ENV, CustomMessagesFactory, TradeOnlyGiftFactory, AccountFactory) {
    $('._jsDynamicTitle').text('Rush - Login');
    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $scope.user = {};
    //$log.info('Login Page');
    $scope.register = function () {
        //$log.info('Redirect to Register Page');
        $location.path('/register');
    };

    $scope.startCustRegResponse =
        $localStorage.startCustomerRegistrationResponse === undefined ? false :
            $localStorage.startCustomerRegistrationResponse;

    if ($cookieStore.get('token') !== undefined) {
        if ($localStorage.newOrderTab === undefined) {
            $localStorage.$default({'newOrderTab': true});
        } else {
            $localStorage.newOrderTab = true;
        }
        $location.path('/account');
    }

    var customMessageError = function (object) {
        blockUI.stop();
        window.location = '/account';
    };

    var customMessageSuccess = function (object) {
        blockUI.stop();

        $scope.messages = object.data;
        $scope.account_name = $account_firstname;
        var modalInstance = $modal.open({
            backdrop: 'static',
            keyboard: false,
            size: 'md',
            templateUrl: ENV.APP_DIR + 'views/custom_message.html',
            scope: $scope,
            controller: 'ModalInstanceController',
            windowClass: 'my-dialog'
        });
        if ($scope.cancelNotice) {
            $location.path('/account');
        }
    };

    var showDialogWithMessage = function ($message) {
        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = $message;
            }],
            className: 'ngdialog-theme-default',
            msg: $message
        });
    };
    var genericShowDialogError = function (object) {
        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = object.error.message;
            }],
            className: 'ngdialog-theme-default',
            msg: object.error.message
        });
    };
    var showDialogAndCheckMessageError = function (object) {
        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = object.error.message;
            }],
            className: 'ngdialog-theme-default',
            msg: object.error.message
        });

        blockUI.stop();
        blockUI.start();
        CustomMessagesFactory.getMessages().success(customMessageSuccess).error(customMessageError);
    };

    // Login Form
    $scope.processLoginForm = function () {

        var postLoginSuccess = function (object) {

            // Clear form inputs
            $scope.user = {};

            var token = object.data.token;
            console.log(object)
            ApiFactory.init(token);
            $cookieStore.put('token', token);
            $cookieStore.put('user_type', object.data.user_type);
            $cookieStore.put('user', object.data.user);
            $cookieStore.put('isAmrodUser', false);
            $cookieStore.put('isTradeOnlyGiftUser', false);

            blockUI.stop();

            if (object.data.user_type == undefined) {
                $accountId = object.data.user.account[0].id;
                $account_firstname = object.data.user.account[0].name;
            } else {
                $accountId = null;
                $account_firstname = 'Ecommerce user';
            }


            // complete Amrod/Togsa registration here

            if ($scope.startCustRegResponse !== false) {
                if ($scope.startCustRegResponse.service == "AMROD") {
                    $localStorage.startCustomerRegistrationResponse = false;
                    if ($scope.startCustRegResponse.success) {
                        var success = function (object) {
//                                console.log("complete custom reg successful");
//                                console.log(object);
                            $apiResponse = object.api_response;
                            if (!$apiResponse.success) {
                                $message = "Failed to link your Rush account with your Amrod account. Please contact support.";
                                showDialogWithMessage($message);
                            } else {
                                $request = {};
                                $request.username = $scope.startCustRegResponse.accountProfile.accountEmail;
                                $request.profileToken = !("profileToken" in $apiResponse) ? $apiResponse.token : $apiResponse.profileToken;
                                $request.accountId = $accountId;

                                var success = function (object) {
                                    $cookieStore.put('isAmrodUser', true);
                                    if ($localStorage.isServiceUser === undefined) {
                                        $localStorage.$default({'isServiceUser': true});
                                    } else {
                                        $localStorage.isServiceUser = true;
                                    }
                                    $localStorage.startCustomerRegistrationResponse = false;
                                    $message = "Succesfully linked your Amrod account.";
                                    showDialogWithMessage($message);

                                    CustomMessagesFactory.getMessages().success(customMessageSuccess).error(customMessageError);
                                };
                                AmrodFactory.linkAccount($request).success(success).error(genericShowDialogError);
                            }
                        };
                        AmrodFactory.completeCustomerRegistration($scope.startCustRegResponse).success(success).error(genericShowDialogError);
                    } else {
//                            console.log("already reg with amrod. linking account directly");
                        // start customer registration failed, profile token returned instead.

                        var success = function (object) {
                            if (object.success === false) {
                                $request = {};
                                $request.username = $scope.startCustRegResponse.accountProfile.accountEmail;
                                $request.profileToken = $scope.startCustRegResponse.token;
                                $request.accountId = $accountId;

                                var success = function (object) {
                                    $cookieStore.put('isAmrodUser', true);
                                    if ($localStorage.isServiceUser === undefined) {
                                        $localStorage.$default({'isServiceUser': true});
                                    } else {
                                        $localStorage.isServiceUser = true;
                                    }
                                    $localStorage.startCustomerRegistrationResponse = false;
                                    $message = "Succesfully linked your Amrod account.";
                                    showDialogWithMessage($message);
                                    CustomMessagesFactory.getMessages().success(customMessageSuccess).error(customMessageError);
                                };

                                blockUI.start();
                                AmrodFactory.linkAccount($request).success(success).error(showDialogAndCheckMessageError);
                            } else {
                                $message = 'Your rush account is already linked to an amrod account. Please contact support.';
                                showDialogWithMessage($message);
                            }
                        };

                        $request = {};
                        $request.accountId = $accountId;
                        AmrodFactory.hasAmrodProfileToken($request).success(success).error(showDialogAndCheckMessageError);
                    }
                }

                if ($scope.startCustRegResponse.service == "TOGSA") {
                    $localStorage.startCustomerRegistrationResponse = false;
                    if ($scope.startCustRegResponse.success) {
                        var success = function (object) {
//                                console.log("complete custom reg successful");
//                                console.log(object);
                            $apiResponse = object.api_response;
                            if (!$apiResponse.success) {
                                $message = "Failed to link your Rush account with your Trade Only Gift account. Please contact support.";
                                showDialogWithMessage($message);
                            } else {
                                $request = {};
                                $request.username = $scope.startCustRegResponse.accountProfile.accountEmail;
                                $request.profileToken = !("profileToken" in $apiResponse) ? $apiResponse.token : $apiResponse.profileToken;
                                $request.accountId = $accountId;

                                var success = function (object) {
                                    $cookieStore.put('isTradeOnlyGiftUser', true);
                                    if ($localStorage.isServiceUser === undefined) {
                                        $localStorage.$default({'isServiceUser': true});
                                    } else {
                                        $localStorage.isServiceUser = true;
                                    }
                                    $localStorage.startCustomerRegistrationResponse = false;

                                    $message = "Succesfully linked your Trade Only Gift account.";
                                    showDialogWithMessage($message);

                                    CustomMessagesFactory.getMessages().success(customMessageSuccess).error(customMessageError);
                                };

                                TradeOnlyGiftFactory.linkAccount($request).success(success).error(genericShowDialogError);
                            }
                        };
                        TradeOnlyGiftFactory.completeCustomerRegistration($scope.startCustRegResponse).success(success).error(genericShowDialogError);
                    } else {
//                            console.log("already reg with TradeOnlyGift. linking account directly");
                        // start customer registration failed, profile token returned instead.
                        var success = function (object) {
                            if (object.success === false) {
                                $request = {};
                                $request.username = $scope.startCustRegResponse.accountProfile.accountEmail;
                                $request.profileToken = $scope.startCustRegResponse.token;
                                $request.accountId = $accountId;
                                var success = function (object) {
                                    $cookieStore.put('isTradeOnlyGiftUser', true);
                                    if ($localStorage.isServiceUser === undefined) {
                                        $localStorage.$default({'isServiceUser': true});
                                    } else {
                                        $localStorage.isServiceUser = true;
                                    }
                                    $localStorage.startCustomerRegistrationResponse = false;

                                    $message = "Succesfully linked your Trade Only Gift account.";
                                    showDialogWithMessage($message);

                                    CustomMessagesFactory.getMessages().success(customMessageSuccess).error(customMessageError);
                                };
                                blockUI.start();
                                TradeOnlyGiftFactory.linkAccount($request).success(success).error(showDialogAndCheckMessageError);
                            } else {
                                $message = 'Your Rush account is already linked to an Trade Only Gift account. Please contact support.';
                                showDialogWithMessage($message);
                            }
                        };
                        $request = {};
                        $request.accountId = $accountId;
                        TradeOnlyGiftFactory.hasTOGProfileToken($request).success(success).error(showDialogAndCheckMessageError);
                    }
                }
            } else {
                var getServicesSuccess = function (object) {
                    if (object.success === true) {
                        for (var i = 0; i < object.services.length; i++) {
                            if (object.services[i].service == "AMROD") {
                                $cookieStore.put('isAmrodUser', object.success);
                                if ($localStorage.isServiceUser === undefined) {
                                    $localStorage.$default({'isServiceUser': true});
                                } else {
                                    $localStorage.isServiceUser = true;
                                }
                            } else if (object.services[i].service == "TOGSA") {
                                $cookieStore.put('isTradeOnlyGiftUser', object.success);
                                if ($localStorage.isServiceUser === undefined) {
                                    $localStorage.$default({'isServiceUser': true});
                                } else {
                                    $localStorage.isServiceUser = true;
                                }
                            }
                        }
                    } else {
                        if ($localStorage.newOrderTab === undefined) {
                            $localStorage.$default({'newOrderTab': true});
                        } else {
                            $localStorage.newOrderTab = true;
                        }
                    }
                    CustomMessagesFactory.getMessages().success(customMessageSuccess).error(customMessageError);
                };

                var getServicesError = function (object) {
                    if ($localStorage.newOrderTab === undefined) {
                        $localStorage.$default({'newOrderTab': true});
                    } else {
                        $localStorage.newOrderTab = true;
                    }
                    CustomMessagesFactory.getMessages().success(customMessageSuccess).error(customMessageError);
                };
                blockUI.start();
                AccountFactory.getServices($accountId).success(getServicesSuccess).error(getServicesError);
            }

        };
        // On fail of login
        var postLoginError = function (object) {
            blockUI.stop();
            showDialogWithMessage(object.error.message);
        };
        blockUI.start();
        BusinessFactory.postLogin($scope.user).success(postLoginSuccess).error(postLoginError);
    };

    // Reset your password on forgot password call.
    $scope.processResetPasswordForm = function () {
        var success = function (object) {
            // notifications.showSuccess({message: object.data.message});

            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.data.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.data.message
            });


            //ngProgress.complete();
            blockUI.stop();
            $scope.user = {};
        };
        var error = function (object) {
            //notifications.showError({message: object.error.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
            //ngProgress.complete();
            blockUI.stop();
        };
//            ngProgress.color('#d20001');
//            ngProgress.start();
        blockUI.start();
        BusinessFactory.resetPassword($scope.user).success(success).error(error);
    };

    $timeout(function () {
        blockUI.stop();
    }, 500);
}]);

/**
 * Services Controller
 * @param
 * @return {[type]}         [description]
 */
app.controller('AmrodController', ['$scope', '$location', 'ngDialog', '$cookieStore', '$timeout', 'blockUI', '$rootScope', '$routeParams', 'AmrodFactory', '$localStorage',
    function ($scope, $location, ngDialog, $cookieStore, $timeout, blockUI, $rootScope, $routeParams, AmrodFactory, $localStorage) {
        $('._jsDynamicTitle').text('Rush - Amrod Registration');
        // blockUI.start();
        $rootScope.bufferDiv = 'bannerShow';
        $rootScope.bannerClass = 'bannerHide';

        $rootScope.bufferDivs = 'subheaderHide';
        $rootScope.subheaderClass = 'subheaderShow';

        $rootScope.bufferDivss = 'partnersHide';
        $rootScope.partnersClass = 'partnersShow';

        $scope.amrod = {};
        console.log($routeParams.username)
        if ($routeParams.username == undefined) {
            document.getElementById("exampleInputEmail1").readOnly = false;
        }
        if ($routeParams.username == 'undefined') {
            $routeParams.username = '';
            document.getElementById("exampleInputEmail1").readOnly = false;
        }

        $scope.amrod.username = $routeParams.username;

        $timeout(function () {
            if ($cookieStore.get('token') !== undefined) {
                blockUI.start('Logging you out');
                $cookieStore.remove('user');
                $cookieStore.remove('token');
                if ($scope.amrod.username != undefined || $scope.amrod.username != '') {
                    window.location = '/amrod/landingpage/' + $scope.amrod.username;
                } else {
                    window.location = '/amrod/landingpage';
                }
            }
        }, 20);


//        $scope.user = {};
//        //$log.info('Login Page');
//        $scope.register = function () {
//            //$log.info('Redirect to Register Page');
//            $location.path('/register');
//        };
//
//        // if( $cookieStore.get('token') !== undefined){
//        //     $location.path('/');
//        // }

// LOG OUT OF CURRENT ACCOUNT (IF LOGGED ON)
        var success = function (object) {
            $location.path('/login');
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = 'Your Amrod account is already linked to a Rush account. Please login to continue.';
                }],
                className: 'ngdialog-theme-default',
                msg: object.message
            });

        };

        var error = function (object) {
        };

        AmrodFactory.checkRegistrationStatus($scope.amrod).success(success).error(error);

        // Login Form
        $scope.useExistingAccount = function () {
            var success = function (object) {
                blockUI.stop();
                //   console.log(object);
                $apiResponse = object.api_response;
                $apiResponse.service = object.service;

                if (!($apiResponse.success || (!$apiResponse.success && $apiResponse.resultCode == 'ACCOUNT_REGISTERED'))) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.message
                    });
                } else {
                    $message = "Please log into your account to complete Amrod registration process.";
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = $message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: $message
                    });
                    $localStorage.$reset();
                    $scope.storage = $localStorage.$default({'startCustomerRegistrationResponse': $apiResponse});
                    $location.path('/login');
                }
            };

            var error = function (object) {
                blockUI.stop();
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message
                });
            };

            blockUI.start();
            AmrodFactory.startCustomerRegistration($scope.amrod).success(success).error(error);
        };

        $scope.registerNewAccount = function () {
            var success = function (object) {
                blockUI.stop();
                //    console.log(object);
                $apiResponse = object.api_response;
                $apiResponse.service = object.service;

                if (!($apiResponse.success || (!$apiResponse.success && $apiResponse.resultCode == 'ACCOUNT_REGISTERED'))) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.message
                    });
                } else {
                    $localStorage.$reset();
                    $scope.storage = $localStorage.$default({'startCustomerRegistrationResponse': $apiResponse});
                    $location.path('/amrod_register');
                }
            };

            var error = function (object) {
                blockUI.stop();
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message
                });
            };

            blockUI.start();
            AmrodFactory.startCustomerRegistration($scope.amrod).success(success).error(error);
        };
        $timeout(function () {
            blockUI.stop();
        }, 500);
    }]);

app.controller('TradeOnlyGiftController', ['$scope', '$location', 'ngDialog', '$cookieStore', '$timeout', 'blockUI', '$rootScope', '$routeParams', 'TradeOnlyGiftFactory', '$localStorage',
    function ($scope, $location, ngDialog, $cookieStore, $timeout, blockUI, $rootScope, $routeParams, TradeOnlyGiftFactory, $localStorage) {
        $('._jsDynamicTitle').text('Rush - TOGSA Registration');
        // blockUI.start();
        $rootScope.bufferDiv = 'bannerShow';
        $rootScope.bannerClass = 'bannerHide';

        $rootScope.bufferDivs = 'subheaderHide';
        $rootScope.subheaderClass = 'subheaderShow';

        $rootScope.bufferDivss = 'partnersHide';
        $rootScope.partnersClass = 'partnersShow';

        $scope.togsa = {};
        console.log($routeParams.username)
        if ($routeParams.username == undefined) {
            document.getElementById("exampleInputEmail1").readOnly = false;
        }
        if ($routeParams.username == 'undefined') {
            $routeParams.username = '';
            document.getElementById("exampleInputEmail1").readOnly = false;
        }

        $scope.togsa.username = $routeParams.username;

        $timeout(function () {
            if ($cookieStore.get('token') !== undefined) {
                blockUI.start('Logging you out');
                $cookieStore.remove('user');
                $cookieStore.remove('token');
                if ($scope.togsa.username != undefined || $scope.togsa.username != '') {
                    window.location = '/togsa/landingpage/' + $scope.togsa.username;
                } else {
                    window.location = '/togsa/landingpage';
                }
            }
        }, 20);

// LOG OUT OF CURRENT ACCOUNT (IF LOGGED ON)
        var success = function (object) {
            $location.path('/login');
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = 'Your Trade Only Gift account is already linked to a Rush account. Please login to continue.';
                }],
                className: 'ngdialog-theme-default',
                msg: object.message
            });

        };

        var error = function (object) {
        };

        TradeOnlyGiftFactory.checkRegistrationStatus($scope.togsa).success(success).error(error);

        // Login Form
        $scope.useExistingAccount = function () {
            var success = function (object) {
                blockUI.stop();
                //   console.log(object);
                $apiResponse = object.api_response;
                $apiResponse.service = object.service;

                if (!($apiResponse.success || (!$apiResponse.success && $apiResponse.resultCode == 'ACCOUNT_REGISTERED'))) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.message
                    });
                } else {
                    $message = "Please log into your account to complete Trade Only Gift registration process.";
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = $message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: $message
                    });
                    $localStorage.$reset();
                    $scope.storage = $localStorage.$default({'startCustomerRegistrationResponse': $apiResponse});
                    $location.path('/login');
                }
            };

            var error = function (object) {
                blockUI.stop();
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message
                });
            };

            blockUI.start();
            TradeOnlyGiftFactory.startCustomerRegistration($scope.togsa).success(success).error(error);
        };

        $scope.registerNewAccount = function () {
            var success = function (object) {
                blockUI.stop();
                //    console.log(object);
                $apiResponse = object.api_response;
                $apiResponse.service = object.service;


                if (!($apiResponse.success || (!$apiResponse.success && $apiResponse.resultCode == 'ACCOUNT_REGISTERED'))) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.message
                    });
                } else {
                    $localStorage.$reset();
                    $scope.storage = $localStorage.$default({'startCustomerRegistrationResponse': $apiResponse});
                    $location.path('/togsa_register');
                }
            };

            var error = function (object) {
                blockUI.stop();
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message
                });
            };

            blockUI.start();
            TradeOnlyGiftFactory.startCustomerRegistration($scope.togsa).success(success).error(error);
        };
        $timeout(function () {
            blockUI.stop();
        }, 500);
    }]);

/*RegisterModal*/
app.controller('RegisterModalController', ['$scope', '$modalInstance', 'blockUI', function ($scope, $modalInstance, blockUI) {
    $scope.closeModal = function () {
        $modalInstance.close();
    };


}]);

app.controller('BusinessRegisterModalController', ['AmrodFactory', 'TradeOnlyGiftFactory', '$localStorage', '$scope', 'ngDialog', '$location', 'BusinessFactory', 'ngProgress', 'notifications', '$log', '$location', '$timeout', 'blockUI', '$rootScope', '$modalStack', '$modal', '$routeParams', 'ENV', function (AmrodFactory, TradeOnlyGiftFactory, $localStorage, $scope, ngDialog, $location, BusinessFactory, ngProgress, notifications, $log, $location, $timeout, blockUI, $rootScope, $modalStack, $modal, $routeParams, ENV) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerShow';
    $rootScope.bufferDiv = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $scope.rush_usertype = {};
    $scope.value = 'vodacom';

    $scope.newValue = function (value) {

        $scope.rush_usertype = value;

    };

    $scope.openRegister = function () {
        $modal.open({
            size: 'lg',
            templateUrl: ENV.APP_DIR + 'partials/_register_modal.html',
            controller: 'RegisterModalController',
            scope: $scope
        });
    };

    $scope.continue = function () {
        if ($scope.rush_usertype == 'amrod') {
            $location.path('/amrod/landingpage')
            $modalStack.dismissAll();

        } else if ($scope.rush_usertype == 'togsa') {
            $location.path('/togsa/landingpage')
            $modalStack.dismissAll();

        } else if ($scope.rush_usertype == 'rush') {
            $location.path('/register')
            $modalStack.dismissAll();

        } else if ($scope.rush_usertype == 'cbre'){
            $location.path('/register/:cbre');
            $modalStack.dismissAll();

        } else {
            $location.path('/register/:vodacom');
            $modalStack.dismissAll();

        }

    };
    $timeout(function () {
        blockUI.stop();
    }, 500);

}]);


app.controller('BusinessRegisterController', ['AmrodFactory', 'TradeOnlyGiftFactory','PromoCodeFactory', '$localStorage', '$scope', 'ngDialog', '$location', 'BusinessFactory', 'ngProgress', 'notifications', '$log', '$location', '$timeout', 'blockUI', '$rootScope', '$modalStack', '$modal', '$routeParams', 'ENV', function (AmrodFactory, TradeOnlyGiftFactory,PromoCodeFactory, $localStorage, $scope, ngDialog, $location, BusinessFactory, ngProgress, notifications, $log, $location, $timeout, blockUI, $rootScope, $modalStack, $modal, $routeParams, ENV) {
    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.hideShowTitle = 'hideElement';
    $rootScope.hideShowAboutus = 'hideElement';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';

    $scope.rush_usertype = {};
    $scope.value = 'vodacom';

    $scope.newValue = function (value) {

        $scope.rush_usertype = value;

    };

    $scope.openRegister = function () {
        $modal.open({
            size: 'lg',
            templateUrl: ENV.APP_DIR + 'partials/_register_modal.html',
            controller: 'RegisterModalController',
            scope: $scope
        });
    };

    $scope.continue = function () {
        if ($scope.rush_usertype == 'amrod') {
            $location.path('/amrod/landingpage')
            $modalStack.dismissAll();

        } else if ($scope.rush_usertype == 'togsa') {
            $location.path('/togsa/landingpage')
            $modalStack.dismissAll();

        } else if ($scope.rush_usertype == 'rush') {
            $location.path('/register')
            $modalStack.dismissAll();

        } else if ($scope.rush_usertype == 'cbrs') {
            $location.path('/register/:cbrs')
            $modalStack.dismissAll();

        } else {
            $location.path('/register/:vodacom');
            $modalStack.dismissAll();
        }

    };

    $scope.login = function () {
        // $log.info('Redirect to Login Page');
        $location.path('/login');
    };


    //$log.info('Register Page');
    $scope.provinces = [
        {label: 'Select a province', value: 'province'},
        {label: 'Eastern Cape', value: 'Eastern Cape'},
        {label: 'Free State', value: 'Free State'},
        {label: 'Gauteng', value: 'Gauteng'},
        {label: 'KwaZulu Natal', value: 'KwaZulu Natal'},
        {label: 'Limpopo', value: 'Limpopo'},
        {label: 'Mpumalanga', value: 'Mpumalanga'},
        {label: 'North West', value: 'North West'},
        {label: 'Northern Cape', value: 'Northern Cape'},
        {label: 'Western Cape', value: 'Western Cape'}
    ];

    $scope.aboutUs = [
        {label: 'How did you hear about Rush?', value: 'Rush'},
        {label: 'Vodacom Red', value: 'Vodacom Red'},
        {label: 'Amrod', value: 'Amrod'},
        {label: 'Trade Only Gifts', value: 'Amrod'},
        {label: 'Rush Sales Person', value: 'Rush Sales Person'},
        {label: 'Facebook', value: 'Facebook'},
        {label: 'LinkedIn', value: 'LinkedIn'},
        {label: 'Twitter', value: 'Twitter'},
        {label: 'Other social media', value: 'other social media'},
        {label: 'Print advert', value: 'print advert'},
        {label: 'News article', value: ' news article'},
        {label: 'Radio', value: 'radio'},
        {label: 'TV', value: 'TV'},
        {label: 'WeChat', value: 'WeChat'},
        {label: 'Word of Mouth', value: 'Word of Mouth'},
        {label: 'Call centre', value: 'Call centre'},
        {label: 'Website', value: 'website'},
        {label: 'Courier', value: 'Courier'},
        {label: 'Emailer', value: 'Emailer'},
        {label: 'Other', value: 'Other'}
    ];

    $scope.titles = [
        {label: 'Mr', value: 'Mr'},
        {label: 'Miss', value: 'Miss'},
        {label: 'Mrs', value: 'Mrs'},
        {label: 'Ms', value: 'Ms'},
        {label: 'Dr', value: 'Dr'},
        {label: 'Prof', value: 'Prof'},
        {label: 'Other', value: 'Other'}
    ];
    $scope.areaCodes = [
        {label: 'ZAF +27', value: '+27 (0)'},
        {label: 'AGO +244 ', value: '+244 (0)'},
        {label: 'BWA +267', value: '+267 (0)'},
        {label: 'BDI +257', value: '+257 (0)'},
        {label: 'COM +269', value: '+269 (0)'},
        {label: 'LSO +266', value: '+266 (0)'},
        {label: 'MDG +261', value: '+261 (0)'},
        {label: 'MWI +265', value: '+265 (0)'},
        {label: 'MUS +230', value: '+230 (0)'},
        {label: 'MOZ +258', value: '+258 (0)'},
        {label: 'NAM +264', value: '+264 (0)'},
        {label: 'REU +262', value: '+262 (0)'},
        {label: 'SYC +248', value: '+248 (0)'},
        {label: 'SWZ +268', value: '+268 (0)'},
        {label: 'ZMB +260', value: '+260 (0)'},
        {label: 'ZWE +263', value: '+263 (0)'}
    ];
//        $scope.areaCodes = [
//            {label: 'ZAF +27 (0)', value: '+27 (0)'},
//            {label: 'AGO +244 (0)', value: '+244 (0)'},
//            {label: 'BWA +267 (0)', value: '+267 (0)'},
//            {label: 'BDI +257 (0)', value: '+257 (0)'},
//            {label: 'COM +269 (0)', value: '+269 (0)'},
//            {label: 'LSO +266 (0)', value: '+266 (0)'},
//            {label: 'MDG +261 (0)', value: '+261 (0)'},
//            {label: 'MWI +265 (0)', value: '+265 (0)'},
//            {label: 'MUS +230 (0)', value: '+230 (0)'},
//            {label: 'MOZ +258 (0)', value: '+258 (0)'},
//            {label: 'NAM +264 (0)', value: '+264 (0)'},
//            {label: 'REU +262 (0)', value: '+262 (0)'},
//            {label: 'SYC +248 (0)', value: '+248 (0)'},
//            {label: 'SWZ +268 (0)', value: '+268 (0)'},
//            {label: 'ZMB +260 (0)', value: '+260 (0)'},
//            {label: 'ZWE +263 (0)', value: '+263 (0)'}
//        ];
    $scope.register = {};
    $scope.register.title = $scope.titles[0];
    $scope.register.int_code_mobile = $scope.areaCodes[0];
    $scope.register.int_code_landline = $scope.areaCodes[0];
    $scope.register.company_int_code = $scope.areaCodes[0];
    $scope.register.province = $scope.provinces[0];
    $scope.register.aboutUs = $scope.aboutUs[0];
    $scope.register.notify = true;
    $scope.register.store_payment = true;
    $scope.isRequiredTitle = false;
    $scope.isRequiredAboutus = false;
    $scope.register.vodacom = $routeParams.vodacom;


    if ($location.path().indexOf('vodacom') > -1 ||  $location.path().indexOf('Vodacom') > -1 || $location.path().indexOf('/register/:vodacom') > -1)  {

        $scope.register.vodacom = 'vodacom';
        $scope.promocode_field = true;

        console.log('vodacom page')

        //check for promocode
        $scope.checkPromoCode = function ($promocode) {
            var success = function (object) {
              $scope.promocode_detail = object;
              $rootScope.promocode_id =  $scope.promocode_detail.Response[0]['id']
              $scope.register.promocode = $rootScope.promocode_id;

            }
            var error = function (object) {
            }
            $post = {"promo_code": $promocode};
            PromoCodeFactory.getPromoCode($post).success(success).error(error);

        }
    }


    if ($location.path().indexOf('cbre') > -1 || $location.path().indexOf('/register/:cbre') > -1)  {
        $scope.register.vodacom = 'cbre';
        $scope.cbre_field = true;
    }



    var cleanUpPhoneNumber = function (phoneNumber) {
        phoneNumber = phoneNumber == null ? "" : phoneNumber;
        return phoneNumber.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "").replace("+270", "").replace("+27 (0)", "").replace("+27", ""); // assumes this will always be SA
//            return phoneNumber.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "");
    };

    var extractIntCode = function (number) {
        var code = number.substring(0, number.indexOf(")") == -1 ? 0 : number.indexOf(")") + 1);
        return code;
    };


    $scope.startCustRegResponse = $localStorage.startCustomerRegistrationResponse === undefined ? false : $localStorage.startCustomerRegistrationResponse;
    console.log($scope.startCustRegResponse);
    $scope.prefilledForm = false;

    if ($scope.startCustRegResponse !== false) {
        if ($scope.startCustRegResponse.service == "AMROD") {
            $scope.prefilledForm = true;
            $scope.register.name = $scope.startCustRegResponse.accountProfile.accountContactFirstname;
            $scope.register.surname = $scope.startCustRegResponse.accountProfile.accountContactLastname;
            $scope.register.email = $scope.startCustRegResponse.accountProfile.accountEmail;
            $scope.register.emailConfirm = $scope.startCustRegResponse.accountProfile.accountEmail;
            $scope.register.mobile = cleanUpPhoneNumber($scope.startCustRegResponse.accountProfile.accountPhone);
            $scope.register.landline = cleanUpPhoneNumber($scope.startCustRegResponse.accountProfile.accountPhone2);
            var icm = extractIntCode($scope.register.mobile);
            var icl = extractIntCode($scope.register.landline);
            $scope.register.int_code_mobile = $scope.areaCodes[0];
            $scope.register.int_code_landline = $scope.areaCodes[0];
            $scope.register.int_code_company = $scope.areaCodes[0];
            $scope.register.int_code_mobile = icm === "" ? $scope.register.int_code_mobile : $scope.areaCodes[$filter('getKey')($scope.areaCodes, icm)];
            $scope.register.int_code_landline = icl === "" ? $scope.register.int_code_landline : $scope.areaCodes[$filter('getKey')($scope.areaCodes, icl)];
            $scope.register.mobile = $scope.register.mobile.replace($scope.register.int_code_mobile.value, "");
            $scope.register.landline = $scope.register.landline.replace($scope.register.int_code_landline.value, "");

            $scope.register.companyVat = $scope.startCustRegResponse.accountProfile.accountVATNumber;
            $scope.register.company = $scope.startCustRegResponse.accountProfile.accountName;
            $scope.register.companyAddress = $scope.startCustRegResponse.accountProfile.accountPhysicalAddress.replace(/\|\|/g, "").replace(/\|/g, ", ");
            var arrayLength = $scope.provinces.length;
            for (var i = 0; i < arrayLength; i++) {
                if ($scope.startCustRegResponse.accountProfile.accountArea.toLowerCase().indexOf($scope.provinces[i].value.toLowerCase()) != -1) {
                    $scope.register.province = $scope.provinces[i];
                }
            }
            $scope.register.aboutUs = $scope.aboutUs[1];
            $localStorage.$reset();
        }
        if ($scope.startCustRegResponse.service == "TOGSA") {
            $scope.prefilledForm = true;
            $scope.register.name = $scope.startCustRegResponse.accountProfile.accountContactName;
            $scope.register.surname = $scope.startCustRegResponse.accountProfile.accountContactLastname;
            $scope.register.email = $scope.startCustRegResponse.accountProfile.accountEmail;
            $scope.register.emailConfirm = $scope.startCustRegResponse.accountProfile.accountEmail;
            $scope.register.mobile = cleanUpPhoneNumber($scope.startCustRegResponse.accountProfile.accountMobilePhone);
            $scope.register.landline = cleanUpPhoneNumber($scope.startCustRegResponse.accountProfile.accountLandlinePhone);
            var icm = extractIntCode($scope.register.mobile);
            var icl = extractIntCode($scope.register.landline);
            $scope.register.int_code_mobile = $scope.areaCodes[0];
            $scope.register.int_code_landline = $scope.areaCodes[0];
            $scope.register.int_code_company = $scope.areaCodes[0];
            $scope.register.int_code_mobile = icm === "" ? $scope.register.int_code_mobile : $scope.areaCodes[$filter('getKey')($scope.areaCodes, icm)];
            $scope.register.int_code_landline = icl === "" ? $scope.register.int_code_landline : $scope.areaCodes[$filter('getKey')($scope.areaCodes, icl)];
            $scope.register.mobile = $scope.register.mobile.replace($scope.register.int_code_mobile.value, "");
            $scope.register.landline = $scope.register.landline.replace($scope.register.int_code_landline.value, "");

            $scope.register.companyVat = $scope.startCustRegResponse.accountProfile.accountVATNumber;
            $scope.register.company = $scope.startCustRegResponse.accountProfile.accountName;
            $scope.register.companyAddress = "";
//                var arrayLength = $scope.provinces.length;
//                for (var i = 0; i < arrayLength; i++) {
//                    if ($scope.startCustRegResponse.accountProfile.accountArea.toLowerCase().indexOf($scope.provinces[i].value.toLowerCase()) != -1)
//                    {
//                        $scope.register.province = $scope.provinces[i];
//                    }
//                }
            $scope.register.aboutUs = $scope.aboutUs[2];
            $localStorage.$reset();
        }
    }

    $scope.checkTitle = function (label) {

        if (label.value == 'Other') {
            $rootScope.hideShowTitle = 'showElement';
            $scope.isRequiredTitle = true;
        } else {
            $rootScope.hideShowTitle = 'hideElement';
            $scope.isRequiredTitle = false;
        }
        //console.log(label);

    };

    $scope.checkAboutus = function (label) {

        if (label.value == 'Other') {
            $rootScope.hideShowAboutus = 'showElement';
            $scope.isRequiredAboutus = true;
        } else {
            $rootScope.hideShowAboutus = 'hideElement';
            $scope.isRequiredAboutus = false;
        }
        //console.log(label);

    };

    $scope.onAddressSelect = function (item, model, label) {
        $scope.register.suburb = item.SUBURB;
        $scope.register.state = item.STATE;
        $scope.register.postcode = item.POSTCODE;
    };

    $scope.onCompanyAddressSelect = function (item, model, label) {
        $scope.register.csuburb = item.SUBURB;
        $scope.register.cstate = item.STATE;
        $scope.register.cpostcode = item.POSTCODE;
    };

    $scope.validateCompanySuburb = function (suburb) {
        function checkSuburb(item) {
            if (suburb.target.value.trim().length === 0) {
                return false;
            }
            return ((item.SUBURB.toLowerCase() + ', ' + item.STATE.toLowerCase() + ', ' + item.POSTCODE).indexOf(suburb.target.value.toLowerCase()) == 0) && (item.USE === 'Yes');
        }

        var item = $scope.detailedSuburbsWithCities.filter(checkSuburb);
        $scope.register.csuburb = '';
        $scope.register.cstate = '';
        $scope.register.cpostcode = '';

        if (item.length > 0) {
            $scope.register.companyAddress = item[0].SUBURB + ', ' + item[0].STATE + ', ' + item[0].POSTCODE + ' ';
            $scope.register.csuburb = item[0].SUBURB;
            $scope.register.cstate = item[0].STATE;
            $scope.register.cpostcode = item[0].POSTCODE;
        }
    };
    $scope.validateSuburb = function (suburb) {
        function checkSuburb(item) {
            if (suburb.target.value.trim().length === 0) {
                return false;
            }
            return ((item.SUBURB.toLowerCase() + ', ' + item.STATE.toLowerCase() + ', ' + item.POSTCODE).indexOf(suburb.target.value.toLowerCase()) == 0) && (item.USE === 'Yes');
        }

        var item = $scope.detailedSuburbsWithCities.filter(checkSuburb);

        $scope.register.suburb = '';
        $scope.register.state = '';
        $scope.register.postcode = '';

        if (item.length > 0) {
            $scope.register.address = item[0].SUBURB + ', ' + item[0].STATE + ', ' + item[0].POSTCODE + ' ';
            $scope.register.suburb = item[0].SUBURB;
            $scope.register.state = item[0].STATE;
            $scope.register.postcode = item[0].POSTCODE;
        }
    };
    // Register Form
    $scope.processRegisterForm = function () {
        // alert($scope.register.vodacom);
        ////console.log('Form submited');
        //console.log($scope.register);
        // On Success of regsiter call
        var success = function (object) {
            //notifications.showSuccess({message: object.message});
            if ($scope.startCustRegResponse !== false) {
                $accountId = object.data.id;
                $req = {};
                $req.response = $scope.startCustRegResponse;
                $req.accountId = $accountId;
                var success = function (amrodResponse) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.message;
                        }],
                        className: 'ngdialog-theme-plain',
                        msg: object.message
                    });
                    $scope.register = {};
                    //ngProgress.complete();
                    blockUI.stop();
                    $location.path('/');
                };
                var error = function (object) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.error.message;
                        }],
                        className: 'ngdialog-theme-plain',
                        msg: object.error.message
                    });
                    $scope.register = {};
                    //ngProgress.complete();
                    blockUI.stop();
                    $location.path('/');
                };
                AmrodFactory.saveStartCusRegResponse($req).success(success).error(error);
            } else {
                ngDialog.open({
                    template: 'withInlineControllers',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.message;
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: object.message
                });

                $scope.register = {};
                //ngProgress.complete();
                blockUI.stop();
                $location.path('/');
            }
        };

        // On failed register call
        var error = function (object) {
            //notifications.showError({message: "Sorry! Error has occured please try again."});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: "Sorry! An error has occured, please try again."
            });
            blockUI.stop();
            // ngProgress.complete();
        };

        // Make Register user call.
        //ngProgress.color('#d20001');
        //ngProgress.start();
        blockUI.start();
        $scope.register.store_payment = false;
        BusinessFactory.registerUser($scope.register).success(success).error(error);
    };
    $scope.processAmrodRegisterForm = function () {
        ////console.log('Form submited');
        //console.log($scope.register);
        // On Success of regsiter call
        var success = function (object) {
            //notifications.showSuccess({message: object.message});
            if ($scope.startCustRegResponse !== false) {
                $accountId = object.data.id;
                $req = {};
                $req.response = $scope.startCustRegResponse;
                $req.accountId = $accountId;
                var success = function (amrodResponse) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.message;
                        }],
                        className: 'ngdialog-theme-plain',
                        msg: object.message
                    });
                    $scope.register = {};
                    //ngProgress.complete();
                    blockUI.stop();
                    $location.path('/');
                };
                var error = function (object) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.error.message;
                        }],
                        className: 'ngdialog-theme-plain',
                        msg: object.error.message
                    });
                    $scope.register = {};
                    //ngProgress.complete();
                    blockUI.stop();
                    $location.path('/');
                };
                if ($scope.startCustRegResponse.service == "AMROD") {
                    AmrodFactory.saveStartCusRegResponse($req).success(success).error(error);
                } else {
                    TradeOnlyGiftFactory.saveStartCusRegResponse($req).success(success).error(error);
                }
            } else {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.message;
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: object.message
                });

                $scope.register = {};
                //ngProgress.complete();
                blockUI.stop();
                $location.path('/');
            }
        };

        // On failed register call
        var error = function (object) {
            //notifications.showError({message: "Sorry! Error has occured please try again."});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: "Sorry! An error has occured, please try again."
            });
            blockUI.stop();
            // ngProgress.complete();
        };

        // Make Register user call.
        //ngProgress.color('#d20001');
        //ngProgress.start();
        blockUI.start();
        BusinessFactory.registerUser($scope.register).success(success).error(error);
    };

    $scope.processTradeOnlyGiftRegisterForm = function () {
        ////console.log('Form submited');
        //console.log($scope.register);
        // On Success of regsiter call
        var success = function (object) {
            //notifications.showSuccess({message: object.message});
            if ($scope.startCustRegResponse !== false) {
                $accountId = object.data.id;
                $req = {};
                $req.response = $scope.startCustRegResponse;
                $req.accountId = $accountId;
                var success = function (togsaResponse) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.message;
                        }],
                        className: 'ngdialog-theme-plain',
                        msg: object.message
                    });
                    $scope.register = {};
                    //ngProgress.complete();
                    blockUI.stop();
                    $location.path('/');
                };
                var error = function (object) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.error.message;
                        }],
                        className: 'ngdialog-theme-plain',
                        msg: object.error.message
                    });
                    $scope.register = {};
                    //ngProgress.complete();
                    blockUI.stop();
                    $location.path('/');
                };
                TradeOnlyGiftFactory.saveStartCusRegResponse($req).success(success).error(error);
            } else {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.message;
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: object.message
                });

                $scope.register = {};
                //ngProgress.complete();
                blockUI.stop();
                $location.path('/');
            }
        };

        // On failed register call
        var error = function (object) {
            //notifications.showError({message: "Sorry! Error has occured please try again."});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: "Sorry! An error has occured, please try again."
            });
            blockUI.stop();
            // ngProgress.complete();
        };

        // Make Register user call.
        //ngProgress.color('#d20001');
        //ngProgress.start();
        blockUI.start();
        BusinessFactory.registerUser($scope.register).success(success).error(error);
    };
    $timeout(function () {
        blockUI.stop();
    }, 500);
}]);


/**
 * [description]
 * @param  {[type]} $scope         [description]
 * @param  {[type]} AccountFactory [description]
 * @param  {[type]} $location      [description]
 * @param  {[type]} $routeParams   [description]
 * @param  {[type]} $cookieStore   [description]
 * @param  {Array}  notifications) {                   $scope.resetPasswordAlerts [description]
 * @return {[type]}                [description]
 */
app.controller('ResetPasswordController', ['$scope', 'ngDialog', 'BusinessFactory', '$location', '$routeParams', '$cookieStore', 'notifications', function ($scope, ngDialog, BusinessFactory, $location, $routeParams, $cookieStore, notifications) {
    ////console.log($routeParams.code);
    ////console.log($routeParams.id);

    $scope.resetPasswordAlerts = [];

    $scope.user = {
        'id': $routeParams.id,
        'reset_code': $routeParams.code
    };

    $scope.processResetPasswordForm = function () {
        ////console.log($scope.user);
        var success = function (object) {
            ////console.log(object);
            //notifications.showSuccess({message: object.data.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.data.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.data.message,
            });

            $scope.user = {};
            $location.path('/login');
        };

        var error = function (object) {
            ////console.log(object);
            //notifications.showError({message: "Sorry but an error has occured, trying to change your password. Please try again."});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: "Sorry but an error has occured, trying to change your password. <br>Please try again.",
            });
        };

        BusinessFactory.newPassword($scope.user).success(success).error(error);
    };
}]);

/**
 * [description]
 * @param  {[type]} $scope         [description]
 * @param  {[type]} AccountFactory [description]
 * @param  {[type]} $cookieStore   [description]
 * @param  {[type]} ApiFactory     [description]
 * @param  {[type]} notifications  [description]
 * @param  {[type]} ngProgress     [description]
 * @param  {Object} $modalStack){               $scope.user [description]
 * @return {[type]}                [description]
 */
app.controller('AuthController', ['$scope', 'ngDialog', 'AccountFactory', '$cookieStore', 'ApiFactory', 'notifications', 'ngProgress', '$modalStack', 'blockUI', '$rootScope', '$timeout', '$modal', function ($scope, ngDialog, AccountFactory, $cookieStore, ApiFactory, notifications, ngProgress, $modalStack, blockUI, $rootScope, $timeout, $modal) {
    $scope.user = {};

    // Login Form
    $scope.processLoginForm = function () {
        //  On success of lgoin
        var success = function (object) {
            //ngProgress.complete();
            blockUI.stop();
            ////console.log(object);
            // Clear form inputs
            $scope.user = {};

            // Create variables
            var token = object.data.token;

            ApiFactory.init(token);

            $cookieStore.put('token', token);
            $cookieStore.put('user', object.data.user);
            window.location.reload();

            // notifications.showSuccess({message: object.data.message});
        };

        // On fail of login
        var error = function (object) {
            //console.log(object);
            //ngProgress.complete();

            //notifications.showError({message: object.error.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message,
            });

            blockUI.stop();
        };

        // Make Login Call
        blockUI.start();
        //ngProgress.color('#d20001');
        //ngProgress.start();
        AccountFactory.postLogin($scope.user).success(success).error(error);
    };

    // Logout of Account
    $scope.processLogout = function () {
        $modalStack.dismissAll();
        if ($cookieStore.get('token') === undefined) {
            window.location = '/login';
        }
        // On Succes of Logout
        var success = function (object) {
            //ngProgress.complete();
            blockUI.stop();

            // Removing a cookie
            $cookieStore.remove('token');
            $cookieStore.remove('user');

            ApiFactory.init();
            window.location = '/login';
        };
        // On Fail of logout
        var error = function (data) {
            $cookieStore.remove('token');
            window.location = '/login';
            ////console.log(data);
            //ngProgress.complete();
            blockUI.stop();
        };

        // Make Logout Call
        //ngProgress.color('#d20001');
        //ngProgress.start();
        blockUI.start();
        AccountFactory.logout().success(success).error(error);
    };

    // Reset your password on forgot password call.
    $scope.processResetPasswordForm = function () {
        var success = function (object) {
            // notifications.showSuccess({message: object.data.message});

            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.data.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.data.message,
            });
            //ngProgress.complete();
            blockUI.stop();
            $scope.user = {};
            $modalStack.dismissAll();
        };

        var error = function (object) {
            //notifications.showError({message: object.error.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message,
            });
            //ngProgress.complete();
            blockUI.stop();
        };
        blockUI.start();
        //ngProgress.color('#d20001');
        //ngProgress.start();
        AccountFactory.resetPassword($scope.user).success(success).error(error);
    };
}]);

/**
 * Parcel Controller
 *
 * @param  {[type]} $scope         [description]
 * @param  {[type]} CourierFactory [description]
 * @param  {[type]} notifications  [description]
 * @param  {[type]} ngProgress     [description]
 * @param  {[type]} $cookieStore   [description]
 * @param  {[type]} $modal         [description]
 * @param  {[type]} $localStorage  [description]
 * @param  {Object} ENV)           {             $scope.address_pickup [description]
 * @return {[type]}                [description]
 */
app.controller('ParcelController', ['$scope', 'ngDialog', '$location', 'CourierFactory', 'AddressBookFactory', 'AccountFactory', 'notifications', 'blockUI', 'ngProgress', '$cookieStore', '$modal', '$modalStack', '$localStorage', 'ENV', '$route', 'pickup', 'dropoff','MarginGroupFactory', function ($scope, ngDialog, $location, CourierFactory, AddressBookFactory, AccountFactory, notifications, blockUI, ngProgress, $cookieStore, $modal, $modalStack, $localStorage, ENV, $route, pickup, dropoff,MarginGroupFactory) {

//        var element = document.getElementById("show-div");
//        element.style.display = "none";

    $scope.addressList = {};

    $scope.address_pickup = {};
    $scope.selected_address_pickup = null;
    $scope.address_pickup.town = '';
    $scope.address_pickup.state = '';
    $scope.address_pickup.postcode = '';

    $scope.address_dropoff = {};
    $scope.selected_address_dropoff = null;
    $scope.address_dropoff.town = '';
    $scope.address_dropoff.state = '';
    $scope.address_dropoff.postcode = '';

    $scope.widget = {
        "type": "Parcel",
        "insurance": false
    };
    var $key = 0;
    $scope.int_code_label = "+27(0)";
    $scope.int_code = "+27";

    $scope.widget.parcelTypeNative = "parcel";
    $scope.widget.type = "Parcel";
    $scope.widget.amtNative = 1;
    $scope.widget.weight = {};
    $scope.widget.length = {};
    $scope.widget.width = {};
    $scope.widget.height = {};
    $scope.widget.docType = {};
    $scope.widget.parcelType = {};
    $scope.widget.parcel_desc = {};
    $scope.widget.amount = {};
    $scope.widget.ordertype = 'account';

    if ($scope.currentUser.accountService !== undefined && $scope.currentUser.accountService[0] !== undefined &&
        $scope.currentUser.accountService[0].service_id == 2) {
        $scope.widget.businessRef = 'TOGSA';
    }

    $scope.open = function (size, templateUrl) {
        var modalInstance = $modal.open({
            size: size,
            templateUrl: ENV.APP_DIR + templateUrl,
            controller: 'ModalInstanceController',
            scope: $scope //Or $scope.$new(
        });
    };

    $scope.get = function () {
        var parcel_desc = document.getElementById('parcel-desc').value;
        if (parcel_desc !== '') {
            $scope.widget.insuranceDesc = parcel_desc;
        }
    }

    $scope.parcels = [];

    $scope.addParcel = function () {
        var newParcel = {
            weight: $scope.widget.weight,
            length: $scope.widget.length,
            width: $scope.widget.width,
            height: $scope.widget.height,
            amount: $scope.widget.amount
        };
        ////console.log($scope.parcels.length);
        // var newParcel = $scope.widget;
        $scope.parcels.push(newParcel);
        $key = Object.keys($scope.widget.amount).length;
        $scope.widget.amount[$key] = 1;
        $scope.widget.docType[$key] = "A4";
        $scope.widget.parcelType[$key] = "parcel";

        //console.log($scope.parcels.length);
        ////console.log('+ clicked');
    };

    $scope.deleteParcel = function (rowNo) {
        $scope.parcels.splice(rowNo, 1);
        $key = Object.keys($scope.widget.amount).length;
    };

    $scope.processParcelWidget = function () {

        //console.log($scope.widget);
        //console.log('processParcelWidget')
        $localStorage.$reset();
        ////console.log('In process quick quote');
        ////console.log($scope.widget);

        if ($scope.widget.weight !== undefined) {
            var $key = Object.keys($scope.parcels).length;
//                console.log('key: ' + $key);
        } else {
            var $key = 0;
            $scope.widget.weight = {};
            $scope.widget.length = {};
            $scope.widget.width = {};
            $scope.widget.height = {};
            $scope.widget.docType = {};
            $scope.widget.parcelType = {};
            $scope.widget.parcel_desc = {};
            $scope.widget.amount = {};
        }

        //$scope.widget.docType[$key] = $scope.widget.docTypeNative;
        $scope.widget.weight[$key] = $scope.widget.weightNative;
        $scope.widget.parcelType[$key] = $scope.widget.parcelTypeNative;
        $scope.widget.amount[$key] = $scope.widget.amtNative;
        $scope.widget.length[$key] = $scope.widget.lengthNative;
        $scope.widget.height[$key] = $scope.widget.heightNative;
        $scope.widget.width[$key] = $scope.widget.widthNative;
        $scope.widget.parcel_desc[$key] = $scope.widget.descNative;
        if ($scope.widget.parcelType[$key] === "parcel") {
            $scope.widget.length[$key] = $scope.widget.lengthNative;
            $scope.widget.width[$key] = $scope.widget.widthNative;
            $scope.widget.height[$key] = $scope.widget.heightNative;
        } else {
            $scope.widget.docType[$key] = $scope.widget.docTypeNative;
        }
        // loop for each parcel
        for (var $i = 0; $i <= $key; $i++) {
            $scope.widget.docType[$i] = "Parcel";
            $scope.widget.parcelType[$i] = 'parcel';
        }

        blockUI.start();
        var success = function (object) {
            $localStorage.$reset();
            blockUI.stop();
            $scope.storage = $localStorage.$default({'order_widget': $scope.widget});
            $scope.cube = object.totalVolume;
            $scope.totalAmount = object.totalAmount;
            $scope.totalWeight = object.totalWeight;
            $scope.priceSize = Object.keys(object.data.CostComparisonResult.CostComparisonResults.ResultSet).length;
            ////console.log($scope.priceSize);
            if ($scope.priceSize !== 0) {
                $scope.storage = $localStorage.$default({'cube': $scope.cube});
                $scope.storage = $localStorage.$default({'totalAmount': $scope.totalAmount});
                $scope.storage = $localStorage.$default({'totalWeight': $scope.totalWeight});
                $scope.storage = $localStorage.$default({'order_price_object': object.data});
                $scope.storage = $localStorage.$default({'order_price_list': object.data.CostComparisonResult.CostComparisonResults.ResultSet});
                $scope.storage = $localStorage.$default({'insurance': object.insurance});
                $location.path('/compare');
                $route.reload();
            } else {
                //notifications.showError({message: "Sorry.We could not find any courier prices for this delivery request."});

                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Sorry.We could not find any courier prices for this delivery request.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: "Sorry. We could not find any courier prices for this delivery request."
                });
            }

        };
        var error = function (object) {
            //      ngProgress.complete();
            blockUI.stop();

            var $key = Object.keys($scope.parcels).length;
            //notifications.showError({message: object.error.message});

            // remove native values from array
            $ptype = $scope.widget.parcelType;
            $pdesc = $scope.widget.parcel_desc;
            $wgt = $scope.widget.weight;
            $amt = $scope.widget.amount;
            $len = $scope.widget.length;
            $hgt = $scope.widget.height;
            $wdt = $scope.widget.width;

            $scope.widget.weight = {};
            $scope.widget.parcel_desc = {};
            $scope.widget.length = {};
            $scope.widget.width = {};
            $scope.widget.height = {};
            $scope.widget.parcelType = {};
            $scope.widget.amount = {};

            for ($in = 0; $in < $key; $in++) {
                $scope.widget.weight[$in] = $wgt[$in];
                $scope.widget.parcelType[$in] = $ptype[$in];
                $scope.widget.parcel_desc[$in] = $pdesc[$in];
                $scope.widget.amount[$in] = $amt[$in];
                $scope.widget.length[$in] = $len[$in];
                $scope.widget.height[$in] = $hgt[$in];
                $scope.widget.width[$in] = $wdt[$in];
            }

            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
        };
        ////console.log($scope.widget);
        var validateInput = function () {
            if (
                ($scope.widget.pick_up == undefined || $scope.widget.pick_up.trim().length == 0) ||
                ($scope.widget.drop_off == undefined || $scope.widget.drop_off.trim().length == 0)
            ) {
                return false;
            }
            var $length = Object.keys($scope.parcels).length + 1;

            for ($i = 0; $i < $length; $i++) {
                if (
                    ($scope.widget.parcel_desc[$i] == undefined) ||
                    ($scope.widget.weight[$i] == undefined) ||
                    ($scope.widget.length[$i] == undefined) ||
                    ($scope.widget.width[$i] == undefined) ||
                    ($scope.widget.height[$i] == undefined) ||
                    ($scope.widget.amount[$i] == undefined)
                ) {
                    return false;
                }
            }

            if ($scope.widget.insurance == true) {
                if ($scope.widget.insuranceValue == "") {
                    return false;
                }
                if ($scope.widget.insuranceDesc.trim() == "") {
                    return false;
                }
            }

            return true;
        };

        if (!validateInput()) {
            object = {};
            object.error = {};
            object.error.message = "Not all the required fields have been filled.";
            error(object);
        } else {
            CourierFactory.findParcelCourierPrices($scope.widget).success(success).error(error);
        }
    };


    $scope.onSelectPickUp = function ($item, $model, $label) {
        $scope.address_pickup.town = $item.SUBURB;
        $scope.address_pickup.state = $item.STATE;
        $scope.address_pickup.postcode = $item.POSTCODE;
    };

    $scope.onSelectDropOff = function ($item, $model, $label) {
        $scope.address_dropoff.town = $item.SUBURB;
        $scope.address_dropoff.state = $item.STATE;
        $scope.address_dropoff.postcode = $item.POSTCODE;
    };

    $scope.resetAddress = function () {
        if ($scope.selected_address_pickup != null) {
            $scope.address_pickup.sender_name = $scope.widget.sender_name;
            $scope.address_pickup.sender_company = $scope.widget.sender_company;
            $scope.address_pickup.sender_email = $scope.widget.sender_email;
            $scope.address_pickup.sender_tel = $scope.widget.sender_tel;
            $scope.address_pickup.sender_mob = $scope.widget.sender_mob;
            $scope.address_pickup.sender_ref = $scope.widget.sender_ref;
            $scope.address_pickup.lineone = $scope.widget.pickup_object.lineone;
            $scope.address_pickup.linetwo = $scope.widget.pickup_object.linetwo;
            $scope.address_pickup.town = $scope.widget.pickup_object.town;
            $scope.address_pickup.state = $scope.widget.pickup_object.state;
            $scope.address_pickup.postcode = $scope.widget.pickup_object.postcode;
            $scope.address_pickup.selectedAddr = $scope.widget.pickup_object.rest;
        }
        if ($scope.selected_address_dropoff != null) {
            $scope.address_dropoff.receiver_name = $scope.widget.receiver_name !== "Personal" ? $scope.widget.receiver_name : "";
            $scope.address_dropoff.account_number = $scope.widget.account_number !== undefined ? $scope.widget.account_number : "";
            $scope.address_dropoff.special_instructions = $scope.widget.account_number !== undefined ? $scope.widget.special_instructions : "";
            $scope.address_dropoff.receiver_contact = $scope.widget.receiver_contact;
            $scope.address_dropoff.receiver_phone = $scope.widget.receiver_phone;
            $scope.address_dropoff.receiver_mobile = $scope.widget.receiver_mobile;
            $scope.address_dropoff.receiver_email = $scope.widget.receiver_email;
            $scope.address_dropoff.lineone = $scope.widget.dropoff_object.lineone;
            $scope.address_dropoff.linetwo = $scope.widget.dropoff_object.linetwo;
            $scope.address_dropoff.town = $scope.widget.dropoff_object.town;
            $scope.address_dropoff.state = $scope.widget.dropoff_object.state;
            $scope.address_dropoff.postcode = $scope.widget.dropoff_object.postcode;
            $scope.address_dropoff.selectedAddr = $scope.widget.dropoff_object.rest;
        }
    }
    // Address Form
    $scope.capturePickupAddress = function () {
        pickup.set($scope.address_pickup);
        $scope.selected_address_pickup = $scope.address_pickup;
        var linetwo = $scope.address_pickup.linetwo === undefined ? '' : $scope.address_pickup.linetwo;
        $scope.widget.pickup_object = {};
        $scope.widget.pickup_object.lineone = $scope.address_pickup.lineone;
        $scope.widget.pickup_object.linetwo = linetwo;
        $scope.widget.pickup_object.town = $scope.address_pickup.town;
        $scope.widget.pickup_object.state = $scope.address_pickup.state;
        $scope.widget.pickup_object.postcode = $scope.address_pickup.postcode;
        $scope.widget.pickup_object.rest = $scope.address_pickup.town.replace(/,/g, "") + ',' + $scope.address_pickup.state.replace(/,/g, "") + ',' + $scope.address_pickup.postcode;
        $scope.widget.pick_up = $scope.address_pickup.lineone.replace(/,/g, "") + ',' + linetwo.replace(/,/g, "") + ',' + $scope.address_pickup.town.replace(/,/g, "") + ',' + $scope.address_pickup.state.replace(/,/g, "") + ',' + $scope.address_pickup.postcode;
        $scope.widget.sender_name = $scope.address_pickup.sender_name;
        $scope.widget.sender_company = $scope.address_pickup.sender_company;
        $scope.widget.sender_email = $scope.address_pickup.sender_email;
        $scope.widget.sender_tel = $scope.address_pickup.sender_tel;
        $scope.widget.sender_mob = $scope.address_pickup.sender_mob;
        $scope.widget.sender_ref = $scope.address_pickup.sender_ref;
        $scope.autoFilledAddressPickUp = true;
//            console.log($scope.address_pickup);

        if ($scope.address_pickup.saveaddress === true) {
//                var address = $scope.address_dropoff.
//                console.log($scope.address_pickup.state)
//                console.log($scope.address_pickup.town)
            $scope.address_pickup.suburb = $scope.address_pickup.town;
            $scope.address_pickup.landline = $scope.address_pickup.sender_tel;
            $scope.address_pickup.mobile_number = $scope.address_pickup.sender_mob;
            $scope.address_pickup.address_line_one = $scope.address_pickup.lineone;
            $scope.address_pickup.address_line_two = $scope.address_pickup.linetwo;
            $scope.address_pickup.suburbplus = $scope.address_pickup.selectedAddr;
            var splitted = $scope.address_pickup.sender_name.split(" ", 2);
//                console.log(splitted[0])
            $scope.address_pickup.firstname = splitted[0];
            $scope.address_pickup.lastname = splitted[1];
            $scope.address_pickup.company_name = $scope.address_pickup.sender_company;
            ;
            $scope.address_pickup.email = $scope.address_pickup.sender_email;
            $scope.address_pickup.user_id = $scope.currentUser.id;
            $scope.address_pickup.name = $scope.address_pickup.sender_name;
            $scope.address_pickup.company_name = $scope.address_pickup.sender_company;
//                $scope.address_dropoff.firstname =
//                $scope.addressBookRecord.name = $scope.addressBookRecord.first_name.trim() + ' ' + $scope.addressBookRecord.last_name.trim();
            var success = function (object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.message;
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: object.message
                });
                $scope.register = {};
//                            populateAddressList();
                blockUI.stop();
            };
            // On failure of create new address record
            var error = function (object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message
                });
                blockUI.stop();
            };

            blockUI.start();
            AddressBookFactory.createAddressBookRecord($scope.address_pickup).success(success).error(error);
        }
    };

    // Drop off form
    $scope.captureDropoffAddress = function () {
        dropoff.set($scope.address_dropoff);
//            console.log($scope.address_dropoff)
        $scope.selected_address_dropoff = $scope.address_dropoff;
        $scope.widget.receiver_name = $scope.address_dropoff.receiver_name !== undefined ? $scope.address_dropoff.receiver_name : "Personal";
        $scope.widget.account_number = $scope.address_dropoff.account_number == undefined ? "" : $scope.address_dropoff.account_number;
        $scope.widget.special_instructions = $scope.address_dropoff.special_instructions == undefined ? "" : $scope.address_dropoff.special_instructions;
        $scope.widget.receiver_contact = $scope.address_dropoff.receiver_contact;
        $scope.widget.receiver_phone = $scope.address_dropoff.receiver_phone;
        $scope.widget.receiver_mobile = $scope.address_dropoff.receiver_mobile;
        $scope.widget.receiver_email = $scope.address_dropoff.receiver_email;
        var linetwo = $scope.address_dropoff.linetwo === undefined ? '' : $scope.address_dropoff.linetwo;
        $scope.widget.dropoff_object = {};
        $scope.widget.dropoff_object.lineone = $scope.address_dropoff.lineone;
        $scope.widget.dropoff_object.linetwo = linetwo;
        $scope.widget.dropoff_object.town = $scope.address_dropoff.town;
        $scope.widget.dropoff_object.state = $scope.address_dropoff.state;
        $scope.widget.dropoff_object.postcode = $scope.address_dropoff.postcode;
        $scope.widget.dropoff_object.rest = $scope.address_dropoff.town.replace(/,/g, "") + ',' + $scope.address_dropoff.state.replace(/,/g, "") + ',' + $scope.address_dropoff.postcode;
        $scope.widget.drop_off = $scope.address_dropoff.lineone.replace(/,/g, "") + ',' + linetwo.replace(/,/g, "") + ',' + $scope.address_dropoff.town.replace(/,/g, "") + ',' + $scope.address_dropoff.state.replace(/,/g, "") + ',' + $scope.address_dropoff.postcode;
        $scope.autoFilledAddressDropOff = true;


        if ($scope.address_dropoff.saveaddress === true) {
//                var address = $scope.address_dropoff.
//                console.log($scope.address_dropoff.state)
//                console.log($scope.address_dropoff.town)
            $scope.address_dropoff.suburb = $scope.address_dropoff.town;
            $scope.address_dropoff.landline = $scope.address_dropoff.receiver_phone;
            $scope.address_dropoff.mobile_number = $scope.address_dropoff.receiver_mobile;
            $scope.address_dropoff.address_line_one = $scope.address_dropoff.lineone;
            $scope.address_dropoff.address_line_two = $scope.address_dropoff.linetwo;
            $scope.address_dropoff.suburbplus = $scope.address_dropoff.selectedAddr;
            var splitted = $scope.address_dropoff.receiver_contact.split(" ", 2);
//                console.log(splitted[0])
            $scope.address_dropoff.firstname = splitted[0];
            $scope.address_dropoff.lastname = splitted[1];
            $scope.address_dropoff.email = $scope.address_dropoff.receiver_email;
            $scope.address_dropoff.user_id = $scope.currentUser.id;
            $scope.address_dropoff.name = $scope.address_dropoff.receiver_contact;
            $scope.address_dropoff.company_name = $scope.address_dropoff.receiver_name;
//                $scope.address_dropoff.firstname =
//                $scope.addressBookRecord.name = $scope.addressBookRecord.first_name.trim() + ' ' + $scope.addressBookRecord.last_name.trim();
            var success = function (object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.message;
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: object.message
                });
                $scope.register = {};
//                            populateAddressList();
                blockUI.stop();
            };
            // On failure of create new address record
            var error = function (object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message
                });
                blockUI.stop();
            };

            blockUI.start();
            AddressBookFactory.createAddressBookRecord($scope.address_dropoff).success(success).error(error);
        }
    };

    $scope.validateSuburbPickup = function (suburb) {
        function checkSuburb(item) {
            if (suburb.target.value.trim().length === 0) {
                return false;
            }
            return ((item.SUBURB.toLowerCase() + ', ' + item.STATE.toLowerCase() + ', ' + item.POSTCODE).indexOf(suburb.target.value.toLowerCase()) == 0) && (item.USE === 'Yes');
        }

        var item = $scope.detailedSuburbsWithCities.filter(checkSuburb);

        $scope.address_pickup.town = '';
        $scope.address_pickup.state = '';
        $scope.address_pickup.postcode = '';

        if (item.length > 0) {
            $scope.address_pickup.town = item[0].SUBURB;
            $scope.address_pickup.selectedAddr = item[0].SUBURB + ', ' + item[0].STATE + ', ' + item[0].POSTCODE + ' ';
            $scope.address_pickup.state = item[0].STATE;
            $scope.address_pickup.postcode = item[0].POSTCODE;
        }
    };

    $scope.validateSuburbDropoff = function (suburb) {
        function checkSuburb(item) {
            if (suburb.target.value.trim().length === 0) {
                return false;
            }
            return ((item.SUBURB.toLowerCase() + ', ' + item.STATE.toLowerCase() + ', ' + item.POSTCODE).indexOf(suburb.target.value.toLowerCase()) == 0) && (item.USE === 'Yes');
        }

        var item = $scope.detailedSuburbsWithCities.filter(checkSuburb);

        $scope.address_dropoff.town = '';
        $scope.address_dropoff.state = '';
        $scope.address_dropoff.postcode = '';

        if (item.length > 0) {
            $scope.address_dropoff.town = item[0].SUBURB;
            $scope.address_dropoff.selectedAddr = item[0].SUBURB + ', ' + item[0].STATE + ', ' + item[0].POSTCODE + ' ';
            $scope.address_dropoff.state = item[0].STATE;
            $scope.address_dropoff.postcode = item[0].POSTCODE;
        }
    };


    $scope.populateAddressList = function () {
        var success = function (object) {
            $scope.addressList = object.data;
        };

        var error = function (object) {
        };

        AddressBookFactory.getAddressBookByUserId($scope.currentUser.id).success(success).error(error);
    };

    $scope.checkAddressLineTwo = function (lineTwo) {
        return !(!lineTwo || lineTwo === "" || 0 === lineTwo.length);
    };

    $scope.type = {};
    $scope.type.addressPickUp = "ADDRESS_PICK_UP";
    $scope.type.addressDropOff = "ADDRESS_DROP_OFF";

    $scope.setChosenAddressType = function (addressType) {
        $scope.type.chosenAddressType = addressType;
    };

    $scope.clearPickupAddress = function () {
        $scope.address_pickup.sender_name = "";
        $scope.address_pickup.sender_company = "";
        $scope.address_pickup.sender_tel = "";
        $scope.address_pickup.sender_mob = "";
        $scope.address_pickup.sender_email = "";
        $scope.address_pickup.town = "";
        $scope.address_pickup.selectedAddr = "";
        $scope.address_pickup.state = "";
        $scope.address_pickup.postcode = "";
        $scope.address_pickup.lineone = "";
        $scope.address_pickup.linetwo = "";
        $scope.widget.pick_up = "";
        $scope.widget.sender_name = "";
        $scope.widget.sender_company = "";
        $scope.widget.sender_email = "";
        $scope.widget.sender_tel = "";
        $scope.widget.sender_mob = "";
        $scope.widget.sender_ref = "";
        $scope.autoFilledAddressPickUp = false;
    };

    $scope.clearDropoffAddress = function () {
        $scope.address_dropoff.receiver_contact = "";
        $scope.address_dropoff.receiver_phone = "";
        $scope.address_dropoff.receiver_mobile = "";
        $scope.address_dropoff.receiver_email = "";
        $scope.address_dropoff.town = "";
        $scope.address_dropoff.selectedAddr = "";
        $scope.address_dropoff.state = "";
        $scope.address_dropoff.postcode = "";
        $scope.address_dropoff.lineone = "";
        $scope.address_dropoff.linetwo = "";
        $scope.address_dropoff.receiver_name = "";
        $scope.address_dropoff.account_number = "";
        $scope.address_dropoff.special_instructions = "";
        $scope.widget.receiver_name = "";
        $scope.widget.account_number = "";
        $scope.widget.special_instructions = "";
        $scope.widget.receiver_contact = "";
        $scope.widget.receiver_phone = "";
        $scope.widget.receiver_mobile = "";
        $scope.widget.receiver_email = "";
        $scope.widget.drop_off = "";
        $scope.autoFilledAddressDropOff = false;
    };

    $scope.setChosenAddress = function (address) {
        if ($scope.type.chosenAddressType === $scope.type.addressDropOff) {
            $scope.address_dropoff.receiver_contact = address.name;
            $scope.address_dropoff.receiver_phone = address.landline;
            $scope.address_dropoff.receiver_mobile = address.mobile_number;
            $scope.address_dropoff.receiver_email = address.email;
            $scope.address_dropoff.town = address.suburb;
            $scope.address_dropoff.selectedAddr = address.suburb + ', ' + address.state + ', ' + address.postcode;
            $scope.address_dropoff.state = address.state;
            $scope.address_dropoff.postcode = address.postcode;
            $scope.address_dropoff.lineone = address.address_line_one;
            $scope.address_dropoff.linetwo = address.address_line_two;
            $scope.address_dropoff.receiver_name = address.company_name;
            $scope.captureDropoffAddress();
            $scope.autoFilledAddressDropOff = true;
        } else if ($scope.type.chosenAddressType === $scope.type.addressPickUp) {
            $scope.address_pickup.sender_name = address.name;
            $scope.address_pickup.sender_company = address.company_name;
            $scope.address_pickup.sender_mob = address.mobile_number;
            $scope.address_pickup.sender_tel = address.landline;
            $scope.address_pickup.sender_email = address.email;
            $scope.address_pickup.town = address.suburb;
            $scope.address_pickup.selectedAddr = address.suburb + ', ' + address.state + ', ' + address.postcode;
            $scope.address_pickup.state = address.state;
            $scope.address_pickup.postcode = address.postcode;
            $scope.address_pickup.lineone = address.address_line_one;
            $scope.address_pickup.linetwo = address.address_line_two;
            $scope.capturePickupAddress();
            $scope.autoFilledAddressPickUp = true;
        }
    };

    var setDefaultRecipientAddress = function () {
        var success = function (object) {
            if (object.error === undefined) {
                $scope.setChosenAddressType($scope.type.addressDropOff);
                $scope.setChosenAddress(object.data);
            }
        };

        var error = function (object) {
        };

        AddressBookFactory.getDefaultRecipientAddress($scope.currentUser.id).success(success).error(error);
    };
    var setDefaultSenderAddress = function () {
        var success = function (object) {
            ////console.log(object.data);
            address = object.data;

            //console.log(address);
            $scope.address_pickup.sender_name = address.first_name + " " + address.last_name;
            $scope.address_pickup.sender_company = address.company_name;
            if (address.mobile_number.indexOf('+') === -1 || address.mobile_number.indexOf('+27') !== -1) {
                $scope.address_pickup.sender_mob = address.mobile_number.replace("+27 (0)", "").replace("+27", "");
            }
            if (address.landline !== undefined && (address.landline.indexOf('+') === -1 || address.landline.indexOf('+27') !== -1)) {
                $scope.address_pickup.sender_tel = address.landline.replace("+27 (0)", "").replace("+27", "");
            }
            $scope.address_pickup.sender_email = address.email;
            if (address.suburb || address.suburb.length !== 0) {
                $scope.address_pickup.town = address.suburb;
                $scope.address_pickup.selectedAddr = address.suburb + ', ' + address.state + ', ' + address.postcode;
                $scope.address_pickup.state = address.state;
                $scope.address_pickup.postcode = address.postcode;
                $scope.address_pickup.lineone = address.lineOne;
                $scope.address_pickup.linetwo = address.lineTwo;
                $scope.capturePickupAddress();
                $scope.autoFilledAddressPickUp = true;
            }
        };
        var error = function (object) {
        };

        AccountFactory.getProfile($scope.currentUser.id).success(success).error(error);

        var success = function (object) {
            if (object.error === undefined) {
                $scope.setChosenAddressType($scope.type.addressPickUp);
                $scope.setChosenAddress(object.data);
            }
        };

        AddressBookFactory.getDefaultSenderAddress($scope.currentUser.id).success(success).error(error);

    };
    if ($scope.currentUser !== null) {
        setDefaultRecipientAddress();
        setDefaultSenderAddress();
    }
}]);

/**
 * Document Controller
 *
 * @param  {[type]} $scope         [description]
 * @param  {[type]} CourierFactory [description]
 * @param  {[type]} notifications  [description]
 * @param  {[type]} ngProgress     [description]
 * @param  {[type]} $cookieStore   [description]
 * @param  {[type]} $modal         [description]
 * @param  {[type]} $localStorage  [description]
 * @param  {Object} ENV)           {             $scope.address_pickup [description]
 * @return {[type]}                [description]
 */

app.controller('DocumentController', ['$scope', 'ngDialog', '$location', 'CourierFactory', 'notifications', 'ngProgress', '$cookieStore', '$modal', '$localStorage', 'ENV', 'blockUI', 'pickup', 'dropoff', '$route', function ($scope, ngDialog, $location, CourierFactory, notifications, ngProgress, $cookieStore, $modal, $localStorage, ENV, blockUI, pickup, dropoff, $route) {
    $scope.addressList = {};

    $scope.address_pickup = {};
    $scope.address_pickup.town = '';
    $scope.address_pickup.state = '';
    $scope.address_pickup.postcode = '';

    $scope.address_dropoff = {};
    $scope.address_dropoff.town = '';
    $scope.address_dropoff.state = '';
    $scope.address_dropoff.postcode = '';

    $scope.widget = {
        "type": "Document",
        "insurance": false
    };

    $scope.int_code_label = "+27(0)";
    $scope.int_code = "+27";

    $scope.widget.parcelTypeNative = "Document";
    $scope.widget.docTypeNative = "A4";
    $scope.widget.type = "Document";
    $scope.widget.doc_amtNative = 1;

    $scope.widget.weight = {};
    $scope.widget.length = {};
    $scope.widget.width = {};
    $scope.widget.height = {};
    $scope.widget.docType = {};
    $scope.widget.parcelType = {};
    $scope.widget.parcel_desc = {};
    $scope.widget.amount = {};
    $scope.widget.doc_weight = {};
    $scope.widget.doc_amt = {};
    $scope.widget.ordertype = 'account';
    $scope.A4 = {"value": "A4"};
    $scope.Legal = {"value": "Legal"};

    $scope.open = function (size, templateUrl) {
        var modalInstance = $modal.open({
            size: size,
            templateUrl: ENV.APP_DIR + templateUrl,
            controller: 'ModalInstanceController',
            scope: $scope, //Or $scope.$new(
        });
    };

    $scope.get_ins = function () {
        var doc_desc = document.getElementById('doc-desc').value;
        if (doc_desc !== '') {
            $scope.widget.insuranceDesc = doc_desc;
        }
        console.log($scope.widget.insuranceDesc);
    }

    $scope.documents = [];
    $scope.addRow = function () {
        var newDocument = {
            parcel_desc: $scope.widget.doc_parcel_desc,
            weight: $scope.widget.doc_weight,
            length: $scope.widget.docType
        };
        $scope.documents.push(newDocument);
        var $key = $scope.documents.length - 1;
        $scope.widget.doc_amt[$key] = 1;
        $scope.widget.docType[$key] = "A4";
    };

    $scope.deleteRow = function (rowNo) {
        $scope.documents.splice(rowNo, 1);
    };

    $scope.processDocumentWidget = function () {
        //console.log('processParcelWidget')
        $scope.capturePickupAddress();
        $scope.captureDropoffAddress();
        $localStorage.$reset();
        ////console.log('In process quick quote');
        ////console.log($scope.widget);

        if ($scope.widget.weight !== undefined) {
            var $key = Object.keys($scope.widget.doc_weight).length;
        } else {
            var $key = 0;
            $scope.widget.weight = {};
            $scope.widget.length = {};
            $scope.widget.width = {};
            $scope.widget.height = {};
            $scope.widget.docType = {};
            $scope.widget.parcelType = {};
            $scope.widget.parcel_desc = {};
            $scope.widget.amount = {};
        }

        //$scope.widget.docType[$key] = $scope.widget.docTypeNative;
        $scope.type = 'Document';
        $scope.widget.parcelType[$key] = 'document';
        $scope.widget.amount[$key] = $scope.widget.doc_amtNative;
        $scope.widget.weight[$key] = $scope.widget.doc_weightNative;
        $scope.widget.parcel_desc[$key] = $scope.widget.doc_descNative;
        $scope.widget.height[$key] = 5;
        $scope.widget.parcelType[$key] = 'document';
        $scope.widget.type = 'Document';

        if ($scope.widget.docTypeNative === "A4") {
            $scope.widget.length[$key] = 30;
            $scope.widget.width[$key] = 21;
            $scope.widget.docType[$key] = 'A4';
        } else {
            $scope.widget.length[$key] = 36;
            $scope.widget.width[$key] = 22;
            $scope.widget.docType[$key] = 'Legal';

        }

        $scope.widget.docType[$key] = $scope.widget.docTypeNative;
        // loop for each parcel
        for (var $i = 0; $i < $scope.documents.length; $i++) {
            $scope.widget.height[$i] = 5;
            $scope.widget.weight[$i] = $scope.widget.doc_weight[$i];
            $scope.widget.amount[$i] = $scope.widget.doc_amt[$i];
            $scope.widget.parcel_desc[$i] = $scope.widget.doc_parcel_desc[$i];
            $scope.widget.parcelType[$i] = 'document';
            if ($scope.widget.docType[$i] === "A4") {
                $scope.widget.length[$i] = 30;
                $scope.widget.width[$i] = 21;
                $scope.widget.docType[$i] = 'A4';
            } else {
                $scope.widget.length[$i] = 36;
                $scope.widget.width[$i] = 22;
                $scope.widget.docType[$i] = 'Legal';
            }

        }

        blockUI.start();
        var success = function (object) {
            $localStorage.$reset();
            blockUI.stop();
            $scope.storage = $localStorage.$default({'order_widget': $scope.widget});
            $scope.cube = object.totalVolume;
            $scope.totalAmount = object.totalAmount;
            $scope.totalWeight = object.totalWeight;
            $scope.priceSize = Object.keys(object.data.CostComparisonResult.CostComparisonResults.ResultSet).length;
            ////console.log($scope.priceSize);
            if ($scope.priceSize !== 0) {
                $scope.storage = $localStorage.$default({'cube': $scope.cube});
                $scope.storage = $localStorage.$default({'totalAmount': $scope.totalAmount});
                $scope.storage = $localStorage.$default({'totalWeight': $scope.totalWeight});
                $scope.storage = $localStorage.$default({'order_price_object': object.data});
                $scope.storage = $localStorage.$default({'order_price_list': object.data.CostComparisonResult.CostComparisonResults.ResultSet});
                $scope.storage = $localStorage.$default({'insurance': object.insurance});
                $location.path('/compare');
                $route.reload();
            } else {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Sorry. We could not find any courier prices for this delivery request.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: "Sorry. We could not find any courier prices for this delivery request."
                });
            }

        };
        var error = function (object) {
            //      ngProgress.complete();
            blockUI.stop();
            ////console.log(object);
            //notifications.showError({message: object.error.message});

            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
        };
        ////console.log($scope.widget);
        var validateInput = function () {
            if (
                ($scope.widget.pick_up == undefined || $scope.widget.pick_up.trim().length == 0) ||
                ($scope.widget.drop_off == undefined || $scope.widget.drop_off.trim().length == 0)
            ) {
                return false;
            }
            var $length = Object.keys($scope.documents).length + 1;

            for ($i = 0; $i < $length; $i++) {
                if (
                    ($scope.widget.parcel_desc[$i] == undefined) ||
                    ($scope.widget.weight[$i] == undefined) ||
                    ($scope.widget.length[$i] == undefined) ||
                    ($scope.widget.width[$i] == undefined) ||
                    ($scope.widget.height[$i] == undefined) ||
                    ($scope.widget.amount[$i] == undefined)
                ) {
                    return false;
                }
            }

            if ($scope.widget.insurance == true) {
                if ($scope.widget.insuranceValue == "") {
                    return false;
                }
                if ($scope.widget.insuranceDesc.trim() == "") {
                    return false;
                }
            }

            return true;
        };

        if (!validateInput()) {
            object = {};
            object.error = {};
            object.error.message = "Not all the required fields have been filled.";
            error(object);
        } else {
            CourierFactory.findParcelCourierPrices($scope.widget).success(success).error(error);
        }
    };


    $scope.onSelectPickUp = function ($item, $model, $label) {
        $scope.address_pickup.town = $item.SUBURB;
        $scope.address_pickup.state = $item.STATE;
        $scope.address_pickup.postcode = $item.POSTCODE;
    };

    $scope.onSelectDropOff = function ($item, $model, $label) {
        $scope.address_dropoff.town = $item.SUBURB;
        $scope.address_dropoff.state = $item.STATE;
        $scope.address_dropoff.postcode = $item.POSTCODE;
    };

    $scope.capturePickupAddress = function () {
//            console.log('capture address')
        $scope.address_pickup = pickup.get();
        var linetwo = $scope.address_pickup.linetwo === undefined ? '' : $scope.address_pickup.linetwo;
        $scope.widget.pickup_object = {};
        $scope.widget.pickup_object.lineone = $scope.address_pickup.lineone;
        $scope.widget.pickup_object.linetwo = linetwo;
        $scope.widget.pickup_object.rest = $scope.address_pickup.town.replace(/,/g, "") + ',' + $scope.address_pickup.state.replace(/,/g, "") + ',' + $scope.address_pickup.postcode;
        $scope.widget.pick_up = $scope.address_pickup.lineone.replace(/,/g, "") + ',' + linetwo.replace(/,/g, "") + ',' + $scope.address_pickup.town.replace(/,/g, "") + ',' + $scope.address_pickup.state.replace(/,/g, "") + ',' + $scope.address_pickup.postcode;
        $scope.widget.sender_name = $scope.address_pickup.sender_name;
        $scope.widget.sender_company = $scope.address_pickup.sender_company;
        $scope.widget.sender_email = $scope.address_pickup.sender_email;
        $scope.widget.sender_tel = $scope.address_pickup.sender_tel;
        $scope.widget.sender_mob = $scope.address_pickup.sender_mob;
        $scope.widget.sender_ref = $scope.address_pickup.sender_ref;
    };

    $scope.captureDropoffAddress = function () {
        $scope.address_dropoff = dropoff.get();
        $scope.widget.receiver_name = $scope.address_dropoff.receiver_name !== undefined ? $scope.address_dropoff.receiver_name : "Personal";
        $scope.widget.account_number = $scope.address_dropoff.account_number !== undefined ? $scope.address_dropoff.account_number : "";
        $scope.widget.special_instructions = $scope.address_dropoff.special_instructions !== undefined ? $scope.address_dropoff.special_instructions : "";
        $scope.widget.receiver_contact = $scope.address_dropoff.receiver_contact;
        $scope.widget.receiver_phone = $scope.address_dropoff.receiver_phone;
        $scope.widget.receiver_mobile = $scope.address_dropoff.receiver_mobile;
        $scope.widget.receiver_email = $scope.address_dropoff.receiver_email;
        $scope.widget.dropoff_object = {};
        $scope.widget.dropoff_object.lineone = $scope.address_dropoff.lineone;
        $scope.widget.dropoff_object.linetwo = linetwo;
        $scope.widget.dropoff_object.rest = $scope.address_dropoff.town.replace(/,/g, "") + ',' + $scope.address_dropoff.state.replace(/,/g, "") + ',' + $scope.address_dropoff.postcode;
        var linetwo = $scope.address_dropoff.linetwo === undefined ? '' : $scope.address_dropoff.linetwo;
        $scope.widget.drop_off = $scope.address_dropoff.lineone.replace(/,/g, "") + ',' + linetwo.replace(/,/g, "") + ',' + $scope.address_dropoff.town.replace(/,/g, "") + ',' + $scope.address_dropoff.state.replace(/,/g, "") + ',' + $scope.address_dropoff.postcode;
    };


}]);

/**
 * Compar Controller
 *
 * @param  {[type]} $scope        [description]
 * @param  {[type]} $cookieStore  [description]
 * @param  {[type]} $localStorage [description]
 * @param  {[type]} $modal        [description]
 * @param  {[type]} ENV)          {             $scope.order_widget [description]
 * @return {[type]}               [description]
 */
app.controller('CompareController', ['$scope','$rootScope', 'dateFilter', 'ngDialog', '$location', '$cookieStore', '$cookies', '$localStorage', '$modal', 'ENV', 'PartnersFactory', '$window', '$interval', 'blockUI', 'BasketFactory','PublicHolidayFactory','MarginGroupFactory','$timeout', function ($scope, $rootScope, dateFilter, ngDialog, $location, $cookieStore, $cookies, $localStorage, $modal, ENV, PartnersFactory, $window, $interval, blockUI, BasketFactory,PublicHolidayFactory,MarginGroupFactory,$timeout) {
    blockUI.start();

/*    if ($cookieStore.get('token') === undefined) {
        $location.path('/login');
    }*/


    var hrs = $scope.currentTime.hours;
    $scope.format = "HH:mm";
    $scope.order_widget = $localStorage.order_widget;
    // console.log($scope.order_widget);
    $scope.order_price_object = $localStorage.order_price_object;
    $scope.order_price_list = $localStorage.order_price_list;
    $scope.insurance = $localStorage.insurance;

    $scope.filterOptions = {2: "Express", 8: "Same Day", 24: "Overnight", 48: "Budget"};
    $scope.deliveryTime = "overnight";

    $scope.currentUser = $cookieStore.get('user');
    $scope.markup_percentage={};

    if ($cookieStore.get('user') !== undefined) {

      $scope.margin_group_id=  $scope.currentUser.account[0].margin_mgmnt_groups_id;



      getMarginGroup = function () {
          var success = function (object) {
              blockUI.stop();
              $scope.marginGroup = object.data;
              $scope.margin_group_markup =   $scope.marginGroup.markup_percentage;
              $scope.margin_group_discount =   $scope.marginGroup.discount_percentage;
              $scope.markup_percentage =   $scope.margin_group_markup / 100;
          };
          var error = function (object) {
              blockUI.stop();
              console.log(object);
          };
          blockUI.start();
          MarginGroupFactory.getMarginGroup($scope.margin_group_id).success(success).error(error);
      };

      getMarginGroup();

    } else {

          getDefaultMarginGroup = function () {
              var success = function (object) {
                  blockUI.stop();
                  $scope.marginGroup = object.data;
                  $scope.margin_group_markup =   $scope.marginGroup.markup_percentage;
                  $scope.margin_group_discount =   $scope.marginGroup.discount_percentage;
                  $scope.markup_percentage =   $scope.margin_group_markup / 100;
              };
              var error = function (object) {
                  blockUI.stop();
                  console.log(object);
              };
              blockUI.start();
              MarginGroupFactory.getMarginGroup(2).success(success).error(error);
          };

          getDefaultMarginGroup();
    }


    $scope.total = {
        "insurance": $scope.insurance.checked,
        "insurance_value": ($scope.insurance.checked) ? $scope.insurance.value : 0,
        "insurance_cost": ($scope.insurance.checked) ? $scope.insurance.cost : 0,
        "insurance_desc": ($scope.insurance.checked) ? $scope.insurance.desc : "No Insurance"
    };

    $scope.sum = function (total, insurance_cost) {
        return total + insurance_cost;
    };

    $scope.isTimeBefore = function (curTime, hours, min) {
        return (curTime.hours === hours && curTime.mins <= min) || curTime < hours;
    };

    $scope.priceSize = Object.keys($scope.order_price_list.Result).length;

    if ($scope.priceSize !== 0) {
        if ($localStorage.order_product !== undefined) {
            redirect();
        }
    }

    $rootScope.public_holidays=[];

    var success = function (object) {
        $rootScope.public_holidays = object.data;
         if ($rootScope.public_holidays.length !== 0){
             $rootScope.public_holiday_message = object.data[0].public_holiday
        }
        blockUI.stop();
    };
    var error = function (error) {
        $scope.status = 'Unable to load holiday data: ' + error.message;
        blockUI.stop();
    };
    PublicHolidayFactory.getPublicHolidays().success(success).error(error);

    $scope.partners = {};

    $scope.loyaltyDifference = 0;

    var success = function (object) {
        $scope.partners = object.data;
        $scope.cheapest = 999999999999999999999;
        $scope.cheapestFilter = "budget";

        var d = new Date();
        var offset = -2;
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        var nd = new Date(utc - (3600000 * offset));

        var hrs = nd.getHours();
        var mins = nd.getMinutes();

        angular.forEach($scope.order_price_list.Result, function (value1, key1) {

            value1.info = {};
            value1.info.show = false;
            angular.forEach($scope.partners, function (value2, key2) {
                if (value2.ifs_account == value1.CarrierAccount) {
                    value1.info = value2;
                }
            });

            value1.filterType = ""; // will be set below


            if (angular.lowercase(value1.CarrierService) == "pronto") {
                ////console.log('pronto');
                value1.filterType = "pronto";
                $scope.expressCount++;
            } else if (angular.lowercase(value1.CarrierService) == "sameday") {
                ////console.log('sameday');
                value1.filterType = "sameday";
                $scope.samedayCount++;
            } else if (angular.lowercase(value1.CarrierService) == "overnight") {
                ////console.log('overnight');
                value1.filterType = "overnight";
                $scope.overnightCount++;
            } else if (angular.lowercase(value1.CarrierService) == "budget") {
                ////console.log('budget');
                value1.filterType = "budget";
                $scope.budgetCount++;
            }

            value1.TotalCostPlusMarkupView = value1.TotalCostPlusMarkup ;
/*            value1. total_net_cost =Math.round( value1.TotalCostPlusMarkup.replace(/,/g, "").replace(" ", "") * 1e2 ) / 1e2;
            value1.insurance_cost =  Math.round(  ( $scope.total.insurance_cost / 1.15 ) * 1e2 ) / 1e2;
            value1. total_net_cost_plus_insurance =  Math.round(  (  value1. total_net_cost +   value1.insurance_cost ) * 1e2 ) / 1e2;
            value1.markup_cost = Math.round(  (value1.total_net_cost_plus_insurance *  $scope.markup_percentage) * 1e2 ) / 1e2;
            value1.gross_selling_price =   (value1. total_net_cost_plus_insurance +   value1.markup_cost).toFixed(2)

            console.log( 'Cost from IFS with insurance-------------------- ' +  value1.TotalCostPlusMarkupView);
            console.log( 'insurance ' +   value1.insurance_cost)
            console.log( 'total_net_cost_plus_insurance ' +   value1. total_net_cost_plus_insurance)
            console.log( 'markup cost ' + value1.markup_cost);
            console.log( 'gross selling price ' +   value1.gross_selling_price );*/


            //test teat tes
            value1.sortByPrice = parseFloat(value1.TotalCostPlusMarkup.replace(/,/g, "").replace(/\./g, ''));
//                if (value1.filterType == 'sameday')
//                {
            value1.sortByPriceDecimal = parseFloat(value1.TotalCostPlusMarkup.replace(/,/g, ""));
//                } else
//                {
//                    value1.sortByPriceDecimal = parseFloat(value1.TotalCostPlusMarkup.replace(/,/g, "54"));
//                }
            // console.log(value1);
            if ((value1.TotalCostPlusMarkup <= $scope.cheapest) && value1.info.show) {
                $scope.cheapest = value1.sortByPriceDecimal;
                if (($scope.cheapestFilter == 'overnight' || $scope.cheapestFilter == 'budget' || $scope.cheapestFilter == 'sameday') && value1.filterType == 'pronto' && hrs < 16) {
                    $scope.cheapest = value1.sortByPriceDecimal;
                    $scope.cheapestFilter = value1.filterType;
                } else if (($scope.cheapestFilter == 'overnight' || $scope.cheapestFilter == 'budget') && value1.filterType == 'sameday' && (hrs < 10) || (hrs === 10 && mins < 31)) {
                    $scope.cheapest = value1.sortByPriceDecimal;
                    $scope.cheapestFilter = value1.filterType;
                } else if ($scope.cheapestFilter == 'overnight' && value1.filterType == 'overnight') {
                    $scope.cheapest = value1.sortByPriceDecimal;
                    $scope.cheapestFilter = value1.filterType;
                } else if ($scope.cheapestFilter == 'budget' && value1.filterType == 'overnight') {
                    $scope.cheapest = value1.sortByPriceDecimal;
                    $scope.cheapestFilter = value1.filterType;
                } else if ($scope.cheapestFilter == 'budget' && value1.filterType == 'budget') {
                    $scope.cheapest = value1.sortByPriceDecimal;
                    $scope.cheapestFilter = value1.filterType;
                }
            }
        });

        $scope.deliveryTime = $scope.cheapestFilter;
        cheapestValue = 1;
        if ($scope.cheapestFilter == "sameday") {
            cheapestValue = 2;
        } else if ($scope.cheapestFilter == "overnight") {
            cheapestValue = 3;
        } else if ($scope.cheapestFilter == "budget") {
            cheapestValue = 4;
        }

        if ($scope.order_widget.businessRef != null && $scope.order_widget.businessRef == "AMROD") {
            if ($scope.cheapest <= 75 && $scope.cheapest > 49.95) {
                $scope.loyaltyDifference = $scope.cheapest - 49.95;
                // console.log("Loyalty difference = " + $scope.loyaltyDifference);
            }
        }
        angular.element("#ex13").slider('setValue', cheapestValue);
        //  console.log("Cheapest is " + $scope.deliveryTime + " @ " + $scope.cheapest);
        blockUI.stop();
        d = new Date();
        n = d.getDay();
        offset = -2;
        utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        nd = new Date(utc - (3600000 * offset));

        hrs = nd.getHours();
        mins = nd.getMinutes();

        if ($rootScope.public_holidays.length !== 0){
           ngDialog.open({
               template: 'withInlineController',
               controller: ['$scope', '$timeout', function ($scope, $timeout) {
                       $scope.msg = $rootScope.public_holiday_message;
                   }],
               className: 'ngdialog-theme-plain',
               msg: "This order will be placed on the next working day and delivered according to service selected.",
           });
        } else if ( n == 6 || n == 0 )  {
             ngDialog.open({
                 template: 'withInlineController',
                 controller: ['$scope', '$timeout', function ($scope, $timeout) {
                         $scope.msg = "This order will be placed on Monday and delivered according to service selected.";
                     }],
                 className: 'ngdialog-theme-plain',
                 msg: "This order will be placed on Monday and delivered according to service selected."
             });
         } else if (checkTimeLimitForServiceType($scope.deliveryTime, hrs, mins)) {

           if (n == 5 && $scope.currentTime.hours >= 15)  {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = "This order will be placed on Monday and delivered according to service selected.";
                        }],
                    className: 'ngdialog-theme-plain',
                    msg: "This order will be placed on Monday and delivered according to service selected."
                });
            } else if ($scope.deliveryTime === 'sameday' && hrs >= 10 && !(hrs >= 13 )) {
               ngDialog.open({
                   template: 'withInlineController',
                   controller: ['$scope', '$timeout', function ($scope, $timeout) {
                       $scope.msg = "You have missed the Sameday cut-off time. Choose Overnight or Budget service.";
                   }],
                   className: 'ngdialog-theme-plain',
                   msg: "You have missed the Sameday cut-off time. Choose Overnight or Budget service.",
               });
           } else if ($scope.deliveryTime === 'overnight' && hrs >= 13  && !(hrs >= 14 ) ) {
              ngDialog.open({
                  template: 'withInlineController',
                  controller: ['$scope', '$timeout', function ($scope, $timeout) {
                      $scope.msg = "You have missed the Overnight cut-off time. Choose Pronto or Budget service.";
                  }],
                  className: 'ngdialog-theme-plain',
                  msg: "You have missed the Overnight cut-off time. Choose Pronto or Budget service.",
              });
          }   else if ($scope.deliveryTime === 'sameday' && hrs >= 10 && !(hrs >= 14 )) {
               ngDialog.open({
                   template: 'withInlineController',
                   controller: ['$scope', '$timeout', function ($scope, $timeout) {
                       $scope.msg = "You have missed the Sameday cut-off time. Choose Budget service.";
                   }],
                   className: 'ngdialog-theme-plain',
                   msg: "You have missed the Sameday cut-off time. Choose Budget service.",
               });
           } else if (($scope.deliveryTime === 'sameday' || $scope.deliveryTime === 'overnight' || $scope.deliveryTime === 'budget' ) && (hrs >= 14  && !(hrs >= 15 ) ) ) {
               ngDialog.open({
                   template: 'withInlineController',
                   controller: ['$scope', '$timeout', function ($scope, $timeout) {
                       $scope.msg = "You have missed the Sameday, Overnight and Budget services. Choose Pronto service.";
                   }],
                   className: 'ngdialog-theme-plain',
                   msg: "You have missed the Sameday, Overnight and Budget services. Choose Pronto service.",
               });
           }   else {
               ngDialog.open({
                   template: 'withInlineController',
                   controller: ['$scope', '$timeout', function ($scope, $timeout) {
                       $scope.msg = "This order will be placed tomorrow and delivered according to service selected.";
                   }],
                   className: 'ngdialog-theme-plain',
                   msg: "This order will be placed tomorrow and delivered according to service selected.",
               });
           }

        };
        $timeout(function () {
            blockUI.stop();
        }, 2000);
    };


    var error = function (object) {
        ////console.log(object.message);
        $timeout(function () {
            blockUI.stop();
        }, 2000);
    };

    PartnersFactory.getPartners().success(success).error(error);

    // $scope.changeDeliveryTime = function (type){
    //   $scope.deliveryTime = type;
    // };

    $scope.confirmBooking = function (option) {

        $scope.storage = $localStorage.$default({'order_product': option});
        ngDialog.open({
            template: 'withInlineControllerServiceSelectedConfirmation',
            controller: ['$scope', '$timeout', '$localStorage', function ($scope, $timeout, $localStorage) {
                $scope.msg = "You have selected a " + option.CarrierService.toLowerCase() + " service.";
                $scope.bookNow = function (option) {
                    var order_widget = $localStorage.order_widget;
                    if (order_widget.ordertype === "quickquote") {
                        redirect();
                    } else {
                        redirectsummary();
                    }
                };
            }],
            className: 'ngdialog-theme-plain',
            msg: "You have selected a " + option.CarrierService.toLowerCase() + " service."
        });
    }


    function redirect() {
        if ($cookieStore.get('token') !== undefined) {
            $location.path('/fullquote');
        } else {
            $location.path('/login_quick_quote');
        }
    }
    ;

    function redirectsummary() {
        if ($cookieStore.get('token') !== undefined) {
            var success = function (object) {
                blockUI.stop();
                $location.path('/basket');

            };

            // On failure
            var error = function (object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = 'Sorry, we failed to add this to your basket.';
                    }],
                    className: 'ngdialog-theme-default',
                    msg: 'Sorry, we failed to add this to your basket.'
                });
                blockUI.stop();
            };

            createOrderObject();
            blockUI.start();

            BasketFactory.addBasketItem($scope.order).success(success).error(error);

        } else {
            $location.path('/login_quick_quote');
        }
    }
    ;

    var createOrderObject = function () {
        // Now Date
        $scope.orderDate = new Date();
        // Get Local Storage
        var d = new Date();
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000); // getTimezoneOffset returns difference between UTC and currentTime
        var nd = new Date(utc + (3600000 * 2));// adds 2 hours to utc time
        var dt = dateFilter(nd, "yyyy-MM-dd HH:mm:ss");

        $scope.order_widget = $localStorage.order_widget;
        $scope.order_price_object = $localStorage.order_price_object;
        $scope.order_price_list = $localStorage.order_price_list;
        $scope.order_product = $localStorage.order_product;
        $scope.insurance = $localStorage.insurance;

        // Create Order
        $scope.order = $scope.order_product;
        $scope.order.order_date = dt;
        $scope.order.type = $scope.order_widget.type;
        $scope.order.account_id = $scope.currentUser.account[0].id;
//            $scope.order.company = $scope.currentUser.account[0].company;
//            $scope.order.company_vat = $scope.currentUser.account[0].company_vat;

        $scope.order.sender_name = $scope.order_widget.sender_name;
        $scope.order.sender_company = ($scope.order_widget.sender_company == undefined) ? "" : $scope.order_widget.sender_company;
        $scope.order.sender_email = $scope.order_widget.sender_email;
        $scope.order.sender_tel = $scope.order_widget.sender_tel;
        $scope.order.sender_mob = $scope.order_widget.sender_mob;
        $scope.order.sender_ref = ($scope.order_widget.sender_ref == undefined) ? "" : $scope.order_widget.sender_ref;

        $scope.order_widget.receiver_name = ($scope.order_widget.receiver_name !== undefined && $scope.order_widget.receiver_name.trim().length > 0) ? $scope.order_widget.receiver_name : "Personal";
        $scope.order.receiver_name = $scope.order_widget.receiver_name;
        $scope.order.receiver_account_no = $scope.order_widget.account_number;
        $scope.order.special_instructions = $scope.order_widget.special_instructions;
        $scope.order.receiver_contact = $scope.order_widget.receiver_contact;

        $scope.order.receiver_phone = $scope.order_widget.receiver_phone === 9 ? $scope.int_code + $scope.order_widget.receiver_phone : $scope.order_widget.receiver_phone;
        $scope.order.receiver_mobile = $scope.order_widget.receiver_mobile === 9 ? $scope.int_code + $scope.order_widget.receiver_mobile : $scope.order_widget.receiver_mobile;

        $scope.order.receiver_email = $scope.order_widget.receiver_email;
        $scope.order.pick_up = $scope.order_widget.pick_up;
        $scope.order.drop_off = $scope.order_widget.drop_off;
        $scope.order.insurance = $scope.insurance.checked;
        $scope.order.insurance_value = ($scope.insurance.checked) ? $scope.insurance.value : 0;
        $scope.order.insurance_cost = ($scope.insurance.checked) ? $scope.insurance.cost : 0;
        $scope.order.insurance_desc = ($scope.insurance.checked) ? $scope.insurance.desc : "No Insurance";
        $scope.order.insurance_text = ($scope.insurance.checked) ? "(incl. insurance for '" + $scope.insurance.desc + "' valued at R" + $scope.insurance.value + ")" : "(insurance not included)";

//        if ($scope.order_widget.docType !== undefined) {
        if ($scope.order_widget.businessRef == "AMROD") {
            $scope.order.docType = $scope.order_widget.docType;
            $scope.order.parcelType = $scope.order_widget.parcelType;
            $scope.order.parcel_desc = $scope.order_widget.parcel_desc;
            $scope.order.length = $scope.order_widget.length;
            $scope.order.width = $scope.order_widget.width;
            $scope.order.height = $scope.order_widget.height;
            $scope.order.weight = $scope.order_widget.weight; // convert weight to string
            $scope.order.amount = $scope.order_widget.amount;
            $scope.order.volumetric = $scope.order_widget.volumetric;
            $scope.order.linked_service = "AMROD";
            $scope.order.linked_service_order_ref = $scope.order_widget.documentNumber;
            $scope.order.loyaltyDifferenceNet = $scope.loyaltyDifference;
            $scope.order.loyaltyDifferenceVat = $scope.loyaltyDifference * 0.15;
            $scope.order.loyaltyDifferenceTotal = $scope.order.loyaltyDifferenceNet + $scope.order.loyaltyDifferenceVat;
            if ($scope.loyaltyDifference > 0) {
                $scope.order.TotalCostPlusMarkup = parseFloat($scope.order.TotalCostPlusMarkup.replace(/,/g, "")) - $scope.order.loyaltyDifferenceNet;
                $scope.order.TotalCostPlusMarkup = $scope.order.TotalCostPlusMarkup.toFixed(2);
                $scope.order.GSTOnMarkup = parseFloat($scope.order.GSTOnMarkup.replace(/,/g, "")) - $scope.order.loyaltyDifferenceVat;
                $scope.order.GSTOnMarkup = $scope.order.GSTOnMarkup.toFixed(2);
                $scope.order.grandtotmrkup = parseFloat($scope.order.grandtotmrkup.replace(/,/g, "")) - $scope.order.loyaltyDifferenceTotal;
                $scope.order.grandtotmrkup = $scope.order.grandtotmrkup.toFixed(2);

                $scope.order.loyaltyDifferenceNet = $scope.order.loyaltyDifferenceNet.toFixed(2);
                $scope.order.loyaltyDifferenceVat = $scope.order.loyaltyDifferenceVat.toFixed(2);
                $scope.order.loyaltyDifferenceTotal = $scope.order.loyaltyDifferenceTotal.toFixed(2);
                // console.log($scope.order);
            } else {
                $scope.order.loyaltyDifferenceNet = "0";
                $scope.order.loyaltyDifferenceVat = "0";
                $scope.order.loyaltyDifferenceTotal = "0";
            }
        } else if ($scope.order_widget.businessRef == "TOGSA") {
            $scope.order.docType = $scope.order_widget.docType;
            $scope.order.parcelType = $scope.order_widget.parcelType;
            $scope.order.parcel_desc = $scope.order_widget.parcel_desc;
            $scope.order.length = $scope.order_widget.length;
            $scope.order.width = $scope.order_widget.width;
            $scope.order.height = $scope.order_widget.height;
            $scope.order.weight = $scope.order_widget.weight; // convert weight to string
            $scope.order.amount = $scope.order_widget.amount;
            $scope.order.volumetric = $scope.order_widget.volumetric;
            $scope.order.linked_service = "TOGSA";
            $scope.order.linked_service_order_ref = $scope.order_widget.orderNumber;
            $scope.order.loyaltyDifferenceNet = "0"; //remove?
            $scope.order.loyaltyDifferenceVat = "0";
            $scope.order.loyaltyDifferenceTotal = "0";
        } else {
            $scope.order.docType = $scope.order_widget.docType;
            $scope.order.parcelType = $scope.order_widget.parcelType;
            $scope.order.length = $scope.order_widget.length;
            $scope.order.width = $scope.order_widget.width;
            $scope.order.height = $scope.order_widget.height;
            $scope.order.weight = $scope.order_widget.weight;
            $scope.order.amount = $scope.order_widget.amount;
            $scope.order.parcel_desc = $scope.order_widget.parcel_desc;
        }
        $scope.order.amt = $localStorage.totalAmount;
        $scope.order.cube = $localStorage.cube;
        $scope.order.wgt = $localStorage.totalWeight;
    };

    var sliderElem = angular.element("#ex13");
    $scope.tickValue = "";
    $scope.tickOptions = {
        1: 'pronto',
        2: 'sameday',
        3: 'overnight',
        4: 'budget'
    };
    $scope.window = "";
    angular.element("#ex13").slider({
        ticks: [1, 2, 3, 4],
        ticks_labels: ['Pronto', 'Sameday', 'Overnight', 'Budget'],
        ticks_snap_bounds: 1,
        value: 3,
        formatter: function (ticks_labels) {
            $scope.tickValue = ticks_labels;
        }
    });

    $interval(function () {
        $scope.deliveryTime = $scope.tickOptions[$scope.tickValue];
    }, 1000);

    $('#ex13').on('slideStop', function () {

        var d = new Date();
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        var nd = new Date(utc + (3600000 * 2));// adds 2 hours to utc time
        var n = nd.getDay();

        delivery = $scope.tickOptions[$scope.tickValue];
        if ($rootScope.public_holidays.length !== 0){
           ngDialog.open({
               template: 'withInlineController',
               controller: ['$scope', '$timeout', function ($scope, $timeout) {
                       $scope.msg = $rootScope.public_holiday_message;
                   }],
               className: 'ngdialog-theme-plain',
               msg: "This order will be placed on the next working day and delivered according to service selected.",
           });
        } else if ( n == 6 || n == 0 )  {
             ngDialog.open({
                 template: 'withInlineController',
                 controller: ['$scope', '$timeout', function ($scope, $timeout) {
                         $scope.msg = "This order will be placed on Monday and delivered according to service selected.";
                     }],
                 className: 'ngdialog-theme-plain',
                 msg: "This order will be placed on Monday and delivered according to service selected."
             });
        } else if (checkTimeLimitForServiceType(delivery, $scope.currentTime.hours, $scope.currentTime.mins)) {

            if (n == 5 && $scope.currentTime.hours >= 15)  {
                 ngDialog.open({
                     template: 'withInlineController',
                     controller: ['$scope', '$timeout', function ($scope, $timeout) {
                             $scope.msg = "This order will be placed on Monday and delivered according to service selected.";
                         }],
                     className: 'ngdialog-theme-plain',
                     msg: "This order will be placed on Monday and delivered according to service selected."
                 });
             } else if (delivery === 'sameday' && $scope.currentTime.hours >= 10 && !($scope.currentTime.hours >= 13 )) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "You have missed the Sameday cut-off time. Choose Overnight or Budget service.";
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: "You have missed the Sameday cut-off time. Choose Overnight or Budget service.",
                });
            } else if (delivery === 'overnight' && $scope.currentTime.hours >= 13  && !($scope.currentTime.hours >= 14 ) ) {
               ngDialog.open({
                   template: 'withInlineController',
                   controller: ['$scope', '$timeout', function ($scope, $timeout) {
                       $scope.msg = "You have missed the Overnight cut-off time. Choose Pronto or Budget service.";
                   }],
                   className: 'ngdialog-theme-plain',
                   msg: "You have missed the Overnight cut-off time. Choose Pronto or Budget service.",
               });
           }   else if (delivery === 'sameday' && $scope.currentTime.hours >= 10 && !($scope.currentTime.hours >= 14 )) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "You have missed the Sameday cut-off time. Choose Budget service.";
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: "You have missed the Sameday cut-off time. Choose Budget service.",
                });
            } else if ((delivery === 'sameday' || delivery === 'overnight' || delivery === 'budget' ) && ($scope.currentTime.hours >= 14  && !($scope.currentTime.hours >= 15 ) ) ) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "You have missed the Sameday, Overnight and Budget services. Choose Pronto service.";
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: "You have missed the Sameday, Overnight and Budget services. Choose Pronto service.",
                });
            }   else {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "This order will be placed tomorrow and delivered according to service selected.";
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: "This order will be placed tomorrow and delivered according to service selected.",
                });
            }
        }
        ;
    });

    var checkTimeLimitForServiceType = function (delivery, hrs, mins) {
        return (delivery == 'pronto' && hrs >= 15) || (delivery == 'sameday' && hrs >= 10 ) || (delivery == 'budget' && hrs >= 14)  || (delivery == 'overnight' && hrs >= 13);
    };

}]);

/**
 * PayU Cancel Controller
 */
app.controller('PayUCancelController', ['$routeParams', 'ngDialog', 'payUFactory', '$window', '$location', '$scope', '$location', '$cookieStore', '$localStorage', 'BusinessFactory', 'OrderFactory', 'blockUI', 'notifications', 'ngProgress', '$modal', 'ENV', function ($routeParams, ngDialog, payUFactory, $window, $location, $scope, $location, $cookieStore, $localStorage, BusinessFactory, OrderFactory, blockUI, notifications, ngProgress, modal, ENV) {

    $scope.orderId = $routeParams.orderId;
    $scope.payUReference = $routeParams.payUReference;

    blockUI.start();

    var success = function (object) {
        // Reset The local Storage
        $localStorage.$reset();
        blockUI.stop();

        $scope.order = object.order;
        // Show Success Message
        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = "Your order has been successfully cancelled."
            }],
            className: 'ngdialog-theme-plain',
            msg: "Your order has been successfully cancelled.",
        });
    };

    var error = function (object) {
        // Reset The local Storage
        $localStorage.$reset();
        blockUI.stop();

        // Show Success Message
        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = "Could not cancel your order."
            }],
            className: 'ngdialog-theme-plain',
            msg: "Could not cancel your order.",
        });
    };

    $post = {"orderId": $scope.orderId, "payUReference": $scope.payUReference};
    //BusinessFactory.payUCancelOrder($post).success(success).error(error);
}]);

/**
 * PayU Controller
 */
app.controller('PayUController', ['$routeParams', 'ngDialog', 'payUFactory', '$window', '$location', '$scope', '$location', '$cookieStore', '$localStorage', 'BusinessFactory', 'OrderFactory', 'blockUI', 'notifications', 'ngProgress', '$modal', 'ENV', function ($routeParams, ngDialog, payUFactory, $window, $location, $scope, $location, $cookieStore, $localStorage, BusinessFactory, OrderFactory, blockUI, notifications, ngProgress, modal, ENV) {

    $('._jsDynamicTitle').text('Rush - Payment Receipt');

    $scope.OrderGroupRef = $routeParams.orderGroupRef;
    $scope.PayUReference = $routeParams.PayUReference;
    $scope.walletTransaction = false;
    $scope.go = function (path) {
        if ($localStorage.newOrderTab === undefined) {
            $localStorage.$default({'newOrderTab': false});
        } else {
            $localStorage.newOrderTab = false;
        }
        if (path === 'NewOrder') {
            $localStorage.newOrderTab = true;
            path = 'account';
        }
        $location.search('PayUReference', null);
        $location.path(path);
    };

    var success = function (object) {

        if (object.locked === 0) {

            var success = function (object) {

                $scope.returnData = object.returnData;
                $scope.environment = object.environment;
                $scope.amount = $scope.returnData.return.basket.amountInCents / 100;
                $scope.currency = $scope.returnData.return.basket.currencyCode;
                $scope.description = $scope.returnData.return.basket.description;

                $scope.orderGroupRef = object.orderGroupRef;

                var success = function (object) {
                    // $location.search('PayUReference', null);
                    blockUI.stop();
                    $localStorage.$reset();
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.message;
                            //$scope.msg1 = object.message_2;
                            //$scope.msg2 = object.message_3;

                        }],
                        className: 'ngdialog-theme-plain',
                        msg: object.message,
                        //msg1: object.message_2,
                        //msg2: object.message_3
                    });
                };

                var error = function (object) {

                    blockUI.stop();

                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.error.message.message;
                        }],
                        className: 'ngdialog-theme-plain',
                        msg: object.error.message.message
                    });

                    $localStorage.$reset();
                };

                if ($scope.environment === 'production') {

                    blockUI.stop();
                    blockUI.start();
                    blockUI.message('Please wait while we process your order. Stopping this process will cancel your booking!');
                    $postData = {"OrderGroupRef": $scope.orderGroupRef, "status": 'Active'};
                    BusinessFactory.completeBasketOrders($postData).success(success).error(error);
                } else if ($scope.environment === 'staging') {

                    blockUI.stop();
                    $localStorage.$reset();
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = "Your payment successfully recorded on staging payu. Omitted 'complete order' for testing purpose.";
                        }],
                        className: 'ngdialog-theme-default',
                        msg: "Your payment successfully recorded on staging payu. Omitted 'complete order' for testing purpose."
                    });
                }
            };

            var error = function (object) {

                if (object.error.message.returnData !== undefined) {
                    $scope.returnData = object.error.message.returnData;
                    $scope.environment = object.error.message.environment;
                    $scope.amount = $scope.returnData.return.basket.amountInCents / 100;
                    $scope.currency = $scope.returnData.return.basket.currencyCode;
                    $scope.description = $scope.returnData.return.basket.description;
                }

                blockUI.stop();

                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Your PayU payment failed. Your order(s) can be found in Basket.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: "Your PayU payment failed. Your order(s) can be found in Basket."
                });
                $localStorage.$reset();
            };

            blockUI.stop();
            blockUI.start();
            $post = {
                "BasketOrderIds": object.BasketOrderIds,
                "OrderGroupRef": object.OrderGroupRef,
                "PayUReference": object.PayUReference
            };
            BusinessFactory.retrievePayUPaymentStatus($post).success(success).error(error);
        }
    };

    var error = function (object) {

        blockUI.stop();

        ngDialog.open({
            template: 'withInlineControllerCheckBasketOrder',
            controller: ['$scope', '$timeout', 'BasketFactory', 'blockUI', '$modalStack', function ($scope, $timeout, BasketFactory, blockUI, $modalStack) {
                $scope.redirectToBasket = function (id) {
                    $modalStack.dismissAll();
                    $window.location.href = object.error.message.redirectUrl;
                };
                $scope.msg = object.error.message.message;
            }],
            className: 'ngdialog-theme-default',
            msg: "object.message"
        });
        $localStorage.$reset();
    };

    blockUI.start();
    $postData = {"OrderGroupRef": $scope.OrderGroupRef, "PayUReference": $scope.PayUReference};
    BusinessFactory.isBasketOrderConfirmed($postData).success(success).error(error);

}]);


/**
 * Summary Controller
 *
 *
 * @param  $scope
 * @param  $cookieStore
 * @param  $localStorage
 * @param  OrderFactory
 * @param  notifications
 * @param  ngProgress
 * @return void
 */
app.controller('SummaryController', ['payUFactory', '$timeout', '$window', '$scope', 'ngDialog', '$location', '$cookieStore', '$cookies', '$localStorage', 'BusinessFactory', 'OrderFactory', 'blockUI', 'notifications', 'ngProgress', '$modal', 'ENV', function (payUFactory, $timeout, $window, $scope, ngDialog, $location, $cookieStore, $cookies, $localStorage, BusinessFactory, OrderFactory, blockUI, notifications, ngProgress, modal, ENV) {
    $scope.currentUser = $cookieStore.get('user');
    $('._jsDynamicTitle').text('Rush - Order Summary');
    ////console.log($scope.currentUser);

    // Redirect the user to the login screen if Token is undefined
    // if ($cookieStore.get('token') == undefined) {
    //     $location.path('/login');
    // }

    // Now Date
    $scope.orderDate = new Date();
    // Get Local Storage
    $scope.order_widget = $localStorage.order_widget;
    $scope.order_price_object = $localStorage.order_price_object;
    $scope.order_price_list = $localStorage.order_price_list;
    $scope.order_product = $localStorage.order_product;
    //console.log($scope.order_product);
    $scope.insurance = $localStorage.insurance;
    // Create Order
    $scope.order = $scope.order_product;
    $scope.order.type = $scope.order_widget.type;
    $scope.order.account_id = $scope.currentUser.account[0].id;
    $scope.order.company = $scope.currentUser.account[0].company;
    $scope.order.company_vat = $scope.currentUser.account[0].company_vat;
//        $scope.order.sender_name = ($scope.order_widget.sender_name == undefined) ? "" : $scope.order_widget.sender_name;
//        $scope.order.sender_email = ($scope.order_widget.sender_email == undefined) ? "" : $scope.order_widget.sender_email;
//        $scope.order.sender_tel = ($scope.order_widget.sender_tel == undefined) ? "" : $scope.order_widget.sender_tel;
    $scope.order.sender_ref = ($scope.order_widget.sender_ref == undefined) ? "" : $scope.order_widget.sender_ref;

    $scope.pickUpUser = {};
    $scope.pickUpUser.tel = $scope.currentUser.account[0].company_landline;

    if ($scope.order_widget.sender_name !== undefined && $scope.order_widget.sender_name.trim().length !== 0) {
        $scope.pickUpUser.first_name = $scope.order_widget.sender_name;
        $scope.pickUpUser.last_name = "";
        $scope.pickUpUser.email = ($scope.order_widget.sender_email == undefined || $scope.order_widget.sender_email.trim().length == 0) ? "" : $scope.order_widget.sender_email;
        $scope.pickUpUser.tel = ($scope.order_widget.sender_tel == undefined || $scope.order_widget.sender_tel.trim().length == 0) ? "" : $scope.order_widget.sender_tel;
    } else {
        $scope.pickUpUser.first_name = $scope.currentUser.first_name;
        $scope.pickUpUser.last_name = $scope.currentUser.last_name;
        $scope.pickUpUser.email = $scope.currentUser.email;
        $scope.pickUpUser.tel = $scope.currentUser.account[0].company_landline.length > 8 ? $scope.currentUser.account[0].company_landline : $scope.currentUser.account[0].mobile_number.replace('+27', '');
    }

    // Add the senders details here if same as business user
    $scope.order.sender_name = $scope.pickUpUser.first_name + ' ' + $scope.pickUpUser.last_name;
    $scope.order.sender_email = $scope.pickUpUser.email;

    $scope.int_code = "+27(0)";
    $scope.order.sender_tel = $scope.pickUpUser.tel === 9 ? $scope.int_code + $scope.pickUpUser.tel : $scope.pickUpUser.tel;

    $scope.order_widget.receiver_name = ($scope.order_widget.receiver_name !== undefined && $scope.order_widget.receiver_name.trim().length > 0) ? $scope.order_widget.receiver_name : "Personal";
    $scope.order.receiver_name = $scope.order_widget.receiver_name;
    $scope.order.receiver_account_no = $scope.order_widget.account_number;
    $scope.order.special_instructions = $scope.order_widget.special_instructions;
    $scope.order.receiver_contact = $scope.order_widget.receiver_contact;

    $scope.order.receiver_phone = $scope.order_widget.receiver_phone === 9 ? $scope.int_code + $scope.order_widget.receiver_phone : $scope.order_widget.receiver_phone;
    $scope.order.receiver_mobile = $scope.order_widget.receiver_mobile === 9 ? $scope.int_code + $scope.order_widget.receiver_mobile : $scope.order_widget.receiver_mobile;

    $scope.order.receiver_email = $scope.order_widget.receiver_email;
    $scope.order.pick_up = $scope.order_widget.pick_up;
    $scope.order.drop_off = $scope.order_widget.drop_off;
    $scope.order.weight = $scope.order_widget.weight;
    $scope.order.insurance = $scope.insurance.checked;
    $scope.order.insurance_value = ($scope.insurance.checked) ? $scope.insurance.value : 0;
    $scope.order.insurance_cost = ($scope.insurance.checked) ? $scope.insurance.cost : 0;
    $scope.order.insurance_desc = ($scope.insurance.checked) ? $scope.insurance.desc : "No Insurance";
    $scope.order.insurance_text = ($scope.insurance.checked) ? "(incl. insurance for '" + $scope.insurance.desc + "' valued at R" + $scope.insurance.value + ")" : "(insurance not included)";

//        if ($scope.order_widget.docType !== undefined) {
    $scope.order.docType = $scope.order_widget.docType;
    $scope.order.parcelType = $scope.order_widget.parcelType;
    $scope.order.length = $scope.order_widget.length;
    $scope.order.width = $scope.order_widget.width;
    $scope.order.height = $scope.order_widget.height;
    $scope.order.amount = $scope.order_widget.amount;
    $scope.order.amt = $localStorage.totalAmount;
    $scope.order.cube = $localStorage.cube;
    $scope.order.wgt = $localStorage.totalWeight;
//        }

    $scope.baskets = $cookies.basket;
    if ($cookies.basket !== undefined) {
        $scope.basket = $cookies.basket;
        var len = $scope.basket.length;
        $scope.basket[len + 1] = $scope.order;
    } else {
        $scope.basket = {};
        $scope.basket[0] = $scope.order;
        $cookies.basket = JSON.stringify($scope.basket);
    }
    $scope.termsLink = "";

    if ($scope.order_product.CarrierName == 'SKYNET') {
        $scope.termsLink = '/skynet-terms';
    } else if ($scope.order_product.CarrierName == 'THE COURIER GUY') {
        $scope.termsLink = '/tcg-terms';
    } else if ($scope.order_product.CarrierName == 'ARAMEX') {
        $scope.termsLink = '/aramex-terms';
    } else if ($scope.order_product.CarrierName == 'COURIER IT') {
        $scope.termsLink = '/courier-it-terms';
    } else if ($scope.order_product.CarrierName == 'GLOBE FLIGHT') {
        $scope.termsLink = '/globeflight-terms';
    }

    $scope.user_type = $scope.currentUser.account[0].user_type;
    if ($scope.user_type === undefined || $scope.user_type === '' || $scope.user_type === null || $scope.user_type == 3) {
        $scope.user_type = 'business';
    } else if ($scope.user_type == 4) {
        $scope.user_type = 'business_plus';
    }

}]);
/**
 * Success Controller
 *
 * @param  {[type]} $scope         [description]
 * @param  {[type]} CourierFactory [description]
 * @param  {[type]} $routeParams   [description]
 * @param  {[type]} $localStorage  [description]
 * @param  {Object} ngProgress)    {             ngProgress.color('#d20001');  ngProgress.start();  $scope.complete [description]
 * @return {[type]}                [description]
 */
app.controller('SuccessController', ['$scope', 'ngDialog', 'CourierFactory', '$routeParams', '$localStorage', 'ngProgress', 'blockUI', function ($scope, ngDialog, CourierFactory, $routeParams, $localStorage, ngProgress, blockUI) {
//  ngProgress.color('#d20001');
//  ngProgress.start();
    blockUI.start();
    $scope.complete = {
        'order_id': $routeParams.order,
        'status': 'Active'
    };
    $scope.message = '';
    var success = function (object) {
        ////console.log(object);
//    ngProgress.complete();
        blockUI.stop();
        $localStorage.reset;
        $scope.message = 'Your order is successful. Expect an email confirming your order. Your Order ID is ' + $routeParams.order;
    };
    var error = function (object) {
        ////console.log(object);
//    ngProgress.complete();
        blockUI.stop();
        $scope.message = 'Your order has failed. Please contact support. Your Order ID is ' + $routeParams.order;
    };
    CourierFactory.completeOrder($scope.complete).success(success).error(error);
}]);
/**
 * Cancel Controller
 *
 * @param  {[type]} $scope         [description]
 * @param  {[type]} CourierFactory [description]
 * @param  {[type]} $routeParams   [description]
 * @param  {[type]} $localStorage  [description]
 * @param  {Object} ngProgress)    {             ngProgress.color('#d20001');  ngProgress.start();  $scope.complete [description]
 * @return {[type]}                [description]
 */
app.controller('CancelController', ['$scope', 'ngDialog', 'CourierFactory', '$routeParams', '$localStorage', 'ngProgress', 'blockUI', function ($scope, ngDialog, CourierFactory, $routeParams, $localStorage, ngProgress, blockUI) {
//  ngProgress.color('#d20001');
//  ngProgress.start();
    blockUI.start();
    $scope.complete = {
        'order_id': $routeParams.order,
        'status': 'Cancelled'
    };
    $scope.message = '';
    var success = function (object) {
        ////console.log(object);
//    ngProgress.complete();
        blockUI.stop();
        $localStorage.reset;
        $scope.message = 'Your order has been cancelled.'
    };
    var error = function (object) {
        ////console.log(object);
//    ngProgress.complete();
        blockUI.stop();
        $scope.message = 'Your order has failed to be cancelled. Please contact support. Your Order ID is ' + $routeParams.order;
    };
    CourierFactory.completeOrder($scope.complete).success(success).error(error);
}]);
/**
 * Reset Password Controller
 *
 * @param  $scope
 * @param  AccountFactory
 * @param  $location
 * @param  $routeParams
 * @param  $cookieStore
 * @return void
 */
app.controller('ResetPasswordController', ['$scope', 'ngDialog', 'AccountFactory', '$location', '$routeParams', '$cookieStore', 'notifications', function ($scope, ngDialog, AccountFactory, $location, $routeParams, $cookieStore, notifications) {
    ////console.log($routeParams.code);
    ////console.log($routeParams.id);

    $scope.resetPasswordAlerts = [];
    $scope.user = {
        'id': $routeParams.id,
        'reset_code': $routeParams.code
    };
    $scope.processResetPasswordForm = function () {
        ////console.log($scope.user);
        var success = function (object) {
            ////console.log(object);
            // notifications.showSuccess({message: object.data.message});

            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.data.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.data.message
            });
            $scope.user = {};
        };
        var error = function (object) {
            ////console.log(object);
            //notifications.showError({message: object.error.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
        };
        AccountFactory.newPassword($scope.user).success(success).error(error);
    };
}]);
/**
 * Account Controller
 *
 * @param  $scope
 * @param  AccountFactory
 * @param  $location
 * @return void
 */
app.controller('AccountController', ['$scope', 'ngDialog', 'AccountFactory', '$location', 'blockUI', '$rootScope', '$timeout', '$cookieStore', 'AmrodFactory', 'TradeOnlyGiftFactory', function ($scope, ngDialog, AccountFactory, $location, blockUI, $rootScope, $timeout, $cookieStore, AmrodFactory, TradeOnlyGiftFactory) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $scope.isAmrodUser;
    var $request = {};
    var user = $cookieStore.get('user');
    if (user !== undefined) {
        var $accountId = user.account[0].id;
        $timeout(function () {
            blockUI.stop();
        }, 500);

        $scope.isAmrodUser = false;
        $scope.isTradeOnlyGiftUser = false;

        var getServicesSuccess = function (object) {
            if (object.success === true) {
                for (var i = 0; i < object.services.length; i++) {
                    if (object.services[i].service == "AMROD") {
                        $scope.isAmrodUser = true;
                    } else if (object.services[i].service == "TOGSA") {
                        $scope.isTradeOnlyGiftUser = true;
                    }
                }
            }
        };

        AccountFactory.getServices($accountId).success(getServicesSuccess).error(function () {
        });
    } else {
        blockUI.stop();
    }

}]);
/**
 * Profile Controller
 *
 * @param  {[type]} $scope         [description]
 * @param  {[type]} AccountFactory [description]
 * @param  {[type]} $location      [description]
 * @param  {[type]} notifications  [description]
 * @param  {Object} ngProgress)    {             $scope.account [description]
 * @return {[type]}                [description]
 */
app.controller('ProfileController', ['$scope', 'ngDialog', 'AccountFactory', '$location', 'notifications', 'ngProgress', '$filter', 'blockUI', '$route', function ($scope, ngDialog, AccountFactory, $location, notifications, ngProgress, $filter, blockUI, $route) {
    // Declarations
    $scope.account = {};
    $scope.disableFields = true;

    $scope.provinces = [
        {label: 'Select a province', value: ''},
        {label: 'Eastern Cape', value: 'Eastern Cape'},
        {label: 'Free State', value: 'Free State'},
        {label: 'Gauteng', value: 'Gauteng'},
        {label: 'KwaZulu Natal', value: 'KwaZulu Natal'},
        {label: 'Limpopo', value: 'Limpopo'},
        {label: 'Mpumalanga', value: 'Mpumalanga'},
        {label: 'North West', value: 'North West'},
        {label: 'Northern Cape', value: 'Northern Cape'},
        {label: 'Western Cape', value: 'Western Cape'}
    ];

    $scope.titles = [
        {label: 'Mr', value: 'Mr'},
        {label: 'Miss', value: 'Miss'},
        {label: 'Mrs', value: 'Mrs'},
        {label: 'Ms', value: 'Ms'},
        {label: 'Dr', value: 'Dr'},
        {label: 'Prof', value: 'Prof'}
    ];

    $scope.areaCodes = [
        {label: 'ZAF +27', value: '+27 (0)'},
        {label: 'AGO +244 ', value: '+244 (0)'},
        {label: 'BWA +267', value: '+267 (0)'},
        {label: 'BDI +257', value: '+257 (0)'},
        {label: 'COM +269', value: '+269 (0)'},
        {label: 'LSO +266', value: '+266 (0)'},
        {label: 'MDG +261', value: '+261 (0)'},
        {label: 'MWI +265', value: '+265 (0)'},
        {label: 'MUS +230', value: '+230 (0)'},
        {label: 'MOZ +258', value: '+258 (0)'},
        {label: 'NAM +264', value: '+264 (0)'},
        {label: 'REU +262', value: '+262 (0)'},
        {label: 'SYC +248', value: '+248 (0)'},
        {label: 'SWZ +268', value: '+268 (0)'},
        {label: 'ZMB +260', value: '+260 (0)'},
        {label: 'ZWE +263', value: '+263 (0)'}
    ];

    $scope.onAddressSelect = function (item, model, label) {
        $scope.account.suburb = item.SUBURB;
        $scope.account.state = item.STATE;
        $scope.account.postcode = item.POSTCODE;
    };

    $scope.validateSuburb = function (suburb) {
        function checkSuburb(item) {
            if (suburb.target.value.trim().length === 0) {
                return false;
            }
            return ((item.SUBURB.toLowerCase() + ', ' + item.STATE.toLowerCase() + ', ' + item.POSTCODE).indexOf(suburb.target.value.toLowerCase()) == 0) && (item.USE === 'Yes');
        }

        var item = $scope.detailedSuburbsWithCities.filter(checkSuburb);

        $scope.account.suburb = '';
        $scope.account.state = '';
        $scope.account.postcode = '';

        if (item.length > 0) {
            $scope.account.address = item[0].SUBURB + ', ' + item[0].STATE + ', ' + item[0].POSTCODE + ' ';
            $scope.account.suburb = item[0].SUBURB;
            $scope.account.state = item[0].STATE;
            $scope.account.postcode = item[0].POSTCODE;
        }
    };

    var success = function (object) {
        $scope.account = object.data;
        $scope.account.titleValue = $scope.titles[$filter('getKey')($scope.titles, $scope.account.title)];
        $scope.account.provinceValue = $scope.provinces[$filter('getKey')($scope.provinces, $scope.account.province)];
        $scope.account.address = ($scope.account.suburb.length > 0) ? $scope.account.suburb + ', ' + $scope.account.state + ', ' + $scope.account.postcode : '';
        $scope.account.mobile_number = formatNumber($scope.account.mobile_number);
        $scope.account.landline = formatNumber($scope.account.landline);
        $scope.account.company_landline = formatNumber($scope.account.company_landline);
        var icm = extractIntCode($scope.account.mobile_number);
        var icl = extractIntCode($scope.account.landline);
        var icc = extractIntCode($scope.account.company_landline);
        $scope.account.int_code_mobile = $scope.areaCodes[0];
        $scope.account.int_code_landline = $scope.areaCodes[0];
        $scope.account.int_code_company = $scope.areaCodes[0];
        $scope.account.int_code_mobile = icm === "" ? $scope.account.int_code_mobile : $scope.areaCodes[$filter('getKey')($scope.areaCodes, icm)];
        $scope.account.int_code_landline = icl === "" ? $scope.account.int_code_landline : $scope.areaCodes[$filter('getKey')($scope.areaCodes, icl)];
        $scope.account.int_code_company = icc === "" ? $scope.account.int_code_company : $scope.areaCodes[$filter('getKey')($scope.areaCodes, icc)];
        $scope.account.mobile_number = $scope.account.mobile_number.replace($scope.account.int_code_mobile.value, "");
        $scope.account.landline = $scope.account.landline.replace($scope.account.int_code_landline.value, "");
        $scope.account.company_landline = $scope.account.company_landline.replace($scope.account.int_code_company.value, "");
        angular.element('.requiredInput').removeClass('ng-pristine');
        angular.element('.requiredInput').removeClass('ng-untouched');
        blockUI.stop();
    };

    var extractIntCode = function (number) {
        var code = number.substring(0, number.indexOf(")") == -1 ? 0 : number.indexOf(")") + 1);
        return code;
    };

    var formatNumber = function (number) {
        return (number !== undefined || number !== null) ? number.replace("+27 (0)", "").replace("+27", "") : '';
    };

    var error = function (object) {
        //notifications.showError({message: object.error.message});
        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = object.error.message;
            }],
            className: 'ngdialog-theme-default',
            msg: object.error.message
        });
        blockUI.stop();
    };

    if ($scope.currentUser !== null) {
        blockUI.start();
        AccountFactory.getProfile($scope.currentUser.id).success(success).error(error);
    } else {
        $location.path('/login');
    }

    // Reset your password on forgot password call.
    $scope.processResetPasswordForm = function () {
        var success = function (object) {
            //notifications.showSuccess({message: object.data.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.data.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.data.message
            });

            blockUI.stop();
        };
        var error = function (object) {
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
            blockUI.stop();
        };

        blockUI.start();
        AccountFactory.resetPassword($scope.account).success(success).error(error);
    };

    // Choose Edit Profile
    $scope.editFields = function () {
        $scope.disableFields = false;
        ////console.log(disableFields);
        angular.element('.edit-btn').addClass('hide');
        angular.element('.update-btn').removeClass('hide');
        angular.element('.requiredInput').addClass('ng-dirty');
        angular.element('.requiredInput').addClass('ng-touched');

    };

    // Choose cancel edit Profile
    $scope.cancelEdits = function () {
        $scope.disableFields = true;
        angular.element('.edit-btn').removeClass('hide');
        angular.element('.update-btn').addClass('hide');
        angular.element('.requiredInput').removeClass('ng-dirty');
        angular.element('.requiredInput').removeClass('ng-touched');
        $route.reload();
    };

    // Choose Save updated profile
    $scope.updateFields = function () {
        $scope.disableFields = true;
        $scope.update = $scope.account;
        $scope.update.title = $scope.account.titleValue === undefined ? '' : $scope.account.titleValue.value;
        $scope.update.province = $scope.account.provinceValue === undefined ? '' : $scope.account.provinceValue.value;
        if ($scope.update.address.trim() === '') {
            $scope.account.suburb = '';
            $scope.account.state = '';
            $scope.account.postcode = '';
        }


        var success = function (object) {
            ////console.log(object);
            //notifications.showSuccess({message: });

            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = 'Successfully updated your profile.';
                }],
                className: 'ngdialog-theme-plain',
                msg: 'Successfully updated your profile.'
            });
            angular.element('.update-btn').addClass('hide');
            angular.element('.edit-btn').removeClass('hide');
            blockUI.stop();
            $route.reload();
        };
        var error = function (object) {
            ////console.log(object);
            //notifications.showError({message: 'Error. Failed to update your profile.'});

            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: 'Error. Failed to update your profile.'
            });
            angular.element('.update-btn').addClass('hide');
            angular.element('.edit-btn').removeClass('hide');
            blockUI.stop();
            $route.reload();
        };
        blockUI.start();
        AccountFactory.updateProfile($scope.update.id, $scope.update).success(success).error(error);
    };
}]);


/**
 * Order Feedback Controller
 */
app.controller('OrderFeedbackController', ['$modalStack', '$scope', 'ngDialog', '$location', '$location', 'notifications', 'ngProgress', '$modal', 'ENV', 'blockUI', 'OrderFactory', function ($modalStack, $scope, ngDialog, $location, $location, notifications, ngProgress, $modal, ENV, blockUI, OrderFactory) {

    $scope.orderId = $scope.order_id;
    $scope.processOrderFeedbackForm = function () {

        $modalStack.dismissAll();

        var success = function (object) {
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = 'Thank you for your feedback.';
                }],
                className: 'ngdialog-theme-plain',
                msg: 'Thank you for your feedback.'
            });
        };

        var error = function (object) {
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = 'Could not send feedback email.';
                }],
                className: 'ngdialog-theme-plain',
                msg: 'Could not send feedback email.'
            });
        };
        $postData = {"orderId": $scope.orderId, "message": $scope.orderfeedback.message};
        OrderFactory.updateOrderFeedback($postData).success(success).error(error);
    };
}]);


/**
 * Order History Controller
 *
 * @param  $scope
 * @param  AccountFactory
 * @param  $location
 * @return void
 */
app.controller('OrderHistoryController', ['$scope', 'ngDialog', '$location', 'AccountFactory', '$location', 'notifications', 'ngProgress', '$modal', 'ENV', 'blockUI', 'OrderFactory', 'TrackingFactory', '$compile', '$timeout', function ($scope, ngDialog, $location, AccountFactory, $location, notifications, ngProgress, $modal, ENV, blockUI, OrderFactory, TrackingFactory, $compile, $timeout) {
    $scope.ordersFound = true;
    $scope.waybill_info = false;

    $scope.totalOrders = 0;
    $scope.ordersPerPage = 10; // this should match however many results your API puts on one page
    if ($scope.currentUser !== null) {
        getResultsPage(1);
        $scope.pagination = {
            current: 1
        };
    } else {
        $location.path('/login');
    }

    $scope.clicked_once = {};
    $scope.order_star_count = 0;

    //add rating
    $scope.addRating = function (e, $id, $locked) {

        if ($locked == false && isOrderSelected($id) == false) {
            var width = (document.getElementById("Fr" + $id).offsetWidth) - 2;
            var xCoor = e.offsetX;

            var percent = (xCoor / width) * 100;
            if (percent < 101) {
                var rating_decimal = ("" + (percent / 100) * 5 + "").substr(0, 3);
                if (rating_decimal.substr(-2) == ".9") {
                    rating_decimal = Math.round(rating_decimal, 2);
                }
                var data_title = document.getElementById("Fr" + $id);
                data_title.setAttribute("data-title", "Set rating of " + rating_decimal);

                var star_value = document.getElementById("Fr-value" + $id);
                star_value.setAttribute("style", "width:" + percent + "%;")
                //$scope.originStarValue = "width:" + (percent - 4) + "%;";

                var success = function (object) {

                    $scope.order_id = $id;

                    $scope.clicked_once[$scope.order_star_count] = $scope.order_id;
                    $scope.order_star_count = $scope.order_star_count + 1;

                    if (object.rate <= 2) {
                        $modal.open({
                            backdrop: 'static',
                            keyboard: false,
                            size: '',
                            templateUrl: ENV.APP_DIR + 'views/order_feedback.html',
                            controller: 'OrderFeedbackController',
                            scope: $scope
                        });
                    }
                };

                var error = function (object) {
                };

                $postData = {"orderId": $id, "rate": rating_decimal};
                OrderFactory.updateOrderRate($postData).success(success).error(error);

            }
        }
    };

    //undo rating
    $scope.undoRating = function (e, $id, $locked) {

        if ($locked == false) {
            var success = function (object) {

                var percent = (object / 5) * 100;
                if (object == undefined || object == null || object <= 0) {
                    var star_value = document.getElementById("Fr-value" + $id);
                    star_value.setAttribute("style", "width:0%;")
                } else {
                    if (percent >= 97) {
                        percent = 97;
                    }
                    var star_value = document.getElementById("Fr-value" + $id);
                    star_value.setAttribute("style", "width:" + (percent) + "%;")
                }
            };

            var error = function (object) {
                var star_value = document.getElementById("Fr-value" + $id);
                star_value.setAttribute("style", "width:0%;")
            };

            $postData = {"orderId": $id};
            OrderFactory.getOrderRate($postData).success(success).error(error);
        }
    };

    //process rating
    $scope.processRating = function (e, $id, $rate_percentage, $rate, $locked) {

        if ($locked == false && isOrderSelected($id) == false) {
            var width = document.getElementById("Fr" + $id).offsetWidth;
            var xCoor = e.offsetX;

            var percent = (xCoor / width) * 100;
            if (percent < 101) {
                var rating_decimal = ("" + ((percent + 3) / 100) * 5 + "").substr(0, 3);
                if (rating_decimal.substr(-2) == ".9") {
                    rating_decimal = Math.round(rating_decimal, 2);
                }
                var data_title = document.getElementById("Fr" + $id);
                if (rating_decimal > 5.0) {
                    rating_decimal = 5.0;
                }
                data_title.setAttribute("data-title", "Set rating of " + rating_decimal);

                var star_value = document.getElementById("Fr-value" + $id);
                star_value.setAttribute("style", "width:" + (percent - 1) + "%;")
            }
        } else if ($locked == true) {
            var data_title = document.getElementById("Fr" + $id);
            data_title.setAttribute("data-title", "Already rated to " + $rate);
        } else {
            var data_title = document.getElementById("Fr" + $id);
            data_title.setAttribute("data-title", "Recently rated");
        }

    };

    function isOrderSelected($orderId) {

        var $length = Object.keys($scope.clicked_once).length;
        for ($i = 0; $i < $length; $i++) {
            if ($scope.clicked_once[$i] == $orderId) {
                return true;
            }
        }

        return false;
    }
    ;

    // Change Page you are on
    $scope.pageChanged = function (newPage) {
        getResultsPage(newPage);
    };

    // Show Order information
    $scope.viewOrderInfo = function (orderItem, order) {
        //console.log(orderItem);
        //console.log(order);
        $scope.orderInfo = orderItem;
        $scope.orderGroup = order;
        // var modalInstance = $modal.open({
        //     size: '',
        //     templateUrl: 'app/partials/_order_info.html',
        //     controller: 'ModalInstanceController',
        //     scope: $scope
        // });
        $modal.open({
            size: 'lg',
            templateUrl: ENV.APP_DIR + 'partials/_order_info.html',
            controller: 'OrderInfoController',
            scope: $scope
        });
    };

    $scope.processTrackingRequest = function ($waybill, $carrier) {

        blockUI.start();

        // Success function for adding a specific waybill's info.
        var success = function (object) {

            blockUI.stop();
            $scope.orderHistory = true; // Differentiate interface from Tracking Page

            $scope.data = {
                'display': true,
                'carrier': object.Carrier,
                'details': object.Response.Data,
                'status': object.Response.Status
            };
            if (object.Carrier_logo.length != 0) {
                $scope.image = {'carrier_logo': object.Carrier_logo[0]['logo']};
            } else {
                $scope.image = false;
            }
            if ($scope.data.status == 3) {

                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Your Carrier Service Cannot be reached. Please try again later.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: 'Your Carrier Service Cannot be reached. Please try again later.'
                });
            } else {

                if ($scope.data.carrier === 'COURIER IT') {
                    $modal.open({
                        size: 'lg',
                        templateUrl: ENV.APP_DIR + 'partials/_courier_it_tracking.html',
                        scope: $scope,
                        windowClass: 'app-modal',
                    });
                    // var compiledeHTML = $compile("<div carrier-courierit></div>")($scope);
                    // $("#container").html(compiledeHTML);

                } else if ($scope.data.carrier === 'SKYNET') {
                    $modal.open({
                        size: 'lg',
                        templateUrl: ENV.APP_DIR + 'partials/_skynet_tracking.html',
                        scope: $scope,
                        windowClass: 'app-modal',
                    });
                    // var compiledeHTML = $compile("<div carrier-skynet></div>")($scope);
                    // $("#container").html(compiledeHTML);

                } else if ($scope.data.carrier === 'DCB LOGISTICS') {
                    $modal.open({
                        size: 'lg',
                        templateUrl: ENV.APP_DIR + 'partials/_dcb_tracking.html',
                        scope: $scope,
                        windowClass: 'app-modal',
                    });
                    // var compiledeHTML = $compile("<div carrier-dcb></div>")($scope);
                    // $("#container").html(compiledeHTML);

                } else if ($scope.data.carrier === 'GLOBE FLIGHT') {
                    $modal.open({
                        size: 'lg',
                        templateUrl: ENV.APP_DIR + 'partials/_globeflight_tracking.html',
                        scope: $scope,
                        windowClass: 'app-modal',
                    });
                    // var compiledeHTML = $compile("<div carrier-globeflight></div>")($scope);
                    // $("#container").html(compiledeHTML);
                } else if ($scope.data.carrier === 'DSV Road') {
                    $modal.open({
                        size: 'lg',
                        templateUrl: ENV.APP_DIR + 'partials/_dsv_tracking.html',
                        scope: $scope,
                        windowClass: 'app-modal',
                    });
                    // var compiledeHTML = $compile("<div carrier-dsv></div>")($scope);
                    // $("#container").html(compiledeHTML);
                } else if ($scope.data.carrier === 'THE COURIER GUY') {
                    $modal.open({
                        size: 'lg',
                        templateUrl: ENV.APP_DIR + 'partials/_TCG_tracking.html',
                        scope: $scope,
                        windowClass: 'app-modal',
                    });
                    // var compiledeHTML = $compile("<div carrier-tcg></div>")($scope);
                    // $("#container").html(compiledeHTML);
                } else if ($scope.data.carrier === 'ROAD TRIP') {
                    console.log('Road Trip');
                    window.open(object.Url)
                }
            }

        };
        // Error function for getting a specific waybill's info.
        var error = function (object) {

            blockUI.stop();

            if ("undefined" === typeof object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Your Carrier Service Cannot be reached. Please try again later.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: 'Your Carrier Service Cannot be reached. Please try again later.'
                });
            }
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message.message,
            });


        };

        $post = {"connoteId": $waybill, "carrier": $carrier};
        // Get a specific waybill's info.

        TrackingFactory.getTracking($post).success(success).error(error);
    };

    $scope.trackWaybill = function (orderItem) {
        $scope.orderInfo = orderItem;
        $scope.carrierName = $scope.orderInfo.carrierName;
        $scope.waybill = $scope.orderInfo.waybill
        $scope.processTrackingRequest($scope.waybill, $scope.carrierName);

        // $modal.open({
        //     size: 'lg',
        //     templateUrl: ENV.APP_DIR + 'partials/_order_tracking.html',
        //     scope: $scope,
        //     windowClass: 'app-modal',
        // });

    };

    // Get Results Per Page
    function getResultsPage(pageNumber) {

        var success = function (object) {
//                console.log(object);
            $scope.orders = object.data;
            $scope.totalOrders = object.paginator.total_count;
            $scope.ordersFound = object.data.length == 0 ? false : true;
//        ngProgress.complete();
            blockUI.stop();
        };
        var error = function (object) {
            //    console.log(object);
            if (object.error.status_code == 404) {
                $scope.ordersFound = false;
            }

            // notifications.showError({message: object.error.message});
            //ngProgress.complete();
            blockUI.stop();
        };
        // Get Order History
//            ngProgress.color('#d20001');
//            ngProgress.start();
        blockUI.start();
        AccountFactory.getGroupedOrderHistory($scope.currentUser.account[0].id, pageNumber).success(success).error(error);
    }
    ;


//        $scope.Results = {};
//        $scope.Results = _.groupBy($scope.orders, $scope.orders.orderGroupRef);
//        console.log($scope.Results);

    $scope.showConfirmDeletion = function (id) {
        ngDialog.open({
            template: 'withInlineControllerConfirmOrderGroupSoftDeletion',
            controller: ['$scope', '$timeout', 'OrderFactory', 'blockUI', '$modalStack', function ($scope, $timeout, OrderFactory, blockUI, $modalStack) {
                $scope.softDeleteOrder = function (id) {
                    $modalStack.dismissAll();

                    var success = function (object) {
                        // //console.log(object.data);
                        getResultsPage(1);
                        $scope.pagination = {
                            current: 1
                        };
                        blockUI.stop();
                    };
                    var error = function (object) {
                        getResultsPage(1);
                        $scope.pagination = {
                            current: 1
                        };
                        blockUI.stop();
                    };
                    blockUI.start();
                    OrderFactory.softDeleteGroup(id).success(success).error(error);
                };
                $scope.index = id;
            }],
            className: 'ngdialog-theme-default',
            msg: "object.message"
        });
    };
}]);

/**
 * Address Book Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('AddressBookController', ['$scope', 'ngDialog', '$location', 'AddressBookFactory', 'notifications', 'ngProgress', '$modal', 'ENV', 'blockUI', function ($scope, ngDialog, $location, AddressBookFactory, notifications, ngProgress, $modal, ENV, blockUI) {

    $scope.addressList = {};
    $scope.addressBookRecord = {};
    $scope.addressBookRecordEdit = {};


    $scope.addressBookRecord.suburb = '';
    $scope.addressBookRecord.state = '';
    $scope.addressBookRecord.postcode = '';

    var populateAddressList = function () {
        var success = function (object) {
            $scope.addressList = object.data;
        };
        var error = function (object) {
            $scope.addressList = {};
        };
        AddressBookFactory.getAddressBookByUserId($scope.addressBookRecord.user_id).success(success).error(error);
    };

    if ($scope.currentUser === null) {
        $location.path('/login');
    } else {
        $scope.addressBookRecord.user_id = $scope.currentUser.id;
        populateAddressList();
    }

    $scope.clearAddAddressForm = function () {
        $scope.addressBookRecord = {};
        $scope.addressBookRecord.user_id = $scope.currentUser.id;
    };
    $scope.open = function (size, templateUrl) {
        var modalInstance = $modal.open({
            size: size,
            templateUrl: ENV.APP_DIR + templateUrl,
            controller: 'ModalInstanceController',
            scope: $scope //Or $scope.$new(

        });
    };
    $scope.onSelectSuburb = function ($item, $model, $label) {
        $scope.addressBookRecord.suburb = $item.SUBURB;
        $scope.addressBookRecord.state = $item.STATE;
        $scope.addressBookRecord.postcode = $item.POSTCODE;
    };
    $scope.onSelectSuburbEdit = function ($item, $model, $label) {
        $scope.addressBookRecordEdit.suburb = $item.SUBURB;
        $scope.addressBookRecordEdit.state = $item.STATE;
        $scope.addressBookRecordEdit.postcode = $item.POSTCODE;
    };
    $scope.validateSuburb = function (suburb) {
        function checkSuburb(item) {
            if (suburb.target.value.trim().length === 0) {
                return false;
            }
            return ((item.SUBURB.toLowerCase() + ', ' + item.STATE.toLowerCase() + ', ' + item.POSTCODE).indexOf(suburb.target.value.toLowerCase()) == 0) && (item.USE === 'Yes');
        }

        var item = $scope.detailedSuburbsWithCities.filter(checkSuburb);
        $scope.addressBookRecord.suburb = '';
        $scope.addressBookRecord.state = '';
        $scope.addressBookRecord.postcode = '';
        if (item.length > 0) {
            $scope.addressBookRecord.suburbPlus = item[0].SUBURB + ', ' + item[0].STATE + ', ' + item[0].POSTCODE + ' ';
            $scope.addressBookRecord.suburb = item[0].SUBURB;
            $scope.addressBookRecord.state = item[0].STATE;
            $scope.addressBookRecord.postcode = item[0].POSTCODE;
        }
    };
    $scope.validateSuburbEdit = function (suburb) {
        function checkSuburb(item) {
            if (suburb.target.value.trim().length === 0) {
                return false;
            }
            return ((item.SUBURB.toLowerCase() + ', ' + item.STATE.toLowerCase() + ', ' + item.POSTCODE).indexOf(suburb.target.value.toLowerCase()) == 0) && (item.USE === 'Yes');
        }

        var item = $scope.detailedSuburbsWithCities.filter(checkSuburb);
        $scope.addressBookRecordEdit.suburb = '';
        $scope.addressBookRecordEdit.state = '';
        $scope.addressBookRecordEdit.postcode = '';
        if (item.length > 0) {
            $scope.addressBookRecordEdit.suburbPlus = item[0].SUBURB + ', ' + item[0].STATE + ', ' + item[0].POSTCODE + ' ';
            $scope.addressBookRecordEdit.suburb = item[0].SUBURB;
            $scope.addressBookRecordEdit.state = item[0].STATE;
            $scope.addressBookRecordEdit.postcode = item[0].POSTCODE;
        }
    };
    $scope.processNewRecord = function () {
        // On Success of create new address record
        $scope.addressBookRecord.name = $scope.addressBookRecord.first_name.trim() + ' ' + $scope.addressBookRecord.last_name.trim();
        var success = function (object) {
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.message
            });
            $scope.register = {};
            populateAddressList();
            blockUI.stop();
        };
        // On failure of create new address record
        var error = function (object) {
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
            blockUI.stop();
        };
        blockUI.start();
        AddressBookFactory.createAddressBookRecord($scope.addressBookRecord).success(success).error(error);
    };
    $scope.setRecipientDefaultAddress = function ($id, $defaultAddressType) {
        var $default = {};
        $default.id = $id;
        $default.default_address_type = $defaultAddressType;
        $default.user_id = $scope.addressBookRecord.user_id;
        // On Success of create new address record
        var success = function (object) {
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.message
            });
            populateAddressList();
            blockUI.stop();
        };
        // On failure of create new address record
        var error = function (object) {
            if ($id !== 0) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message
                });
            }
            populateAddressList();
            blockUI.stop();
        };
        blockUI.start();
        AddressBookFactory.setAddressBookRecordAsDefault($default).success(success).error(error);
    };
    $scope.checkAddressLineTwo = function (lineTwo) {
        return !(!lineTwo || lineTwo === "" || 0 === lineTwo.length);
    };
    $scope.showConfirmDeletion = function (id) {
        ngDialog.open({
            template: 'withInlineControllerConfirmDeletion',
            controller: ['$scope', '$timeout', 'AddressBookFactory', 'blockUI', '$modalStack', function ($scope, $timeout, AddressBookFactory, blockUI, $modalStack) {
                $scope.deleteAddress = function (id) {
                    $modalStack.dismissAll();
                    var success = function (object) {

                        // //console.log(object.data);
                        populateAddressList();
                        blockUI.stop();
                    };
                    var error = function (object) {
                        populateAddressList();
                        blockUI.stop();
                    };
                    blockUI.start();
                    AddressBookFactory.deleteAddressBookRecord(id).success(success).error(error);
                };
                $scope.index = id;
            }],
            className: 'ngdialog-theme-default',
            msg: "object.message"
        });
    };
    $scope.showEditContact = function (address) {
        $scope.addressBookRecordEdit = $.extend(true, {}, address);
        $scope.addressBookRecordEdit.name = address.name;
        $scope.addressBookRecordEdit.suburbPlus = $scope.addressBookRecordEdit.suburb + ", " + $scope.addressBookRecordEdit.state + ", " + $scope.addressBookRecordEdit.postcode;
        var modalInstance = $modal.open({
            size: '',
            templateUrl: ENV.APP_DIR + 'views/address_book_record_edit.html',
            controller: 'ModalInstanceController',
            scope: $scope
        });
    };
    $scope.updateRecord = function () {
        // On Success of updates
        var success = function (object) {
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.message
            });
            $scope.addressBookRecordEdit = {};
            populateAddressList();
            blockUI.stop();
        };
        // On failure of update
        var error = function (object) {
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
            $scope.addressBookRecordEdit = {};
            blockUI.stop();
        };
        blockUI.start();
        AddressBookFactory.updateAddressBookRecord($scope.addressBookRecordEdit.id, $scope.addressBookRecordEdit).success(success).error(error);
    };
}]);
/**
 * [description]
 * @param  {[type]} $scope         [description]
 * @param  {[type]} $modalInstance [description]
 * @param  {[type]} orderInfo)     {            //console.log($scope.orderInfo);  $scope.pick_up [description]
 * @return {[type]}                [description]
 */
app.controller('OrderInfoController', ['$scope', 'ngDialog', '$modalInstance', 'blockUI', function ($scope, ngDialog, $modalInstance, orderInfo, blockUI) {
    ////console.log($scope.orderInfo);

    $scope.pick_up = $scope.orderInfo.pickUp.split(",");
    $scope.drop_off = $scope.orderInfo.dropOff.split(",");
    $scope.orderInfo.insurance_text = ($scope.orderInfo.insurance) ? "(incl. insurance for parcel valued at R" + $scope.orderInfo.insurance_value + ")" : "(insurance not included)";
}]);
/**
 * [description]
 * @param  {[type]} $scope         [description]
 * @param  {[type]} $modalInstance [description]
 * @param  {[type]} orderInfo)     {            //console.log($scope.orderInfo);  $scope.pick_up [description]
 * @return {[type]}                [description]
 */
app.controller('ArticleInfoController', ['$scope', 'ngDialog', '$modalInstance', 'blockUI', function ($scope, ngDialog, $modalInstance, orderInfo, blockUI) {
    ////console.log($scope.orderInfo);
    $scope.title = $scope.article.title;
    $scope.featured = $scope.article.featured;
    $scope.published = $scope.article.published;
    $scope.show = $scope.article.show;
    $scope.story = $scope.article.story;
    $scope.updated = $scope.article.updated;
    $scope.closeModal = function () {
        $modalInstance.close();
    }
//        $scope.pick_up = $scope.orderInfo.pickUp.split(",");
//        $scope.drop_off = $scope.orderInfo.dropOff.split(",");
//        $scope.orderInfo.insurance_text = ($scope.orderInfo.insurance) ? "(incl. insurance for parcel valued at R" + $scope.orderInfo.insurance_value + ")" : "(insurance not included)";

}]);
/**
 * Auth Controller
 * Login and Logout of Account.
 *
 * @param  $scope
 * @param  AccountFactory
 * @param  $cookieStore
 * @param  ApiFactory
 * @return void
 */
// app.controller('AuthController', ['$scope', 'AccountFactory', '$cookieStore', 'ApiFactory', 'notifications', 'ngProgress', '$modalStack', function ($scope, AccountFactory, $cookieStore, ApiFactory, notifications, ngProgress, $modalStack){
//   $scope.user = {};

//   // Login Form
//   $scope.processLoginForm = function() {
//     //  On success of lgoin
//     var success = function(object){
//       ngProgress.complete();
//       ////console.log(object);
//       // Clear form inputs
//       $scope.user = {};

//       // Create variables
//       var token = object.data.token;

//       ApiFactory.init(token);

//       $cookieStore.put('token', token);
//       $cookieStore.put('user', object.data.user);
//       window.location.reload();

//       // notifications.showSuccess({message: object.data.message});
//     };

//     // On fail of login
//     var error = function(object){
//       ////console.log(object);
//       ngProgress.complete();
//       notifications.showError({message: object.error.message});
//     };

//     // Make Login Call
//     ngProgress.color('#d20001');
//     ngProgress.start();
//     AccountFactory.postLogin($scope.user).success(success).error(error);
//   };

//   // Logout of Account
//   $scope.processLogout = function () {
//     // On Succes of Logout
//     var success = function (object){
//       ngProgress.complete();
//       // Removing a cookie
//       $cookieStore.remove('token');
//       $cookieStore.remove('user');

//       ApiFactory.init();
//       window.location.reload();
//     };
//     // On Fail of logout
//     var error = function (data){
//       $cookieStore.remove('token');
//       ////console.log(data);
//       ngProgress.complete();
//     };

//     // Make Logout Call
//     ngProgress.color('#d20001');
//     ngProgress.start();
//     AccountFactory.logout().success(success).error(error);
//   };

//   // Reset your password on forgot password call.
//   $scope.processResetPasswordForm = function () {
//     var success = function (object) {
//       notifications.showSuccess({message: object.data.message});
//       ngProgress.complete();
//       $scope.user = {};
//       $modalStack.dismissAll();
//     };

//     var error = function (object) {
//       notifications.showError({message: object.error.message});
//       ngProgress.complete();
//     };

//     ngProgress.color('#d20001');
//     ngProgress.start();
//     AccountFactory.resetPassword($scope.user).success(success).error(error);
//   };
// }]);

/**
 * Register Controller
 *
 * @param  $scope
 * @param  AccountFactory
 * @return void
 */
app.controller('RegisterController', ['$scope', 'ngDialog', 'AccountFactory', 'notifications', 'ngProgress', '$modalStack', 'blockUI', '$timeout', '$rootScope', function ($scope, ngDialog, AccountFactory, notifications, ngProgress, $modalStack, blockUI, $timeout, $rootScope) {

    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $scope.provinces = [
        {label: 'Select a province', value: ''},
        {label: 'Eastern Cape', value: 'Eastern Cape'},
        {label: 'Free State', value: 'Free State'},
        {label: 'Gauteng', value: 'Gauteng'},
        {label: 'KwaZulu Natal', value: 'KwaZulu Natal'},
        {label: 'Limpopo', value: 'Limpopo'},
        {label: 'Mpumalanga', value: 'Mpumalanga'},
        {label: 'North West', value: 'North West'},
        {label: 'Northern Cape', value: 'Northern Cape'},
        {label: 'Western Cape', value: 'Western Cape'}
    ];
    $scope.titles = [
        {label: 'Mr', value: 'Mr'},
        {label: 'Miss', value: 'Miss'},
        {label: 'Mrs', value: 'Mrs'},
        {label: 'Ms', value: 'Ms'},
        {label: 'Dr', value: 'Dr'},
        {label: 'Prof', value: 'Prof'}
    ];
    $scope.areaCodes = [
        {label: '+27 (0)', value: '+27 (0)'}
    ];
    $scope.register = {};
    $scope.register.title = $scope.titles[0];
    $scope.register.int_code = $scope.areaCodes[0];
    $scope.register.company_int_code = $scope.areaCodes[0];
    $scope.register.province = $scope.provinces[0];
    $scope.register.notify = true;
    var address = $scope.register.lineOne + ', ' + $scope.register.lineTwo + ', ' + $scope.register.address;
    $scope.register.address = address;
    // Register Form
    $scope.processRegisterForm = function () {
        //console.log($scope.register.address);
        //console.log('Form submited');
        //console.log($scope.register);
        // On Success of regsiter call

        var success = function (object) {
            //notifications.showSuccess({message: object.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.message
            });
            $scope.register = {};
//      ngProgress.complete();
            blockUI.stop();
            $modalStack.dismissAll();
        };
        // On faile register call
        var error = function (object) {
            //notifications.showError({message: object.error.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
//      ngProgress.complete();
            blockUI.stop();
        };
        // Make Regester user call.
//            ngProgress.color('#d20001');
//            ngProgress.start();
        blockUI.start();
        AccountFactory.registerUser($scope.register).success(success).error(error);
    };
    $timeout(function () {
        blockUI.stop();
    }, 500);
}]);
/**
 * Activate Controller
 *
 * @param  $scope
 * @param  AccountFactory
 * @param  $location
 * @param  $routeParams
 * @return void
 */
app.controller('ActivateController', ['AmrodFactory', 'TradeOnlyGiftFactory', '$scope', 'ngDialog', 'AccountFactory', '$location', '$routeParams', '$cookieStore', 'ngProgress', 'blockUI', function (AmrodFactory, TradeOnlyGiftFactory, $scope, ngDialog, AccountFactory, $location, $routeParams, $cookieStore, ngProgress, blockUI) {
//        ngProgress.color('#d20001');
//        ngProgress.start();
    blockUI.start();
    ////console.log($routeParams.code);
    ////console.log($routeParams.id);
    if ($cookieStore.get('token') !== undefined) {
        window.location.href = '#/';
    }

    $scope.status = '';
    $scope.message = '';
    $userId = $routeParams.id;
    $post = {"id": $userId, "activate_code": $routeParams.code};
    ////console.log($post);
    $scope.open_app = false

    var success = function (object) {
        if($routeParams.app !== undefined && $routeParams.app === 'true'){
            $scope.open_app = true
        }
        $scope.status = 'Congratulations';
        $scope.message = 'Your account has been activated successfully';
//            ngProgress.complete();
        var amrodSuccess = function (object) {
            console.log("linking account");
            // console.log(object);
            $scope.startCustRegResponse = object.startCustRegResponse;
            // response found

            //console.log($userId);
            $accountId = object.accountId;
            if ($scope.startCustRegResponse.success) {
                var success = function (object) {
                    //   console.log(object);
                    $apiResponse = object.api_response;
                    if (!$apiResponse.success) {
                        blockUI.stop();
                        $message = "Failed to link your Rush account with your Amrod account. Please contact support.";
                        ngDialog.open({
                            template: 'withInlineController',
                            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                                $scope.msg = $message;
                            }],
                            className: 'ngdialog-theme-default',
                            msg: $message
                        });
                    } else {
                        $request = {};
                        $request.username = $scope.startCustRegResponse.accountProfile.accountEmail;
                        $request.profileToken = $apiResponse.profileToken;
                        $request.accountId = $accountId;

                        var success = function (object) {
                            blockUI.stop();
                        };

                        var error = function (object) {
                            blockUI.stop();
                            ngDialog.open({
                                template: 'withInlineController',
                                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                                    $scope.msg = object.error.message;
                                }],
                                className: 'ngdialog-theme-default',
                                msg: object.error.message
                            });
                        };
                        AmrodFactory.linkAccount($request).success(success).error(error);
                    }
                };

                var error = function (object) {
                    blockUI.stop();
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.error.message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.error.message
                    });
                };

                AmrodFactory.completeCustomerRegistration($scope.startCustRegResponse).success(success).error(error);

            } else {
                $request = {};
                $request.username = $scope.startCustRegResponse.accountProfile.accountEmail;
                $request.profileToken = $scope.startCustRegResponse.token;
                $request.accountId = $accountId;
                var success = function (object) {
                    blockUI.stop();
                };

                var error = function (object) {
                    blockUI.stop();
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.error.message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.error.message
                    });
                };
                AmrodFactory.linkAccount($request).success(success).error(error);
            }

        };

        var error = function (object) {
            blockUI.stop();
        };
        $req = {};
        $req.userId = $userId;
        AmrodFactory.getStartCusRegResponse($req).success(amrodSuccess).error(error);

        var togsaSuccess = function (object) {
            console.log("linking account");
            // console.log(object);
            $scope.startCustRegResponse = object.startCustRegResponse;
            // response found

            //console.log($userId);
            $accountId = object.accountId;
            if ($scope.startCustRegResponse.success) {
                var success = function (object) {
                    //   console.log(object);
                    $apiResponse = object.api_response;
                    if (!$apiResponse.success) {
                        blockUI.stop();
                        $message = "Failed to link your Rush account with your Trade Only Gift account. Please contact support.";
                        ngDialog.open({
                            template: 'withInlineController',
                            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                                $scope.msg = $message;
                            }],
                            className: 'ngdialog-theme-default',
                            msg: $message
                        });
                    } else {
                        $request = {};
                        $request.username = $scope.startCustRegResponse.accountProfile.accountEmail;
                        $request.profileToken = $apiResponse.profileToken;
                        $request.accountId = $accountId;

                        var success = function (object) {
                            blockUI.stop();
                        };

                        var error = function (object) {
                            blockUI.stop();
                            ngDialog.open({
                                template: 'withInlineController',
                                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                                    $scope.msg = object.error.message;
                                }],
                                className: 'ngdialog-theme-default',
                                msg: object.error.message
                            });
                        };
                        TradeOnlyGiftFactory.linkAccount($request).success(success).error(error);
                    }
                };

                var error = function (object) {
                    blockUI.stop();
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.error.message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.error.message
                    });
                };

                TradeOnlyGiftFactory.completeCustomerRegistration($scope.startCustRegResponse).success(success).error(error);

            } else {
                $request = {};
                $request.username = $scope.startCustRegResponse.accountProfile.accountEmail;
                $request.profileToken = $scope.startCustRegResponse.token;
                $request.accountId = $accountId;
                var success = function (object) {
                    blockUI.stop();
                };

                var error = function (object) {
                    blockUI.stop();
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.error.message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.error.message
                    });
                };
                TradeOnlyGiftFactory.linkAccount($request).success(success).error(error);
            }

        };

        $req = {};
        $req.userId = $userId;
        TradeOnlyGiftFactory.getStartCusRegResponse($req).success(togsaSuccess).error(error);
    };
    var error = function (object) {
        ////console.log(object);
        $scope.status = 'Error has Occured';
        $scope.message = object.error.message;
//            ngProgress.complete();
        blockUI.stop();
    };
    AccountFactory.activateAccount($post).success(success).error(error);
}]);
/**
 * Form Controller
 *
 * @param  $scope
 * @param  AccountFactory
 * @param  ContactFactory
 * @param  $location
 * @return void
 */
app.controller('FormController', ['$scope', 'blockUI', 'AccountFactory', 'ContactFactory', '$location', 'notifications', 'ngProgress', 'ngDialog', function ($scope, blockUI, AccountFactory, ContactFactory, $location, notifications, ngProgress, ngDialog) {
    //console.log("controller");
    // Contact Us Form
    $scope.ContactForm = function () {
//            ngProgress.start();
        blockUI.start();
        var success = function (object) {
            //console.log("success");
            //console.log(object);
            $scope.status = "Successfully sent the contact form. We will be in touch with you shortly.";
            //notifications.showSuccess({message: object.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.message,
            });
            $scope.contact = {};
//                ngProgress.complete();
            blockUI.stop();
        };
        var error = function (object) {
            //console.log("error");
            $scope.status = "Error has occured. Unable to send contact form at this time, please try again in a few minutes.";
            //notifications.showError({message: $scope.status});

            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: $scope.status,
            });
//                ngProgress.complete();
            blockUI.stop();
        };
        //console.log($scope.contact);
        ContactFactory.sendContactEmail($scope.contact).success(success).error(error);
    };
}]);
/**
 * Home Controller
 *
 * @param  $scope
 * @param  $modal
 * @param  $location
 * @param  ngProgress
 * @param  PartnersFactory
 * @param  GlobalFactory
 * @param  $routeParams
 * @return void
 */

app.controller('HomeController', ['$scope', 'ngDialog', '$modal', '$location', 'ngDialog', 'PartnersFactory', 'ArticlesFactory', 'GlobalFactory', '$routeParams', 'ENV', 'blockUI', '$rootScope', function ($scope, ngDialog, $modal, $location, ngDialog, PartnersFactory, ArticlesFactory, GlobalFactory, $routeParams, ENV, blockUI, $rootScope) {
    //ngProgress.color('#d20001');
    //ngProgress.start();
    blockUI.start();
    //$rootScope.modelName=true;
    $rootScope.bannerClass = 'bannerShow';
    $rootScope.bufferDiv = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    //console.log($rootScope.modelName);
    $scope.partners = {};
    $scope.articles = {};
    $('._jsDynamicTitle').text('Rush');
    $rootScope.theme = 'ngdialog-theme-plain';
    var success = function (object) {
        $scope.partners = object.data;
        //ngProgress.complete();
        blockUI.stop();
    };
    var error = function (object) {
        //console.log(object.message);
        //ngProgress.complete();
        blockUI.stop();
    };
    PartnersFactory.getPartners().success(success).error(error);
    var success = function (articles) {
        $scope.articles = articles.data;
        ////console.log($scope.articles.data);
        //ngProgress.complete();
        blockUI.stop();
    };
    var error = function (error) {
        $scope.status = 'Unable to load customer data: ' + error.message;
        // ngProgress.complete();
        blockUI.stop();
    };
    ArticlesFactory.getArticles().success(success).error(error);
//  ngProgress.color('#d20001');
//  ngProgress.complete();
    $rootScope.bannerClass = 'bannerShow';
}]);
/**
 * Business Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('BusinessController', ['$scope', 'ngDialog', '$localStorage', '$cookieStore', '$location', 'ngProgress', 'PartnersFactory', 'GlobalFactory', '$routeParams', 'ENV', 'blockUI', '$rootScope', '$timeout', '$route', function ($scope, ngDialog, $localStorage, $cookieStore, $location, ngProgress, PartnersFactory, GlobalFactory, $routeParams, ENV, blockUI, $rootScope, $timeout, $route) {

    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';

    $scope.currentUser = {};
    $scope.currentLoggedInUser = false;
    $scope.go = function (path) {
        if ($localStorage.newOrderTab === undefined) {
            $localStorage.$default({'newOrderTab': false});
        } else {
            $localStorage.newOrderTab = false;
        }
        if (path === 'NewOrder') {
            $localStorage.newOrderTab = true;
            path = 'account';
        }
        if ($location.path() === '/' + path) {
            $route.reload();
        }
        $location.path(path);
    };
    $('._jsDynamicTitle').text('Rush - Business');
    ////console.log($cookieStore.get('user'));
    ////console.log($routeParams.id);

    // Check if the Business User id has been sent through.
    if ($cookieStore.get('token') !== undefined) {
        $scope.currentUser = $cookieStore.get('user');
        $scope.currentLoggedInUser = true;
        ////console.log('Current User');
        ////console.log($scope.currentUser);

        // Log all Cookie Store pms to database table.
        GlobalFactory.log({
            "message": "Business User ID: " + $scope.currentUser.id,
            "type": "Log",
            "data": JSON.stringify($cookieStore)
        }).success(function (object) {
            //console.log('Logged Business User.');
            ////console.log(object);
            $scope.parcelWidget = ENV.APP_DIR + "partials/_parcel_widget.html";
//                $scope.documentWidget = ENV.APP_DIR + "partials/_document_widget.html";
        }).error(function (object) {
            ////console.log('Failed to log we chat user: ' + $routeParams.id);
        });
    } else {
        //$location.path('/login');
    }
    $timeout(function () {
        blockUI.stop();
    }, 500);
}]);
/**
 * About Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('AboutController', ['$scope', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - About');
    $timeout(function () {
        blockUI.stop();
    }, 500);
}])

/**
 * PRONTO Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('ProntoController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Pronto Service');
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#sameday').offset().top - 550}, 1000);
    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500);
}])

/**
 * SAMEDAY Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('SamedayController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Same Day Service');
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#sameday').offset().top - 250}, 1000);
    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);
/**
 * OVERNIGHT Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('OvernightController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Overnight Service');
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#sameday').offset().top - 60}, 1000);
    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);
/**
 * BUDGET Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('BudgetController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Budget Service');
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#sameday').offset().top + 100}, 1000);
    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);
/**
 * RUSH MOBILE Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('RushMobileController', ['$scope', 'ngDialog', 'blockUI', '$rootScope', '$log', '$timeout', '$modal', function ($scope, ngDialog, blockUI, $rootScope, $log, $timeout, $modal) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Mobile Service');
    $scope.open = function (size, image, text) {

        var modalInstance = $modal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'myModalContent.html',
            controller: 'ModalPreviewInstanceCtrl',
            size: size,
            resolve: {
                image: function () {
                    return image;
                },
                text: function () {
                    return text;
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            $scope.selected = selectedItem;
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };
    $timeout(function () {
        blockUI.stop();
    }, 500);
}]);
/**
 * RushBusinessController Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('RushBusinessController', ['$scope', 'ngDialog', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    //console.log($rootScope.bufferDiv);
    $('._jsDynamicTitle').text('Rush - Business Service');
    $timeout(function () {
        blockUI.stop();
    }, 500);
}]);
/**
 * RushEnterpriseController Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('RushEnterpriseController', ['$scope', 'ngDialog', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Enterprise Service');
    $timeout(function () {
        blockUI.stop();
    }, 500);
}]);
/**
 * BusinessPlusController Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('BusinessPlusController', ['$scope', 'ngDialog', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Business Plus Service');
    $timeout(function () {
        blockUI.stop();
    }, 500);
}]);
/**
 * About Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('ContactController', ['$scope', 'ngDialog', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Contact Us');
    /*        $scope.init = function () {

            $('html, body').animate({scrollTop: $('#top').offset().top - 300}, 1000);
        };*/
    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}])

app.controller('PackingController', ['$scope', 'ngDialog', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Packing');
    /*        $scope.init = function () {

            $('html, body').animate({scrollTop: $('#top').offset().top - 300}, 1000);
        };*/
    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}])

/**
 * Articles Controller
 *
 * @param  $scope
 * @param  ArticlesFactory
 * @param  $location
 * @return void
 */
app.controller('ArticlesController', ['$scope', 'ngDialog', 'ArticlesFactory', '$location', '$routeParams', 'ngProgress', 'blockUI', '$rootScope', '$modal', 'ENV', function ($scope, ngDialog, ArticlesFactory, $location, $routeParams, ngProgress, blockUI, $rootScope, $modal, ENV) {
    // ngProgress.color('#d20001');
    //ngProgress.start();
    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $scope.status = '';
    $scope.articles = {};
    $scope.article = {};
    $scope.articleModal = function (article) {

        $scope.article = article;
        //console.log(article);
        if ($scope.article.external_link == '1') {
            //alert('link');
            window.open($scope.article.external_page);
        } else {
            $modal.open({
                size: 'lg',
                templateUrl: ENV.APP_DIR + 'partials/_news.html',
                controller: 'ArticleInfoController',
                scope: $scope
            });
        }
    };
    $('._jsDynamicTitle').text('Rush - News');
    if ($routeParams.id !== undefined) {
        getArticle($routeParams.id);
    } else {
        getArticles();
    }

    function getArticles() {

        var success = function (articles) {
            $scope.articles = articles.data;
            ////console.log($scope.articles.data);
            //ngProgress.complete();
            blockUI.stop();
        };
        var error = function (error) {
            $scope.status = 'Unable to load customer data: ' + error.message;
            // ngProgress.complete();
            blockUI.stop();
        };
        ArticlesFactory.getArticles().success(success).error(error);
    }

    function getArticle(id) {
        var success = function (article) {
            $scope.article = article.data;
            //ngProgress.complete();
            blockUI.stop();
            ////console.log($scope.article);
        };
        var error = function (error) {
            $scope.status = 'Unable to load customer data: ' + error.message;
            //ngProgress.complete();
            blockUI.stop();
        };
        ////console.log('test');
        // var error = function (error){
        //   $scope.status = 'Unable to load customer data: ' + error.message;
        // };

        ArticlesFactory.getArticle($routeParams.id).success(success).error(error);
    }

}]);
/**
 * Faqs Controller
 *
 * @param  $scope
 * @param  FAQsFactory
 * @param  $location
 * @return void
 */
app.controller('FaqsController', ['$scope', 'ngDialog', '$timeout', 'FAQsFactory', '$location', 'ngProgress', 'blockUI', '$rootScope', function ($scope, ngDialog, $timeout, FAQsFactory, $location, ngProgress, blockUI, $rootScope) {
//  ngProgress.color('#d20001');
//  ngProgress.start();
    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $scope.status = '';
    $scope.faqs = {};
    getFaqs();
    $scope.status = {
        isFirstOpen: false,
        isFirstDisabled: false,
        isPrivacy: true
    };
    // $scope.init = function () {
    //
    //     $('html, body').animate({scrollTop: $('#top').offset().top - 300}, 1000);
    // };
    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });

    function getFaqs() {

        var success = function (faqs) {
            ////console.log(faqs);
            $scope.faqs = faqs.data;
//      ngProgress.complete();

            blockUI.stop();
        };
        var error = function (error) {
            $scope.status = 'Unable to load customer data: ' + error.message;
//      ngProgress.complete();
            blockUI.stop();
        };
        FAQsFactory.getFaqs().success(success).error(error);
    }


}]);

/**
 * custom message Controller
 *
 * @param  $scope
 * @param  CustomMessagesFactory
 * @param  $location
 * @return void
 */
app.controller('MessageController', ['$scope', 'ngDialog', '$timeout', 'CustomMessagesFactory', '$location', 'ngProgress', 'blockUI', '$rootScope', '$localStorage', function ($scope, ngDialog, $timeout, CustomMessagesFactory, $location, ngProgress, blockUI, $rootScope, $localStorage) {

    blockUI.start();

    $scope.status = '';
    $scope.messages = {};

    $scope.status = {
        isFirstOpen: false,
        isFirstDisabled: false,
        isPrivacy: true
    };
    $scope.init = function () {

        $('html, body').animate({scrollTop: $('#top').offset().top - 300}, 1000);
    };
    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });

    $scope.getMessages = function () {

        var success = function (messages) {
            ////console.log(messages);

            $scope.messages = messages.data;
//      ngProgress.complete();

            blockUI.stop();
        };
        var error = function (error) {
            $scope.status = 'Unable to load customer data: ' + error.message;
//      ngProgress.complete();
            blockUI.stop();
        };
        CustomMessagesFactory.getMessages().success(success).error(error);
    }

    $scope.getMessages();

}]);

/**
 * Testimonies Controller
 *
 * @param  $scope
 * @param  TestimoniesFactory
 * @param  $location
 * @return void
 */
app.controller('TestimoniesController', ['$scope', 'ngDialog', 'TestimoniesFactory', '$location', 'ngProgress', 'blockUI', '$rootScope', function ($scope, ngDialog, TestimoniesFactory, $location, ngProgress, blockUI, $rootScope) {
//  ngProgress.color('#d20001');
//  ngProgress.start();
    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';

    $scope.status = '';
    $scope.testimonies = {};
    $('._jsDynamicTitle').text('Rush - Testimonials');
    $scope.go = function (path) {
        $location.path(path);
    };
    getTestimonies();

    function getTestimonies() {
        var success = function (testimonies) {
            $scope.testimonies = testimonies.data;
//      ngProgress.complete();
            blockUI.stop();
        };
        var error = function (error) {
            $scope.status = 'Unable to load customer data: ' + error.message;
//      ngProgress.complete();
            //blockUI.stop();
        };
        TestimoniesFactory.getTestimonies().success(success).error(success);
    }

}]);
/**
 * Partners Controller
 *
 * @param  $scope
 * @param  PartnersFactory
 * @param  $location
 * @return void
 */
app.controller('PartnersController', ['$scope', 'ngDialog', 'PartnersFactory', '$location', function ($scope, ngDialog, PartnersFactory, $location) {

    $scope.status = '';
    $scope.partners = {};
    $('._jsDynamicTitle').text('Rush - Services');
    $scope.showpopup = function () {
        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = "Successfully loaded tracking data.";
            }],
            className: 'ngdialog-theme-plain',
            msg: "Successfully loaded tracking data.",
        });
    };
//        getPartners();
//
//        function getPartners() {
//            var success = function (partners) {
//                $scope.partners = partners.data;
//            };
//            var error = function (error) {
//                $scope.status = 'Unable to load customer data: ' + error.message;
//            };
//
//            PartnersFactory.getPartners().success(success).error(error);
//        }

}]);
app.controller('TermsController', ['$scope', 'ngDialog', '$rootScope', 'blockUI', '$timeout', function ($scope, ngDialog, $rootScope, blockUI, $timeout) {

    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Terms & Conditions');
    $scope.oneAtATime = true;
    // $scope.init = function () {
    //
    //     $('html, body').animate({scrollTop: $('#top').offset().top - 300}, 1000);
    // };
    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $scope.status = {
        isFirstOpen: true,
        isFirstDisabled: false,
        isPrivacy: false
    };
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);
app.controller('PrivacyController', ['$scope', 'ngDialog', '$anchorScroll', '$location', '$rootScope', 'blockUI', '$timeout', function ($scope, ngDialog, $anchorScroll, $location, $rootScope, blockUI, $timeout) {
    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Privacy Policy');
    $scope.oneAtATime = true;
    $scope.status = {
        isFirstOpen: false,
        isFirstDisabled: false,
        isPrivacy: true
    };
    $scope.init = function () {
        if ($scope.status.isPrivacy) {
            $('html, body').animate({scrollTop: $('#privacypol').offset().top - 250}, 1000);
        }
    };
    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);
app.controller('PackageGuideController', ['$scope', 'ngDialog', '$timeout', 'blockUI', function ($scope, ngDialog, $timeout, blockUI) {
    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide'

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $('._jsDynamicTitle').text('Rush - Packing');
    $scope.oneAtATime = true;
    // $scope.init = function () {
    //
    //     $('html, body').animate({scrollTop: $('#top').offset().top - 300}, 1000);
    // };
//        angular.element(document).ready(function () {
//            // your code here
//            $timeout($scope.init, 500);
//        });

    $scope.status = {
        isFirstOpen: true,
        isFirstDisabled: false,
        isPrivacy: false
    };
    $timeout(function () {
        blockUI.stop();
    }, 500);
}]);
app.controller('CollapseController', ['$scope', function ($scope) {
    $scope.isCollapsed = true;
}]);
/**
 * Retrieve Tracking Controller
 *
 * @param $scope
 * @param notifications
 * @param Tracking Factory
 */
app.controller('TrackingController', ['ENV', '$modal', '$window', '$scope', 'ngDialog', 'TrackingFactory', 'notifications', 'blockUI', '$timeout', '$rootScope', '$compile', function (ENV, $modal, $window, $scope, ngDialog, TrackingFactory, notifications, blockUI, $timeout, $rootScope, $compile) {
    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.modelName = false;
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.hideShowHtml = 'hideElement';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';
    $scope.tracking = {};

    $scope.carriers = [
        {label: 'The Courier Guy', value: 1},
        {label: 'Courier IT', value: 3},
        {label: 'DCB Logistics', value: 9},
        {label: 'DSV Road', value: 10},
        {label: 'Globe Flight', value: 5},
        {label: 'Skynet', value: 4},
        {label: 'Road Trip', value: 7}
    ];

    $('._jsDynamicTitle').text('Rush - Tracking');


    $scope.processTrackingRequest = function () {

        blockUI.start();

        // Success function for adding a specific waybill's info.
        var success = function (object) {

            blockUI.stop();

            $scope.data = {
                'display': true,
                'carrier': object.Carrier,
                'details': object.Response.Data,
                'status': object.Response.Status
            };
            $scope.image = {'carrier_logo': object.Carrier_logo[0]['logo']};
            console.log($scope.data.details)
            if ($scope.data.status == 3) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Your Carrier Service Cannot be reached. Please try again later.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: 'Your Carrier Service Cannot be reached. Please try again later.'
                });
            } else {
                if ($scope.data.carrier === 3) {

                    var compiledeHTML = $compile("<div carrier-courierit></div>")($scope);
                    $("#container").html(compiledeHTML);

                } else if ($scope.data.carrier === 4) {

                    var compiledeHTML = $compile("<div carrier-skynet></div>")($scope);
                    $("#container").html(compiledeHTML);

                } else if ($scope.data.carrier === 9) {

                    var compiledeHTML = $compile("<div carrier-dcb></div>")($scope);
                    $("#container").html(compiledeHTML);

                } else if ($scope.data.carrier === 5) {

                    var compiledeHTML = $compile("<div carrier-globeflight></div>")($scope);
                    $("#container").html(compiledeHTML);
                } else if ($scope.data.carrier === 10) {

                    var compiledeHTML = $compile("<div carrier-dsv></div>")($scope);
                    $("#container").html(compiledeHTML);
                } else if ($scope.data.carrier === 1) {

                    var compiledeHTML = $compile("<div carrier-tcg></div>")($scope);
                    $("#container").html(compiledeHTML);
                } else if ($scope.data.carrier === 7) {
                    console.log('Road Trip');
                    window.open(object.Url)
                }
            }

        };
        // Error function for getting a specific waybill's info.
        var error = function (object) {

            blockUI.stop();

            if ("undefined" === typeof object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Your Carrier Service Cannot be reached. Please try again later.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: 'Your Carrier Service Cannot be reached. Please try again later.'
                });
            }
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message.message,
            });


        };

        $post = {"connoteId": $scope.tracking.number.toUpperCase(), "carrier": $scope.tracking.carrier.value};
        console.log($post)
        // Get a specific waybill's info.

        TrackingFactory.getTracking($post).success(success).error(error);
    };
    $timeout(function () {
        blockUI.stop();
    }, 500);
}]);
/**
 * Parcel/Document options fields clone
 *
 * @param  $scope
 * @param  $location
 * @return void
 */
app.controller('CloneInputsController', ['$scope', function ($scope) {
    $scope.inputCounter = 0;
    $scope.inputs = [{
        id: 'input'
    }];
    $scope.add = function () {
        $scope.inputTemplate = {
            id: 'input-' + $scope.inputCounter,
            name: ''
        };
        $scope.inputCounter += 1;
        $scope.inputs.push($scope.inputTemplate);
        angular.element('#add-row').addClass('hide');
        angular.element('#subt-row').removeClass('hide');
        ////console.log($scope.inputCounter);
    };
    $scope.subt = function () {
        $scope.inputCounter -= 1;
        $scope.inputs.splice($scope.inputTemplate, 1);
        ////console.log($scope.inputCounter);
    };
}]);
/**
 * ModalDemoCtrl
 *
 * @param  $scope
 * @param  $modal
 * @param  $log
 * @return object
 */
app.controller('ModalDemoController', ['$scope', '$modal', '$log', '$rootScope', 'ENV', 'ngDialog', function ($scope, $modal, $log, $rootScope, ENV, ngDialog) {
    $scope.open = function (size, templateUrl) {
        var modalInstance = $modal.open({
            size: size,
            templateUrl: ENV.APP_DIR + templateUrl,
            controller: 'ModalInstanceController',
            scope: $scope, //Or $scope.$new(
        });
        ////console.log('open');

        modalInstance.result.then(function (selectedItem) {
            $scope.selected = selectedItem;
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
        ////console.log('result');
    };

    $scope.showLogOutMessage = function () {
        ngDialog.open({
            template: 'withInlineControllerLogOut',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = "object.message";
            }],
            className: 'ngdialog-theme-default',
            msg: "object.message"
        });
    };
}]);
/**
 * ModalInstanceCtrl
 * Please note that $modalInstance represents a modal window (instance) dependency.
 * It is not the same as the $modal service used above.
 *
 * @param  $scope
 * @param  $modalInstance
 * @return void
 */
app.controller('ModalInstanceController', ['$scope', '$modalInstance', '$location', '$route', function ($scope, $modalInstance, $location, $route) {

    $scope.$on('$modalInstance', function () {
        $modalInstance.close();
        ////console.log('close');
    });
    $scope.ok = function () {
        $modalInstance.close();
    };
    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
    $scope.close = function () {
        $modalInstance.close();
    };
    $scope.cancelNotice = function () {
        $modalInstance.close();
        window.location = '/account';
    };
}]);

/**
 * AnchorScrollController
 *
 * @param  $scope
 * @param  $document
 * @return void
 */
// app.controller('AnchorScrollController', ['duScroll', function($scope, $document){
//   $scope.toTheTop = function() {
//     $document.scrollTopAnimated(0, 5000).then(function() {
//       console &&//console.log('You just scrolled to the top!');
//     });
//   };
//   var Services = angular.element(document.getElementById('services'));
//   $scope.toServices = function() {
//     $document.scrollToElementAnimated(Services);
//   };

//   var Contactus = angular.element(document.getElementById('Contactus'));
//   $scope.toContactus = function() {
//     $document.scrollToElementAnimated(Contactus);
//   };
// }]).value('duScrollOffset', 30);

/**
 * ParcelTabController
 *
 * @param  $scope
 * @param  $document
 * @return void
 */
app.controller('ParcelTabController', ['$scope', 'ngDialog', '$document', function ($scope, ngDialog, $window) {
    $scope.tabs = [
        {title: 'Parcels', content: '1'},
        {title: 'Documents', content: '2', disabled: true}
    ];
    $scope.alertMe = function () {
        setTimeout(function () {
            $window.alert('You\'ve selected the alert tab!');
        });
    };
}]);
/**
 * [description]
 * @param  {[type]}
 * @param  {Array}
 * @param  {[type]}
 * @return {[type]}
 */
app.controller('profileSwitchController', ['$scope', '$localStorage', '$cookieStore', '$location', 'WalletFactory', 'ngDialog', function ($scope, $localStorage, $cookieStore, $location, WalletFactory, ngDialog) {
    $('._jsDynamicTitle').text("Rush - Dashboard");

    $location.search('PayUReference', null);

    var user = $cookieStore.get('user');
    //$scope.tabs = {'profile':false,'order':true,'wallet':false,'history':false,'address':false,'orders':false,'master':false};
    $scope.tabs = {0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: true};
    $scope.showTab = function ($k) {
        angular.forEach($scope.tabs, function (value, key) {
            if ($k == key) {
                $scope.tabs[$k] = true;
            } else {
                $scope.tabs[key] = false;
            }
        })
    }
    var showDialogWithMessage = function ($message, $msgTwo) {
        console.log("here too");
        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = $message;
                $scope.msgTwo = $msgTwo;
            }],
            className: 'ngdialog-theme-default custom-align-text',
            msg: $message,
            msgTwo: $msgTwo
        });
    };

//        console.log(user)
    if (user === undefined) {
        $location.path('/login');
    } else {
        $scope.wallet = {};
        var success = function (object) {
            $scope.wallet = object;
        };
        var error = function (object) {
            console.log(object);
            $scope.wallet.amount = 0;
        };

        $request = {};
        $request.account_id = $scope.currentUser.account[0].id;
        WalletFactory.getWalletForAccount($request).success(success).error(error);

        $scope.newOrderTab = $localStorage.newOrderTab === undefined ? false : $localStorage.newOrderTab;

        if ($localStorage.newOrderTab !== undefined && $localStorage.newOrderTab === true) {
            $localStorage.newOrderTab = false;
        }

        $scope.walletTab = $localStorage.walletTab === undefined ? false : $localStorage.walletTab;

        if ($localStorage.walletTab !== undefined && $localStorage.walletTab === true) {
            $localStorage.walletTab = false;
        }

        $scope.isServiceUser = $localStorage.isServiceUser === undefined ? false : $localStorage.isServiceUser;

        if ($localStorage.isServiceUser !== undefined && $localStorage.isServiceUser === true) {
            $localStorage.isServiceUser = false;
        }


        if ($scope.isServiceUser) {
            if ($cookieStore.get('isAmrodUser') && $cookieStore.get('isTradeOnlyGiftUser')) {
                showDialogWithMessage("All your promotional orders that are Ready for Collection can be found under the Ready for Collection tab.", "For orders from another supplier, use the New Order tab.");
            } else if ($cookieStore.get('isAmrodUser')) {
                showDialogWithMessage("All your Amrod orders that are Ready for Collection can be found under the Ready for Collection tab. You will receive 1000 ALP points for each of these orders.", "Your Rush Waybill will automatically be sent to Amrod dispatch. For orders from another supplier, use the New Order tab.");
            } else if ($cookieStore.get('isTradeOnlyGiftUser')) {
                showDialogWithMessage("All your Trade Only Gift orders that are Ready for Collection can be found under the Ready for Collection tab.", "For orders from another supplier, use the New Order tab.");
            }
        }
    }

}]);

app.controller('RefillWalletPayUController', ['$routeParams', 'ngDialog', 'payUFactory', '$window', '$location', '$scope', '$location', '$cookieStore', '$localStorage', 'BusinessFactory', 'OrderFactory', 'blockUI', 'notifications', 'ngProgress', '$modal', 'ENV', function ($routeParams, ngDialog, payUFactory, $window, $location, $scope, $location, $cookieStore, $localStorage, BusinessFactory, OrderFactory, blockUI, notifications, ngProgress, modal, ENV) {

    $('._jsDynamicTitle').text('Rush - Payment Receipt');

    $scope.PayUReference = $routeParams.PayUReference;
    $scope.walletTransaction = true;
    $scope.go = function (path) {
        if ($localStorage.walletTab === undefined) {
            $localStorage.$default({'walletTab': false});
        } else {
            $localStorage.walletTab = false;
        }
        if (path === 'WalletTab') {
            $localStorage.walletTab = true;
            path = 'account';
        }
        $location.search('PayUReference', null);
        $location.path(path);
    };

    var success = function (object) {

        $scope.returnData = object.returnData;
        $scope.environment = object.environment;
        $scope.amount = $scope.returnData.return.basket.amountInCents / 100;
        $scope.currency = $scope.returnData.return.basket.currencyCode;
        $scope.description = $scope.returnData.return.basket.description;

        blockUI.stop();

        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = "Your Wallet has been successfully credited. ";
            }],
            className: 'ngdialog-theme-plain',
            msg: "Your Wallet has been successfully credited. "
        });
    };

    var error = function (object) {

        blockUI.stop();

        ngDialog.open({
            template: 'withInlineControllerCheckBasketOrder',
            controller: ['$scope', '$timeout', 'BasketFactory', 'blockUI', '$modalStack', function ($scope, $timeout, BasketFactory, blockUI, $modalStack) {
                $scope.redirectToBasket = function (id) {
                    $modalStack.dismissAll();
                    $window.location.href = object.error.message.redirectUrl;
                };
                $scope.msg = object.error.message.message;
            }],
            className: 'ngdialog-theme-default',
            msg: "object.message"
        });
        $localStorage.$reset();
    };

    blockUI.start();
    $postData = {"PayUReference": $scope.PayUReference};
    BusinessFactory.getRefillWalletPayUStatus($postData).success(success).error(error);

}]);

app.controller('WalletController', ['$scope', '$localStorage', '$cookieStore', '$location', 'WalletFactory', 'BusinessFactory', 'SharedWalletFactory', 'blockUI', 'ngDialog', '$window', '$location', '$modal', 'ENV', '$modalStack', function ($scope, $localStorage, $cookieStore, $location, WalletFactory, BusinessFactory, SharedWalletFactory, blockUI, ngDialog, $window, $location, $modal, ENV, $modalStack) {

    $scope.refillAmounts = [
        {label: '2 500', value: '2500'},
        {label: '5 000', value: '5000'},
        {label: '10 000', value: '10000'},
        {label: '20 000', value: '20000'},
        {label: '50 000', value: '50000'},
        {label: '100 000', value: '100000'}
    ];
    $scope.wallet = {};
    $scope.sharedUsers = {};
    $scope.usersList = {};
    $scope.refillAmount = {label: '2 500', value: '2500'}; // must use object property as model, else won't be reassigned on selection of radio button

    $scope.openSharedUserList = function () {
        var usersListSuccess = function (object) {
            $scope.usersListCount = object.paginator.total_count;
            $scope.usersList.users = object;

            $modalStack.dismissAll();
            $modal.open({
                size: 'lg',
                templateUrl: ENV.APP_DIR + 'partials/_add_wallet_shared_user.html',
                scope: $scope,
                windowClass: 'app-modal',
            });
            blockUI.stop();
        };

        var usersListError = function (object) {
            $scope.usersListCount = 0; // show error msg??
            blockUI.stop();
        };

        blockUI.start();
        SharedWalletFactory.getUsersList(1).success(usersListSuccess).error(usersListError);
    };

    $scope.searchSharedUser = function (searchStr) {

        var usersListSuccess = function (object) {
            blockUI.stop();
            $scope.usersListCount = object.paginator.total_count;
            $scope.usersList.users = object;
        };

        var usersListError = function (object) {
            blockUI.stop();
            $scope.usersListCount = 0; // show error msg??
        };

        blockUI.start();
        SharedWalletFactory.getUsersList(1, searchStr).success(usersListSuccess).error(usersListError);
    };

    $scope.usersListPageChanged = function (newPage) {
        var usersListSuccess = function (object) {
            $scope.usersListCount = object.paginator.total_count;
            $scope.usersList.users = object;
            blockUI.stop();
        };

        var usersListError = function (object) {
            $scope.usersListCount = 0; // show error msg??
            blockUI.stop();
        };

        blockUI.start();
        SharedWalletFactory.getUsersList(newPage).success(usersListSuccess).error(usersListError);
    };

    $scope.addSharedUser = function (user) {

        var addSharedUserSuccess = function (object) {
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.message
            });
            user.is_shared_user = true;
            blockUI.stop();

        };

        var addSharedUserError = function (object) {
            blockUI.stop();
        };

        $request = {};
        $request.account_id = user.id;
        $request.wallet_id = $scope.wallet.wallet_id;

        blockUI.start();
        SharedWalletFactory.addSharedUser($request).success(addSharedUserSuccess).error(addSharedUserError);
        $scope.sharedUserPageChanged(1);
    };

    $scope.blockUser = function (id) {

        var success = function (object) {
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.message
            });
            blockUI.stop();
        };

        var error = function (object) {
            blockUI.stop();
            console.log(object);
        };

        blockUI.start();
        SharedWalletFactory.blockSharedUser(id).success(success).error(error);
    };

    $scope.removeSharedUser = function (id) {
        var mainScope = $scope;
        console.log($scope.sharedUsers);
        ngDialog.open({
            template: 'withInlineControllerConfirmSharedUserDeletion',
            controller: ['$scope', '$timeout', 'SharedWalletFactory', 'blockUI', '$modalStack', function ($scope, $timeout, SharedWalletFactory, blockUI, $modalStack) {
                $scope.deleteSharedUser = function (id) {
                    $modalStack.dismissAll();
                    var success = function (object) {

                        $scope.mainScope.sharedUserPageChanged(1);
                        // //console.log(object.data);
                        blockUI.stop();
                    };
                    var error = function (object) {
                        blockUI.stop();
                    };
                    blockUI.start();
                    SharedWalletFactory.deleteSharedUser(id).success(success).error(error);
                };
                $scope.index = id;
                $scope.mainScope = mainScope;
            }],
            className: 'ngdialog-theme-plain'
        });
    };

    $scope.walletPageChanged = function (newPage) {
        var getTransactionsSuccess = function (object) {
            $scope.walletTransactionsCount = object.paginator.total_count;
            $scope.wallet.transactions = object;
            blockUI.stop();
        };

        var getTransactionsError = function (object) {
            $scope.walletTransactionsCount = 0; // show error msg??
            blockUI.stop();
        };


        blockUI.start();
        WalletFactory.getTransactions($scope.wallet.account_id, newPage).success(getTransactionsSuccess).error(getTransactionsError);
    };

    $scope.sharedUserPageChanged = function (newPage) {
        var getSharedUsersSuccess = function (object) {
            $scope.sharedUsersCount = object.paginator.total_count;
            $scope.sharedUsers.users = object;
            blockUI.stop();
        };

        var getSharedUsersError = function (object) {
            $scope.sharedUsersCount = 0; // show error msg??
            blockUI.stop();
        };

        blockUI.start();
        SharedWalletFactory.getSharedUsers($scope.wallet.wallet_id, newPage).success(getSharedUsersSuccess).error(getSharedUsersError);
    };

    var success = function (object) {
        $scope.wallet = object;

        var getPendingDepositTransactionsSuccess = function (object) {

            // has pending transactions
            // console.log(object);
            $count = object.length;
            $countDone = 0;

            for (var $index = 0; $index < $count; $index++) {
                var getRefillWalletPayUStatusOnComplete = function (object) {
                    $countDone++;
                    if ($countDone === $count) {
                        var success = function (object) {
                            blockUI.stop();
                            $scope.walletTransactionsCount = object.paginator.total_count;
                            $scope.wallet.transactions = object;
                        };

                        var error = function (object) {
                            blockUI.stop();
                            $scope.walletTransactionsCount = 0; // show error msg??
                        };

                        WalletFactory.getTransactions($scope.wallet.account_id, 1).success(success).error(error);

                        var sharedUsersSuccess = function (object) {
                            blockUI.stop();
                            $scope.sharedUsersCount = object.paginator.total_count;
                            $scope.sharedUsers.users = object;
                        };

                        var sharedUsersError = function (object) {
                            blockUI.stop();
                            $scope.sharedUsersCount = 0; // show error msg??
                        };

                        SharedWalletFactory.getSharedUsers($scope.wallet.wallet_id, 1).success(sharedUsersSuccess).error(sharedUsersError);

                    }
                };
                var getRefillWalletPayUStatusInComplete = function () {
                    blockUI.stop();
                    console.log('Error')
                }
                $postData = {"PayUReference": object[$index].transaction_reference};
                BusinessFactory.getRefillWalletPayUStatus($postData).success(getRefillWalletPayUStatusOnComplete).error(getRefillWalletPayUStatusInComplete);
            }
        };

        var getPendingDepositTransactionsError = function (object) {
            var getTransactionsSuccess = function (object) {
                blockUI.stop();
                $scope.walletTransactionsCount = object.paginator.total_count;
                $scope.wallet.transactions = object;
            };

            var getTransactionsError = function (object) {
                blockUI.stop();
                $scope.walletTransactionsCount = 0; // show error msg??
            };

            WalletFactory.getTransactions($scope.wallet.account_id, 1).success(getTransactionsSuccess).error(getTransactionsError);

            if (!$scope.wallet.is_shared_user) {
                var getSharedUsersSuccess = function (object) {
                    blockUI.stop();
                    $scope.sharedUsersCount = object.paginator.total_count;
                    $scope.sharedUsers.users = object;
                };

                var getSharedUsersError = function (object) {
                    blockUI.stop();
                    $scope.sharedUsersCount = 0; // show error msg??
                };

                SharedWalletFactory.getSharedUsers($scope.wallet.wallet_id, 1).success(getSharedUsersSuccess).error(getSharedUsersError);
            }
        };

        $request = {};
        $request.wallet_id = object.wallet_id;
        WalletFactory.getPendingDepositTransactions($request).success(getPendingDepositTransactionsSuccess).error(getPendingDepositTransactionsError);

    };

    var error = function (object) {
        blockUI.stop();
        console.log(object);
    };

    if ($scope.currentUser !== null) {
        blockUI.start();

        $request = {};
        $request.account_id = $scope.currentUser.account[0].id;
        WalletFactory.getWalletForAccount($request).success(success).error(error);
    } else {
        $location.path('/login');
    }

    $scope.refillWallet = function () {
        $request = {};
        $request.wallet_id = $scope.wallet.wallet_id;
        $request.amount = $scope.refillAmount.value;
        $request.transaction_type = "DEPOSIT";
        $request.transaction_reference = "" + Date.now();
        $request.status = "PENDING";
        $request.transaction_by_account_id = $scope.currentUser.account[0].id;

        var success = function (object) {
            blockUI.stop();
            var success = function (object) {
                blockUI.stop();
                $window.location.href = object.redirectUrl;
            };
            var error = function (object) {
                blockUI.stop();
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = 'Failed to redirect to PayU Payment. Please contact Support Team.';
                    }],
                    className: 'ngdialog-theme-default',
                    msg: 'Failed to redirect to PayU Payment. Please contact Support Team.'
                });
                $localStorage.$reset();
            };

            $request = {};
            $request.accountId = $scope.currentUser.account[0].id;
            $request.walletTransactionId = object.wallet_transaction_id;
            blockUI.start();
            BusinessFactory.refillWallet($request).success(success).error(error);
        };
        var error = function (object) {
            blockUI.stop();
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
        };
        blockUI.start();
        WalletFactory.addTransaction($request).success(success).error(error);
    };
}]);
/**
 * [description]
 * @param  {[type]}
 * @return {[type]}
 */
app.controller('loginSwitcherController', ['$scope', '$modal', 'ENV', '$modalStack', function ($scope, $modal, ENV, $modalStack) {

    $scope.loginSwitcher = function (type) {
        if (type == 'fgtpass') {
            angular.element('#fgtPassForm').removeClass("hide");
            angular.element('#loginForm').addClass("hide");
        } else if (type == 'login') {
            angular.element('#fgtPassForm').addClass("hide");
            angular.element('#loginForm').removeClass("hide");
        }

    };
    $scope.registerModal = function () {
        $modalStack.dismissAll();
        $modal.open({
            size: 'lg',
            templateUrl: ENV.APP_DIR + 'partials/_register.html',
            controller: 'ModalInstanceController'
        });
    };
}]);
app.controller('ItemCtrl', ['$scope', function ($scope) {
    $scope.cost = 350
}]);
app.controller('SliderCtrl', ['$scope', function ($scope) {
    // $scope.tickValue = "";
    // $scope.tickOptions = {
    //   1:'express',
    //   2:'sameday',
    //   3:'overnight',
    //   4:'budget'
    // };
    // angular.element("#ex13").slider({
    //   ticks: [1, 2, 3, 4],
    //   ticks_labels: ['express', 'sameday', 'overnight', 'budget'],
    //   ticks_snap_bounds: 1,
    //   value: 3,
    //   formatter: function(ticks_labels) {
    //     $scope.tickValue = ticks_labels;
    //   }
    // });

    // $scope.changeDeliveryTime = function() {
    //  //console.log($scope.tickValue);
    //  //console.log($scope.tickOptions[$scope.tickValue]);
    // }

}]);
app.controller('MobileController', ['$scope', '$modal', '$log', function ($scope, $modal, $log) {
    $('._jsDynamicTitle').text('Rush - Mobile');
    // $(".modalImage").on("click", function(event) {
    //   event.preventDefault();
    //   $('#imagepreview').attr('src', $('.modalImage').attr('href')); // here asign the image to the modal when the user click the enlarge link
    //   $('#imagemodal').modal('show'); // imagemodal is the id attribute assigned to the bootstrap modal, then i use the show function
    // });

    $scope.open = function (size, image, text) {

        var modalInstance = $modal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'myModalContent.html',
            controller: 'ModalPreviewInstanceCtrl',
            size: size,
            resolve: {
                image: function () {
                    return image;
                },
                text: function () {
                    return text;
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            $scope.selected = selectedItem;
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };
}]);
/**
 * ModalPreviewInstanceCtrl
 * Please note that $modalInstance represents a modal window (instance) dependency.
 * It is not the same as the $modal service used above.
 *
 * @param  $scope
 * @param  $modalInstance
 * @return void
 */
app.controller('ModalPreviewInstanceCtrl', ['$scope', '$modalInstance', 'image', 'text', function ($scope, $modalInstance, image, text) {

    $scope.$on('$modalInstance', function () {
        $modalInstance.close();
        ////console.log('close');
    });
    $scope.imageText = text;
    $scope.imagePreview = image;
    $scope.ok = function () {
        $modalInstance.close();
    };
    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
    $scope.close = function () {
        $modalInstance.close();
    };
}]);
app.controller('AuthQuickQuoteController', ['$scope', 'AccountFactory', '$cookieStore', 'ApiFactory', 'notifications', 'ngProgress', '$modalStack', '$timeout', '$location', 'blockUI', 'ngDialog', '$modal', 'ENV', function ($scope, AccountFactory, $cookieStore, ApiFactory, notifications, ngProgress, $modalStack, $timeout, $location, blockUI, ngDialog, $modal, ENV) {

    $scope.user = {};


    $scope.loginSwitcher = function (type) {
        console.log(type)
        if (type == 'fgtpass') {
            angular.element('#fgtPassForm').removeClass("hide");
            angular.element('#loginForm').addClass("hide");
        } else if (type == 'login') {
            angular.element('#fgtPassForm').addClass("hide");
            angular.element('#loginForm').removeClass("hide");
        }

    };
    $scope.registerModal = function () {
        $modalStack.dismissAll();
        $modal.open({
            size: 'lg',
            templateUrl: ENV.APP_DIR + 'partials/_register.html',
            controller: 'ModalInstanceController'
        });
    };


    // Login Form
    $scope.processLoginForm = function () {
        //  On success of lgoin
        var success = function (object) {
//                ngProgress.complete();
            ////console.log(object);
            // Clear form inputs
            $scope.user = {};
            // Create variables
            var token = object.data.token;
            ApiFactory.init(token);
            $cookieStore.put('token', token);
            $cookieStore.put('user', object.data.user);
//                window.location.reload();
            blockUI.stop();
            //notifications.showSuccess({message: "Successfully logged in."});
            $timeout(function () {
                blockUI.stop();
//                    ngProgress.complete();
                window.location = '/fullquote';
                // $location.path('/business'); Changed to reload the menu
            }, 500);
        };
        // On fail of login
        var error = function (object) {
            //console.log(object);
//                ngProgress.complete();
            //notifications.showError({message: object.error.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message,
            });
        };
        // Make Login Call
//            ngProgress.color('#d20001');
//            ngProgress.start();
        AccountFactory.postLogin($scope.user).success(success).error(error);
    };
    // Logout of Account
    $scope.processModalDemoController = function () {
        if ($cookieStore.get('token') === undefined) {
            $location.path('/login');
        }
        // On Succes of Logout
        var success = function (object) {
            ngProgress.complete();
            // Removing a cookie
            $cookieStore.remove('token');
            $cookieStore.remove('user');
            ApiFactory.init();
            window.location = '/login';
        };
        // On Fail of logout
        var error = function (data) {
            $cookieStore.remove('token');
            ////console.log(data);
            ngProgress.complete();
        };
        // Make Logout Call
        ngProgress.color('#d20001');
        ngProgress.start();
        AccountFactory.logout().success(success).error(error);
    };
    // Reset your password on forgot password call.
    $scope.processResetPasswordForm = function () {
        var success = function (object) {
            //notifications.showSuccess({message: object.data.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.data.message;
                }],
                className: 'ngdialog-theme-plain',
                msg: object.data.message,
            });
            ngProgress.complete();
            $scope.user = {};
            $modalStack.dismissAll();
        };
        var error = function (object) {
            // notifications.showError({message: object.error.message});
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message,
            });
            //ngProgress.complete();
        };
        ngProgress.color('#d20001');
        ngProgress.start();
        AccountFactory.resetPassword($scope.user).success(success).error(error);
    };
}]);
app.controller('FullQuoteController', ['$scope', 'ngDialog', '$location', '$modal', 'ENV', '$localStorage', 'blockUI', 'CourierFactory', 'AccountFactory', 'notifications', '$http', 'quickQuotes', '$timeout', '$rootScope', '$modal', 'BasketFactory', 'dateFilter', '$route','$cookieStore','MarginGroupFactory', function ($scope, ngDialog, $location, $modal, ENV, $localStorage, blockUI, CourierFactory, AccountFactory, notifications, $http, quickQuotes, $timeout, $rootScope, $modal, BasketFactory, dateFilter, $route, $cookieStore, MarginGroupFactory) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';

    $('._jsDynamicTitle').text('Rush - Complete Order');
    $scope.widget = quickQuotes.get();
    $scope.int_code_label = "+27(0)";
    $scope.int_code = "+27";
    // used in summary
    $scope.order_widget = $localStorage.order_widget;
    $scope.order_widget.pickup = localStorage.getItem('pick_up');
    $scope.order_widget.dropoff = localStorage.getItem('drop_off');
    $scope.order_widget.receiver_mobile = $scope.order_widget.receiver_mobile.replace("+27 (0)", "").replace("+27", "");
    $scope.order_widget.receiver_phone = $scope.order_widget.receiver_phone.replace("+27 (0)", "").replace("+27", "");
    $scope.order_widget.pickup;
    $scope.order_widget.dropoff;
    $scope.order_price_object = $localStorage.order_price_object;
    $scope.order_price_list = $localStorage.order_price_list;
    $scope.order_product = $localStorage.order_product;
    $scope.insurance = $localStorage.insurance;
    $scope.order = $scope.order_product;
    $scope.order.type = $scope.order_widget.type;
    $scope.order.weight = $scope.order_widget.weight;
    $scope.order.amt = $localStorage.totalAmount;
    $scope.order.cube = $localStorage.cube;
    $scope.order.wgt = $localStorage.totalWeight;
    $scope.order.insurance = $scope.insurance.checked;
    $scope.order.insurance_value = ($scope.insurance.checked) ? $scope.insurance.value : 0;
    $scope.order.insurance_cost = ($scope.insurance.checked) ? $scope.insurance.cost : 0;
    $scope.order.insurance_desc = ($scope.insurance.checked) ? $scope.insurance.desc : "No Insurance";
    $scope.order.insurance_text = ($scope.insurance.checked) ? "(incl. insurance for '" + $scope.insurance.desc + "' valued at R" + $scope.insurance.value + ")" : "(insurance not included)";
    $scope.open = function (size, templateUrl) {
        var modalInstance = $modal.open({
            size: size,
            templateUrl: ENV.APP_DIR + templateUrl,
            controller: 'ModalInstanceController',
            scope: $scope

        });
    };
    $timeout(function () {
        blockUI.stop();
    }, 500);
    $scope.go = function (path) {
        $location.path(path);
    };
    $scope.functionLog = function () {
        //console.log('test');
    };
    $scope.address_pickup = {};
    $scope.address_dropoff = {};

    $scope.clearPickupAddress = function () {
        $scope.order_widget.sender_name = "";
        $scope.order_widget.sender_company = "";
        $scope.order_widget.sender_tel = "";
        $scope.order_widget.sender_mob = "";
        $scope.order_widget.sender_email = "";
        $scope.address_pickup.lineone = "";
        $scope.address_pickup.linetwo = "";
        $scope.order_widget.sender_ref = "";
        $scope.autoFilledAddressPickUp = false;
    };

    $scope.clearDropoffAddress = function () {
        $scope.order_widget.receiver_contact = "";
        $scope.order_widget.receiver_phone = "";
        $scope.order_widget.receiver_mobile = "";
        $scope.order_widget.receiver_email = "";
        $scope.address_dropoff.lineone = "";
        $scope.address_dropoff.linetwo = "";
        $scope.order_widget.receiver_name = "";
        $scope.order_widget.account_number = "";
        $scope.order_widget.special_instructions = "";
        $scope.autoFilledAddressDropOff = false;
    };

    $scope.captureFullQuotePickupAddress = function () {
        var p_linetwo = $scope.address_pickup.linetwo === undefined ? '' : $scope.address_pickup.linetwo;
        $scope.order_widget.pick_up_address = $scope.address_pickup.lineone.replace(/,/g, "") + ',' + p_linetwo.replace(/,/g, "") + ',' + $scope.order_widget.pickup;
        $scope.order_widget.pickup_object = {};
        $scope.order_widget.pickup_object.lineone = $scope.address_pickup.lineone;
        $scope.order_widget.pickup_object.linetwo = p_linetwo;
        $scope.order_widget.pickup_object.rest = $scope.order_widget.pickup;
        $scope.order_widget.sender_tel = $scope.order_widget.sender_tel;
        $scope.order_widget.sender_mob = $scope.order_widget.sender_mob;
        $scope.autoFilledAddressPickUp = true;
    }

    $scope.captureFullQuoteDropoffAddress = function () {
        var d_linetwo = $scope.address_dropoff.linetwo === undefined ? '' : $scope.address_dropoff.linetwo;
        $scope.order_widget.drop_off_address = $scope.address_dropoff.lineone.replace(/,/g, "") + ',' + d_linetwo.replace(/,/g, "") + ',' + $scope.order_widget.dropoff;
        $scope.order_widget.dropoff_object = {};
        $scope.order_widget.dropoff_object.lineone = $scope.address_dropoff.lineone;
        $scope.order_widget.dropoff_object.linetwo = d_linetwo;
        $scope.order_widget.dropoff_object.rest = $scope.order_widget.dropoff;
        $scope.order_widget.receiver_name = ($scope.order_widget.receiver_name !== undefined && $scope.order_widget.receiver_name.trim().length > 0) ? $scope.order_widget.receiver_name : "Personal";
        $scope.order_widget.account_number = $scope.order_widget.account_number !== undefined ? $scope.order_widget.account_number : "";
        $scope.order_widget.special_instructions = $scope.order_widget.special_instructions !== undefined ? $scope.order_widget.special_instructions : "";
        $scope.order_widget.receiver_mobile = $scope.order_widget.receiver_mobile;
        $scope.order_widget.receiver_phone = $scope.order_widget.receiver_phone;
        $scope.autoFilledAddressDropOff = true;
    }

    //get margin group
    $scope.currentUser = $cookieStore.get('user');
    $scope.markup_percentage={};
    $scope.discount_percentage={};

    if ($cookieStore.get('user') !== undefined) {

      $scope.margin_group_id=  $scope.currentUser.account[0].margin_mgmnt_groups_id;

      getMarginGroup = function () {
          var success = function (object) {
              blockUI.stop();
              $scope.marginGroup = object.data;
              $scope.margin_group_markup =   $scope.marginGroup.markup_percentage;
              $scope.margin_group_discount =   $scope.marginGroup.discount_percentage;
              $scope.discount_percentage = $scope.margin_group_discount / 100;
              $scope.markup_percentage =   $scope.margin_group_markup / 100;

              //costing for margin groups
              $scope.net_cost =  parseFloat( $scope.order_product.TotalCost.replace(/,/g, "").replace(" ", "") ) +  parseFloat( $scope.order.insurance_cost/1.15 )
              $scope.markup_cost =  parseFloat ($scope.net_cost *   $scope.markup_percentage )
              $scope.gross_selling_price = parseFloat ($scope.net_cost + $scope.markup_cost)
              $scope.discount_cost = parseFloat($scope.gross_selling_price *   $scope.discount_percentage)
              $scope.net_selling_price = parseFloat ($scope.gross_selling_price  - $scope.discount_cost)
              $scope.vat_cost = parseFloat (0.15 * $scope.net_selling_price)
              $scope.total_cost_plus_vat =  parseFloat (  $scope.net_selling_price + $scope.vat_cost)

          };
          var error = function (object) {
              blockUI.stop();
              console.log(object);
          };
          blockUI.start();
          MarginGroupFactory.getMarginGroup($scope.margin_group_id).success(success).error(error);
      };

      getMarginGroup();

    } else {

          getDefaultMarginGroup = function () {
              var success = function (object) {
                  blockUI.stop();
                  $scope.marginGroup = object.data;
                  $scope.margin_group_markup =   $scope.marginGroup.markup_percentage;
                  $scope.margin_group_discount =   $scope.marginGroup.discount_percentage;
                  $scope.markup_percentage =   $scope.margin_group_markup / 100;

                  //costing for margin group
                  $scope.net_cost =  parseFloat( $scope.order_product.TotalCost ) +  parseFloat( $scope.order.insurance_cost/1.15 )
                  $scope.markup_cost =  parseFloat ($scope.net_cost *   $scope.markup_percentage )
                  $scope.gross_selling_price = parseFloat ($scope.net_cost + $scope.markup_cost)
                  $scope.discount_cost = parseFloat($scope.gross_selling_price *   $scope.discount_percentage)
                  $scope.net_selling_price = parseFloat ($scope.gross_selling_price  - $scope.discount_cost)
                  $scope.vat_cost = parseFloat (0.15 * $scope.net_selling_price)
                  $scope.total_cost_plus_vat =  parseFloat (  $scope.net_selling_price + $scope.vat_cost)
              };
              var error = function (object) {
                  blockUI.stop();
                  console.log(object);
              };
              blockUI.start();
              MarginGroupFactory.getMarginGroup(2).success(success).error(error);
          };

          getDefaultMarginGroup();
    }



    $scope.processFullQuote = function () {
        //console.log("process");
        blockUI.start();
        $scope.captureFullQuotePickupAddress();
        $scope.captureFullQuoteDropoffAddress();
        $scope.order_widget.amt = $scope.widget.amt;
        $scope.order_widget.cube = $scope.widget.cube;
        $scope.order_widget.wgt = $scope.widget.wgt;

        quickQuotes.set($scope.widget);
        $localStorage.$reset();
        $scope.storage = $localStorage.$default({'totalAmount': $scope.order.amt});
        $scope.storage = $localStorage.$default({'cube': $scope.order.cube});
        $scope.storage = $localStorage.$default({'totalWeight': $scope.order.wgt});
        $scope.storage = $localStorage.$default({'order_widget': $scope.order_widget});
        $scope.storage = $localStorage.$default({'order_price_object': $scope.order_price_object});
        $scope.storage = $localStorage.$default({'order_price_list': $scope.order_price_list});
        $scope.storage = $localStorage.$default({'order_product': $scope.order_product});
        $scope.storage = $localStorage.$default({'insurance': $scope.insurance});
        blockUI.stop();

        var success = function (object) {
            blockUI.stop();
            $location.path('/basket');

        };

        // On failure
        var error = function (object) {
            console.log(object);
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = 'Sorry, we failed to add this to your basket.';
                }],
                className: 'ngdialog-theme-default',
                msg: 'Sorry, we failed to add this to your basket.'
            });
            blockUI.stop();
        };

        createOrderObject();
        blockUI.start();
        console.log('order')
        console.log($scope.order)
        BasketFactory.addBasketItem($scope.order).success(success).error(error);
    };
    var createOrderObject = function () {
        $scope.orderDate = new Date();
        // Get Local Storage
        var d = new Date();
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000); // getTimezoneOffset returns difference between UTC and currentTime
        var nd = new Date(utc + (3600000 * 2));// adds 2 hours to utc time
        var dt = dateFilter(nd, "yyyy-MM-dd HH:mm:ss");

        $scope.order_widget = $localStorage.order_widget;
        $scope.order_price_object = $localStorage.order_price_object;
        $scope.order_price_list = $localStorage.order_price_list;
        $scope.order_product = $localStorage.order_product;
        $scope.insurance = $localStorage.insurance;

        // Create Order
        $scope.order = $scope.order_product;
        $scope.order.order_date = dt;
        $scope.order.type = $scope.order_widget.type;
        $scope.order.account_id = $scope.currentUser.account[0].id;
        $scope.order.company = $scope.currentUser.account[0].company;
        $scope.order.company_vat = $scope.currentUser.account[0].company_vat;
        // console.log($scope.order.company + ", " + $scope.order.company_vat);
        $scope.order.sender_name = $scope.order_widget.sender_name;
        $scope.order.sender_company = $scope.order_widget.sender_company;
        $scope.order.sender_email = $scope.order_widget.sender_email;
        $scope.order.sender_tel = $scope.order_widget.sender_tel;
        $scope.order.sender_mob = $scope.order_widget.sender_mob;
        $scope.order.sender_ref = ($scope.order_widget.sender_ref == undefined) ? "" : $scope.order_widget.sender_ref;

        $scope.order_widget.receiver_name = ($scope.order_widget.receiver_name !== undefined && $scope.order_widget.receiver_name.trim().length > 0) ? $scope.order_widget.receiver_name : "Personal";
        $scope.order.receiver_name = $scope.order_widget.receiver_name;
        $scope.order.receiver_account_no = $scope.order_widget.account_number;
        $scope.order.special_instructions = $scope.order_widget.special_instructions;
        $scope.order.receiver_contact = $scope.order_widget.receiver_contact;

        $scope.order.receiver_phone = $scope.order_widget.receiver_phone === 9 ? $scope.int_code + $scope.order_widget.receiver_phone : $scope.order_widget.receiver_phone;
        $scope.order.receiver_mobile = $scope.order_widget.receiver_mobile === 9 ? $scope.int_code + $scope.order_widget.receiver_mobile : $scope.order_widget.receiver_mobile;

        $scope.order.receiver_email = $scope.order_widget.receiver_email;
        $scope.order.pick_up = $scope.order_widget.pick_up_address;
        $scope.order.drop_off = $scope.order_widget.drop_off_address;
        $scope.order.insurance = $scope.insurance.checked;
        $scope.order.insurance_value = ($scope.insurance.checked) ? $scope.insurance.value : 0;
        $scope.order.insurance_cost = ($scope.insurance.checked) ? $scope.insurance.cost : 0;
        $scope.order.insurance_desc = ($scope.insurance.checked) ? $scope.insurance.desc : "No Insurance";
        $scope.order.insurance_text = ($scope.insurance.checked) ? "(incl. insurance for '" + $scope.insurance.desc + "' valued at R" + $scope.insurance.value + ")" : "(insurance not included)";

//        if ($scope.order_widget.docType !== undefined) {
        $scope.order.docType = $scope.order_widget.docType;
        $scope.order.parcelType = $scope.order_widget.parcelType;
        $scope.order.parcel_desc = $scope.order_widget.parcel_desc;
        $scope.order.length = $scope.order_widget.length;
        $scope.order.width = $scope.order_widget.width;
        $scope.order.height = $scope.order_widget.height;
        $scope.order.weight = $scope.order_widget.weight;
        $scope.order.amount = $scope.order_widget.amount;
        $scope.order.amt = $localStorage.totalAmount;
        $scope.order.cube = $localStorage.cube;
        $scope.order.wgt = $localStorage.totalWeight;
        //  console.log($scope.order)
    };
    var setDefaultSenderDetails = function () {
        var success = function (object) {
            ////console.log(object.data);
            address = object.data;
            //console.log(address);
            $scope.order_widget.sender_name = address.first_name + " " + address.last_name;
            $scope.order_widget.sender_company = address.company_name;

            // if it doesn't country code, or if it's SA NUM
            if (address.mobile_number.indexOf('+') === -1 || address.mobile_number.indexOf('+27') !== -1) {
                $scope.order_widget.sender_mob = address.mobile_number.replace("+27 (0)", "").replace("+27", "");
            }
            if (address.landline.indexOf('+') === -1 || address.landline.indexOf('+27') !== -1) {
                $scope.order_widget.sender_tel = address.landline.replace("+27 (0)", "").replace("+27", "");
            }
            $scope.order_widget.sender_email = address.email;
        };
        var error = function (object) {
        };

        AccountFactory.getProfile($scope.currentUser.id).success(success).error(error);

    };
    if ($scope.currentUser !== null) {
        setDefaultSenderDetails();
    }
}]);
app.controller('PanelRightController', ['$scope', '$rootScope', '$http', '$location', 'quickQuotes', '$timeout',
    'blockUI', '$modal', 'ENV', '$localStorage', '$route', '$cookieStore', function ($scope, $rootScope, $http, $location, quickQuotes, $timeout, blockUI, $modal, ENV, $localStorage, $route, $cookieStore) {

        blockUI.start();
        //$rootScope.bannerClass = 'bannerHide';
        // $rootScope.bufferDiv = 'bannerShow';
        // $('._jsDynamicTitle').text('Rush - Pronto Service');

        $scope.address = {};
        $scope.widget = {
            "type": "parcel",
            "insurance": false
        };
        $scope.onPickupSelect = function ($item, $model, $label) {
            $scope.widget.pickup_right = $item.SUBURB + ', ' + $item.STATE + ', ' + $item.POSTCODE;
        };
        $scope.onDropoffSelect = function ($item, $model, $label) {
            $scope.widget.dropoff_right = $item.SUBURB + ', ' + $item.STATE + ', ' + $item.POSTCODE;
        };
        quickQuotes.set($scope.widget);

        if ($cookieStore.get('token') !== undefined) {
            $scope.currentLoggedInUser = true;
        } else {
            //do write code here
        }

        $scope.go = function (path) {
            if ($localStorage.newOrderTab === undefined) {
                $localStorage.$default({'newOrderTab': false});
            } else {
                $localStorage.newOrderTab = false;
            }
            if (path === 'NewOrder') {
                $localStorage.newOrderTab = true;
                path = 'account';
            }
            if ($location.path() === '/' + path) {
                $route.reload();
            }
            $location.path(path);
        };

        $scope.quote = function () {
            $location.path('quickquote');
        };

        // $scope.open = function (size, templateUrl) {
        //     var modalInstance = $modal.open({
        //         size: size,
        //         templateUrl: ENV.APP_DIR + templateUrl,
        //         controller: 'ModalInstanceController',
        //         scope: $scope //Or $scope.$new(

        //     });
        // };
        $timeout(function () {
            blockUI.stop();
        }, 500);
    }]);


app.controller('QuickQuoteController', ['$scope', 'ngDialog', '$location', '$modalStack', 'ENV', '$localStorage', 'blockUI', 'CourierFactory', 'notifications', '$http', 'quickQuotes', '$timeout', '$rootScope', '$modal', '$route', function ($scope, ngDialog, $location, $modalStack, ENV, $localStorage, blockUI, CourierFactory, notifications, $http, quickQuotes, $timeout, $rootScope, $modal, $route) {

    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';

    $('._jsDynamicTitle').text('Rush - Quick Quote');
    $localStorage.$reset();
    $scope.widget = quickQuotes.get();
    $scope.widget.amtNative = 1;
    $scope.widget.doc_amtNative = 1;
//        $scope.widget.descNative = $scope.widget.parcel_desc;
    $scope.widget.lengthNative = undefined;
    $scope.widget.widthNative = undefined;
    $scope.widget.heightNative = undefined;
    $scope.widget.parcel_desc = {};
    $scope.widget.weight = {};
    $scope.widget.length = {};
    $scope.widget.width = {};
    $scope.widget.height = {};
    $scope.widget.docType = {};
    $scope.widget.parcelType = {};
    $scope.widget.amount = {};
    $scope.widget.doc_amt = {};
    $scope.widget.doc_weight = {};
    $scope.widget.pickup = $scope.widget.pickup_right;
    $scope.widget.dropoff = $scope.widget.dropoff_right;
    $scope.widget.ordertype = 'quickquote';
    $scope.address = {};
    $scope.widget = {
        "insurance": false
    };

    $scope.widget.type = 'Parcel';

    $scope.parcelWidget = "'frontend/app/partials/_parcel.html'";
    $scope.documentWidget = ENV.APP_DIR + "partials/_document.html";

    $scope.get = function () {
        var parcel_desc = document.getElementById('parcel-desc').value;
        if (parcel_desc !== '') {
            $scope.widget.insuranceDesc = parcel_desc;
        }
    }

    var $key = 0;
    $timeout(function () {
        blockUI.stop();
    }, 500);
    $scope.address_pickup = {};
    $scope.address_dropoff = {};
    $scope.go = function (path) {
        $location.path(path);
    };
    $scope.functionLog = function () {
        //console.log('test');
    };
    $scope.parcels = [];
    $scope.addParcel = function () {

        var newParcel = {
            weight: $scope.widget.weight,
            length: $scope.widget.length,
            width: $scope.widget.width,
            height: $scope.widget.height,
            amount: $scope.widget.amount
        };
        $scope.parcels.push(newParcel);
        $key = Object.keys($scope.parcels).length;
        $scope.widget.amount[$key - 1] = 1;
        $scope.widget.parcelType[$key] = "parcel";
    };

    $scope.deleteParcel = function (rowNo) {
        $scope.parcels.splice(rowNo, 1);
    };

    $scope.address_pickup.lineone = "quickquote";
    $scope.address_dropoff.lineone = "quickquote";
    $scope.get_pickup = localStorage.getItem('pick_up');
    $scope.get_dropoff = localStorage.getItem('drop_off');
    $scope.widget.pick_up = $scope.address_pickup.lineone.replace(/,/g, "") + ',,' + $scope.widget.pickup;
    $scope.widget.drop_off = $scope.address_dropoff.lineone.replace(/,/g, "") + ',,' + $scope.widget.dropoff;
    $scope.widget.receiver_contact = "quickquote";
    $scope.widget.receiver_email = "quickquote@gmail.com";
    $scope.widget.receiver_mobile = "123456789";
    $scope.widget.receiver_phone = "123456789";
    $scope.widget.receiver_name = "Personal";
    $scope.widget.account_number = "";
    $scope.widget.special_instructions = "";
    $scope.widget.amount = {};
    $scope.widget.parcelType = {};
    $scope.widget.docType = {};

    $scope.onPickupSelect = function ($item, $model, $label) {
        $scope.address_pickup.town = $item.SUBURB;
        $scope.address_pickup.state = $item.STATE;
        $scope.address_pickup.postcode = $item.POSTCODE;
        $scope.widget.pickup = $scope.address_pickup.town + ', ' + $scope.address_pickup.state + ', ' + $scope.address_pickup.postcode;

        var pick_up = $scope.widget.pickup;
        localStorage.setItem('pick_up', pick_up)

    };

    $scope.onDropoffSelect = function ($item, $model, $label) {
        $scope.address_dropoff.town = $item.SUBURB;
        $scope.address_dropoff.state = $item.STATE;
        $scope.address_dropoff.postcode = $item.POSTCODE;
        $scope.widget.dropoff = $scope.address_dropoff.town + ', ' + $scope.address_dropoff.state + ', ' + $scope.address_dropoff.postcode;

        var drop_off = $scope.widget.dropoff;
        localStorage.setItem('drop_off', drop_off)
    };

    $scope.processParcelWidget = function () {

        $localStorage.$reset();

        $scope.get_pickup = localStorage.getItem('pick_up');
        $scope.get_dropoff = localStorage.getItem('drop_off');

        $scope.widget.pick_up = $scope.address_pickup.lineone.replace(/,/g, "") + ',,' + $scope.get_pickup;
        $scope.widget.drop_off = $scope.address_dropoff.lineone.replace(/,/g, "") + ',,' + $scope.get_dropoff;
//            console.log($scope.widget.pick_up)
//            console.log($scope.widget.drop_off)

        var pick_up_address = $scope.widget.pick_up;
        localStorage.setItem('pick_up_address', pick_up_address)

        var drop_off_address = $scope.widget.drop_off;
        localStorage.setItem('drop_off_address', drop_off_address)

        if ($scope.widget.weight !== undefined) {
            var $key = Object.keys($scope.parcels).length;
//                console.log('key: ' + $key)
        } else {
            var $key = 0;
            $scope.widget.weight = {};
            $scope.widget.length = {};
            $scope.widget.width = {};
            $scope.widget.height = {};
            $scope.widget.docType = {};
            $scope.widget.parcel_desc = {};
            $scope.widget.parcelType = {};
            $scope.widget.amount = {};
        }

        //$scope.widget.docType[$key] = $scope.widget.docTypeNative;
        $scope.widget.parcel_desc[$key] = $scope.widget.descNative;
        $scope.widget.weight[$key] = $scope.widget.weightNative;
        $scope.widget.parcelType[$key] = $scope.widget.parcelTypeNative;
        $scope.widget.amount[$key] = $scope.widget.amtNative;
        $scope.widget.length[$key] = $scope.widget.lengthNative;
        $scope.widget.height[$key] = $scope.widget.heightNative;
        $scope.widget.width[$key] = $scope.widget.widthNative;
        $scope.widget.parcelType[$key] === "parcel"

        // loop for each parcel
        for (var $i = 0; $i <= $key; $i++) {
            $scope.widget.docType[$i] = "Parcel";
            $scope.widget.parcelType[$i] = 'parcel';
        }

        blockUI.start();
        var success = function (object) {
            quickQuotes.set($scope.widget);

            $localStorage.$reset();
            blockUI.stop();
            $modalStack.dismissAll();
            $scope.storage = $localStorage.$default({'order_widget': $scope.widget});
            $scope.cube = object.totalVolume;
            $scope.totalAmount = object.totalAmount;
            $scope.totalWeight = object.totalWeight;
            $scope.priceSize = Object.keys(object.data.CostComparisonResult.CostComparisonResults.ResultSet).length;
            ////console.log($scope.priceSize);
            if ($scope.priceSize !== 0) {
                $scope.storage = $localStorage.$default({'cube': $scope.cube});
                $scope.storage = $localStorage.$default({'totalAmount': $scope.totalAmount});
                $scope.storage = $localStorage.$default({'totalWeight': $scope.totalWeight});
                $scope.storage = $localStorage.$default({'order_price_object': object.data});
                $scope.storage = $localStorage.$default({'order_price_list': object.data.CostComparisonResult.CostComparisonResults.ResultSet});
                $scope.storage = $localStorage.$default({'insurance': object.insurance});
                //$location.path('/compare');
                $location.path('/compare');
                $route.reload();
                quickQuotes.set($scope.widget);

                var address = $scope.widget.pickup;
                var drop = $scope.widget.dropoff;
                $scope.widget.pick_up = address;
                $scope.widget.drop_off = drop;
                $scope.widget.receiver_contact = "";
                $scope.widget.receiver_email = "";
                $scope.widget.receiver_mobile = "";
                $scope.widget.receiver_phone = "";
                $scope.widget.receiver_name = "";
                $scope.widget.account_number = "";
                $scope.widget.sender_name = "";
                $scope.widget.sender_company = "";
                $scope.widget.sender_mob = "";
                $scope.widget.sender_tel = "";
                $scope.widget.sender_ref = "";
                $scope.widget.special_instructions = "";
                $scope.widget.ordertype = 'quickquote';

                quickQuotes.set($scope.widget);
            } else {

                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Sorry. We could not find any courier prices for this delivery request.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: "Sorry. We could not find any courier prices for this delivery request."
                });
            }

        };
        var error = function (object) {

            blockUI.stop();
            var $key = Object.keys($scope.parcels).length;

            //notifications.showError({message: object.error.message});

            // remove native values from array
            $ptype = $scope.widget.parcelType;
            $wgt = $scope.widget.weight;
            $amt = $scope.widget.amount;
            $len = $scope.widget.length;
            $hgt = $scope.widget.height;
            $wdt = $scope.widget.width;

            $scope.widget.weight = {};
            $scope.widget.length = {};
            $scope.widget.width = {};
            $scope.widget.height = {};
            $scope.widget.parcelType = {};
            $scope.widget.amount = {};

            for ($in = 0; $in < $key; $in++) {
                $scope.widget.weight[$in] = $wgt[$in];
                $scope.widget.parcelType[$in] = $ptype[$in];
                $scope.widget.amount[$in] = $amt[$in];
                $scope.widget.length[$in] = $len[$in];
                $scope.widget.height[$in] = $hgt[$in];
                $scope.widget.width[$in] = $wdt[$in];
            }

            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
        };
        ////console.log($scope.widget);
        var validateInput = function () {
            if (
                ($scope.widget.pick_up == undefined || $scope.widget.pick_up.trim().length == 0) ||
                ($scope.widget.drop_off == undefined || $scope.widget.drop_off.trim().length == 0)
            ) {
                return false;
            }
            var $length = Object.keys($scope.parcels).length + 1;

            for ($i = 0; $i < $length; $i++) {
                if (
                    ($scope.widget.parcel_desc[$i] == undefined) ||
                    ($scope.widget.weight[$i] == undefined) ||
                    ($scope.widget.length[$i] == undefined) ||
                    ($scope.widget.width[$i] == undefined) ||
                    ($scope.widget.height[$i] == undefined) ||
                    ($scope.widget.amount[$i] == undefined)
                ) {
                    return false;
                }
            }

            if ($scope.widget.insurance == true) {
                if ($scope.widget.insuranceValue == "") {
                    return false;
                }
                if ($scope.widget.insuranceDesc.trim() == "") {
                    return false;
                }
            }

            return true;
        };

        if (!validateInput()) {
            object = {};
            object.error = {};
            object.error.message = "Not all the required fields have been filled.";
            error(object);
        } else {
            CourierFactory.findParcelCourierPrices($scope.widget).success(success).error(error);
        }
    };

}]);

app.controller('QuickQuoteDocController', ['$scope', 'ngDialog', '$location', '$modalStack', 'ENV', '$localStorage', 'blockUI', 'CourierFactory', 'notifications', '$http', 'quickQuotes', '$timeout', '$rootScope', '$modal', '$route', function ($scope, ngDialog, $location, $modalStack, ENV, $localStorage, blockUI, CourierFactory, notifications, $http, quickQuotes, $timeout, $rootScope, $modal, $route) {

    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';

    $('._jsDynamicTitle').text('Rush - Quick Quote');
    $localStorage.$reset();
    $scope.widget = quickQuotes.get();
    $scope.widget.amtNative = 1;
    $scope.widget.doc_amtNative = 1;
    $scope.widget.docTypeNative = "A4";
    $scope.widget.doc_weightNative = $scope.widget.weightNative;
    $scope.widget.docTypeNative;
//        $scope.widget.parcelTypeNative = "Document";
//        $scope.widget.type = "Document";
    $scope.widget.lengthNative = undefined;
    $scope.widget.widthNative = undefined;
    $scope.widget.heightNative = undefined;
    $scope.widget.weight = {};
    $scope.widget.length = {};
    $scope.widget.width = {};
    $scope.widget.height = {};
    $scope.widget.docType = "A4";
    $scope.widget.parcelType = {};
    $scope.widget.amount = {};
    $scope.widget.doc_amt = {};
    $scope.widget.doc_weight = {};
    $scope.widget.pickup = $scope.widget.pickup_right;
    $scope.widget.dropoff = $scope.widget.dropoff_right;
    $scope.widget.ordertype = 'quickquote';
    $scope.address = {};
    $scope.widget = {
        "insurance": false
    };

    $scope.parcelWidget = "'frontend/app/partials/_parcel.html'";
    $scope.documentWidget = ENV.APP_DIR + "partials/_document.html";

    $scope.get_ins = function () {
        var doc_desc = document.getElementById('doc-desc').value;
        if (doc_desc !== '') {
            $scope.widget.insuranceDesc = doc_desc;
        }
//            console.log($scope.widget.insuranceDesc);
    }

    var $key = 0;
    $timeout(function () {
        blockUI.stop();
    }, 500);
    $scope.address_pickup = {};
    $scope.address_dropoff = {};
    $scope.go = function (path) {
        $location.path(path);
    };
    $scope.functionLog = function () {
    };

    $scope.documents = [];
    $scope.addRow = function () {
        var newDocument = {
            parcel_desc: $scope.widget.doc_parcel_desc,
            weight: $scope.widget.doc_weight,
            length: $scope.widget.docType
        };
        $scope.documents.push(newDocument);
        var $key = $scope.documents.length;
        $scope.widget.doc_amt[$key - 1] = 1;
        $scope.widget.docType[$key - 1] = 'A4';
    };

    $scope.deleteRow = function (rowNo) {
        $scope.documents.splice(rowNo, 1);
    };
    $scope.address_pickup.lineone = "quickquote";
    $scope.address_dropoff.lineone = "quickquote";
    $scope.get_pickup = localStorage.getItem('pick_up');
    $scope.get_dropoff = localStorage.getItem('drop_off');
    $scope.widget.pick_up = $scope.address_pickup.lineone.replace(/,/g, "") + ',,' + $scope.widget.pickup;
    $scope.widget.drop_off = $scope.address_dropoff.lineone.replace(/,/g, "") + ',,' + $scope.widget.dropoff;
    $scope.widget.receiver_contact = "quickquote";
    $scope.widget.receiver_email = "quickquote@gmail.com";
    $scope.widget.receiver_mobile = "123456789";
    $scope.widget.receiver_phone = "123456789";
    $scope.widget.receiver_name = "Personal";
    $scope.widget.account_number = "";
    $scope.widget.special_instructions = "";
    $scope.widget.doc_amt = {};
    $scope.widget.docType = "A4";
    //console.log($scope.widget);
    $scope.onPickupSelect = function ($item, $model, $label) {
        $scope.address_pickup.town = $item.SUBURB;
        $scope.address_pickup.state = $item.STATE;
        $scope.address_pickup.postcode = $item.POSTCODE;
        $scope.widget.pickup = $scope.address_pickup.town + ', ' + $scope.address_pickup.state + ', ' + $scope.address_pickup.postcode;

        var pick_up = $scope.widget.pickup;
        localStorage.setItem('pick_up', pick_up)

    };

    $scope.onDropoffSelect = function ($item, $model, $label) {
        $scope.address_dropoff.town = $item.SUBURB;
        $scope.address_dropoff.state = $item.STATE;
        $scope.address_dropoff.postcode = $item.POSTCODE;
        $scope.widget.dropoff = $scope.address_dropoff.town + ', ' + $scope.address_dropoff.state + ', ' + $scope.address_dropoff.postcode;

        var drop_off = $scope.widget.dropoff;
        localStorage.setItem('drop_off', drop_off)
    };


    $scope.processDocumentWidget = function () {
        //console.log('processParcelWidget')

        $localStorage.$reset();
        $scope.widget.docTypeNative = "A4";
        $scope.get_pickup = localStorage.getItem('pick_up');
        $scope.get_dropoff = localStorage.getItem('drop_off');

        $scope.widget.pick_up = $scope.address_pickup.lineone.replace(/,/g, "") + ',,' + $scope.get_pickup;
        $scope.widget.drop_off = $scope.address_dropoff.lineone.replace(/,/g, "") + ',,' + $scope.get_dropoff;
//            console.log($scope.widget.pick_up)
//            console.log($scope.widget.drop_off)

        var pick_up_address = $scope.widget.pick_up;
        localStorage.setItem('pick_up_address', pick_up_address)

        var drop_off_address = $scope.widget.drop_off;
        localStorage.setItem('drop_off_address', drop_off_address)

        if ($scope.widget.weight !== undefined) {
            var $key = Object.keys($scope.widget.doc_weight).length;
        } else {
            var $key = 0;
            $scope.widget.weight = {};
            $scope.widget.length = {};
            $scope.widget.width = {};
            $scope.widget.height = {};
            $scope.widget.docType = {};
            $scope.widget.parcelType = {};
            $scope.widget.parcel_desc = {};
            $scope.widget.amount = {};
        }

        $scope.type = 'Document';
        $scope.widget.parcelType[$key] = 'document';
        $scope.widget.amount[$key] = $scope.widget.doc_amtNative;
        $scope.widget.weight[$key] = $scope.widget.doc_weightNative;
        $scope.widget.docType[$key] = $scope.widget.docTypeNative;
        $scope.widget.parcel_desc[$key] = $scope.widget.doc_descNative;
//            $scope.widget.docType[$key] === 'A4';

        $scope.widget.height[$key] = 2;
        $scope.widget.type = 'Document';

        if ($scope.widget.docTypeNative === "A4") {
            $scope.widget.length[$key] = 30;
            $scope.widget.width[$key] = 21;
            $scope.widget.docType[$key] = 'A4';
        } else {
            $scope.widget.length[$key] = 36;
            $scope.widget.width[$key] = 22;
            $scope.widget.docType[$key] = 'Legal';

        }
//            console.log($scope.widget.docTypeNative)
        $scope.widget.docType[$key] = $scope.widget.docTypeNative;
        // loop for each parcel
        for (var $i = 0; $i < $scope.documents.length; $i++) {
            $scope.widget.height[$i] = 2;
            $scope.widget.weight[$i] = $scope.widget.doc_weight[$i];
            $scope.widget.amount[$i] = $scope.widget.doc_amt[$i];
            $scope.widget.parcel_desc[$i] = $scope.widget.doc_parcel_desc[$i];
            $scope.widget.parcelType[$i] = 'document';
            if ($scope.widget.docType[$i] === "A4") {
                $scope.widget.length[$i] = 30;
                $scope.widget.width[$i] = 21;
                $scope.widget.docType[$i] = 'A4';
            } else {
                $scope.widget.length[$i] = 36;
                $scope.widget.width[$i] = 22;
                $scope.widget.docType[$i] = 'Legal';

            }

        }

        blockUI.start();
        var success = function (object) {
            quickQuotes.set($scope.widget);
            //console.log(quickQuotes.get());

            $localStorage.$reset();
            blockUI.stop();
            $modalStack.dismissAll();
            $scope.storage = $localStorage.$default({'order_widget': $scope.widget});
            $scope.cube = object.totalVolume;
            $scope.totalAmount = object.totalAmount;
            $scope.totalWeight = object.totalWeight;
            $scope.priceSize = Object.keys(object.data.CostComparisonResult.CostComparisonResults.ResultSet).length;
            ////console.log($scope.priceSize);
            if ($scope.priceSize !== 0) {
                $scope.storage = $localStorage.$default({'cube': $scope.cube});
                $scope.storage = $localStorage.$default({'totalAmount': $scope.totalAmount});
                $scope.storage = $localStorage.$default({'totalWeight': $scope.totalWeight});
                $scope.storage = $localStorage.$default({'order_price_object': object.data});
                $scope.storage = $localStorage.$default({'order_price_list': object.data.CostComparisonResult.CostComparisonResults.ResultSet});
                $scope.storage = $localStorage.$default({'insurance': object.insurance});
                //$location.path('/compare');
                $location.path('/compare');
                $route.reload();
                quickQuotes.set($scope.widget);
//                    var address=$scope.widget.pick_up.substr($scope.widget.pick_up.indexOf(",,") + 1);
//
                var address = $scope.widget.pickup;
                var drop = $scope.widget.dropoff;
                $scope.widget.pick_up = address;
                $scope.widget.drop_off = drop;
                $scope.widget.receiver_contact = "";
                $scope.widget.receiver_email = "";
                $scope.widget.receiver_mobile = "";
                $scope.widget.receiver_phone = "";
                $scope.widget.receiver_name = "";
                $scope.widget.account_number = "";
                $scope.widget.sender_name = "";
                $scope.widget.sender_company = "";
                $scope.widget.sender_mob = "";
                $scope.widget.sender_tel = "";
                $scope.widget.sender_ref = "";
                $scope.widget.special_instructions = "";
                $scope.widget.ordertype = 'quickquote';
                $scope.widget.docTypeNative = 'A4'
//                    $scope.widget.rowNum = $key;
                quickQuotes.set($scope.widget);
            } else {
                //notifications.showError({message: "Sorry.We could not find any courier prices for this delivery request."});

                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Sorry. We could not find any courier prices for this delivery request.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: "Sorry. We could not find any courier prices for this delivery request."
                });
            }

        };
        var error = function (object) {
            //      ngProgress.complete();
            blockUI.stop();
            ////console.log(object);
            //notifications.showError({message: object.error.message});

            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
        };
        ////console.log($scope.widget);
        var validateInput = function () {
            if (
                ($scope.widget.pick_up == undefined || $scope.widget.pick_up.trim().length == 0) ||
                ($scope.widget.drop_off == undefined || $scope.widget.drop_off.trim().length == 0)
            ) {
                return false;
            }
            var $length = Object.keys($scope.documents).length + 1;

            for ($i = 0; $i < $length; $i++) {
                if (
                    ($scope.widget.parcel_desc[$i] == undefined) ||
                    ($scope.widget.weight[$i] == undefined) ||
                    ($scope.widget.length[$i] == undefined) ||
                    ($scope.widget.width[$i] == undefined) ||
                    ($scope.widget.height[$i] == undefined) ||
                    ($scope.widget.amount[$i] == undefined)
                ) {
                    return false;
                }
            }

            if ($scope.widget.insurance == true) {
                if ($scope.widget.insuranceValue == "") {
                    return false;
                }
                if ($scope.widget.insuranceDesc.trim() == "") {
                    return false;
                }
            }

            return true;
        };

        if (!validateInput()) {
            object = {};
            object.error = {};
            object.error.message = "Not all the required fields have been filled.";
            error(object);
        } else {
            CourierFactory.findParcelCourierPrices($scope.widget).success(success).error(error);
        }
    };

}]);

app.controller('BasketController', ['payUFactory', 'AmrodFactory', 'BasketFactory', 'WalletFactory', 'VoucherFactory','MarginGroupFactory', '$timeout', '$window', '$scope', '$rootScope', 'ngDialog', '$location', '$cookieStore', '$cookies', '$localStorage', 'BusinessFactory', 'OrderFactory', 'blockUI', 'notifications', 'ngProgress', '$modal', 'ENV', '$route', function (payUFactory, AmrodFactory, BasketFactory, WalletFactory, VoucherFactory,MarginGroupFactory, $timeout, $window, $scope, $rootScope, ngDialog, $location, $cookieStore, $cookies, $localStorage, BusinessFactory, OrderFactory, blockUI, notifications, ngProgress, modal, ENV, $route) {

    $('._jsDynamicTitle').text('Rush - Basket');
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';

    if ($cookieStore.get('user') == undefined) {
        $location.path('/login');
    }

    $scope.currentUser = $cookieStore.get('user');
    $scope.margin_group_id=  $scope.currentUser.account[0].margin_mgmnt_groups_id;
    $scope.marginGroup={};

    getMarginGroup = function () {
        var success = function (object) {
            blockUI.stop();
            $scope.marginGroup = object.data;
            $scope.margin_group_markup =   $scope.marginGroup.markup_percentage;
            $scope.margin_group_discount =   $scope.marginGroup.discount_percentage;
        };
        var error = function (object) {
            blockUI.stop();
            console.log(object);
        };
        blockUI.start();
        MarginGroupFactory.getMarginGroup($scope.margin_group_id).success(success).error(error);
    };

    getMarginGroup();

    $scope.go = function (path) {
        if ($localStorage.newOrderTab === undefined) {
            $localStorage.$default({'newOrderTab': false});
        } else {
            $localStorage.newOrderTab = false;
        }
        if (path === 'NewOrder') {
            $localStorage.newOrderTab = true;
            path = 'account';
        }
        if ($location.path() === '/' + path) {
            $route.reload();
        }
        $location.path(path);
    };
    $scope.termsLinks = {};
    //  console.log($scope.order)
    $scope.basket = {};
    $scope.basket.totalOrderItems = 0;
    $scope.basket.totalGrandPrice = 0;
    $scope.basket.total_cost_plus_vat=0
    $scope.basket.totalNetPrice = 0;
    $scope.basket.totalVatPrice = 0;
    $scope.basket.totalInsuranceCost = 0;

     //change totalGrandPrice to total_cost_plus_vat ( from margin group calculation)
    $scope.checkWalletAvailability = function (walletAmount, total_cost_plus_vat) {
        return parseFloat(walletAmount) < parseFloat(total_cost_plus_vat);
    };
    $scope.checkTimeLimitForServiceType = function (delivery) {
        delivery = delivery.toLowerCase();
        var d = new Date();
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000); // getTimezoneOffset returns difference between UTC and currentTime
        var nd = new Date(utc + (3600000 * 2)); // adds 2 hours to utc time
        var hrs = nd.getHours();
        var mins = nd.getMinutes();
        return (delivery == 'pronto' && hrs >= 17) || (delivery == 'sameday' && !(hrs < 10 || (hrs === 10 && mins <= 30))) || ((delivery == 'budget' || delivery == 'overnight') && !(hrs < 14 || (hrs === 14 && mins <= 30)));
    };
    var success = function (object) {
        $scope.wallet = {};
        $scope.wallet.amount = 0;
        getWallet = function () {
            var success = function (object) {
                blockUI.stop();
                $scope.wallet = object;
//                    console.log($scope.wallet);
            };
            var error = function (object) {
                blockUI.stop();
                console.log(object);
            };
            blockUI.start();
            $request = {};
            $request.account_id = $scope.currentUser.account[0].id;
            WalletFactory.getWalletForAccount($request).success(success).error(error);
        };
        getWallet();
        $scope.basket.totalGrandPrice = 0;
        $scope.basket.totalPerService = [];
        $scope.basket.totalNetPrice = 0;
        $scope.basket.totalVatPrice = 0;
        $scope.basket.totalInsuranceCost = 0;
        $scope.basket.netWithInsurance = 0;

        //cost for margin group calculation
        $scope.basket.markup_cost = 0;
        $scope.basket.gross_selling_price= 0;
        $scope.basket.discount_cost = 0;
        $scope.basket.net_selling_price = 0;
        $scope.basket.vat_cost = 0;
        $scope.basket.total_cost_plus_vat = 0;

        $scope.basket.vatWithInsurance = 0;
        $scope.basket.existNonRush = false;
        $scope.basket.onlyNonRush = true;
        $scope.basket.services = [];
        $scope.basket.services["ALL"] = true;
        $scope.basket.services["RUSH"] = false;
        $scope.basket.services["TOGSA"] = false;
        $scope.basket.services["AMROD"] = false;

        $scope.baskets = object.data;
        // $scope.storage = $localStorage.$default({'baskets': object.data});

        $scope.debtors = {};
        $scope.debtors.hasdebts = false;
        $scope.debtors.total_debts = 0;
        $scope.debtors.debts = object.debtors;
        $order_refs = [];
        for (i in $scope.debtors.debts) {
            $order_refs.push($scope.debtors.debts[i].order_group_ref)
        }

        if (object.debtors !== undefined && $scope.debtors.debts.length > 0) {
            $scope.debtors.hasdebts = true;
            $scope.debtors.total_debts = object.total_debts;

            var success = function (object) {
                for ($i in $scope.debtors.debts) {
                    $scope.debtors.debts[$i].invoicenumber = object[$i][0].id;
                }

            }
            var error = function (object) {
                console.log(object)
            }
            OrderFactory.getInvoiceNumber($order_refs).success(success).error(error);
        }

        $scope.basket.totalOrderItems = $scope.baskets.length; // or use sizeOf

        for (var i = 0; i < $scope.baskets.length; i++) {
            if ($scope.baskets[i].linkedService != 'RUSH') {
                $scope.basket.existNonRush = true;
            } else {
                $scope.basket.onlyNonRush = false;
            }
        }
        $scope.order = {};
        $scope.order.agree = false;
        $scope.order.agreeCharges = false;

        if ($scope.basket.onlyNonRush) {

            $scope.order.agreeCharges = true;
        }

        $scope.termsLinks = {};
        for ($ind = 0; $ind < $scope.basket.totalOrderItems; $ind++) {
//                console.log($scope.baskets[$ind]);
            // $log.info($scope.order_product.CarrierName);
            termsLink = '';
            if ($scope.baskets[$ind].carrierName === 'SKYNET') {
                termsLink = '/skynet-terms';
            } else if ($scope.baskets[$ind].carrierName === 'THE COURIER GUY') {
                termsLink = '/tcg-terms';
            } else if ($scope.baskets[$ind].carrierName === 'ARAMEX') {
                termsLink = '/aramex-terms';
            } else if ($scope.baskets[$ind].carrierName === 'COURIER IT') {
                termsLink = '/courier-it-terms';
            } else if ($scope.baskets[$ind].carrierName === 'GLOBE FLIGHT') {
                termsLink = '/globeflight-terms';
            } else if ($scope.baskets[$ind].carrierName === 'DCB LOGISTICS') {
                termsLink = '/dcb-terms';
            }
            if (termsLink !== '') {
                $scope.termsLinks[$scope.baskets[$ind].carrierName] = termsLink;
            }
            $itemInsuranceCost = parseFloat(parseFloat($scope.baskets[$ind].insurance_cost.replace(/,/g, "").replace(" ", "")));
            $itemInsuranceCostNet = $itemInsuranceCost / 1.15;
            $itemInsuranceCostVat = 0.15 * ($itemInsuranceCost / 1.15);
            $itemNetCost = parseFloat($scope.baskets[$ind].totalCostPlusMarkup.replace(/,/g, "").replace(" ", ""));
            $itemNetCosts = parseFloat($scope.baskets[$ind].totalCost.replace(/,/g, "").replace(" ", ""));
            $itemVat = parseFloat($scope.baskets[$ind].gstonMarkup.replace(/,/g, "").replace(" ", ""));
            $scope.baskets[$ind].grandTotWithInsurance = parseFloat($scope.baskets[$ind].grandTotMrkup.replace(/,/g, "").replace(" ", "")) + $itemInsuranceCost;
            $scope.baskets[$ind].netWithInsurance = ($itemNetCosts + $itemInsuranceCostNet).toFixed(2);
            $scope.baskets[$ind].netCostWithInsurance = ($itemNetCosts + $itemInsuranceCostNet).toFixed(2);
            console.log($scope.baskets[$ind].totalCost);
            //percentage discount and markup
            $scope.markup_percentage =parseFloat(($scope.marginGroup.markup_percentage / 100).toFixed(2));
            $scope.discount_percentage =parseFloat(($scope.marginGroup.discount_percentage / 100).toFixed(2));
            $scope.discount_description = $scope.marginGroup.description;
            console.log('----------------------')

            $scope.baskets[$ind].insurance_cost_net = Math.round( $itemInsuranceCostNet * 1e2 ) / 1e2;
            $scope.baskets[$ind].insurance_plus_markup_cost = parseFloat(  $scope.baskets[$ind].insurance_cost_net + ($scope.baskets[$ind].insurance_cost_net *   $scope.markup_percentage) )
            console.log('insurance_cost')
            console.log($scope.baskets[$ind].insurance_cost_net)
            console.log('insurance_plus_markup_cost')
            console.log(  $scope.baskets[$ind].insurance_plus_markup_cost)

            $scope.baskets[$ind].courier_fee_plus_markup_cost = parseFloat(  $itemNetCosts + ($itemNetCosts *   $scope.markup_percentage) )
            console.log('courrier_fee')
            console.log($itemNetCosts)
            console.log('courier_fee_plus_markup_cost')
            console.log(  $scope.baskets[$ind].courier_fee_plus_markup_cost)


            console.log('---------')
            console.log('net_cost')

            console.log( 'type '+ typeof  $scope.baskets[$ind].netWithInsurance)
            console.log( $scope.baskets[$ind].netWithInsurance)

            $scope.baskets[$ind].netWithInsurance_float= parseFloat( $scope.baskets[$ind].netWithInsurance)
            console.log( 'type '+ typeof $scope.baskets[$ind].netWithInsurance_float)
            console.log( $scope.baskets[$ind].netWithInsurance_float)

            $scope.baskets[$ind].netWithInsurance_fixedto= $scope.baskets[$ind].netWithInsurance_float.toFixed(2)
            console.log( 'type '+ typeof   $scope.baskets[$ind].netWithInsurance_fixedto)
            console.log(   $scope.baskets[$ind].netWithInsurance_fixedto)

            //costing from margin group
            $scope.baskets[$ind].markup_cost =   parseFloat((  $scope.baskets[$ind].netWithInsurance  *   $scope.markup_percentage).toFixed(2));
            console.log('markup_cost')
            console.log(  $scope.baskets[$ind].markup_cost)

            $scope.baskets[$ind].gross_selling_price = parseFloat($scope.baskets[$ind].netWithInsurance)  +    $scope.baskets[$ind].markup_cost;
            console.log('gross selling price')
            console.log($scope.baskets[$ind].gross_selling_price)

           $scope.baskets[$ind].discount_cost =   parseFloat(($scope.baskets[$ind].gross_selling_price  *   $scope.discount_percentage).toFixed(2));
           console.log('discount_cost')
           console.log( $scope.baskets[$ind].discount_cost)

           $scope.baskets[$ind].net_selling_price =    parseFloat(($scope.baskets[$ind].gross_selling_price  -    $scope.baskets[$ind].discount_cost).toFixed(2));
           console.log('net_selling_price')
           console.log( $scope.baskets[$ind].net_selling_price)

           $scope.baskets[$ind].vat_cost =   parseFloat((0.15 * $scope.baskets[$ind].net_selling_price).toFixed(2));
           console.log('vat cost')
           console.log( $scope.baskets[$ind].vat_cost)

           $scope.baskets[$ind].total_cost_plus_vat =    parseFloat(($scope.baskets[$ind].net_selling_price  +   $scope.baskets[$ind].vat_cost).toFixed(2));
           console.log('total_cost_plus_vat')
           console.log( $scope.baskets[$ind].total_cost_plus_vat)

            console.log('---------')

            //to display on checkout page
            $scope.baskets[$ind].discount_display = $scope.marginGroup.discount_percentage;
            $scope.baskets[$ind].gross_selling_price_display =   $scope.baskets[$ind].gross_selling_price.toFixed(2);
            $scope.baskets[$ind].discount_cost_display =   $scope.baskets[$ind].discount_cost.toFixed(2);
            $scope.baskets[$ind].net_selling_price_display =   $scope.baskets[$ind].net_selling_price.toFixed(2);
            $scope.baskets[$ind].vat_cost_display =   $scope.baskets[$ind].vat_cost.toFixed(2);
            $scope.baskets[$ind].total_cost_plus_vat_display =   $scope.baskets[$ind].total_cost_plus_vat.toFixed(2);

            console.log(  $scope.baskets[$ind].discount_display)
            console.log(  $scope.baskets[$ind].gross_selling_price_display)
            console.log(  $scope.baskets[$ind].discount_cost_display)
            console.log(  $scope.baskets[$ind].net_selling_price_display)
            console.log(  $scope.baskets[$ind].vat_cost_display)
            console.log(  $scope.baskets[$ind].total_cost_plus_vat_display)


            $scope.baskets[$ind].vatWithInsurance = ($itemVat + $itemInsuranceCostVat).toFixed(2);
            $scope.baskets[$ind].hasInsurance = ($itemInsuranceCost !== 0);
            var len = $scope.baskets[$ind].basketItem.length;
            for (var i = 0; i < len; i++) {
                var weight = parseFloat($scope.baskets[$ind].basketItem[i].weight);
                $scope.baskets[$ind].basketItem[i].weight = Math.ceil(weight);
            }

            $scope.basket.totalGrandPrice += $scope.baskets[$ind].grandTotWithInsurance;
            $linkedService = $scope.baskets[$ind].linkedService;
            $scope.basket.services[$linkedService] = true;
            if ($scope.basket.totalPerService[$linkedService] == null) {
                $scope.basket.totalPerService[$linkedService] = 0;
            }
            //change grandTotWithInsurance to total_cost_plus_vat ( from margin group calculation)
            $scope.basket.totalPerService[$linkedService] += $scope.baskets[$ind].total_cost_plus_vat;
            $scope.baskets[$ind].grandTotWithInsurance = $scope.baskets[$ind].grandTotWithInsurance.toFixed(2);
            $scope.basket.totalNetPrice += $itemNetCost;
            $scope.basket.totalVatPrice += $itemVat;
            $scope.basket.totalInsuranceCost += $itemInsuranceCost;
            $scope.basket.netWithInsurance += ($itemNetCost + $itemInsuranceCostNet);

            //costing from margin group calculation
            $scope.basket.markup_cost += $scope.baskets[$ind].markup_cost;
            $scope.basket.markup_cost = Math.round( $scope.basket.markup_cost * 1e2 ) / 1e2;

            $scope.basket.gross_selling_price += $scope.baskets[$ind].gross_selling_price;
            $scope.basket.gross_selling_price = Math.round( $scope.basket.gross_selling_price * 1e2 ) / 1e2;

            $scope.basket.discount_cost += $scope.baskets[$ind].discount_cost;
            $scope.basket.discount_cost = Math.round( $scope.basket.discount_cost * 1e2 ) / 1e2;

            $scope.basket.net_selling_price += $scope.baskets[$ind].net_selling_price;
            $scope.basket.net_selling_price = Math.round( $scope.basket.net_selling_price * 1e2 ) / 1e2;

            $scope.basket.vat_cost += $scope.baskets[$ind].vat_cost;
            $scope.basket.vat_cost = Math.round( $scope.basket.vat_cost * 1e2 ) / 1e2;

            $scope.basket.total_cost_plus_vat += $scope.baskets[$ind].total_cost_plus_vat;
            $scope.basket.total_cost_plus_vat = Math.round( $scope.basket.total_cost_plus_vat * 1e2 ) / 1e2;

            console.log("---------------------net price summary------------------------------");
            console.log("initial markup_cost"+$scope.baskets[$ind].markup_cost);
            console.log($scope.basket.markup_cost);

            console.log("initial gross_selling_price"+$scope.baskets[$ind].gross_selling_price);
            console.log($scope.basket.gross_selling_price);

            console.log("initial discount_cost"+$scope.baskets[$ind].discount_cost);
            console.log($scope.basket.discount_cost);

            console.log("initial net_selling_price"+$scope.baskets[$ind].net_selling_price);
            console.log($scope.basket.net_selling_price);

            console.log("initial vat cost"+$scope.baskets[$ind].vat_cost);
            console.log($scope.basket.vat_cost);

            console.log("initial total_cost_plus_vat "+$scope.baskets[$ind].total_cost_plus_vat);
            console.log($scope.basket.total_cost_plus_vat);

            $scope.basket.vatWithInsurance += ($itemVat + $itemInsuranceCostVat + (0.15 * $scope.debtors.total_debts));
            $scope.baskets[$ind].splitPickup = $scope.baskets[$ind].pickUp.split(",");
            $scope.baskets[$ind].splitDropoff = $scope.baskets[$ind].dropOff.split(",");
            $scope.baskets[$ind].exceededTimeLimit = $scope.checkTimeLimitForServiceType($scope.baskets[$ind].carrierService);
            $scope.basket.debts_vat = 0.15 * $scope.debtors.total_debts;
        }

          var success = function (object) {
            console.log(object)
          };

          var error = function (object) {
              console.log(object)
          };
          console.log($scope.baskets)
          BasketFactory.updateBasketItem($scope.currentUser.account[0].id, $scope.baskets).success(success).error(error);


        $scope.basket.netCost = $scope.basket.totalNetPrice + $scope.basket.totalInsuranceCost;
        $scope.basket.totalGrandPrice = (parseFloat($scope.basket.totalGrandPrice) + parseFloat($scope.debtors.total_debts) + parseFloat($scope.basket.debts_vat)).toFixed(2);

        //change totalGrandPrice to total_cost_plus_vat ( from margin group calculation)
        $scope.basket.total_cost_plus_vat = (parseFloat($scope.basket.total_cost_plus_vat) + parseFloat($scope.debtors.total_debts) + parseFloat($scope.basket.debts_vat)).toFixed(2);
        $scope.basket.totalCostVouchers = $scope.basket.total_cost_plus_vat;
        $scope.basket.grand_total_without_voucher = $scope.basket.total_cost_plus_vat;

        $scope.basket.totalNetPrice = $scope.basket.totalNetPrice.toFixed(2);
        $scope.basket.totalVatPrice = $scope.basket.totalVatPrice.toFixed(2);
        $scope.basket.totalInsuranceCost = $scope.basket.totalInsuranceCost.toFixed(2);
        $scope.basket.netWithInsurance = $scope.basket.netWithInsurance.toFixed(2);
        $scope.basket.vatWithInsurance = $scope.basket.vatWithInsurance.toFixed(2);
        $scope.basket.markup_cost =   $scope.basket.markup_cost.toFixed(2);
        $scope.basket.gross_selling_price =   $scope.basket.gross_selling_price.toFixed(2);
        console.log('---------------------gggggggggggggggggggggggggggggggggggg---------------------------------')
        console.log($scope.basket.gross_selling_price)
        console.log('---------------------gggggggggggggggggggggggggggggggggggg---------------------------------')
        $scope.basket.discount_cost =   $scope.basket.discount_cost.toFixed(2);
        $scope.basket.net_selling_price =   $scope.basket.net_selling_price.toFixed(2);
        $scope.basket.vat_cost  =  (parseFloat($scope.basket.vat_cost) + parseFloat($scope.basket.debts_vat)).toFixed(2);
        $scope.basket.discount_display = $scope.marginGroup.discount_percentage;

        //change totalGrandPrice to total_cost_plus_vat ( from margin group calculation)
        $scope.basket.totalPerService["ALL"] = $scope.basket.total_cost_plus_vat;

        $scope.basket.existVouchers = false;
        $scope.basket.vouchers = object.vouchers;
        if ($scope.basket.vouchers !== undefined && $scope.basket.vouchers.length > 0) {
            for (var $i = 0; $i < $scope.basket.vouchers.length; $i++) {
                $scope.basket.existVouchers = $scope.basket.existVouchers || $scope.basket.services[$scope.basket.vouchers[$i].service_type];
            }
        }

        blockUI.stop();
    };
    var revalidateOrders = function () {
        $continue = true;
        $scope.basket.totalOrderItems = $scope.baskets.length;
        for ($ind = 0; $ind < $scope.basket.totalOrderItems; $ind++) {
            $scope.baskets[$ind].exceededTimeLimit = $scope.checkTimeLimitForServiceType($scope.baskets[$ind].carrierService);
            if ($scope.baskets[$ind].exceededTimeLimit) {
                $continue = false; // set to false
            }
        }
        //console.log('revalidate orders')
        return $continue;
    };
    $scope.user_type = $scope.currentUser.account[0].user_type;
    $scope.account_id = $scope.currentUser.account[0].id;
    if ($scope.user_type === undefined || $scope.user_type == '' || $scope.user_type == null || $scope.user_type == 3) {
        $scope.user_type = 'business';
    } else if ($scope.user_type == 4) {
        $scope.user_type = 'business_plus';
    } else if ($scope.user_type == 5) {
        $scope.user_type = 'business_gold';
    } else if ($scope.user_type == 6) {
        $scope.user_type = 'amrod_business_plus';
    }

    $scope.showErrorMessage = function (errorMessage) {
        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = errorMessage;
            }],
            className: 'ngdialog-theme-default',
            msg: errorMessage
        });
    };
    $scope.checkout = function () {
        var success = function (object) {
            $scope.request = {};
            $scope.request.profileToken = object[0].value;
            var success = function (object) {
                $ordersValid = true;
                $activeOrders = object.api_response.activeOrders;
                for (var i = 0; i < $scope.baskets.length; i++) {
                    if ($scope.baskets[i].linkedService == "AMROD") {
                        for (var j = 0; j < $scope.baskets.basketItem; j++) {
                            $scope.baskets[i].invalidOrder = false;
                            $foundIt = false;
                            for (var k = 0; k < $activeOrders.length; k++) {
                                if ($scope.baskets[i].basketItem[j].linkedServiceOrderRef == $activeOrders[k].documentNumber) {
                                    $foundIt = true;
                                    if (!$activeOrders[k].rushCollectionReady) {
                                        $ordersValid = false;
                                        $scope.baskets[i].invalidOrder = true;
                                    }
                                }
                            }
                            if (!$foundIt) {
                                $ordersValid = false;
                                $scope.baskets[i].invalidOrder = true;
                            }
                        }
                    }
                }

                blockUI.stop();
                if (!$ordersValid) {
                    amrodErrorMessage = "Some of your orders are no longer available. Please delete them and check out again.";
                    $scope.showErrorMessage(amrodErrorMessage);
                } else {
                    $continue = revalidateOrders();
                    if ($continue) {
                        console.log('checkout');
                        $scope.processPayment();
                    } else {
                        ngDialog.open({
                            template: 'withInlineControllerConfirmBasketCheckout',
                            className: 'ngdialog-theme-default',
                            msg: "object.message",
                            scope: $scope
                        });
                    }
                }
            };
            var error = function (object) {
                blockUI.stop();
                $scope.amrodErrorMessage = object.error.message;
                $scope.showErrorMessage(object.error.message);
            };
            blockUI.start();
            AmrodFactory.getActiveOrders($scope.request).success(success).error(error);
        };
        var error = function () {
            amrodErrorMessage = "No Amrod credentials set. Your account was not registered properly. Please register again.";
            $scope.showErrorMessage(amrodErrorMessage);
        };
        //if no amrod orders set
        $hasAmrodOrders = false;
        for (var i = 0; i < $scope.baskets.length; i++) {
            if ($scope.baskets[i].linkedService == "AMROD") {
                $hasAmrodOrders = true;
                break;
            }
        }
        if ($hasAmrodOrders) {
            $scope.request = {};
            $scope.request.accountId = $scope.baskets[0].account_id;
            AmrodFactory.getProfileToken($scope.request).success(success).error(error);
        } else {
            $continue = revalidateOrders();
            if ($continue) {
                console.log('checkout');
                $scope.processPayment();
            } else {
                ngDialog.open({
                    template: 'withInlineControllerConfirmBasketCheckout',
                    className: 'ngdialog-theme-default',
                    msg: "object.message",
                    scope: $scope
                });
            }
        }

    };
    $scope.processPayment = function () {
        //change totalGrandPrice to total_cost_plus_vat ( from margin group calculation)
        if ($scope.user_type === 'business' && $scope.basket.total_cost_plus_vat > 0) {
            blockUI.start();
            var success = function (object) {

                if (object.warning_message) {
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.message,
                    });
                    blockUI.stop();
                    return false;
                }

                $postData = {"orderGroupRef": object.OrderGroupRef};
                if ($scope.order.useWallet === true) {
                    console.log('Payment Method: Wallet');
                    $orderGroupRef = object.OrderGroupRef;
                    $scope.debtorStatus = {};
                    var deleteBasketSuccess = function (object) {
                        blockUI.stop();
                        var addTransactionSuccess = function (object) {
                            blockUI.stop();
                            var completeBasketOrdersSuccess = function (object) {
                                $localStorage.$reset();
                                blockUI.stop();
                                ngDialog.open({
                                    template: 'withInlineController',
                                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                                        $scope.msg = object.message;
                                        //$scope.msg1 = object.message_2;
                                        //$scope.msg2 = object.message_3;

                                    }],
                                    className: 'ngdialog-theme-plain',
                                    msg: object.message,
                                    //msg1: object.message_2,
                                    //msg2: object.message_3
                                });
                                if ($localStorage.newOrderTab === undefined) {
                                    $localStorage.$default({'newOrderTab': true});
                                } else {
                                    $localStorage.newOrderTab = true;
                                }
                                $location.path('account');
                            };
                            var completeBasketOrdersError = function (object) {

                                blockUI.stop();
                                ngDialog.open({
                                    template: 'withInlineController',
                                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                                        $scope.msg = object.error.message.message;
                                    }],
                                    className: 'ngdialog-theme-default',
                                    msg: object.error.message.message
                                });
                                $route.reload();
                            };
                            blockUI.start();
                            blockUI.message('Please wait while we process your order. Stopping this process will cancel your booking!.');
                            $postData = {"OrderGroupRef": $orderGroupRef, "status": 'Active'};
                            BusinessFactory.completeBasketOrders($postData).success(completeBasketOrdersSuccess).error(completeBasketOrdersError);
                        };
                        var addTransactionError = function (object) {
                            blockUI.stop();
                            ngDialog.open({
                                template: 'withInlineController',
                                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                                    $scope.msg = object.error.message.message;
                                }],
                                className: 'ngdialog-theme-default',
                                msg: object.error.message.message
                            });
                        };
                        blockUI.start();
                        blockUI.message('Please wait while we process your order. Stopping this process will cancel your booking!.');
                        $request = {};
                        $request.wallet_id = $scope.wallet.wallet_id;
                        //change totalGrandPrice to total_cost_plus_vat ( from margin group calculation)
                        $request.amount = $scope.basket.total_cost_plus_vat;
                        $request.transaction_type = "PAYMENT";
                        $request.transaction_reference = $orderGroupRef;
                        $request.status = "SUCCESS";
                        $request.transaction_by_account_id = $scope.currentUser.account[0].id;
                        $scope.debtorStatus();
                        WalletFactory.addTransaction($request).success(addTransactionSuccess).error(addTransactionError);
                    };
                    var deleteBasketError = function (object) {
                        blockUI.stop();
                        ngDialog.open({
                            template: 'withInlineController',
                            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                                $scope.msg = object.error.message.message;
                            }],
                            className: 'ngdialog-theme-default',
                            msg: object.error.message.message
                        });
                        $route.reload();
                    };
                    BasketFactory.deleteBasket($scope.wallet.account_id).success(deleteBasketSuccess).error(deleteBasketError);

                    $scope.debtorStatus = function () {

                        $scope.debtor_status = $scope.debtors.debts;
                        $order_refs = [];
                        console.log($scope.debtor_status)
                        for (i in $scope.debtor_status) {
                            var order_ref = {
                                'order_group_ref': $scope.debtor_status[i].order_group_ref,
                                'id': $scope.debtor_status[i].id,
                                'account_id': $scope.debtor_status[i].account_id
                            };
                            $order_refs.push(order_ref)
                        }

                        if ($scope.debtors !== undefined && $scope.debtors.debts.length > 0) {

                            var success = function () {
                            }
                            var error = function () {
                            }
                            WalletFactory.updateDebtorStatus($order_refs).success(success).error(error);
                        }
                        ;
                    }
                } else {
                    console.log('Payment Method: PayU');
                    var success = function (object) {
                        blockUI.stop();
                        $window.location.href = object.redirectUrl;
                    };
                    var error = function (object) {
                        $error_post = {
                            "orderGroupRef": object.error.message.order_group_ref,
                            "errorMessage": object.error.message.message,
                            "responseError": object.error.message.response_error,
                            "process": "[BusinessFactory.setRPPPayU]",
                            "accountId": $scope.account_id
                        };
                        BusinessFactory.notifyRushBasketSupport($error_post);
                        blockUI.stop();
                        $localStorage.$reset();
                        ngDialog.open({
                            template: 'withInlineController',
                            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                                $scope.msg = "Your Payment could not proceed. Please try again.";
                            }],
                            className: 'ngdialog-theme-default',
                            msg: "Your Payment could not proceed. Please try again.",
                        });
                    };
                    BusinessFactory.setPayU($postData).success(success).error(error);
                }
            };
            var error = function (object) {
                $error_post = {
                    "orderGroupRef": object.error.message.order_group_ref,
                    "errorMessage": object.error.message.message,
                    "responseError": object.error.message.response_error,
                    "process": "[OrderFactory:createMultipleOrders]",
                    "accountId": $scope.account_id
                };
                BusinessFactory.notifyRushBasketSupport($error_post);
                $localStorage.$reset();
                blockUI.stop();
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Error, could not continue with your Order. Please contact support.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: "Error, could not continue with your Order. Please contact support."
                });
            };
            $postData = {
                "account_id": $scope.currentUser.account[0].id,
                "total_net_cost": $scope.basket.totalNetPrice,
                "total_vat": $scope.basket.totalVatPrice,
                "total_insurance_cost": $scope.basket.totalInsuranceCost,
                "grand_total": $scope.basket.totalGrandPrice,
                "vouchers": $scope.voucherIds,
                "debts": $scope.debtors.debts,
                "grand_total_without_voucher": $scope.basket.grand_total_without_voucher,

                //cost from margin group calculation
                "markup_cost": $scope.basket.markup_cost,
                "discount_cost": $scope.basket.discount_cost,
                "gross_selling_price": $scope.basket.gross_selling_price,
                "net_selling_price": $scope.basket.net_selling_price,
                "vat_cost": $scope.basket.vat_cost,
                "total_cost_plus_vat": $scope.basket.total_cost_plus_vat,

            };
            if ($scope.order.useWallet) {
                $addPostData = {
                    "wallet_id": $scope.wallet.wallet_id
                };
                $.extend($postData, $addPostData);
            }
            OrderFactory.createMultipleOrders($postData).success(success).error(error);
        } else if (
            //change totalGrandPrice to total_cost_plus_vat ( from margin group calculation)
            ($scope.user_type === 'business_plus' || $scope.user_type === 'business_gold' || $scope.user_type === 'amrod_business_plus')
            || $scope.basket.total_cost_plus_vat <= 0
        ) {
            blockUI.start();
            var success = function (object) {

                blockUI.stop();
                var success = function (object) {
                    $localStorage.$reset();
                    blockUI.stop();
                    ngDialog.open({
                        template: 'withInlineControllerBasketOrdersCompletedBusinessPlus',
                        controller: ['$scope', '$timeout', 'BasketFactory', 'blockUI', '$modalStack', function ($scope, $timeout, AddressBookFactory, blockUI, $modalStack) {
                            $scope.msg = object.message;
                            //$scope.msg1 = object.message_2;
                            //$scope.msg2 = object.message_3;
                            $scope.redirectToProfile = function (url) {
                                $modalStack.dismissAll();
                                $window.location.href = url;
                            };
                            $scope.index = object.redirect;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.message,
                        //msg1: object.message_2,
                        //msg2: object.message_3
                    });
                };
                var error = function (object) {

                    blockUI.stop();
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = object.error.message.message;
                        }],
                        className: 'ngdialog-theme-default',
                        msg: object.error.message.message,
                    });
                };
                blockUI.start();
                blockUI.message('Please wait while we process your order. Stopping this process will cancel your booking!.');
                $postData = {"OrderGroupRef": object.OrderGroupRef, "status": 'Active'};
                BusinessFactory.completeBasketOrders($postData).success(success).error(error);
            };
            var error = function (object) {

                $error_post = {
                    "orderGroupRef": object.error.message.order_group_ref,
                    "errorMessage": object.error.message.message,
                    "responseError": object.error.message.response_error,
                    "process": "[OrderFactory:createMultipleOrders]",
                    "accountId": $scope.account_id
                };
                BusinessFactory.notifyRushBasketSupport($error_post);
                $localStorage.$reset();
                blockUI.stop();
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message,
                });
            };
            $postData = {
                "account_id": $scope.currentUser.account[0].id,
                "total_net_cost": $scope.basket.totalNetPrice,
                "total_vat": $scope.basket.totalVatPrice,
                "total_insurance_cost": $scope.basket.totalInsuranceCost,
                "grand_total": "0.00",
                "vouchers": $scope.voucherIds,
                "debts": $scope.debtors.debts,
                "grand_total_without_voucher": $scope.basket.grand_total_without_voucher,

                //cost from margin group calculation
                "markup_cost": $scope.basket.markup_cost,
                "discount_cost": $scope.basket.discount_cost,
                "gross_selling_price": $scope.basket.gross_selling_price,
                "net_selling_price": $scope.basket.net_selling_price,
                "vat_cost": $scope.basket.vat_cost,
                "total_cost_plus_vat": $scope.basket.total_cost_plus_vat,
            };
            OrderFactory.createMultipleOrders($postData).success(success).error(error);
        } else {
            console.log('no user type');
        }

    };
    // On failure of create new address record

    var error = function (object) {
        console.log(object);
        ngDialog.open({
            template: 'withInlineController',
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.msg = object.error.message;
            }],
            className: 'ngdialog-theme-default',
            msg: object.error.message
        });
        blockUI.stop();
    };
    blockUI.start();
    getBasket = function () {
        BasketFactory.getBasket($scope.currentUser.account[0].id).success(success).error(error);
    };
    getBasket();
    $scope.showConfirmDeletion = function (id) {
        ngDialog.open({
            template: 'withInlineControllerConfirmBasketOrderDeletion',
            controller: ['$scope', '$timeout', 'BasketFactory', 'blockUI', '$modalStack', function ($scope, $timeout, BasketFactory, blockUI, $modalStack) {
                $scope.deleteOrder = function (id) {
                    $modalStack.dismissAll();
                    var success = function (object) {
                        // //console.log(object.data);
                        getBasket();
                    };
                    var error = function (object) {
                        blockUI.stop();
                        ngDialog.open({
                            template: 'withInlineController',
                            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                                $scope.msg = object.error.message;
                            }],
                            className: 'ngdialog-theme-default',
                            msg: object.error.message
                        });
                    };
                    blockUI.start();
                    BasketFactory.deleteOrderItemFrom(id).success(success).error(error);
                };
                $scope.index = id;
            }],
            className: 'ngdialog-theme-default',
            msg: "object.message"
        });
    };
    $scope.vouchersChecked = {};
    $scope.voucherIds = [];
    $scope.checkedDebtorStatus = {};
    $scope.uncheckedDebtorStatus = {};
    $scope.updateVoucherSelection = function ($event, id, amount, type) {

        var newVoucher = {
            "id": id,
            "amount": amount
        };

        $scope.checkedDebtorStatus = function () {

            $scope.debtor_status = $scope.debtors.debts;
            $order_refs = [];
            for (i in $scope.debtor_status) {
                var order_ref = {
                    'order_group_ref': $scope.debtor_status[i].order_group_ref,
                    'id': $scope.debtor_status[i].id,
                    'account_id': $scope.debtor_status[i].account_id
                };
                $order_refs.push(order_ref)
            }

            if ($scope.debtors !== undefined && $scope.debtors.debts.length > 0) {

                var success = function () {
                }
                var error = function () {
                }
                VoucherFactory.checkedDebtorStatus($order_refs).success(success).error(error);
            }
            ;
        }

        $scope.uncheckedDebtorStatus = function () {

            $scope.debtor_status = $scope.debtors.debts;
            $order_refs = [];
            for (i in $scope.debtor_status) {
                var order_ref = {
                    'order_group_ref': $scope.debtor_status[i].order_group_ref,
                    'id': $scope.debtor_status[i].id,
                    'account_id': $scope.debtor_status[i].account_id
                };
                $order_refs.push(order_ref)
            }

            if ($scope.debtors !== undefined && $scope.debtors.debts.length > 0) {

                var success = function () {
                }
                var error = function () {
                }
                VoucherFactory.uncheckedDebtorStatus($order_refs).success(success).error(error);
            }
            ;
        }
        // if amount for the service specific voucher is greater than the service orders, use total of service orders.
        if (parseFloat(amount) > parseFloat($scope.basket.totalPerService[type])) {
            amount = $scope.basket.totalPerService[type];
        }
        if (!$scope.vouchersChecked[id]) { //checkbox already checked selected
            $scope.vouchersChecked[id] = true;
            $scope.basket.totalCostVouchers = $scope.basket.totalCostVouchers - parseFloat(amount);
            $scope.voucherIds.push(newVoucher);
            $scope.checkedDebtorStatus();
            //$scope.voucherIds[id]=id;
            //console.log("Checkbox is ticked for discount. totalCostWithVouchers="+$scope.basket.totalCostVouchers);

        } else { //checkbox unticked.
            $scope.vouchersChecked[id] = false;
            $scope.basket.totalCostVouchers = $scope.basket.totalCostVouchers + parseFloat(amount);
            for (var i = 0; i < Object.keys($scope.voucherIds).length; i++) {
                if ($scope.voucherIds[i]["id"] == id) {
                    $scope.voucherIds.splice(i, 1);
                }
            }
            $scope.uncheckedDebtorStatus();
            //$scope.voucherIds.splice(id, 1);
            //$scope.voucherIds[id]=-1;
            //console.log("Checkbox is unticked for discount. totalCostWithVouchers="+$scope.basket.totalCostVouchers);
        }

        if ($scope.basket.totalCostVouchers <= 0) {
            //change totalGrandPrice to total_cost_plus_vat ( from margin group calculation)
            $scope.basket.total_cost_plus_vat = 0;
        } else {
            //change totalGrandPrice to total_cost_plus_vat ( from margin group calculation)
            $scope.basket.total_cost_plus_vat = $scope.basket.totalCostVouchers;
            $scope.basket.total_cost_plus_vat = $scope.basket.total_cost_plus_vat.toFixed(2);
        }
    }


}]);

app.controller('ActiveOrderController', ['AmrodFactory', 'TradeOnlyGiftFactory', 'BasketFactory', '$scope', 'ngDialog', '$location', '$cookieStore', '$localStorage', 'blockUI','MarginGroupFactory',
    function (AmrodFactory, TradeOnlyGiftFactory, BasketFactory, $scope, ngDialog, $location, $cookieStore, $localStorage, blockUI,MarginGroupFactory) {
        $scope.request = {};
//        $scope.checkedOrders = [];
        var user = $cookieStore.get('user');
        $scope.activeAmrodOrders = [];
        $scope.activeAmrodOrdersCount = 0;
        $scope.activeTogsaOrders = [];
        $scope.activeTogsaOrdersCount = 0;

        $scope.isAmrodCollapsed = true;
        $scope.isTogsaCollapsed = true;

        $scope.checkPresenceInBasket = function ($activeOrder, $serviceType, $index) {
            //alert($index);

            var successCheckBasketAmrod = function (object) {
                if (object.found) {
                    blockUI.stop();
                    $scope.activeAmrodOrders[$index].check = false;
                    $scope.activeAmrodOrders[$index].inBasket = true;
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = "This order is already in your basket.";
                        }],
                        className: 'ngdialog-theme-default',
                        msg: "This order is already in your basket."
                    });
                } else {
                    blockUI.stop();
                }
                $scope.filterAmrodOrders();

            };
            var successCheckBasketTogsa = function (object) {
                if (object.found) {
                    blockUI.stop();
                    $scope.activeTogsaOrders[$index].check = false;
                    $scope.activeTogsaOrders[$index].inBasket = true;
                    ngDialog.open({
                        template: 'withInlineController',
                        controller: ['$scope', '$timeout', function ($scope, $timeout) {
                            $scope.msg = "This order is already in your basket.";
                        }],
                        className: 'ngdialog-theme-default',
                        msg: "This order is already in your basket."
                    });
                } else {
                    blockUI.stop();
                }
                $scope.filterTogsaOrders();

            };
            var errorCheckBasket = function (object) {
                // console.log(object);
                blockUI.stop();
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Sorry. We failed to check whether this order is in your basket.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: "Sorry. We failed to check whether this order is in your basket."
                });
            };
            $checkBasket = {};
            $checkBasket.accountId = user.account[0].id;
            if ($serviceType == 'AMROD') {
                $checkBasket.linkedServiceOrderRef = $activeOrder.documentNumber;
                blockUI.start();
                BasketFactory.checkLSORefPresence($checkBasket).success(successCheckBasketAmrod).error(errorCheckBasket);
            } else if ($serviceType == 'TOGSA') {
                $checkBasket.linkedServiceOrderRef = $activeOrder.orderNumber;
                blockUI.start();
                BasketFactory.checkLSORefPresence($checkBasket).success(successCheckBasketTogsa).error(errorCheckBasket);
            }
        };

        $scope.rushOrders = function ($serviceType) {
            if ($serviceType == 'AMROD') {
                rushAmrodOrders();
            } else if ($serviceType == 'TOGSA') {
                rushTradeOnlyGiftOrders();
            }

        }
        var rushAmrodOrders = function () {
            var activeOrders = $scope.filterAmrodOrders();
            $scope.amrod_order = {};
            var address = activeOrders[0].collectionAddress;
            var splitted = address.split("|", 6);
            $scope.amrod_order.collectionAddress = activeOrders[0].collectionAddress;
            $scope.amrod_order.collectionBranch = activeOrders[0].collectionBranch;
            $scope.amrod_order.collectionContactPhone = activeOrders[0].collectionContactPhone;
            $scope.amrod_order.collectionContactEmail = activeOrders[0].collectionContactEmail;
            $scope.amrod_order.branch = splitted[0];
            $scope.amrod_order.lineone = splitted[1] + ', ';
            $scope.amrod_order.linetwo = splitted[2] + ', ';
            $scope.amrod_order.suburb_state = splitted[3] + ', ' + splitted[4] + ', ' + splitted[5];
            $scope.amrod_order.sender_name = activeOrders[0].collectionContact;
            $scope.amrod_order.sender_company = '';
            $scope.amrod_order.sender_mob = activeOrders[0].collectionContactPhone;
            $scope.amrod_order.sender_tel = activeOrders[0].collectionContactPhone;
            $scope.amrod_order.sender_email = activeOrders[0].collectionContactEmail;
            $scope.amrod_order.documentNumber = [];
            $scope.amrod_order.detail = [];
            $scope.amrod_order.firstStockItem = [];
            $scope.amrod_order.orderNumber = [];
            $scope.amrod_order.status = [];
            $scope.amrod_order.volumetricEstimate = [];
            $scope.amrod_order.volumetric = [];
            $scope.amrod_order.weight = [];
            $scope.amrod_order.amount = [];
            $scope.amrod_order.isSample = [];
            $scope.amrod_order.docType = [];
            $scope.amrod_order.parcelType = [];
            $scope.amrod_order.parcel_desc = [];
            $scope.amrod_order.length = [];
            $scope.amrod_order.width = [];
            $scope.amrod_order.height = [];
            $scope.amrod_order.totalAmount = 0;
            for ($i = 0; $i < activeOrders.length; $i++) {
                $scope.amrod_order.detail[$i] = activeOrders[$i].detail;
                $scope.amrod_order.firstStockItem[$i] = activeOrders[$i].firstStockItem;
                $scope.amrod_order.orderNumber[$i] = activeOrders[$i].orderNumber;
                $scope.amrod_order.status[$i] = activeOrders[$i].status;
                $scope.amrod_order.volumetricEstimate[$i] = activeOrders[$i].volumetricEstimate;
                $scope.amrod_order.volumetric[$i] = activeOrders[$i].volumetric / 1000000;

                var buffedUpWeight = activeOrders[$i].weight * 1.2; //AMROD WEIGHT BUFFER
                var weight = buffedUpWeight < 1 ? 1 : buffedUpWeight.toFixed(5);

                $scope.amrod_order.weight[$i] = weight;
                $scope.amrod_order.amount[$i] = activeOrders[$i].numberOfBoxes;
                $scope.amrod_order.isSample[$i] = activeOrders[$i].isSample;
                $scope.amrod_order.documentNumber[$i] = activeOrders[$i].documentNumber;

                $scope.amrod_order.totalAmount += activeOrders[$i].numberOfBoxes;

                $scope.amrod_order.docType[$i] = "Parcel";
                $scope.amrod_order.parcelType[$i] = "parcel";
                $scope.amrod_order.parcel_desc[$i] = "N/A";
                $scope.amrod_order.length[$i] = "N/A";
                $scope.amrod_order.width[$i] = "N/A";
                $scope.amrod_order.height[$i] = "N/A";
                $scope.amrod_order.height[$i] = 'N/A';
            }
            if ($localStorage.amrod_order === undefined) {
                $scope.storage = $localStorage.$default({'amrod_order': $scope.amrod_order});
            } else {
                $scope.storage = $localStorage.amrod_order = $scope.amrod_order;
            }
            $location.path('/amrod/confirmAddress');

        };
        var rushTradeOnlyGiftOrders = function () {
            var activeOrders = $scope.filterTogsaOrders();
            $scope.togsa_order = {};
            $scope.togsa_order.collectionAddress = activeOrders[0].collectionAddress;
            $scope.togsa_order.collectionBranch = activeOrders[0].collectionBranch;
            $scope.togsa_order.collectionContactPhone = activeOrders[0].collectionContactPhone;
            $scope.togsa_order.collectionContactEmail = activeOrders[0].collectionContactEmail;
            $scope.togsa_order.branch = activeOrders[0].collectionBranch;
            $scope.togsa_order.lineone = activeOrders[0].collectionAddress.collectionAddressLineOne;
            $scope.togsa_order.linetwo = activeOrders[0].collectionAddress.collectionAddressLineTwo;
            $scope.togsa_order.suburb_state =
                activeOrders[0].collectionAddress.collectionAddressSuburb
                + ', ' +
                activeOrders[0].collectionAddress.collectionAddressState
                + ', ' +
                activeOrders[0].collectionAddress.collectionAddressPostalCode;
            $scope.togsa_order.sender_name = activeOrders[0].collectionContact;
//            $scope.togsa_order.sender_name = "ubeid jamal";
            $scope.togsa_order.sender_company = '';
            $scope.togsa_order.sender_mob = activeOrders[0].collectionContactPhone;
            $scope.togsa_order.sender_tel = activeOrders[0].collectionContactPhone;
            $scope.togsa_order.sender_email = activeOrders[0].collectionContactEmail;
//            $scope.togsa_order.sender_mob = "2704654564546";
//            $scope.togsa_order.sender_tel = "2704654564546";
//            $scope.togsa_order.sender_email = "ubeidullah.jamal+tg@jiniguru.net";
//            $scope.togsa_order.sender_email = "vanessa@togsa.co.za";
            $scope.togsa_order.detail = [];
            $scope.togsa_order.firstStockItem = [];
            $scope.togsa_order.orderNumber = [];
            $scope.togsa_order.status = [];
            $scope.togsa_order.volumetricEstimate = [];
            $scope.togsa_order.volumetric = [];
            $scope.togsa_order.weight = [];
            $scope.togsa_order.amount = [];
            $scope.togsa_order.isSample = [];
            $scope.togsa_order.docType = [];
            $scope.togsa_order.parcelType = [];
            $scope.togsa_order.parcel_desc = [];
            $scope.togsa_order.length = [];
            $scope.togsa_order.width = [];
            $scope.togsa_order.height = [];
            $scope.togsa_order.totalAmount = 0;
            for ($i = 0; $i < activeOrders.length; $i++) {
                $scope.togsa_order.detail[$i] = activeOrders[$i].detail;
                $scope.togsa_order.firstStockItem[$i] = activeOrders[$i].firstStockItem;
                $scope.togsa_order.orderNumber[$i] = activeOrders[$i].orderNumber;
                $scope.togsa_order.status[$i] = activeOrders[$i].status;
                $scope.togsa_order.volumetric[$i] = activeOrders[$i].volumetric;

                var buffedUpWeight = activeOrders[$i].weight * 1.0; //TOGSA - no buffer
                var weight = buffedUpWeight < 1 ? 1 : buffedUpWeight.toFixed(5);

                $scope.togsa_order.weight[$i] = weight;
                $scope.togsa_order.amount[$i] = activeOrders[$i].numberOfBoxes;
                $scope.togsa_order.isSample[$i] = activeOrders[$i].isSample;

                $scope.togsa_order.totalAmount += activeOrders[$i].numberOfBoxes;

                $scope.togsa_order.docType[$i] = "Parcel";
                $scope.togsa_order.parcelType[$i] = "parcel";
                $scope.togsa_order.parcel_desc[$i] = 'N/A';
                $scope.togsa_order.length[$i] = "N/A";
                $scope.togsa_order.width[$i] = "N/A";
                $scope.togsa_order.height[$i] = "N/A";
                $scope.togsa_order.parcel_desc[$i] = "N/A";
            }
            if ($localStorage.togsa_order === undefined) {
                $scope.storage = $localStorage.$default({'togsa_order': $scope.togsa_order});
            } else {
                $scope.storage = $localStorage.togsa_order = $scope.togsa_order;
            }
            $location.path('/togsa/confirmAddress');

        };

        $scope.filterAmrodOrders = function () {
            filtered = $scope.activeAmrodOrders.filter(function (el) {
                return el.check;
            });
            $scope.hasAmrodOrder = filtered.length > 0;
//            console.log(filtered);
            return filtered;
        };
        $scope.filterTogsaOrders = function () {
            filtered = $scope.activeTogsaOrders.filter(function (el) {
                return el.check;
            });
            $scope.hasTogsaOrder = filtered.length > 0;
//            console.log(filtered);
            return filtered;
        };

        $scope.activeAmrodOrdersStatus = "PENDING";
        $scope.activeTradeOnlyGiftOrdersStatus = "PENDING";
        var amrodSuccess = function (object) {
            $scope.request = {};
            $scope.request.profileToken = object[0].value;
//            if ($localStorage.amrodProfileToken === undefined)
//            {
//                $localStorage.$default({'amrodProfileToken': $scope.request.amrodProfileToken});
//            } else {
//                $localStorage.amrodProfileToken = $scope.request.amrodProfileToken;
//            }

            var success = function (object) {
                $scope.activeAmrodOrders = object.api_response.activeOrders;
                $scope.activeAmrodOrdersCount = 0;
                $scope.storage = $localStorage.$default({'activeAmrodOrders': $scope.activeAmrodOrders});
                $hasOrder = false;
                for (var i = 0; i < $scope.activeAmrodOrders.length; i++) {
                    $validOrder = ($scope.activeAmrodOrders[i].collectionBranch.indexOf('Johannesburg') != -1 && $scope.activeAmrodOrders[i].rushCollectionReady);
                    $scope.activeAmrodOrders[i].show = $validOrder;
                    if ($validOrder)
                        $scope.activeAmrodOrdersCount++;
                    $scope.activeAmrodOrders[i].check = false;
                    $hasOrder = $hasOrder || $validOrder;
                }
                $scope.activeAmrodOrdersStatus = $hasOrder ? "FOUND" : "EMPTY";
            };
            var error = function (object) {
                $scope.activeAmrodOrdersStatus = "ERROR";
                $scope.amrodErrorMessage = object.error.message;
            };
            AmrodFactory.getActiveOrders($scope.request).success(success).error(error);
        };

        var togsaSuccess = function (object) {
            $scope.request = {};
            $scope.request.profileToken = object[0].value;

            var success = function (object) {
                if (object.api_response.success == true) {
                    $scope.activeTogsaOrders = object.api_response.activeOrders;
                    $scope.activeTogsaOrdersCount = 0;
                    $scope.storage = $localStorage.$default({'activeTogsaOrders': $scope.activeOrders});
                    $hasOrder = false;
                    for (var i = 0; i < $scope.activeTogsaOrders.length; i++) {
                        $scope.activeTogsaOrders[i].volumetricEstimate = $scope.activeTogsaOrders[i].volumetric == 0;
                        $scope.activeTogsaOrders[i].show = true;
                        $scope.activeTogsaOrdersCount++;
                        $scope.activeTogsaOrders[i].check = false;
                        $hasOrder = true;
                    }
                    $scope.activeTradeOnlyGiftOrdersStatus = $hasOrder ? "FOUND" : "EMPTY";

                } else {
                    $scope.activeTradeOnlyGiftOrdersStatus = "ERROR";
                    $scope.togsaErrorMessage = object.message;
                }
            };
            var error = function (object) {
                $scope.activeTradeOnlyGiftOrdersStatus = "ERROR";
                $scope.togsaErrorMessage = object.error.message;
            };
            TradeOnlyGiftFactory.getActiveOrders($scope.request).success(success).error(error);
        };

        var amrodError = function () {
            $scope.activeAmrodOrdersStatus = "ERROR";
            $scope.amrodErrorMessage = "No Amrod credentials set. Your account was not registered properly. Please register again.";
        };
        var togsaError = function () {
            $scope.activeTradeOnlyGiftOrdersStatus = "ERROR";
            $scope.togsaErrorMessage = "No Trade Only Gift credentials set. Your account was not registered properly. Please register again.";
        };

        if (user === undefined) {
            $location.path('\login');
        } else {
            $scope.request.accountId = user.account[0].id;
            AmrodFactory.getProfileToken($scope.request).success(amrodSuccess).error(amrodError);
            TradeOnlyGiftFactory.getProfileToken($scope.request).success(togsaSuccess).error(togsaError);
        }

        $scope.checkActiveOrder = function ($activeOrder, $serviceType, $index) {
            if ($activeOrder.volumetric != 0 || !$activeOrder.volumetricEstimate) {
                $activeOrder.check = !$activeOrder.check;
                $scope.checkPresenceInBasket($activeOrder, $serviceType, $index);
            }
        };
    }]);

app.controller('AmrodQuoteController', ['AddressBookFactory', 'CourierFactory', 'dropoff', '$scope', 'ngDialog', '$location', '$localStorage', 'blockUI', '$modal', '$route', function (AddressBookFactory, CourierFactory, dropoff, $scope, ngDialog, $location, $localStorage, blockUI, $modal, $route) {
    $scope.amrod_order = $localStorage.amrod_order;
    console.log($scope.amrod_order);
    var address = $scope.amrod_order.collectionAddress;
    var splitted = address.split("|", 6);
    $scope.amrod_order.pick_up = splitted[1] + ', ' + splitted[2] + ', ' + splitted[3] + ', ' + splitted[4] + ', ' + splitted[5];
    $scope.widget = {
        "type": "Parcel",
        "insurance": false,
        "enduser": false
    };
    $scope.address_dropoff = {};
    $scope.address_dropoff.insurance = false;
    $scope.address_dropoff.insuranceDesc = "";
    $scope.address_dropoff.insuranceValue = null;
    $scope.int_code_label = '+27 (0)';
    $scope.int_code = '+27 (0)';
    $scope.open = function (size, templateUrl) {
        var modalInstance = $modal.open({
            size: 'lg',
            templateUrl: "frontend/app/" + templateUrl,
            controller: 'ModalInstanceController',
            scope: $scope //Or $scope.$new(
        });
    };
    $scope.validateSuburbDropoff = function (suburb) {
        function checkSuburb(item) {
            if (suburb.target.value.trim().length === 0) {
                return false;
            }
            return ((item.SUBURB.toLowerCase() + ', ' + item.STATE.toLowerCase() + ', ' + item.POSTCODE).indexOf(suburb.target.value.toLowerCase()) == 0) && (item.USE === 'Yes');
        }

        var item = $scope.detailedSuburbsWithCities.filter(checkSuburb);
        //console.log(item);
        $scope.address_dropoff.town = '';
        $scope.address_dropoff.state = '';
        $scope.address_dropoff.postcode = '';
        if (item.length > 0) {
            $scope.address_dropoff.town = item[0].SUBURB;
            $scope.address_dropoff.selectedAddr = item[0].SUBURB + ', ' + item[0].STATE + ', ' + item[0].POSTCODE + ' ';
            $scope.address_dropoff.state = item[0].STATE;
            $scope.address_dropoff.postcode = item[0].POSTCODE;
        }
    };
    $scope.populateAddressList = function () {
        var success = function (object) {
            $scope.addressList = object.data;
        };
        var error = function (object) {
        };
        AddressBookFactory.getAddressBookByUserId($scope.currentUser.id).success(success).error(error);
    };
    $scope.checkAddressLineTwo = function (lineTwo) {
        return !(!lineTwo || lineTwo === "" || 0 === lineTwo.length);
    };
    $scope.setChosenAddressType = function (addressType) {
        $scope.type.chosenAddressType = addressType;
    };

    $scope.clearDropoffAddress = function () {
        $scope.address_dropoff.receiver_contact = "";
        $scope.address_dropoff.receiver_phone = "";
        $scope.address_dropoff.receiver_mobile = "";
        $scope.address_dropoff.receiver_email = "";
        $scope.address_dropoff.town = "";
        $scope.address_dropoff.selectedAddr = "";
        $scope.address_dropoff.state = "";
        $scope.address_dropoff.postcode = "";
        $scope.address_dropoff.lineone = "";
        $scope.address_dropoff.linetwo = "";
        $scope.address_dropoff.receiver_name = "";
        $scope.address_dropoff.account_number = "";
        $scope.address_dropoff.special_instructions = "";
        $scope.widget.receiver_name = "";
        $scope.widget.account_number = "";
        $scope.widget.special_instructions = "";
        $scope.widget.receiver_contact = "";
        $scope.widget.receiver_phone = "";
        $scope.widget.receiver_mobile = "";
        $scope.widget.receiver_email = "";
        $scope.widget.drop_off = "";
        $scope.autoFilledAddressDropOff = false;
    };
    $scope.address_dropoff = {};
    $scope.type = {};
    $scope.type.addressPickUp = "ADDRESS_PICK_UP";
    $scope.type.addressDropOff = "ADDRESS_DROP_OFF";
    $scope.setChosenAddress = function (address) {
        if ($scope.type.chosenAddressType === $scope.type.addressDropOff) {
            $scope.address_dropoff.receiver_contact = address.name;
            $scope.address_dropoff.receiver_phone = address.landline;
            $scope.address_dropoff.receiver_mobile = address.mobile_number;
            $scope.address_dropoff.receiver_email = address.email;
            $scope.address_dropoff.town = address.suburb;
            $scope.address_dropoff.selectedAddr = address.suburb + ', ' + address.state + ', ' + address.postcode;
            $scope.address_dropoff.state = address.state;
            $scope.address_dropoff.postcode = address.postcode;
            $scope.address_dropoff.lineone = address.address_line_one;
            $scope.address_dropoff.linetwo = address.address_line_two;
            $scope.address_dropoff.receiver_name = address.company_name;
            $scope.captureDropoffAddress();
            $scope.autoFilledAddressDropOff = true;
        } else if ($scope.type.chosenAddressType === $scope.type.addressPickUp) {
            $scope.address_pickup.sender_name = address.name;
            $scope.address_pickup.sender_company = address.company_name;
            $scope.address_pickup.sender_mob = address.mobile_number;
            $scope.address_pickup.sender_tel = address.landline;
            $scope.address_pickup.sender_email = address.email;
            $scope.address_pickup.town = address.suburb;
            $scope.address_pickup.selectedAddr = address.suburb + ', ' + address.state + ', ' + address.postcode;
            $scope.address_pickup.state = address.state;
            $scope.address_pickup.postcode = address.postcode;
            $scope.address_pickup.lineone = address.address_line_one;
            $scope.address_pickup.linetwo = address.address_line_two;
            $scope.capturePickupAddress();
            $scope.autoFilledAddressPickUp = true;
        }
    };
    $scope.captureDropoffAddress = function () {
        dropoff.set($scope.address_dropoff);
        //console.log($scope.address_dropoff);

        var splitted_dropoff = $scope.address_dropoff.selectedAddr.split(",", 3);
        $scope.widget.receiver_name = $scope.address_dropoff.receiver_name !== undefined ? $scope.address_dropoff.receiver_name : "Personal";
        $scope.widget.account_number = $scope.address_dropoff.account_number !== undefined ? $scope.address_dropoff.account_number : "";
        $scope.widget.special_instructions = $scope.address_dropoff.special_instructions !== undefined ? $scope.address_dropoff.special_instructions : "";
        $scope.widget.receiver_contact = $scope.address_dropoff.receiver_contact;
        $scope.widget.receiver_phone = $scope.address_dropoff.receiver_phone;
        $scope.widget.receiver_mobile = $scope.address_dropoff.receiver_mobile;
        $scope.widget.receiver_email = $scope.address_dropoff.receiver_email;
        var linetwo = $scope.address_dropoff.linetwo === undefined ? '' : $scope.address_dropoff.linetwo;
        $scope.widget.dropoff_object = {};
        $scope.widget.dropoff_object.lineone = $scope.address_dropoff.lineone;
        $scope.widget.dropoff_object.linetwo = linetwo;
        //$scope.widget.dropoff_object.rest = $scope.address_dropoff.town.replace(/,/g, "") + ',' + $scope.address_dropoff.state.replace(/,/g, "") + ',' + $scope.address_dropoff.postcode;
        $scope.widget.dropoff_object.rest = splitted_dropoff[0] + ',' + splitted_dropoff[1] + ',' + splitted_dropoff[2];
        $scope.widget.drop_off = $scope.address_dropoff.lineone.replace(/,/g, "") + ',' + linetwo.replace(/,/g, "") + ',' + $scope.address_dropoff.town.replace(/,/g, "") + ',' + $scope.address_dropoff.state.replace(/,/g, "") + ',' + $scope.address_dropoff.postcode;
        $scope.widget.enduser = $scope.address_dropoff.end_user === undefined ? false : $scope.address_dropoff.end_user;
        $scope.widget.insurance = $scope.address_dropoff.insurance === undefined ? false : $scope.address_dropoff.insurance;
        $scope.widget.insuranceDesc = $scope.address_dropoff.insuranceDesc === undefined ? "" : $scope.address_dropoff.insuranceDesc;
        $scope.widget.insuranceValue = $scope.address_dropoff.insuranceValue === undefined ? "" : $scope.address_dropoff.insuranceValue;
        $scope.insurance = {};
        $scope.insurance.insurance = $scope.widget.insurance;
        $scope.insurance.insuranceDesc = $scope.widget.insuranceDesc;
        $scope.insurance.insuranceValue = $scope.widget.insuranceValue;
        $scope.widget.amount = $scope.amrod_order.amount;
        $scope.widget.amtNative = $scope.amrod_order.amount[0];
        $scope.storage = $localStorage.$default({'widget': $scope.widget});
        $scope.storage = $localStorage.$default({'enduser': $scope.widget.enduser});
        $scope.autoFilledAddressDropOff = true;
        if ($scope.address_dropoff.saveaddress === true) {
//                var address = $scope.address_dropoff.
//                console.log($scope.address_dropoff.state)
//                console.log($scope.address_dropoff.town)
            $scope.address_dropoff.suburb = $scope.address_dropoff.town;
            $scope.address_dropoff.landline = $scope.address_dropoff.receiver_phone;
            $scope.address_dropoff.mobile_number = $scope.address_dropoff.receiver_mobile;
            $scope.address_dropoff.address_line_one = $scope.address_dropoff.lineone;
            $scope.address_dropoff.address_line_two = $scope.address_dropoff.linetwo;
            $scope.address_dropoff.suburbplus = $scope.address_dropoff.selectedAddr;
            var splitted = $scope.address_dropoff.receiver_contact.split(" ", 2);
//                console.log(splitted[0])
            $scope.address_dropoff.firstname = splitted[0];
            $scope.address_dropoff.lastname = splitted[1];
            $scope.address_dropoff.email = $scope.address_dropoff.receiver_email;
            $scope.address_dropoff.user_id = $scope.currentUser.id;
            $scope.address_dropoff.name = $scope.address_dropoff.receiver_contact;
//                $scope.address_dropoff.firstname =
//                $scope.addressBookRecord.name = $scope.addressBookRecord.first_name.trim() + ' ' + $scope.addressBookRecord.last_name.trim();
            var success = function (object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.message;
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: object.message
                });
                $scope.register = {};
//                            populateAddressList();
                blockUI.stop();
            };
            // On failure of create new address record
            var error = function (object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message
                });
                blockUI.stop();
            };
            blockUI.start();
            AddressBookFactory.createAddressBookRecord($scope.address_dropoff).success(success).error(error);
        }

    };
    var setDefaultRecipientAddress = function () {
        var success = function (object) {
            if (object.error === undefined) {
                $scope.setChosenAddressType($scope.type.addressDropOff);
                $scope.setChosenAddress(object.data);
            }
        };
        var error = function (object) {
        };
        AddressBookFactory.getDefaultRecipientAddress($scope.currentUser.id).success(success).error(error);
    };
    $scope.clearDropoffAddress = function () {
        $scope.address_dropoff.receiver_contact = "";
        $scope.address_dropoff.receiver_phone = "";
        $scope.address_dropoff.receiver_mobile = "";
        $scope.address_dropoff.receiver_email = "";
        $scope.address_dropoff.town = "";
        $scope.address_dropoff.selectedAddr = "";
        $scope.address_dropoff.state = "";
        $scope.address_dropoff.postcode = "";
        $scope.address_dropoff.lineone = "";
        $scope.address_dropoff.linetwo = "";
        $scope.address_dropoff.receiver_name = "";
        $scope.address_dropoff.account_number = "";
        $scope.address_dropoff.special_instructions = "";
        $scope.widget.receiver_name = "";
        $scope.widget.account_number = "";
        $scope.widget.special_instructions = "";
        $scope.widget.receiver_contact = "";
        $scope.widget.receiver_phone = "";
        $scope.widget.receiver_mobile = "";
        $scope.widget.receiver_email = "";
        $scope.widget.drop_off = "";
        $scope.autoFilledAddressDropOff = false;
    };
    $scope.processOrder = function () {
        $scope.captureDropoffAddress();
        var address = $scope.amrod_order.collectionAddress;
        var splitted = address.split("|", 6);
        splitted[2] = splitted[2].replace(',', '');
        splitted[1] = splitted[1].replace(',', '');
        $scope.widget.pick_up = splitted[1] + ', ' + splitted[2] + ', ' + splitted[3] + ', ' + splitted[4] + ', ' + splitted[5];
        // console.log($scope.widget.pick_up)
        var correctPhoneNumber = function (phoneNum) {
            if (phoneNum.indexOf("(011)") !== -1) {
                phoneNum = phoneNum.replace("(011)", "11");
            }
            if (phoneNum.indexOf("+") === -1) {
                phoneNum = "+27" + phoneNum;
            }
            return phoneNum.replace(" ", "");
        };
        $scope.widget.sender_tel = correctPhoneNumber($scope.amrod_order.collectionContactPhone);
        $scope.widget.sender_mob = $scope.widget.sender_tel;
        $scope.widget.pickup_object = {};
        $scope.widget.pickup_object.lineone = splitted[1].toUpperCase();
        $scope.widget.pickup_object.linetwo = splitted[2].toUpperCase();
        $scope.widget.pickup_object.rest = splitted[3].toUpperCase() + ', ' + splitted[4].toUpperCase() + ', ' + splitted[5].toUpperCase();
        $scope.widget.sender_name = splitted[0];
        $scope.widget.sender_company = '';
        $scope.widget.sender_email = $scope.amrod_order.collectionContactEmail;
        $scope.widget.volumetric = $scope.amrod_order.volumetric;
        $scope.widget.isSample = $scope.amrod_order.isSample;
        $scope.widget.weight = $scope.amrod_order.weight;
        $scope.widget.length = $scope.amrod_order.length;
        $scope.widget.width = $scope.amrod_order.width;
        $scope.widget.height = $scope.amrod_order.height;
        $scope.widget.parcelType = $scope.amrod_order.parcelType;
        $scope.widget.parcel_desc = $scope.amrod_order.parcel_desc;
        $scope.widget.docType = $scope.amrod_order.docType;
        $scope.widget.documentNumber = $scope.amrod_order.documentNumber;
        // FIXME - May create confusion on invoice
//            if ($scope.widget.isSample == false) {
//                $scope.widget.docType = 'Parcel';
//            } else {
//                $scope.widget.docType = 'Document';
//            }
        //console.log($scope.widget.volume)
        // console.log($scope.widget);

        $scope.widget.businessRef = "AMROD";
        $scope.processParcelWidget();
    };
    $scope.processParcelWidget = function () {

        // console.log('processParcelWidget')
        //  console.log($scope.widget)

        blockUI.start();
        var success = function (object) {
            $localStorage.$reset();
            blockUI.stop();
//                console.log($scope.widget);
            $scope.storage = $localStorage.$default({'order_widget': $scope.widget});
            $scope.cube = object.totalVolume;
            $scope.totalAmount = object.totalAmount;
            $scope.totalWeight = object.totalWeight;
            $scope.priceSize = Object.keys(object.data.CostComparisonResult.CostComparisonResults.ResultSet).length;
            if ($scope.priceSize !== 0) {
                $scope.storage = $localStorage.$default({'cube': $scope.cube});
                $scope.storage = $localStorage.$default({'totalAmount': $scope.totalAmount});
                $scope.storage = $localStorage.$default({'totalWeight': $scope.totalWeight});
                $scope.storage = $localStorage.$default({'order_price_object': object.data});
                $scope.storage = $localStorage.$default({'order_price_list': object.data.CostComparisonResult.CostComparisonResults.ResultSet});
                $scope.storage = $localStorage.$default({'insurance': object.insurance});
                //console.log($scope.widget);
                $location.path('/compare');
                $route.reload();
            } else {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Sorry.We could not find any courier prices for this delivery request.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: "Sorry. We could not find any courier prices for this delivery request."
                });
            }

        };
        var error = function (object) {
            blockUI.stop();
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
        };
        CourierFactory.findAmrodParcelCourierPrices($scope.widget).success(success).error(error);
    };
}]);
app.controller('TradeOnlyGiftQuoteController', ['AddressBookFactory', 'CourierFactory', 'dropoff', '$scope', 'ngDialog', '$location', '$localStorage', 'blockUI', '$modal', '$route', function (AddressBookFactory, CourierFactory, dropoff, $scope, ngDialog, $location, $localStorage, blockUI, $modal, $route) {
    $scope.togsa_order = $localStorage.togsa_order;

    $scope.togsa_order.pick_up =
        $scope.togsa_order.lineone + ", " +
        $scope.togsa_order.linetwo + ", " +
        $scope.togsa_order.suburb_state;

//        console.log($scope.togsa_order.pick_up);

    $scope.widget = {
        "type": "Parcel",
        "insurance": false,
        "enduser": false
    };
    $scope.address_dropoff = {};
    $scope.address_dropoff.insurance = false;
    $scope.address_dropoff.insuranceDesc = "";
    $scope.address_dropoff.insuranceValue = null;
    $scope.int_code_label = '+27 (0)';
    $scope.int_code = '+27 (0)';
    $scope.open = function (size, templateUrl) {
        var modalInstance = $modal.open({
            size: 'lg',
            templateUrl: "frontend/app/" + templateUrl,
            controller: 'ModalInstanceController',
            scope: $scope
        });
    };
    $scope.validateSuburbDropoff = function (suburb) {
        function checkSuburb(item) {
            if (suburb.target.value.trim().length === 0) {
                return false;
            }
            return ((item.SUBURB.toLowerCase() + ', ' + item.STATE.toLowerCase() + ', ' + item.POSTCODE).indexOf(suburb.target.value.toLowerCase()) == 0) && (item.USE === 'Yes');
        }

        var item = $scope.detailedSuburbsWithCities.filter(checkSuburb);
        //console.log(item);
        $scope.address_dropoff.town = '';
        $scope.address_dropoff.state = '';
        $scope.address_dropoff.postcode = '';
        if (item.length > 0) {
            $scope.address_dropoff.town = item[0].SUBURB;
            $scope.address_dropoff.selectedAddr = item[0].SUBURB + ', ' + item[0].STATE + ', ' + item[0].POSTCODE + ' ';
            $scope.address_dropoff.state = item[0].STATE;
            $scope.address_dropoff.postcode = item[0].POSTCODE;
        }
    };
    $scope.populateAddressList = function () {
        var success = function (object) {
            $scope.addressList = object.data;
        };
        var error = function (object) {
        };
        AddressBookFactory.getAddressBookByUserId($scope.currentUser.id).success(success).error(error);
    };
    $scope.checkAddressLineTwo = function (lineTwo) {
        return !(!lineTwo || lineTwo === "" || 0 === lineTwo.length);
    };
    $scope.setChosenAddressType = function (addressType) {
        $scope.type.chosenAddressType = addressType;
    };

    $scope.clearDropoffAddress = function () {
        $scope.address_dropoff.receiver_contact = "";
        $scope.address_dropoff.receiver_phone = "";
        $scope.address_dropoff.receiver_mobile = "";
        $scope.address_dropoff.receiver_email = "";
        $scope.address_dropoff.town = "";
        $scope.address_dropoff.selectedAddr = "";
        $scope.address_dropoff.state = "";
        $scope.address_dropoff.postcode = "";
        $scope.address_dropoff.lineone = "";
        $scope.address_dropoff.linetwo = "";
        $scope.address_dropoff.receiver_name = "";
        $scope.address_dropoff.account_number = "";
        $scope.address_dropoff.special_instructions = "";
        $scope.widget.receiver_name = "";
        $scope.widget.account_number = "";
        $scope.widget.special_instructions = "";
        $scope.widget.receiver_contact = "";
        $scope.widget.receiver_phone = "";
        $scope.widget.receiver_mobile = "";
        $scope.widget.receiver_email = "";
        $scope.widget.drop_off = "";
        $scope.autoFilledAddressDropOff = false;
    };
    $scope.address_dropoff = {};
    $scope.type = {};
    $scope.type.addressPickUp = "ADDRESS_PICK_UP";
    $scope.type.addressDropOff = "ADDRESS_DROP_OFF";
    $scope.setChosenAddress = function (address) {
        if ($scope.type.chosenAddressType === $scope.type.addressDropOff) {
            $scope.address_dropoff.receiver_contact = address.name;
            $scope.address_dropoff.receiver_phone = address.landline;
            $scope.address_dropoff.receiver_mobile = address.mobile_number;
            $scope.address_dropoff.receiver_email = address.email;
            $scope.address_dropoff.town = address.suburb;
            $scope.address_dropoff.selectedAddr = address.suburb + ', ' + address.state + ', ' + address.postcode;
            $scope.address_dropoff.state = address.state;
            $scope.address_dropoff.postcode = address.postcode;
            $scope.address_dropoff.lineone = address.address_line_one;
            $scope.address_dropoff.linetwo = address.address_line_two;
            $scope.address_dropoff.receiver_name = address.company_name;
            $scope.captureDropoffAddress();
            $scope.autoFilledAddressDropOff = true;
        } else if ($scope.type.chosenAddressType === $scope.type.addressPickUp) {
            $scope.address_pickup.sender_name = address.name;
            $scope.address_pickup.sender_company = address.company_name;
            $scope.address_pickup.sender_mob = address.mobile_number;
            $scope.address_pickup.sender_tel = address.landline;
            $scope.address_pickup.sender_email = address.email;
            $scope.address_pickup.town = address.suburb;
            $scope.address_pickup.selectedAddr = address.suburb + ', ' + address.state + ', ' + address.postcode;
            $scope.address_pickup.state = address.state;
            $scope.address_pickup.postcode = address.postcode;
            $scope.address_pickup.lineone = address.address_line_one;
            $scope.address_pickup.linetwo = address.address_line_two;
            $scope.capturePickupAddress();
            $scope.autoFilledAddressPickUp = true;
        }
    };
    $scope.captureDropoffAddress = function () {
        dropoff.set($scope.address_dropoff);
        //console.log($scope.address_dropoff);

        var splitted_dropoff = $scope.address_dropoff.selectedAddr.split(",", 3);
        $scope.widget.receiver_name = $scope.address_dropoff.receiver_name !== undefined ? $scope.address_dropoff.receiver_name : "Personal";
        $scope.widget.account_number = $scope.address_dropoff.account_number !== undefined ? $scope.address_dropoff.account_number : "";
        $scope.widget.special_instructions = $scope.address_dropoff.special_instructions !== undefined ? $scope.address_dropoff.special_instructions : "";
        $scope.widget.receiver_contact = $scope.address_dropoff.receiver_contact;
        $scope.widget.receiver_phone = $scope.address_dropoff.receiver_phone;
        $scope.widget.receiver_mobile = $scope.address_dropoff.receiver_mobile;
        $scope.widget.receiver_email = $scope.address_dropoff.receiver_email;
        var linetwo = $scope.address_dropoff.linetwo === undefined ? '' : $scope.address_dropoff.linetwo;
        $scope.widget.dropoff_object = {};
        $scope.widget.dropoff_object.lineone = $scope.address_dropoff.lineone;
        $scope.widget.dropoff_object.linetwo = linetwo;
        //$scope.widget.dropoff_object.rest = $scope.address_dropoff.town.replace(/,/g, "") + ',' + $scope.address_dropoff.state.replace(/,/g, "") + ',' + $scope.address_dropoff.postcode;
        $scope.widget.dropoff_object.rest = splitted_dropoff[0] + ',' + splitted_dropoff[1] + ',' + splitted_dropoff[2];
        $scope.widget.drop_off = $scope.address_dropoff.lineone.replace(/,/g, "") + ',' + linetwo.replace(/,/g, "") + ',' + $scope.address_dropoff.town.replace(/,/g, "") + ',' + $scope.address_dropoff.state.replace(/,/g, "") + ',' + $scope.address_dropoff.postcode;
        $scope.widget.enduser = $scope.address_dropoff.end_user === undefined ? false : $scope.address_dropoff.end_user;
        $scope.widget.insurance = $scope.address_dropoff.insurance === undefined ? false : $scope.address_dropoff.insurance;
        $scope.widget.insuranceDesc = $scope.address_dropoff.insuranceDesc === undefined ? "" : $scope.address_dropoff.insuranceDesc;
        $scope.widget.insuranceValue = $scope.address_dropoff.insuranceValue === undefined ? "" : $scope.address_dropoff.insuranceValue;
        $scope.insurance = {};
        $scope.insurance.insurance = $scope.widget.insurance;
        $scope.insurance.insuranceDesc = $scope.widget.insuranceDesc;
        $scope.insurance.insuranceValue = $scope.widget.insuranceValue;
        $scope.widget.amount = $scope.togsa_order.amount;
        $scope.widget.amtNative = $scope.togsa_order.amount[0];

        $scope.storage = $localStorage.$default({'widget': $scope.widget});
        $scope.storage = $localStorage.$default({'enduser': $scope.widget.enduser});
        $scope.autoFilledAddressDropOff = true;
        if ($scope.address_dropoff.saveaddress === true) {
//                var address = $scope.address_dropoff.
//                console.log($scope.address_dropoff.state)
//                console.log($scope.address_dropoff.town)
            $scope.address_dropoff.suburb = $scope.address_dropoff.town;
            $scope.address_dropoff.landline = $scope.address_dropoff.receiver_phone;
            $scope.address_dropoff.mobile_number = $scope.address_dropoff.receiver_mobile;
            $scope.address_dropoff.address_line_one = $scope.address_dropoff.lineone;
            $scope.address_dropoff.address_line_two = $scope.address_dropoff.linetwo;
            $scope.address_dropoff.suburbplus = $scope.address_dropoff.selectedAddr;
            var splitted = $scope.address_dropoff.receiver_contact.split(" ", 2);
            $scope.address_dropoff.firstname = splitted[0];
            $scope.address_dropoff.lastname = splitted[1];
            $scope.address_dropoff.email = $scope.address_dropoff.receiver_email;
            $scope.address_dropoff.user_id = $scope.currentUser.id;
            $scope.address_dropoff.name = $scope.address_dropoff.receiver_contact;
//                $scope.address_dropoff.firstname =
//                $scope.addressBookRecord.name = $scope.addressBookRecord.first_name.trim() + ' ' + $scope.addressBookRecord.last_name.trim();
            var success = function (object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.message;
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: object.message
                });
                $scope.register = {};
                blockUI.stop();
            };
            // On failure of create new address record
            var error = function (object) {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message
                });
                blockUI.stop();
            };
            blockUI.start();
            AddressBookFactory.createAddressBookRecord($scope.address_dropoff).success(success).error(error);
        }
    };

    $scope.clearDropoffAddress = function () {
        $scope.address_dropoff.receiver_contact = "";
        $scope.address_dropoff.receiver_phone = "";
        $scope.address_dropoff.receiver_mobile = "";
        $scope.address_dropoff.receiver_email = "";
        $scope.address_dropoff.town = "";
        $scope.address_dropoff.selectedAddr = "";
        $scope.address_dropoff.state = "";
        $scope.address_dropoff.postcode = "";
        $scope.address_dropoff.lineone = "";
        $scope.address_dropoff.linetwo = "";
        $scope.address_dropoff.receiver_name = "";
        $scope.address_dropoff.account_number = "";
        $scope.address_dropoff.special_instructions = "";
        $scope.widget.receiver_name = "";
        $scope.widget.account_number = "";
        $scope.widget.special_instructions = "";
        $scope.widget.receiver_contact = "";
        $scope.widget.receiver_phone = "";
        $scope.widget.receiver_mobile = "";
        $scope.widget.receiver_email = "";
        $scope.widget.drop_off = "";
        $scope.autoFilledAddressDropOff = false;
    };

    $scope.processOrder = function () {
        $scope.captureDropoffAddress();
        $scope.widget.pick_up = $scope.togsa_order.pick_up;
        // console.log($scope.widget.pick_up)
        var correctPhoneNumber = function (phoneNum) {
            if (phoneNum.indexOf("(011)") !== -1) {
                phoneNum = phoneNum.replace("(011)", "11");
            }
            if (phoneNum.indexOf("+") === -1) {
                phoneNum = "+27" + phoneNum;
            }
            return phoneNum.replace(" ", "");
        };

        $scope.widget.sender_tel = correctPhoneNumber($scope.togsa_order.sender_tel);
        $scope.widget.sender_mob = $scope.widget.sender_tel;
        $scope.widget.pickup_object = {};
        $scope.widget.pickup_object.lineone = $scope.togsa_order.lineone.toUpperCase();
        $scope.widget.pickup_object.linetwo = $scope.togsa_order.linetwo.toUpperCase();
        $scope.widget.pickup_object.rest = $scope.togsa_order.suburb_state.toUpperCase();
        $scope.widget.sender_name = $scope.togsa_order.sender_name;
        $scope.widget.sender_company = 'GIFT SHOWROOM';
        $scope.widget.sender_email = $scope.togsa_order.sender_email;
        $scope.widget.volumetric = $scope.togsa_order.volumetric;
        $scope.widget.isSample = $scope.togsa_order.isSample;
        $scope.widget.weight = $scope.togsa_order.weight;
        $scope.widget.length = $scope.togsa_order.length;
        $scope.widget.width = $scope.togsa_order.width;
        $scope.widget.height = $scope.togsa_order.height;
        $scope.widget.parcelType = $scope.togsa_order.parcelType;
        $scope.widget.docType = $scope.togsa_order.docType;
        $scope.widget.parcel_desc = $scope.togsa_order.parcel_desc;
        $scope.widget.orderNumber = $scope.togsa_order.orderNumber;
        $scope.widget.businessRef = "TOGSA";
        $scope.processParcelWidget();
    };

    $scope.processParcelWidget = function () {
        blockUI.start();
        var success = function (object) {
            $localStorage.$reset();
            blockUI.stop();
//                console.log($scope.widget);
            $scope.storage = $localStorage.$default({'order_widget': $scope.widget});
            $scope.cube = object.totalVolume;
            $scope.totalAmount = object.totalAmount;
            $scope.totalWeight = object.totalWeight;
            $scope.priceSize = Object.keys(object.data.CostComparisonResult.CostComparisonResults.ResultSet).length;
            if ($scope.priceSize !== 0) {
                $scope.storage = $localStorage.$default({'cube': $scope.cube});
                $scope.storage = $localStorage.$default({'totalAmount': $scope.totalAmount});
                $scope.storage = $localStorage.$default({'totalWeight': $scope.totalWeight});
                $scope.storage = $localStorage.$default({'order_price_object': object.data});
                $scope.storage = $localStorage.$default({'order_price_list': object.data.CostComparisonResult.CostComparisonResults.ResultSet});
                $scope.storage = $localStorage.$default({'insurance': object.insurance});
                //console.log($scope.widget);
                $location.path('/compare');
                $route.reload();
            } else {
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Sorry.We could not find any courier prices for this delivery request.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: "Sorry. We could not find any courier prices for this delivery request."
                });
            }

        };
        var error = function (object) {
            blockUI.stop();
            ngDialog.open({
                template: 'withInlineController',
                controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope.msg = object.error.message;
                }],
                className: 'ngdialog-theme-default',
                msg: object.error.message
            });
        };
        CourierFactory.findTOGSAParcelCourierPrices($scope.widget).success(success).error(error);
    };
}]);

app.controller('CompleteOrderController', ['$scope', 'ngDialog', '$location', '$localStorage', 'BusinessFactory', 'blockUI', '$route',
    function ($scope, ngDialog, $location, $localStorage, BusinessFactory, blockUI, $route) {
        $scope.completeWalletOrder = function ($orderGroupRef) {
            var completeBasketOrdersSuccess = function (object) {
                $localStorage.$reset();
                blockUI.stop();
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.message;
                    }],
                    className: 'ngdialog-theme-plain',
                    msg: object.message
                });
                if ($localStorage.newOrderTab === undefined) {
                    $localStorage.$default({'newOrderTab': true});
                } else {
                    $localStorage.newOrderTab = true;
                }
                $location.path('account');
            };
            var completeBasketOrdersError = function (object) {

                blockUI.stop();
                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = object.error.message.message;
                    }],
                    className: 'ngdialog-theme-default',
                    msg: object.error.message.message
                });
                $route.reload();
            };
            blockUI.start();
            blockUI.message('Please wait while we process your order. Stopping this process will cancel your booking!.');
            $postData = {"OrderGroupRef": $orderGroupRef, "status": 'Active'};
            BusinessFactory.completeBasketOrders($postData).success(completeBasketOrdersSuccess).error(completeBasketOrdersError);
        };
    }]);

app.controller('ProhibitedGoodsController', ['$scope', 'ngDialog', '$anchorScroll', '$location', '$rootScope', 'blockUI', '$timeout', function ($scope, ngDialog, $anchorScroll, $location, $rootScope, blockUI, $timeout) {
    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';

    $('._jsDynamicTitle').text('Rush - Prohibited Goods');
    $scope.oneAtATime = true;
    $scope.status = {
        isFirstOpen: false,
        isFirstDisabled: false,
        isPrivacy_prohibited: true
    };
    $scope.init = function () {
        if ($scope.status.isPrivacy_prohibited) {
            $('html, body').animate({scrollTop: $('#prohibited').offset().top - 250}, 1000);
        }
    };
    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);
app.controller('ItemsNotInsuredController', ['$scope', 'ngDialog', '$anchorScroll', '$location', '$rootScope', 'blockUI', '$timeout', function ($scope, ngDialog, $anchorScroll, $location, $rootScope, blockUI, $timeout) {
    blockUI.start();
    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';

    $rootScope.bufferDivs = 'subheaderHide';
    $rootScope.subheaderClass = 'subheaderShow';

    $rootScope.bufferDivss = 'partnersHide';
    $rootScope.partnersClass = 'partnersShow';

    $('._jsDynamicTitle').text('Rush - Items not insured for loss or damage');
    $scope.oneAtATime = true;
    $scope.status = {
        isFirstOpen: false,
        isFirstDisabled: false,
        isPrivacy: true
    };
    $scope.init = function () {
        if ($scope.status.isPrivacy) {
            $('html, body').animate({scrollTop: $('#not-insured').offset().top - 250}, 1000);
        }
    };
    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);

app.controller('PayuDebtorController', ['$routeParams', 'ngDialog', 'payUFactory', '$window', '$location', '$scope', '$location', '$cookieStore', '$localStorage', 'BusinessFactory', 'blockUI', '$modal', 'ENV', function ($routeParams, ngDialog, payUFactory, $window, $location, $scope, $location, $cookieStore, $localStorage, BusinessFactory, blockUI, modal, ENV) {
    console.log($routeParams);
    $scope.OrderGroupRef = $routeParams.orderGroupRef;
    $scope.PayUReference = $routeParams.PayUReference;
    $scope.walletTransaction = false;

    var success = function (object) {
        console.log(object)

        if (object.locked === 0) {

            var success = function (object) {

                blockUI.stop();
                $scope.returnData = object.returnData;
                $scope.environment = object.environment;
                $scope.amount = $scope.returnData.return.basket.amountInCents / 100;
                $scope.currency = $scope.returnData.return.basket.currencyCode;
                $scope.description = $scope.returnData.return.basket.description;

                $scope.orderGroupRef = object.orderGroupRef;
                var success = function (object) {
                    blockUI.stop();
                    console.log(object)
                }
                var error = function (object) {
                    console.log(object)
                }
                blockUI.start();
                BusinessFactory.sendDebtsRecoveryEmail($scope.orderGroupRef).success(success).error(error);

                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = 'Debt cleared.';
                    }],
                    className: 'ngdialog-theme-default',
                    msg: 'Debt cleared.'
                });
            };

            var error = function (object) {

                if (object.error.message.returnData !== undefined) {
                    $scope.returnData = object.error.message.returnData;
                    $scope.environment = object.error.message.environment;
                    $scope.amount = $scope.returnData.return.basket.amountInCents / 100;
                    $scope.currency = $scope.returnData.return.basket.currencyCode;
                    $scope.description = $scope.returnData.return.basket.description;
                }

                blockUI.stop();

                ngDialog.open({
                    template: 'withInlineController',
                    controller: ['$scope', '$timeout', function ($scope, $timeout) {
                        $scope.msg = "Your PayU payment failed. Your order(s) can be found in Basket.";
                    }],
                    className: 'ngdialog-theme-default',
                    msg: "Your PayU payment failed. Your order(s) can be found in Basket."
                });
                $localStorage.$reset();
            };

            blockUI.stop();
            blockUI.start();
            $post = {
                "BasketOrderIds": object.BasketOrderIds,
                "OrderGroupRef": object.OrderGroupRef,
                "PayUReference": object.PayUReference
            };
            BusinessFactory.retrievePayUDebtPaymentStatus($post).success(success).error(error);
        }
    };

    var error = function (object) {

        blockUI.stop();

        ngDialog.open({
            template: 'withInlineControllerCheckBasketOrder',
            controller: ['$scope', '$timeout', 'BasketFactory', 'blockUI', '$modalStack', function ($scope, $timeout, BasketFactory, blockUI, $modalStack) {
                $scope.redirectToBasket = function (id) {
                    $modalStack.dismissAll();
                    $window.location.href = object.error.message.redirectUrl;
                };
                $scope.msg = object.error.message.message;
            }],
            className: 'ngdialog-theme-default',
            msg: "object.message"
        });
        $localStorage.$reset();
    };

    blockUI.start();
    $postData = {"OrderGroupRef": $scope.OrderGroupRef, "PayUReference": $scope.PayUReference, "isDebtor": true};
    console.log($postData)
    BusinessFactory.isPaymentConfirmed($postData).success(success).error(error);

}]);

/**
 * Retrieve EcommerceAPI Controller
 *
 * @param $scope
 * @param notifications
 * @param Tracking Factory
 */

app.controller('EcommerceApiController', ['$scope', 'ngDialog', '$timeout', 'FAQsFactory', '$location', 'ngProgress', 'blockUI', '$rootScope', function ($scope, ngDialog, $timeout, FAQsFactory, $location, ngProgress, blockUI, $rootScope) {

    $('._jsDynamicTitle').text('Rush - Ecommerce API');

    $rootScope.bufferDiv = 'bannerShow';
    $rootScope.bannerClass = 'bannerHide';
//
//        $rootScope.subheaderClass = 'subheaderShow';
//        $rootScope.bufferDiv = 'subheaderHide';
//
//        $rootScope.subheaderClass = 'subheaderShow';
//        $rootScope.bufferDiv = 'subheaderHide';
//
//
    $rootScope.bufferDivs = 'subheaderShow';
    $rootScope.subheaderClass = 'subheaderHide';

    $rootScope.bufferDivss = 'partnersShow';
    $rootScope.partnersClass = 'partnersHide';

    $scope.test = function (object) {
        var form = document.createElement("form");
        form.method = "GET";
        form.action = "/rushapi";
        document.body.appendChild(form);
        form.submit();
    }


}]);

/**
 * EcommerceCostController Controller
 *
 * @param  $scope
 * @return void
 */
app.controller('EcommerceCostController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderShow';
    $rootScope.subheaderClass = 'subheaderHide';

    $rootScope.bufferDivss = 'partnersShow';
    $rootScope.partnersClass = 'partnersHide';

    $('._jsDynamicTitle').text('Rush - Ecommerce API');
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#costComparison').offset().top - 250}, 1000);

    }
    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);


app.controller('EcommerceListAddressController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderShow';
    $rootScope.subheaderClass = 'subheaderHide';

    $rootScope.bufferDivss = 'partnersShow';
    $rootScope.partnersClass = 'partnersHide';
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#addresses').offset().top - 250}, 1000);

    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);

app.controller('EcommerceBookingController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderShow';
    $rootScope.subheaderClass = 'subheaderHide';

    $rootScope.bufferDivss = 'partnersShow';
    $rootScope.partnersClass = 'partnersHide';
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#bookingConfirmation').offset().top - 250}, 1000);

    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);


app.controller('EcommerceGenerateController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderShow';
    $rootScope.subheaderClass = 'subheaderHide';

    $rootScope.bufferDivss = 'partnersShow';
    $rootScope.partnersClass = 'partnersHide';
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#generatePDF').offset().top - 250}, 1000);

    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);

app.controller('EcommerceTrackWaybillController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderShow';
    $rootScope.subheaderClass = 'subheaderHide';

    $rootScope.bufferDivss = 'partnersShow';
    $rootScope.partnersClass = 'partnersHide';
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#trackwaybill').offset().top - 250}, 1000);

    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);


app.controller('EcommerceValidAddressController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderShow';
    $rootScope.subheaderClass = 'subheaderHide';

    $rootScope.bufferDivss = 'partnersShow';
    $rootScope.partnersClass = 'partnersHide';
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#old_address').offset().top - 250}, 1000);

    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);

app.controller('EcommerceNewValidAddressController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderShow';
    $rootScope.subheaderClass = 'subheaderHide';

    $rootScope.bufferDivss = 'partnersShow';
    $rootScope.partnersClass = 'partnersHide';
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#new_address').offset().top - 250}, 1000);

    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);


app.controller('EcommerceWeightController', ['$scope', 'ngDialog', '$anchorScroll', 'blockUI', '$rootScope', '$timeout', function ($scope, ngDialog, $anchorScroll, blockUI, $rootScope, $timeout) {
    blockUI.start();
    $rootScope.bannerClass = 'bannerHide';
    $rootScope.bufferDiv = 'bannerShow';

    $rootScope.bufferDivs = 'subheaderShow';
    $rootScope.subheaderClass = 'subheaderHide';

    $rootScope.bufferDivss = 'partnersShow';
    $rootScope.partnersClass = 'partnersHide';
    $scope.init = function () {
        $('html, body').animate({scrollTop: $('#minimum_weight').offset().top - 250}, 1000);

    }

    angular.element(document).ready(function () {
        // your code here
        $timeout($scope.init, 500);
    });
    $timeout(function () {
        blockUI.stop();
    }, 500)
}]);
