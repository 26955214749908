// Create application module.
var app = angular.module('app', [
    'ngRoute',
    'ui.bootstrap',
    'ngCookies',
    'ngNotificationsBar',
    'ngProgress',
    'datatables',
    'uiSlider',
    'ngStorage',
    'angularUtils.directives.dirPagination',
    'ngTouch',
    'angulartics',
    'angulartics.google.analytics',
//        'angular-flexslider',
    'blockUI',
    'ngDialog'
]);

// Set the Local Environment
// app.constant('ENV', {
// 	"ENVIRONMENT"	: "local",
// 	// "API_URL"		: 'http://api.rush.local:8000/api/v1/',
// 	"API_URL"  		: 'http://www.rush.co.za/api/v1/',
// 	'APP_DIR'		: "app/",
// 	'PUBLIC_DIR'	: "public/",
// 	'QUOTE_ACTIVE'	: false,
// });

// Set the Staging Environment
// app.constant('ENV', {
// 	"ENVIRONMENT" : "staging",
// 	"API_URL": 'http://rush.immedia.co.za/api/v1/',
// 	"APP_DIR": "frontend/app/",
// 	'PUBLIC_DIR'	: "frontend/public/",
// 	'QUOTE_ACTIVE'	: true,
// });

// Set the Production Environment
app.constant('ENV', {
    "ENVIRONMENT": "production",
    "API_URL": '/api/v1/',
    "APP_DIR": "frontend/app/",
    'PUBLIC_DIR': "frontend/public/",
    'QUOTE_ACTIVE': true,
});

app.config(['ngDialogProvider', function (ngDialogProvider) {
    ngDialogProvider.setDefaults({
        className: 'ngdialog-theme-default',
        plain: false,
        showClose: true,
        closeByDocument: true,
        closeByEscape: true,
        appendTo: false,
        preCloseCallback: function () {
            console.log('default pre-close callback');
        }
    });
}]);


// Set the routes
app.config(['$routeProvider', "ENV", "paginationTemplateProvider", "$locationProvider", "blockUIConfig", function ($routeProvider, ENV, paginationTemplateProvider, $locationProvider, blockUIConfig) {

    blockUIConfig.autoInjectBodyBlock = true;
    blockUIConfig.autoBlock = false;
    //blockUIConfig.templateUrl = ENV.APP_DIR + 'views/spinner.html';

    //blockUIConfig.cssClass = 'block-ui my-custom-class';

    // use the HTML5 History API
    $locationProvider.html5Mode({enabled: true}).hashPrefix('!');

    // Pagination Template Path
    var templatePath = ENV.APP_DIR + 'dirPagination.tpl.html';
    // console.log(templatePath);
    paginationTemplateProvider.setPath(templatePath);

    // Home Page
    $routeProvider.when('/', {
        templateUrl: ENV.APP_DIR + 'views/home.html',
        controller: 'HomeController'
    });

    $routeProvider.when('/skynet-terms', {
        templateUrl: ENV.APP_DIR + 'views/skynet-terms.html'
    });
    $routeProvider.when('/globeflight-terms', {
        templateUrl: ENV.APP_DIR + 'views/globeflight-terms.html'
    });
    $routeProvider.when('/dcb-terms', {
        templateUrl: ENV.APP_DIR + 'views/dcb-terms.html'
    });
    $routeProvider.when('/aramex-terms', {
        templateUrl: ENV.APP_DIR + 'views/aramex-terms.html'
    });
    $routeProvider.when('/tcg-terms', {
        templateUrl: ENV.APP_DIR + 'views/tcg-terms.html'
    });
    $routeProvider.when('/courier-it-terms', {
        templateUrl: ENV.APP_DIR + 'views/courier-it-terms.html'
    });

    // Login Page
    $routeProvider.when('/login', {
        templateUrl: ENV.APP_DIR + 'views/login.html',
        controller: 'LoginController'
    });

    // Login Page to redirect to summary
    $routeProvider.when('/login_quick_quote', {
        templateUrl: ENV.APP_DIR + 'views/login_quick_quote.html',
        controller: 'AuthQuickQuoteController'
    });

    // Quick Quote
    $routeProvider.when('/quickquote', {
        templateUrl: ENV.APP_DIR + 'views/quick_quote.html',
        controller: 'QuickQuoteController'
    });

    // Full Quote
    $routeProvider.when('/fullquote', {
        templateUrl: ENV.APP_DIR + 'views/fullquote.html',
        controller: 'FullQuoteController'
    });


    // Register Page
    $routeProvider.when('/register', {
        templateUrl: ENV.APP_DIR + 'views/register.html',
        controller: 'BusinessRegisterController'
    });

    //Amrod Register Page
    $routeProvider.when('/amrod_register', {
        templateUrl: ENV.APP_DIR + 'views/amrod_register.html',
        controller: 'BusinessRegisterController'
    });

    //Trade Only Gift Register Page
    $routeProvider.when('/togsa_register', {
        templateUrl: ENV.APP_DIR + 'views/togsa_register.html',
        controller: 'BusinessRegisterController'
    });

    // // pronto Page
    // $routeProvider.when('/courier-services/pronto', {
    //     templateUrl: ENV.APP_DIR + 'views/services.html',
    //     controller: 'ProntoController'
    // });
    //
    // // sameday Page
    // $routeProvider.when('/courier-services/same-day', {
    //     templateUrl: ENV.APP_DIR + 'views/services.html',
    //     controller: 'SamedayController'
    // });
    //
    // // overnight Page
    // $routeProvider.when('/courier-services/overnight', {
    //     templateUrl: ENV.APP_DIR + 'views/services.html',
    //     controller: 'OvernightController'
    // });
    //
    //
    // // budget Page
    // $routeProvider.when('/courier-services/budget', {
    //     templateUrl: ENV.APP_DIR + 'views/services.html',
    //     controller: 'BudgetController'
    // });

    // Reset password Page
    $routeProvider.when('/resetpassword', {
        templateUrl: ENV.APP_DIR + 'views/resetpassword.html',
        controller: 'ResetPasswordController'
    });

    // Reset Password
    $routeProvider.when('/resetpassword/:id/:code?', {
        templateUrl: ENV.APP_DIR + 'views/resetpassword.html',
        controller: 'ResetPasswordController'
    });

    // When first hit by we chat user
    $routeProvider.when('/openid/:id', {
        templateUrl: ENV.APP_DIR + 'views/home.html',
        controller: 'HomeController'
    });

    // request for testing directory
    // $routeProvider.when('/.well-known/acme-challenge/:encrypt', {
    //     templateUrl: ENV.APP_DIR + '/.well-known/acme-challenge/:encrypt',
    //     controller: 'EncryptController'
    // });

    // About Page
    $routeProvider.when('/about', {
        templateUrl: ENV.APP_DIR + 'views/about.html',
        controller: 'AboutController'
    });

    // About Page
    $routeProvider.when('/contact-us', {
        templateUrl: ENV.APP_DIR + 'views/contactus.html',
        controller: 'ContactController'
    });

    // Services Page
    $routeProvider.when('/courierservices', {
        templateUrl: ENV.APP_DIR + 'views/services.html',
        controller: 'PartnersController'
    });

    // Packing Page
    $routeProvider.when('/packings', {
        templateUrl: ENV.APP_DIR + 'views/package_guidelines.html',
        controller: 'PackingController'
    });

    // Tracking Page
    $routeProvider.when('/tracking', {
        templateUrl: ENV.APP_DIR + 'views/tracking.html',
        controller: 'TrackingController'
    });

    // custom message Page
    $routeProvider.when('/messages', {
        templateUrl: ENV.APP_DIR + 'views/custom_message.html',
        controller: 'MessageController'
    });

    // Testimonials Page
    $routeProvider.when('/testimonials', {
        templateUrl: ENV.APP_DIR + 'views/testimonials.html',
        controller: 'TestimoniesController'
    });

    // FAQs Page
    $routeProvider.when('/faqs', {
        templateUrl: ENV.APP_DIR + 'views/faqs.html',
        controller: 'FaqsController'
    });
    //Rush for mobile
    $routeProvider.when('/rush-mobile', {
        templateUrl: ENV.APP_DIR + 'views/rush_mobile.html',
        controller: 'RushMobileController'
    });

    //Rush for business
    $routeProvider.when('/rush-business', {
        templateUrl: ENV.APP_DIR + 'views/rush_business.html',
        controller: 'RushBusinessController'
    });

    //Rush for enterprise
    $routeProvider.when('/rush-enterprise', {
        templateUrl: ENV.APP_DIR + 'views/rush_enterprise.html',
        controller: 'RushEnterpriseController'
    });

    //Rush for business plus
    $routeProvider.when('/business-plus', {
        templateUrl: ENV.APP_DIR + 'views/business_plus.html',
        controller: 'BusinessPlusController'
    });

    // Articles History
    // News page
    $routeProvider.when('/news', {
        templateUrl: ENV.APP_DIR + 'views/news.html',
        controller: 'ArticlesController'
    });

    // Articles Single
    // News Article page
    $routeProvider.when('/news/:id', {
        templateUrl: ENV.APP_DIR + 'views/news_article.html',
        controller: 'ArticlesController'
    });

    // Compare Couriers
    $routeProvider.when('/compare', {
        templateUrl: ENV.APP_DIR + 'views/comparison.html',
        controller: 'CompareController'
    });

    $routeProvider.when('/compareFullQuote', {
        templateUrl: ENV.APP_DIR + 'views/comparison.html',
        controller: 'CompareFullQuoteController'
    });
    // Summary Page
    $routeProvider.when('/summary', {
        templateUrl: ENV.APP_DIR + 'views/summary.html',
        controller: 'SummaryController'
    });

    // PayU response page
    $routeProvider.when('/business/returnPayU/:orderGroupRef', {
        templateUrl: ENV.APP_DIR + 'views/return_payu.html',
        controller: 'PayUController'
    });
    // PayU response page for debtor
    $routeProvider.when('/business/debtor/returnPayU/:orderGroupRef', {
        templateUrl: ENV.APP_DIR + 'views/debtor_redirect_url.html',
        controller: 'PayuDebtorController'
    });

    // Refill Wallet - PayU response page
    // $routeProvider.when('/business/refillWallet', {
    //     templateUrl: ENV.APP_DIR + 'views/return_payu.html',
    //     controller: 'RefillWalletPayUController'
    // });


    // Refill Wallet - peachpayment response page
    $routeProvider.when('/business/refillWallet', {
        templateUrl: ENV.APP_DIR + 'views/return_peachpayment.html',
        controller: 'refillWalletPeachPaymentController'
    });

    $routeProvider.when('/business/return_peachpayment', {
        templateUrl: ENV.APP_DIR + 'views/return_peachpayment.html',
        controller: 'PeachPaymentController'
    });


    // Purchase Page
    $routeProvider.when('/purchase', {
        templateUrl: ENV.APP_DIR + 'views/purchase.html',
        controller: 'PurchaseController'
    });

    // Success Page
    $routeProvider.when('/success/:order?', {
        templateUrl: ENV.APP_DIR + 'views/success.html',
        controller: 'SuccessController'
    });

    // Cancel Order Page
    $routeProvider.when('/business/cancelOrder/:orderId', {
        templateUrl: ENV.APP_DIR + 'views/cancel_order.html',
        controller: 'PayUCancelController'
    });

    // Cancel Page
    $routeProvider.when('/cancel/:order?', {
        templateUrl: ENV.APP_DIR + 'views/cancel.html',
        controller: 'CancelController'
    });

    // Activate account
    $routeProvider.when('/activate/:id/:code/:app', {
        templateUrl: ENV.APP_DIR + 'views/activate.html',
        controller: 'ActivateController'
    });

    // Reset Password
    // $routeProvider.when('/resetpassword/:id/:code?', {
    // 	templateUrl: ENV.APP_DIR + 'views/resetpassword.html',
    // 	controller: 'ResetPasswordController'
    // });


    // Account
    $routeProvider.when('/account', {
        templateUrl: ENV.APP_DIR + 'views/account.html',
        controller: 'AccountController'
    });
    // New Order
    $routeProvider.when('/newOrder', {
        templateUrl: ENV.APP_DIR + 'partials/_parcel_widget.html',
        controller: 'AccountController'
    });

    // Basket
    $routeProvider.when('/basket', {
        templateUrl: ENV.APP_DIR + 'views/basket.html',
        controller: 'BasketController'
    });

    // Terms
    $routeProvider.when('/terms', {
        templateUrl: ENV.APP_DIR + 'views/termsandconditions.html',
        controller: 'TermsController'
    });

    // Privacy Policy
    $routeProvider.when('/terms/privacy', {
        templateUrl: ENV.APP_DIR + 'views/termsandconditions.html',
        controller: 'PrivacyController'
    });

    // Prohibited goods
    $routeProvider.when('/packing/prohibitedgoods', {
        templateUrl: ENV.APP_DIR + 'views/package_guidelines.html',
        controller: 'ProhibitedGoodsController'
    });
    // Prohibited goods
    $routeProvider.when('/packing/itemsNotInsured', {
        templateUrl: ENV.APP_DIR + 'views/package_guidelines.html',
        controller: 'ItemsNotInsuredController'
    });

    // Privacy Policy
    $routeProvider.when('/packing', {
        templateUrl: ENV.APP_DIR + 'views/package_guidelines.html',
        controller: 'PackageGuideController'
    });

    // ecommerceAPI
    $routeProvider.when('/eCommerce', {
        templateUrl: ENV.APP_DIR + 'views/ecommerce_api.html',
        controller: 'EcommerceApiController'
    });

    // ecommerceAPI documentation
    $routeProvider.when('/ecommerce-developer', {
        templateUrl: ENV.APP_DIR + 'views/ecommerce_documentation.html',
        controller: 'EcommerceApiController'
    });

    // ecommerceAPI documentation
    $routeProvider.when('/documentation/api', {
        templateUrl: ENV.APP_DIR + 'views/ecomerce_version1.html',
        controller: 'EcommerceApiController'
    });

    // cost comparison
    $routeProvider.when('/ecommerce/cost-comparison', {
        templateUrl: ENV.APP_DIR + 'views/ecomerce_version1.html',
        controller: 'EcommerceCostController'
    });


    // list address
    $routeProvider.when('/ecommerce/list-address', {
        templateUrl: ENV.APP_DIR + 'views/ecomerce_version1.html',
        controller: 'EcommerceListAddressController'
    });

    // bookingConfirmation
    $routeProvider.when('/ecommerce/booking-confrimation', {
        templateUrl: ENV.APP_DIR + 'views/ecomerce_version1.html',
        controller: 'EcommerceBookingController'
    });

    // generate pdf
    $routeProvider.when('/ecommerce/waybill-PDF', {
        templateUrl: ENV.APP_DIR + 'views/ecomerce_version1.html',
        controller: 'EcommerceGenerateController'
    });

    // trackWaybill pdf
    $routeProvider.when('/ecommerce/track-waybill', {
        templateUrl: ENV.APP_DIR + 'views/ecomerce_version1.html',
        controller: 'EcommerceTrackWaybillController'
    });

    // vaild address
    $routeProvider.when('/ecommerce/valid-address', {
        templateUrl: ENV.APP_DIR + 'views/ecomerce_version1.html',
        controller: 'EcommerceValidAddressController'
    });

    // new vaild address
    $routeProvider.when('/ecommerce/new-valid-address', {
        templateUrl: ENV.APP_DIR + 'views/ecomerce_version1.html',
        controller: 'EcommerceNewValidAddressController'
    });

    // min weight 2kg
    $routeProvider.when('/ecommerce/minimum-weight', {
        templateUrl: ENV.APP_DIR + 'views/ecomerce_version1.html',
        controller: 'EcommerceWeightController'
    });




    // Mobile
    $routeProvider.when('/mobile', {
        templateUrl: ENV.APP_DIR + 'views/mobile.html',
        controller: 'MobileController'
    });

    // Business
    $routeProvider.when('/business', {
        templateUrl: ENV.APP_DIR + 'views/business.html',
        controller: 'BusinessController'
    });

    // Amrod
    $routeProvider.when('/amrod/landingpage/:username', {
        templateUrl: ENV.APP_DIR + 'views/amrod_landing_page.html',
        controller: 'AmrodController'
    });

    $routeProvider.when('/amrod/landingpage', {
        templateUrl: ENV.APP_DIR + 'views/amrod_landing_page.html',
        controller: 'AmrodController'
    });

    $routeProvider.when('/amrod/confirmAddress', {
        templateUrl: ENV.APP_DIR + 'views/_amrod_rushit.html'
        // controller: 'ActiveOrderController'
    });

    // TOGSA
    $routeProvider.when('/togsa/landingpage/:username', {
        templateUrl: ENV.APP_DIR + 'views/togsa_landing_page.html',
        controller: 'TradeOnlyGiftController'
    });

    $routeProvider.when('/togsa/landingpage', {
        templateUrl: ENV.APP_DIR + 'views/togsa_landing_page.html',
        controller: 'TradeOnlyGiftController'
    });

    $routeProvider.when('/togsa/confirmAddress', {
        templateUrl: ENV.APP_DIR + 'views/_togsa_rushit.html'
    });
    $routeProvider.when('/payment/completeOrder', {
        templateUrl: ENV.APP_DIR + 'views/completeOrder.html'
    });

    //videos

    $routeProvider.when('/add_address_demo', {
        templateUrl: ENV.APP_DIR + 'views/add_address_book.html'
    });

    $routeProvider.when('/togsa_order_demo', {
        templateUrl: ENV.APP_DIR + 'views/togsa_order_video.html'
    });

    $routeProvider.when('/wallet_demo', {
        templateUrl: ENV.APP_DIR + 'views/credit_wallet.html'
    });

    $routeProvider.when('/login_demo', {
        templateUrl: ENV.APP_DIR + 'views/rush_login.html'
    });

    $routeProvider.when('/editprofile_demo', {
        templateUrl: ENV.APP_DIR + 'views/edit_profile.html'
    });

    $routeProvider.when('/creditcard_payment_demo', {
        templateUrl: ENV.APP_DIR + 'views/order_credit_card.html'
    });

    $routeProvider.when('/payment_wallet_demo', {
        templateUrl: ENV.APP_DIR + 'views/order_wallet.html'
    });

    $routeProvider.when('/registration_demo', {
        templateUrl: ENV.APP_DIR + 'views/registration_video.html'
    });

    $routeProvider.when('/rate_courier_demo', {
        templateUrl: ENV.APP_DIR + 'views/rate_courier.html'
    });

    $routeProvider.when('/amrod_register_login', {
        templateUrl: ENV.APP_DIR + 'views/rate_courier.html'
    });

    $routeProvider.when('/rush_amrod_order', {
        templateUrl: ENV.APP_DIR + 'views/rate_courier.html'
    });

    $routeProvider.when('/how_to_rush_order', {
        templateUrl: ENV.APP_DIR + 'views/how_to_rush.html'
    });

    $routeProvider.when('/trackings', {
        templateUrl: ENV.APP_DIR + 'views/trackings.html'
    });

    $routeProvider.when('/payment_eft', {
        templateUrl: ENV.APP_DIR + 'views/payment_eft.html'
    });

    //video page with link
    $routeProvider.when('/link_video', {
        templateUrl: ENV.APP_DIR + 'views/link_video.html'
    });

    //test page
    $routeProvider.when('/test_video', {
        templateUrl: ENV.APP_DIR + 'views/test_video.html'
    });

    // Vodacom landing Page
    $routeProvider.when('/register/:vodacom', {
        templateUrl: ENV.APP_DIR + 'views/register.html',
        controller: 'BusinessRegisterController'
    });

    // Vodacom landing Page
    $routeProvider.when('/Vodacom', {
        templateUrl: ENV.APP_DIR + 'views/register.html',
        controller: 'BusinessRegisterController'
    });
// Vodacom landing Page
    $routeProvider.when('/vodacom', {
        templateUrl: ENV.APP_DIR + 'views/register.html',
        controller: 'BusinessRegisterController'
    });

    // cbrs landing Page
    $routeProvider.when('/register/:cbre', {
        templateUrl: ENV.APP_DIR + 'views/register.html',
        controller: 'BusinessRegisterController'
    });

    // cbrs landing Page
    $routeProvider.when('/cbre', {
        templateUrl: ENV.APP_DIR + 'views/register.html',
        controller: 'BusinessRegisterController'
    });

    // 404 Error
    $routeProvider.when('/error404', {
        templateUrl: ENV.APP_DIR + 'views/error404.html'
    });

    // Home Page
    // $routeProvider.when('/:root', {
    // 	templateUrl: function(urlattr){
    //                return ENV.APP_DIR + 'views/' + urlattr.root + '.html';
    //            },
    // 	controller: 'HomeController'
    // });

    $routeProvider.otherwise({redirectTo: '/error404'});

}]);

/**
 * Find a key for object
 *
 * @param  none
 * @return key
 */
app.filter('getKey', function () {
    return function (input, value) {
        var i = 0, len = input.length;
        for (; i < len; i++) {
            if (input[i].value == value) {
                return i;
            }
        }
        return null;
    };
});

/**
 * Html to plain text
 *
 * @param  html
 * @return text
 */
app.filter('htmlToPlaintext', function () {
    return function (text) {
        return String(text).replace(/<[^>]+>/gm, '');
    };
});

/**
 * Replace comma with break tag
 *
 * @param string
 * @return html
 */
app.filter('replaceCommaBreak', function () {
    return function (html) {
        return String(html).replace(/,/g, '<br/>');
    };
});
/**
 * Trust Filter
 *
 * @param value, type
 * @return value
 */app.filter('trust', ['$sce', function ($sce) {
    return function (value, type) {
        return $sce.trustAs(type || 'html', value);
    };
}]);

/**
 * Raw HTML
 * @param  html
 * @return Raw HTML
 */
app.filter('rawHtml', ['$sce', function ($sce) {
    return function (val) {
        return $sce.trustAsHtml(val);
    };
}]);

/**
 * Add Insurance to total
 *
 * @param  total
 * @param  insurance
 * @return sum
 */
app.filter('addInsurance', function () {
    return function (total, insurance) {
        total = total + "";
        return parseFloat(total.replace(",", "")) + parseFloat(insurance);
    };
});

/**
 * Remove loyalty difference from total
 *
 * @param  total
 * @param  difference
 * @return sum
 */
app.filter('removeLoyaltyDifference', function () {
    return function (total, difference) {
        total = total + "";
        return parseFloat(total.replace(",", "")) - parseFloat(difference);
    };
});

/**
 * Unique filter for compare page
 * @param  keyname
 * @return output
 */
app.filter('unique', function () {
    return function (collection, keyname) {
        var output = [],
            keysExpress = [],
            keysSameday = [],
            keysOvernight = [],
            keysBudget = [];

        angular.forEach(collection, function (item) {
            var key = item[keyname];
            if ((keysExpress.indexOf(key) === -1) && (item['filterType'] === 'pronto')) {
                keysExpress.push(key);
                output.push(item);
            } else if ((keysSameday.indexOf(key) === -1) && (item['filterType'] === 'sameday')) {
                keysSameday.push(key);
                output.push(item);
            } else if ((keysOvernight.indexOf(key) === -1) && (item['filterType'] === 'overnight')) {
                keysOvernight.push(key);
                output.push(item);
            } else if ((keysBudget.indexOf(key) === -1) && (item['filterType'] === 'budget')) {
                keysBudget.push(key);
                output.push(item);
            }
        });

        return output;
    };
});

/**
 * Application Controller
 * The global controller which runs always through out the application.
 *
 * @param  $scope
 * @param  $rootscope
 * @param  ApiFactory
 * @param  $cookieStore
 * @param  ENV
 * @return void
 */
app.controller('AppController', ['$scope', 'ApiFactory', '$cookieStore', 'ENV', '$location', '$modal', 'GlobalFactory', '$rootScope', '$window', '$routeParams', '$anchorScroll', '$timeout', function ($scope, ApiFactory, $cookieStore, ENV, $location, $modal, GlobalFactory, $rootScope, $window, $routeParams, $anchorScroll, $timeout) {

    $scope.bootStrapDataTablesUrl = ENV.PUBLIC_DIR + "css/dataTables.bootstrap.css";
    $scope.hoverIn = function () {
        // this.hoverEdit = true;
        $('.business-offerings-menu').fadeIn();
    };

    $scope.hoverOut = function () {
        // this.hoverEdit = false;
        $('.business-offerings-menu').fadeOut();
    };
    //$rootScope.modelName= true;
    //$rootScope.bannerClass = 'bannerShow';
    //console.log($rootScope.bannerClass);
    $scope.format = 'H:mm';
    $scope.currentTime = {};

    $scope.stylesheets = [
        {href: ENV.PUBLIC_DIR + 'css/custom.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/notificationsbar.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/nprogress.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/angular-slider.min.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/bootstrap-slider.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/slider-pro.min.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/slick.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/socialIcons.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/flexslider.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/angular-block-ui.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/demo.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/owl.carousel.min.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/owl.theme.min.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/owl.transitions.min.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/ngDialog.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/ngDialog-custom-width.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/ngDialog-theme-default.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/ngDialog-theme-plain.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/dataTables.bootstrap.css', type: 'text/css', rel: 'stylesheet'},
        {href: ENV.PUBLIC_DIR + 'css/Fr.star.css', type: 'text/css', rel: 'stylesheet'}
    ];

    $rootScope.$on('$routeChangeSuccess', function () {
        var interval = setInterval(function () {
            if (document.readyState == 'complete') {
                $window.scrollTo(0, 0);
                clearInterval(interval);
            }
        }, 200);
    });

    $scope.pubicDir = ENV.PUBLIC_DIR;
    $scope.appDir = ENV.APP_DIR;
    $scope.quoteActive = ENV.QUOTE_ACTIVE;

    $scope.suburbsWithCities = {};

    ApiFactory.init();
    // console.log($cookieStore.get('token'));
    $scope.currentUser = null;
    $scope.isCollapsed = true;

    if ($cookieStore.get('token') !== undefined) {
        // console.log($cookieStore.get('user'));
        $scope.currentUser = $cookieStore.get('user');
    }

    if ($location.hash() == 'login') {
        $modal.open({
            size: '',
            templateUrl: ENV.APP_DIR + 'partials/_login.html',
            controller: 'ModalInstanceController'
        });
    }

    if ($location.hash() == 'register') {
        $modal.open({
            size: 'lg',
            templateUrl: ENV.APP_DIR + 'partials/_register.html',
            controller: 'ModalInstanceController'
        });
    }

    $scope.gotoContactUs = function () {
        $('html, body').animate({scrollTop: $('#Contactus').offset().top}, 1000);
    };

    // Get Suburbs and save to scope variable.
    GlobalFactory.getSuburbs().success(function (data) {
        // console.log(data);
        $scope.suburbsWithCities = data;
    }).error(function () {
        // console.log('error');
    });

    // Get Suburbs and save to scope variable.
    GlobalFactory.getDetailedSuburbs().success(function (data) {
        // console.log(data);
        $scope.detailedSuburbsWithCities = data;
    }).error(function () {
        // console.log('error');
    });

    $scope.startsWith = function (expected, actual) {
        return expected.toUpperCase().indexOf(actual.toUpperCase()) === 0;
    };

    if ($cookieStore.get('token') !== undefined) {
        // console.log($cookieStore.get('user'));
        $scope.currentUser = $cookieStore.get('user');
    } else {

        // if($location.path().indexOf('/login') == -1 && $location.path().indexOf('/register') == -1 && $location.path().indexOf('/resetpassword') == -1){
        // 	$location.path('/login');
        // }
    }
}]);
